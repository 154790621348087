import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import Loader from "react-loader";
import httpBrowsing_frontend from "../../Utilities/httpBrowsing_frontend";
import Table_Indicator from "../../Frontend.content/Sub-modules/Table";

import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";

import {
  Button,
  Modal,
  Form,
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Table,
} from "react-bootstrap";

import notification from "./../../Utilities/Notification";
import Map from "../../Frontend.content/Sub-modules/Map";
import Frontend_Sidebar from "../../Page.components/Frontend_Sidebar";
import Frontend_Navbar from "../../Page.components/Frontend_Navbar";
import Table_Filter from "../../Frontend.Filters/Table-Filter";
import {
  Tost_Context,
  Indicator_title_Context,
  Indicator_title_eng_Context,
  Right_component_nepali_Context,
  Right_component_english_Context,
  Right_name_nepali_Context,
  Right_name_english_Context,
  Filter_clicked_Context,
  Download_Date_from_context,
  Download_Date_to_context,
  sidebar_Clicked_Context,
  Selected_fiscal_year_Context,
  Province_Context,
  District_Context,
  Municipality_Context,
  Filter_Second_Data_Context,
  Filter_First_Data_Context,
  Right_indicator_id_Context,
  Total_Data_Context,
  Second_Total_Data_Context,
  Compare_Graph_Context,
  Loading_Context,
  Change_Language_Context,
  Indicator_Data_Context,
  Paginate_Data_Context,
  page_size_Context,
  currentPage_Context,
  Table_Loader_Context,
  Edit_Data_Context,
  Add_Data_Context,
  Loading_Language_Context,
  Item_delete_check_Context,
  province1_Context,
  province2_Context,
  bagmati_Context,
  gandaki_Context,
  province5_Context,
  karnali_Context,
  sudurpaschim_Context,
  Edited_Context,
  Prov1_Context,
  Prov2_Context,
  Prov3_Context,
  Prov4_Context,
  Prov5_Context,
  Prov6_Context,
  Prov7_Context,
  Municipality_Data_Context,
  Fiscal_Year_Context,
  Filter_Data_context,
  Table_Header_Context,
  Province1_total_Context,
  Province2_total_Context,
  Province3_total_Context,
  Province4_total_Context,
  Province5_total_Context,
  Province6_total_Context,
  Province7_total_Context,
  Legend_array_Context,
  Load_map_Context,
  Selected_first_Province_Context,
} from "../../Utilities/Store";
import Graph from "../../Frontend.content/Sub-modules/Chart";
import Graph_Filter from "../../Frontend.Filters/Graph-Filter";
import Map_Filter from "../../Frontend.Filters/Map-filter";
const Explore = (props) => {
  const [Load_map, setLoad_map] = useContext(Load_map_Context);
  const [Municipality_Data, setMunicipality_Data] = useContext(
    Municipality_Data_Context
  );
  const [Selected_first_Province, setSelected_first_Province] = useContext(
    Selected_first_Province_Context
  );
  const [Filter_Data, setFilter_Data] = useContext(Filter_Data_context);
  const [Fiscal_Year, setFiscal_Year] = useContext(Fiscal_Year_Context);
  const [province1, setProvince1] = useContext(province1_Context);
  const [province2, setProvince2] = useContext(province2_Context);
  const [bagmati, setBagmati] = useContext(bagmati_Context);
  const [gandaki, setGandaki] = useContext(gandaki_Context);
  const [province5, setProvince5] = useContext(province5_Context);
  const [karnali, setKarnali] = useContext(karnali_Context);
  const [sudurpaschim, setSudurpaschim] = useContext(sudurpaschim_Context);
  const [Edited, setEdited] = useContext(Edited_Context);
  const [Loading_Language, setLoading_Language] = useContext(
    Loading_Language_Context
  ); //used to disable language switch untill response is obtained
  const [Table_Loader, setTable_Loader] = useContext(Table_Loader_Context);
  const [Indicator_Data, setIndicator_Data] = useContext(
    Indicator_Data_Context
  );
  const [Item_delete_check, setItem_delete_check] = useContext(
    Item_delete_check_Context
  );
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Edit_Data, SetEdit_Data] = useContext(Edit_Data_Context); //when set true a model displays to edit the data
  const [Add_Data, setAdd_Data] = useContext(Add_Data_Context); //when set true a model displays to give entry on data entry form
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title
  const [Paginate_Data, setPaginate_Data] = useContext(Paginate_Data_Context);
  const [Total_Data, setTotal_Data] = useContext(Total_Data_Context);
  const [Tost, setTost] = useContext(Tost_Context); //used to store boolean value during language switch
  const [Sidebar_Data, setSidebar_Data] = useState([]); //all fetched sidebar data is stored here
  // const [Test, setTest] = useState(false); //testing the function on click
  const [Province, setProvince] = useContext(Province_Context);
  const [District, setDistrict] = useContext(District_Context);
  const [Municipality, setMunicipality] = useContext(Municipality_Context);
  const [Loading_Display, setLoading_Display] = useState(true);
  const [Loading, setLoading] = useContext(Loading_Context);
  const [RightID, setRightID] = useState("");
  const [page_size, setPage_size] = useContext(page_size_Context);
  const [currentPage, setCurrentPage] = useContext(currentPage_Context);
  const [sidebar_Clicked, setSidebar_Clicked] = useContext(
    sidebar_Clicked_Context
  ); //first level sidebar clicked
  const [Right_component_id, setRight_component_id] = useState("");

  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );

  const [Compare, setCompare] = useState(false);

  const [Right_name, setRight_name] = useState("");
  const [Filter_clicked, setFilter_clicked] = useContext(
    Filter_clicked_Context
  ); //is set true when filter button is clicked
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Second_Total_Data, setSecond_Total_Data] = useContext(
    Second_Total_Data_Context
  ); //stores the comparison second entered data
  const [Compare_Graph, setCompare_Graph] = useContext(Compare_Graph_Context); //Is set true when user click  second buttom to compare the graph
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [Filter_First_Data, setFilter_First_Data] = useContext(
    Filter_First_Data_Context
  );
  const [Filter_Second_Data, setFilter_Second_Data] = useContext(
    Filter_Second_Data_Context
  );
  const [Table_Header, setTable_Header] = useContext(Table_Header_Context); //used to store header of the table
  const [Change_Language, setChange_Language] = useContext(
    Change_Language_Context
  ); //used to change the toggle button for language
  const [Prov1, setProv1] = useContext(Prov1_Context);
  const [Prov2, setProv2] = useContext(Prov2_Context);
  const [Prov3, setProv3] = useContext(Prov3_Context);
  const [Prov4, setProv4] = useContext(Prov4_Context);
  const [Prov5, setProv5] = useContext(Prov5_Context);
  const [Prov6, setProv6] = useContext(Prov6_Context);
  const [Prov7, setProv7] = useContext(Prov7_Context);
  const [Province1_total, setProvince1_total] = useContext(
    Province1_total_Context
  );
  const [Province2_total, setProvince2_total] = useContext(
    Province2_total_Context
  );
  const [Province3_total, setProvince3_total] = useContext(
    Province3_total_Context
  );
  const [Province4_total, setProvince4_total] = useContext(
    Province4_total_Context
  );
  const [Province5_total, setProvince5_total] = useContext(
    Province5_total_Context
  );
  const [Province6_total, setProvince6_total] = useContext(
    Province6_total_Context
  );
  const [Province7_total, setProvince7_total] = useContext(
    Province7_total_Context
  );
  const [Legend_array, setLegend_array] = useContext(Legend_array_Context);
  const [Select_Data_type, setSelect_Data_type] = useState(
    `${Tost ? "MAP" : "नक्सा"}`
  ); //used to store selected data type table,chart or map
  const [Select_Data_type_image, setSelect_Data_type_image] = useState(
    "/metro-earth.svg"
  ); //used to store image of selected data type table,chart or map
  const handleRight = (id, name) => {
    setRightID(id);
    setRight_name(name);
    // setTest(true);
  };
  const [Previous_Page_No, setPrevious_Page_No] = useState(0); //used to store initial page number
  let Selecting_First_District = District.filter(
    (item, i) => item.province === Number(Filter_First_Data.province)
  );
  let Selecting_First_Municipality = Municipality.filter(
    (item) => item.district === Number(Filter_First_Data.district)
  );
  let Selecting_Second_District = District.filter(
    (item, i) => item.province === Number(Filter_Second_Data.province)
  );
  let Selecting_Second_Municipality = Municipality.filter(
    (item) => item.district === Number(Filter_Second_Data.district)
  );

  let Right_components = [];
  Right_components = Sidebar_Data.filter((item, i) => item.id === RightID).map(
    (item) => item.component
  );
  let Indicators = [];
  if (Right_components.length) {
    Indicators = Right_components[0]
      .filter((item, i) => item.id === Right_component_id)
      .map((item) => item.indicator);
  }
  const handleChangeFirstProvince_Compare = (id, name) => {
    setFilter_First_Data({
      ...Filter_First_Data,
      province: id,
      district: "",
      municipality: "",
      province_name: name,
      district_name: "",
      municipality_name: "",
    });
  };

  const handleChangeFirstDistrict_Compare = (id, name) => {
    setFilter_First_Data({
      ...Filter_First_Data,
      district: id,
      municipality: "",
      district_name: name,
      municipality_name: "",
    });
  };
  const handleChangeFirstMunicipality_Compare = (id, name) => {
    setFilter_First_Data({
      ...Filter_First_Data,
      municipality: id,
      municipality_name: name,
    });
  };
  const handleChangeSecondProvince_Compare = (id, name) => {
    setFilter_Second_Data({
      ...Filter_Second_Data,
      province: id,
      district: "",
      municipality: "",
      province_name: name,
      district_name: "",
      municipality_name: "",
    });
  };

  const handleChangeSecondDistrict_Compare = (id, name) => {
    setFilter_Second_Data({
      ...Filter_Second_Data,
      district: id,
      municipality: "",
      district_name: name,
      municipality_name: "",
    });
  };
  const handleChangeSecondMunicipality_Compare = (id, name) => {
    setFilter_Second_Data({
      ...Filter_Second_Data,
      municipality: id,
      municipality_name: name,
    });
  };
  const changeCurrentPage = (numPage) => {
    setTable_Loader(true);
    setCurrentPage(numPage);

    setPrevious_Page_No(currentPage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    httpBrowsing_frontend
      .get(
        `/frontend/data/graph/monthly/${Right_indicator_id}&province=${Filter_First_Data.province}&district=${Filter_First_Data.district}&municipality=${Filter_First_Data.municipality}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
      )
      .then((res) => {
        setTotal_Data(res.data.data);
        setCompare(false);
      })
      // setShow(false);
      // setFilter_Data({
      //   province: "",
      //   district: "",
      //   municipality: "",
      // });

      .catch((err) => {
        console.log("what is error>>>", err.response);

        // setFilter_Data({
        //   province: "",
        //   district: "",
        //   municipality: "",
        // });
      });
  };
  const handlePageSize = (e) => {
    if (Paginate_Data.next === null) {
      setPage_size(page_size);
    } else {
      setPage_size(e.target.value);
    }

    setTable_Loader(true);
    httpBrowsing_frontend
      .get(
        `/frontend/data/${
          Tost ? "eng" : "nep"
        }/?indicator=${Right_indicator_id}&page=${currentPage}&page_size=${page_size}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}&province=${
          Filter_Data.province
        }&district=${Filter_Data.district}&municipality=${
          Filter_Data.municipality
        }`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setIndicator_title(res.data.indicator_title_ne);
        setIndicator_title_eng(res.data.indicator_title_en);
        setPaginate_Data(res.data);
        if (window.screen.width < 990) {
          setSidebar_Clicked(false);
        }

        setLoading(false);
        setTable_Loader(false);
        setLoading_Language(false);
        setRight_name_english(res.data.right_en);
        setRight_name_nepali(res.data.right_ne);
        setRight_component_english(res.data.right_component_en);
        setRight_component_nepali(res.data.right_component_ne);
      })
      .catch((err) => {
        console.log("This is final error>>>", err.response);
      });
  };

  useEffect(() => {
    const Fiscal_year = () => {
      if (Right_indicator_id) {
        httpBrowsing_frontend
          .get(
            `/frontend/indicators/${Right_indicator_id}/get-fields/${
              Tost ? "eng" : "nep"
            }/`
          )
          .then((res) => {
            delete res.data.created_by;
            delete res.data.last_updated;
            delete res.data.operations;
            delete res.data.updated_by;
            setTable_Header(res.data);
          })
          .catch((err) => {
            console.log("this is error>>>", err.response);
          });
      }
    };
    Fiscal_year();
  }, [Tost, Right_indicator_id]);
  useEffect(() => {
    setTable_Loader(true);
    httpBrowsing_frontend
      .get(
        `/frontend/data/${
          Tost ? "eng" : "nep"
        }/?indicator=${Right_indicator_id}&page=${currentPage}&page_size=${page_size}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}&province=${
          Filter_Data.province
        }&district=${Filter_Data.district}&municipality=${
          Filter_Data.municipality
        }`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setIndicator_title(res.data.indicator_title_ne);
        setIndicator_title_eng(res.data.indicator_title_en);
        setPaginate_Data(res.data);
        if (window.screen.width < 990) {
          setSidebar_Clicked(false);
        }

        setLoading(false);
        setTable_Loader(false);
        setLoading_Language(false);
        setRight_name_english(res.data.right_en);
        setRight_name_nepali(res.data.right_ne);
        setRight_component_english(res.data.right_component_en);
        setRight_component_nepali(res.data.right_component_ne);
      })
      .catch((err) => {
        console.log("This is final error>>>", err.response);
      });
  }, [
    Tost,

    currentPage,
    Right_indicator_id,
    Filter_Data.province,
    Filter_Data.district,
    Filter_Data.municipality,
    Edited,
    Item_delete_check,
    Select_Data_type,
  ]);
 
  useEffect(() => {
    setLoad_map(true);
    httpBrowsing_frontend
      .get(
        `/frontend/municipality/data/?indicator=${Right_indicator_id}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}&Province=${Selected_first_Province.prov_id}`
      )
      .then((res) => {
        let Province_total_array = [];

        let Legend_array = [];
        setProvince1_total(res.data[0].total);
        setProvince2_total(res.data[1].total);
        setProvince3_total(res.data[2].total);
        setProvince4_total(res.data[3].total);
        setProvince5_total(res.data[4].total);
        setProvince6_total(res.data[5].total);
        setProvince7_total(res.data[6].total);

        Province_total_array.push(res.data[0].total);
        Province_total_array.push(res.data[1].total);
        Province_total_array.push(res.data[2].total);
        Province_total_array.push(res.data[3].total);
        Province_total_array.push(res.data[4].total);
        Province_total_array.push(res.data[5].total);
        Province_total_array.push(res.data[6].total);
        Province_total_array.sort((a, b) => b - a);

        Legend_array = [
          0,
          Math.round(0 + Province_total_array[0] / 5),
          Math.round((0 + Province_total_array[0] / 5) * 2),
          Math.round((0 + Province_total_array[0] / 5) * 3),
          Math.round(Province_total_array[0]),
        ];
        setLegend_array(Legend_array);
        setLoad_map(false);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });

    // httpBrowsing_frontend
    //   .get(
    //     `/frontend/municipality/data/?indicator=${Right_indicator_id}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
    //   )
    //   .then((res) => {
    //     setMunicipality_Data(res.data.filter((item) => item.code !== null));
    //   })
    //   .catch((err) => {
    //     console.log("This is error>>>", err.response);
    //   });
    // httpBrowsing_frontend
    //   .get(
    //     `/frontend/municipality/data/?indicator=${Right_indicator_id}&province=1&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
    //   )
    //   .then((res) => {
    //     setProv1(res.data.map((item) => item.total));

    //     setProvince1(res.data.filter((item) => item.code !== null));
    //   })
    //   .catch((err) => {
    //     console.log("This is error>>>", err.response);
    //   });
    // httpBrowsing_frontend
    //   .get(
    //     `/frontend/municipality/data/?indicator=${Right_indicator_id}&province=2&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
    //   )
    //   .then((res) => {
    //     setProv2(res.data.map((item) => item.total));
    //     setProvince2(res.data.filter((item) => item.code !== null));
    //   })
    //   .catch((err) => {
    //     console.log("This is error>>>", err.response);
    //   });
    // httpBrowsing_frontend
    //   .get(
    //     `/frontend/municipality/data/?indicator=${Right_indicator_id}&province=3&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
    //   )
    //   .then((res) => {
    //     setProv3(res.data.map((item) => item.total));
    //     setBagmati(res.data.filter((item) => item.code !== null));
    //   })
    //   .catch((err) => {
    //     console.log("This is error>>>", err.response);
    //   });
    // httpBrowsing_frontend
    //   .get(
    //     `/frontend/municipality/data/?indicator=${Right_indicator_id}&province=4&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
    //   )
    //   .then((res) => {
    //     setProv4(res.data.map((item) => item.total));
    //     setGandaki(res.data.filter((item) => item.code !== null));
    //   })
    //   .catch((err) => {
    //     console.log("This is error>>>", err.response);
    //   });
    // httpBrowsing_frontend
    //   .get(
    //     `/frontend/municipality/data/?indicator=${Right_indicator_id}&province=5&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
    //   )
    //   .then((res) => {
    //     setProv5(res.data.map((item) => item.total));
    //     setProvince5(res.data.filter((item) => item.code !== null));
    //   })
    //   .catch((err) => {
    //     console.log("This is error>>>", err.response);
    //   });
    // httpBrowsing_frontend
    //   .get(
    //     `/frontend/municipality/data/?indicator=${Right_indicator_id}&province=6&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
    //   )
    //   .then((res) => {
    //     setProv6(res.data.map((item) => item.total));
    //     setKarnali(res.data.filter((item) => item.code !== null));
    //   })
    //   .catch((err) => {
    //     console.log("This is error>>>", err.response);
    //   });
    // httpBrowsing_frontend
    //   .get(
    //     `/frontend/municipality/data/?indicator=${Right_indicator_id}&province=7&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
    //   )
    //   .then((res) => {
    //     setProv7(res.data.map((item) => item.total));
    //     setSudurpaschim(res.data.filter((item) => item.code !== null));
    //   })
    //   .catch((err) => {
    //     console.log("This is error>>>", err.response);
    //   });
  }, [Right_indicator_id, Select_Data_type]);

  // useEffect(() => {
  //   let Province_total_array = [];
  //   let Province1_total = null;
  //   let res.data[0].total = null;
  //   let Province3_total = null;
  //   let Province4_total = null;
  //   let Province5_total = null;
  //   let Province6_total = null;
  //   let Province7_total = null;
  //   let Legend_array = [];
  //   if (Prov1) {
  //     Province1_total = Prov1.reduce(function (a, b) {
  //       return a + b;
  //     }, 0);
  //     setProvince1_total(Province1_total);
  //   }

  //   if (Prov2) {
  //     Province2_total = Prov2.reduce(function (a, b) {
  //       return a + b;
  //     }, 0);
  //     setProvince2_total(Province2_total);
  //   }

  //   if (Prov3) {
  //     Province3_total = Prov3.reduce(function (a, b) {
  //       return a + b;
  //     }, 0);
  //     setProvince3_total(Province3_total);
  //   }

  //   if (Prov4) {
  //     Province4_total = Prov4.reduce(function (a, b) {
  //       return a + b;
  //     }, 0);
  //     setProvince4_total(Province4_total);
  //   }

  //   if (Prov5) {
  //     Province5_total = Prov5.reduce(function (a, b) {
  //       return a + b;
  //     }, 0);
  //     setProvince5_total(Province5_total);
  //   }

  //   if (Prov6) {
  //     Province6_total = Prov6.reduce(function (a, b) {
  //       return a + b;
  //     }, 0);
  //     setProvince6_total(Province6_total);
  //   }

  //   if (Prov7) {
  //     Province7_total = Prov7.reduce(function (a, b) {
  //       return a + b;
  //     }, 0);
  //     setProvince7_total(Province7_total);
  //   }

  //   Province_total_array.push(Province1_total);
  //   Province_total_array.push(Province2_total);
  //   Province_total_array.push(Province3_total);
  //   Province_total_array.push(Province4_total);
  //   Province_total_array.push(Province5_total);
  //   Province_total_array.push(Province6_total);
  //   Province_total_array.push(Province7_total);
  //   Province_total_array.sort((a, b) => b - a);

  //   Legend_array = [
  //     0,
  //     Math.round(0 + Province_total_array[0] / 5),
  //     Math.round((0 + Province_total_array[0] / 5) * 2),
  //     Math.round((0 + Province_total_array[0] / 5) * 3),
  //     Math.round(Province_total_array[0]),
  //   ];
  //   setLegend_array(Legend_array);
  //   setLoad_map(false);
  // }, [Prov7]);

  useEffect(() => {
    httpBrowsing_frontend
      .get(`/frontend/get-years/${Tost ? "eng" : "nep"}/`)
      .then((res) => {
        setFiscal_Year(res.data);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
    setSelect_Data_type(`${Tost ? "MAP" : "नक्सा"}`);
  }, [Tost]);

  return (
    <React.Fragment>
      <Tester>
        <div
          className={sidebar_Clicked ? "d-flex toggled" : "d-flex"}
          id="wrapper"
        >
          {/* Sidebar */}

          {/* Page Content */}
          <div id="page-content-wrapper">
            <Frontend_Navbar data={props} />
            {Loading ? (
              <Loader />
            ) : (
              <div className="container-fluid main-content">
                <div className="d-flex  flex-row bd-highlight">
                  <div className=" bd-highlight">
                    <Frontend_Sidebar datal={props.history.location.state} />
                  </div>
                  <div
                    className="p-2 bd-highlight layer-content "
                    style={{ backgroundColor: "#f9fbfc", height: "100vh" }}
                  >
                    <div className="row contain ">
                      {Tost ? (
                        <Breadcrumb className="bread-crumb">
                          <Breadcrumb.Item>
                            {Right_name_english}
                          </Breadcrumb.Item>

                          <Breadcrumb.Item>
                            {Right_component_english}
                          </Breadcrumb.Item>

                          <Breadcrumb.Item>
                            {Indicator_title_eng}
                          </Breadcrumb.Item>
                        </Breadcrumb>
                      ) : (
                        <Breadcrumb className="bread-crumb">
                          <Breadcrumb.Item>{Right_name_nepali}</Breadcrumb.Item>

                          <Breadcrumb.Item>
                            {Right_component_nepali}
                          </Breadcrumb.Item>

                          <Breadcrumb.Item>{Indicator_title}</Breadcrumb.Item>
                        </Breadcrumb>
                      )}
                    </div>
                    <div className="row contain indicator-name-option">
                      {Tost ? (
                        <h1>{Indicator_title_eng}</h1>
                      ) : (
                        <h1>{Indicator_title}</h1>
                      )}

                      {Select_Data_type === "तालिका" ||
                      Select_Data_type === "नक्सा" ? (
                        <DropdownButton
                          id="dropdown-basic-button"
                          key={Select_Data_type_image}
                          className={
                            sidebar_Clicked
                              ? "view-data data-type-media-query"
                              : "view-data"
                          }
                          title={[
                            <img src={Select_Data_type_image} alt="table" />,
                            <span> {Select_Data_type}</span>,
                          ]}
                        >
                          {Select_Data_type === "तालिका" ? (
                            ""
                          ) : (
                            <Dropdown.Item
                              value="तालिका"
                              onClick={() => {
                                setSelect_Data_type("तालिका");
                                setSelect_Data_type_image("/awesome-table.svg");
                                // setSelected_fiscal_year("");
                                // setDownload_Date_from("");
                                // setDownload_Date_to("");
                                setFilter_clicked(false);
                              }}
                            >
                              <img src="/awesome-table.svg" alt="table" />
                              तालिका
                            </Dropdown.Item>
                          )}
                          {Select_Data_type === "नक्सा" ? (
                            ""
                          ) : (
                            <Dropdown.Item
                              value="नक्सा"
                              onClick={() => {
                                setSelect_Data_type("नक्सा");
                                setSelect_Data_type_image("/metro-earth.svg");
                                // setSelected_fiscal_year("");
                                // setDownload_Date_from("");
                                // setDownload_Date_to("");
                                setFilter_clicked(false);
                              }}
                            >
                              <img src="/metro-earth.svg" alt="नक्सा" />
                              नक्सा
                            </Dropdown.Item>
                          )}
                        </DropdownButton>
                      ) : Select_Data_type === "TABLE" ||
                        Select_Data_type === "MAP" ? (
                        <DropdownButton
                          id="dropdown-basic-button"
                          key={Select_Data_type_image}
                          className={
                            sidebar_Clicked
                              ? "view-data data-type-media-query"
                              : "view-data"
                          }
                          style={{ backgroundColor: "red !important" }}
                          title={[
                            <img src={Select_Data_type_image} alt="table" />,
                            <span> {Select_Data_type}</span>,
                          ]}
                        >
                          {Select_Data_type === "TABLE" ? (
                            ""
                          ) : (
                            <Dropdown.Item
                              value="TABLE"
                              onClick={() => {
                                setSelect_Data_type("TABLE");
                                setSelect_Data_type_image("/awesome-table.svg");
                                // setSelected_fiscal_year("");
                                // setDownload_Date_from("");
                                // setDownload_Date_to("");
                                setFilter_clicked(false);
                              }}
                            >
                              <img src="/awesome-table.svg" alt="table" />
                              TABLE
                            </Dropdown.Item>
                          )}
                          {Select_Data_type === "MAP" ? (
                            ""
                          ) : (
                            <Dropdown.Item
                              value="MAP"
                              onClick={() => {
                                setSelect_Data_type("MAP");
                                setSelect_Data_type_image("/metro-earth.svg");
                                // setSelected_fiscal_year("");
                                // setDownload_Date_from("");
                                // setDownload_Date_to("");
                                setFilter_clicked(false);
                              }}
                            >
                              <img src="/metro-earth.svg" alt="map" />
                              MAP
                            </Dropdown.Item>
                          )}
                        </DropdownButton>
                      ) : (
                        ""
                      )}
                    </div>

                    {Select_Data_type === "तालिका" ? <Table_Filter /> : ""}
                    {Select_Data_type === "TABLE" ? <Table_Filter /> : ""}
                    {Select_Data_type === "MAP" ? <Map_Filter /> : ""}
                    {Select_Data_type === "नक्सा" ? <Map_Filter /> : ""}
                    {Table_Loader ? (
                      <Loader />
                    ) : (
                      <>
                        <div
                          className={
                            sidebar_Clicked
                              ? "test-table"
                              : "test-table full-screen"
                          }
                          id="map-capture"
                        >
                          {Select_Data_type === "TABLE" ? (
                            <Table_Indicator />
                          ) : (
                            ""
                          )}
                          {Select_Data_type === "तालिका" ? (
                            <Table_Indicator />
                          ) : (
                            ""
                          )}

                          {Load_map ? (
                            <Loader />
                          ) : Select_Data_type === "नक्सा" ? (
                            <Map />
                          ) : (
                            ""
                          )}
                          {Load_map ? (
                            <Loader />
                          ) : Select_Data_type === "MAP" ? (
                            <Map />
                          ) : (
                            ""
                          )}
                        </div>
                        {Select_Data_type === "TABLE" ||
                        Select_Data_type === "तालिका" ? (
                          Indicator_Data.length === 0 ? (
                            ""
                          ) : Paginate_Data.count > 20 ? (
                            <div className="row" style={{ marginTop: "10px" }}>
                              <div className="pagination">
                                <form className="row-data">
                                  <label className="show" htmlFor="Show">
                                    <b>Show Data:</b>
                                  </label>
                                  <select
                                    value={page_size}
                                    onChange={handlePageSize}
                                  >
                                    <option value="20">20</option>
                                    <option value="40">40</option>
                                    <option value="60">60</option>
                                    <option value="80">80</option>
                                    <option value="100">100</option>
                                  </select>
                                </form>
                              </div>
                              <div className="page">
                                <Pagination
                                  currentPage={currentPage}
                                  totalSize={Paginate_Data.count}
                                  sizePerPage={page_size}
                                  changeCurrentPage={changeCurrentPage}
                                  theme="bootstrap"
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* /#page-content-wrapper */}
        </div>
      </Tester>
    </React.Fragment>
  );
};
const Tester = styled.div`
  .main-content {
    height: 100vh;
  }
  .dropdown-item {
    display: flex !important;
    align-items: center !important;
  }
  .view-data button {
    display: flex !important;
    align-items: center !important;
  }
  @media only screen and (max-width: 700px) {
    .indicator-name-option {
      display: flex;
      flex-direction: column;
    }
    .view-data {
      margin-left: 0px !important;
    }
  }

  .layer-content {
    width: 100%;
  }
  @media only screen and (max-width: 500px) {
    .right-container {
      height: 60px;
      width: 40px;
    }
    .right-image {
      width: 60px;
      height: 60px;
    }
    .fa {
      height: 40px !important;
      width: 40px !important;
    }
    .indicator-name-option {
      display: flex;
      flex-direction: column;
      margin-bottom: 0px !important;
    }
    .layer-content {
      width: 88%;
    }
    .contain h1 {
      font-size: 16px !important;
      margin-bottom: 15px;
    }
    .view-data {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
    .view-data button {
      font-size: 12px !important;
      display: flex !important;
      align-items: center !important;
    }
    .view-data button img {
      height: 15px !important;
    }
    .breadcrumb-item {
      font-size: 12px !important;
    }
  }

  .view-data img {
    margin-right: 10px;
    height: 18px;
  }

  .view-data {
    margin-left: auto;
    margin-right: 80px;
  }
  .view-data button {
    background-color: #ffffff;
    color: #405467;
    opacity: 1;
    border-radius: 10px;
    border: 2px solid #405467;
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #ffffff;
    color: #405467;
    opacity: 1;
    border-radius: 10px;
  }
  .breadcrumb {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .bread-crumb {
    width: fit-content;
  }
  .bread-crumb a {
    color: #333333;
    opacity: 0.61;
    text-decoration: none;
  }

  #page-content-wrapper {
    width: 100%;
  }
  .main-content {
    padding: 0;
  }
  .contain {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .contain h1 {
    color: #405467;
    font-size: 30px;
    font-weight: bold;
  }

  .fa {
    font-size: 30px !important;
    cursor: pointer !important;
    box-shadow: 5px 10px 18px #888888;
    border-radius: 10%;
    font-size: 15px !important;
    padding: 15px;
    display: flex;
    justify-content: center;
    height: 60px;
    width: 60px;
    align-items: center;
  }

  .secondary-sidebar {
    width: 200px;
    padding-top: 30px;
  }
  .secondary-sidebar h5 {
    margin-left: 15px;
  }

  .sidebar-1-design {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .sidebar-1-design .right-image {
    display: flex;
    width: 80px;
    justify-content: center;
  }

  #sidebar-wrapper {
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }
  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  #sidebar-wrapper .list-group2 {
    width: 0px !important;
  }
  #page-content-wrapper {
    min-width: 100vw;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }
`;

export default Explore;
