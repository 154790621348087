import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import styled from "styled-components";
import notification from "../../Utilities/Notification";
import httpBrowsing_frontend from "../../Utilities/httpBrowsing_frontend";
import { useLocalStorage } from "../../Utilities/Localstorage";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { englishToNepaliNumber, nepaliToEnglishNumber } from "nepali-number";
import {
  Tost_Context,
  RightClicked_Context,
  RightComponentClicked_Context,
  Select_Language_Context,
  Loading_Language_Context,
  Loading_Context,
  Load_on_Lan_Change_Context,
  RightID_Context,
  Right_component_id_Context,
  Right_indicator_id_Context,
  Right_component_nepali_Context,
  Indicator_title_Context,
  Indicator_title_eng_Context,
  Right_component_english_Context,
  Right_name_nepali_Context,
  Right_name_english_Context,
  Add_Data_Context,
  Edit_Data_Context,
  Indicator_Data_Context,
  Paginate_Data_Context,
  page_size_Context,
  currentPage_Context,
  Download_Date_to_context,
  Download_Date_from_context,
  Change_Language_Context,
  Table_Loader_Context,
  Item_delete_check_Context,
  Edited_Context,
  Table_Header_Context,
} from "../../Utilities/Store";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

const Table_Indicator = (props) => {
  const [Edited, setEdited] = useContext(Edited_Context);
  const [Tost, setTost] = useContext(Tost_Context); //used to store boolean value during language switch
  const [Item_delete_check, setItem_delete_check] = useContext(
    Item_delete_check_Context
  );
  const [Table_Header, setTable_Header] = useContext(Table_Header_Context); //used to store header of the table
  const [Indicator_Data, setIndicator_Data] = useContext(
    Indicator_Data_Context
  ); //used to store the fetched data

  const [currentPage, setCurrentPage] = useContext(currentPage_Context); //used to store the current page number selected in table pagination
  const [page_size, setPage_size] = useContext(page_size_Context); //used to store the size of data in selected page
  const [is_Supervisor, setIs_Supervisor] = useState(null);
  const [is_Superuser, setIs_Superuser] = useState(null);
  const [Editing_Data_id, setEditing_Data_id] = useState(""); //used to store the id of editing data
  const [Change_Language, setChange_Language] = useContext(
    Change_Language_Context
  ); //used to change the toggle button for language
  const [Loading_Add_Button, setLoading_Add_Button] = useState(false);
  const [Deleting_Data_id, setDeleting_Data_id] = useState(""); //used to store the id of deleting data
  const [Fiscal_Year, setFiscal_year] = useState([]); //this is used to store overall fiscal years.
  const [RightClicked, setRightClicked] = useContext(RightClicked_Context);
  const [RightComponentClicked, setRightComponentClicked] = useContext(
    RightComponentClicked_Context
  );
  const [Table_Loader, setTable_Loader] = useContext(Table_Loader_Context);
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Secondary_Loading, setSecondary_Loading] = useState(false); //used as secondary loader in table content change
  const [Select_Language, setSelect_Language] = useContext(
    Select_Language_Context
  ); //switched when user select english or nepali language
  const [Next, setNext] = useState(""); //this is used to store the next fetching link of total data fetched

  const [Paginate_Data, setPaginate_Data] = useContext(Paginate_Data_Context); //used to store pagination data
  const [Initial_Loading, setInitial_Loading] = useState(false); //used to set loader during initialization
  const [Global_Loader, setGlobal_Loader] = useState(true);
  const [Loading_Language, setLoading_Language] = useContext(
    Loading_Language_Context
  );

  const [Loading, setLoading] = useContext(Loading_Context); //used to set loader during initialization
  const [Load_on_Lan_Change, setLoad_on_Lan_Change] = useContext(
    Load_on_Lan_Change_Context
  ); //this value is changed only when language is changed
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title
  const [IsSort, setIsSort] = useState(true); //boolean is set when user click sort button
  const [RightID, setRightID] = useContext(RightID_Context);
  const [Previous_Page_No, setPrevious_Page_No] = useState(0); //used to store initial page number
  const [Right_component_id, setRight_component_id] = useContext(
    Right_component_id_Context
  );
  const [show, setShow] = useState(false); //boolean set to show/hide delete alert form
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [date, setDate] = useState(""); //calender value date is selected
  const [Edit_Data, SetEdit_Data] = useContext(Edit_Data_Context); //when set true a model displays to edit the data
  const [Add_Data, setAdd_Data] = useContext(Add_Data_Context); //when set true a model displays to give entry on data entry form
  const [Selected_fiscal_year, setSelected_fiscal_year] = useState({
    fiscal_year: "",
    province: "",
    district: "",
    municipality: "",
    prov_name: "",
    dist_name: "",
    muni_name: "",
    filter_date_from: "",
    filter_date_to: "",
  }); //this is used to store selected fiscal year from secondary navbar
  const [Submitting_Data, SetSubmitting_Data] = useState({
    province: null,
    district: null,
    municipality: null,
    total: null,
    date: "",
    male: null,
    female: null,
    other: null,
    _0_to_20: null,
    _21_or_older: null,

    _0_to_19: null,
    _20_to_29: null,
    _30_to_39: null,
    _40_to_59: null,
    _60_or_older: null,
    amount: null,
    age: null,
    area: null,
    duration: null,
    medium: null,
    ratio: null,
    weight: null,
    Type: null,
    passed: null,
    failed: null,
    _0_to_16: null,
    _17_to_18: null,
    participate: null,
    non_participate: null,
  });

  const handleClose_Data_Edit = () => {
    SetEdit_Data(false);
    SetSubmitting_Data({
      total: "",
      date: "",
      male: Table_Header.gender ? "" : null,
      female: Table_Header.gender ? "" : null,
      other: Table_Header.gender ? "" : null,
      under_18: Table_Header.age_group ? "" : null,
      adult: Table_Header.age_group ? "" : null,
      senior: Table_Header.age_group ? "" : null,
      amount: Table_Header.amount === "" ? "" : null,
      duration: Table_Header.duration === "" ? "" : null,
    });
  };

  {
    /** Used to submit the edited data */
  }
  const handleClick = (e) => console.log("hello");

  const handleSortProvince = () => {
    setTable_Loader(true);
    httpBrowsing_frontend
      .get(
        `/frontend/data/${
          Tost ? "eng" : "nep"
        }/?sortby=province_asc&page=${currentPage}&page_size=${page_size}`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setTable_Loader(false);
      })
      .catch((err) => {
        console.log("Ths is error>>>", err.response);
      });
  };
  const sortProvince = () => {
    if (!Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return b.प्रदेश.id - a.प्रदेश.id;
      });
    } else if (!Tost && !IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return a.प्रदेश.id - b.प्रदेश.id;
      });
    } else if (Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return b.province.id - a.province.id;
      });
    } else {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return a.province.id - b.province.id;
      });
    }
  };

  const sortDate = () => {
    if (!Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return (
          new Date(nepaliToEnglishNumber(b.मिती)) -
          new Date(nepaliToEnglishNumber(a.मिती))
        );
      });
    } else if (!Tost && !IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return (
          new Date(nepaliToEnglishNumber(a.मिती)) -
          new Date(nepaliToEnglishNumber(b.मिती))
        );
      });
    } else if (Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    } else {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    }
  };
  const sortCount = () => {
    if (!Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return (
          nepaliToEnglishNumber(b.संख्या) - nepaliToEnglishNumber(a.संख्या)
        );
      });
    } else if (!Tost && !IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return (
          nepaliToEnglishNumber(a.संख्या) - nepaliToEnglishNumber(b.संख्या)
        );
      });
    } else if (Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return b.total - a.total;
      });
    } else {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return a.total - b.total;
      });
    }
  };
  const renderTableHeader = () => {
    if (Table_Header.length !== 0) {
      let header = Object.keys(Table_Header);

      return header.map((key, index) => {
        if (key === "gender") {
          return (
            <>
              <th className="head" key={index.key}>
                MALE
              </th>
              <th className="head" key={index.key}>
                FEMALE
              </th>
              <th className="head" key={index.key}>
                OTHER
              </th>
            </>
          );
        }

        if (key === "लिँग") {
          return (
            <>
              <th className="head" key={index.key}>
                पुरुष
              </th>
              <th className="head" key={index.key}>
                महिला
              </th>
              <th className="head" key={index.key}>
                अन्य
              </th>
            </>
          );
        }
        if (key === "महिला_उमेर_समुह") {
          return (
            <>
              <th className="head" key={index.key}>
                २० बर्ष मुनी
              </th>
              <th className="head" key={index.key}>
                २० बर्ष वा बढी
              </th>
            </>
          );
        }
        if (key === "बच्चा_उमेर_समुह") {
          return (
            <>
              <th className="head" key={index.key}>
                १६ बर्ष भन्दा कम
              </th>
              <th className="head" key={index.key}>
                १६ बर्ष देखी १८ बर्ष
              </th>
            </>
          );
        }
        if (key === "children_age_group") {
          return (
            <>
              <th className="head" key={index.key}>
                BELOW 16 YEARS
              </th>
              <th className="head" key={index.key}>
                16 TO 18 YEARS
              </th>
            </>
          );
        }
        if (key === "भाग_लिएको_नलिएको") {
          return (
            <>
              <th className="head" key={index.key}>
                भाग लिएको शिशु
              </th>
              <th className="head" key={index.key}>
                भाग नलिएको शिशु
              </th>
            </>
          );
        }
        if (key === "participation_count") {
          return (
            <>
              <th className="head" key={index.key}>
                PARTICIPATED CHILD
              </th>
              <th className="head" key={index.key}>
                NON_PARTICIPATED CHILD
              </th>
            </>
          );
        }
        if (key === "woman_age_group") {
          return (
            <>
              <th className="head" key={index.key}>
                BELOW 20 YEARS
              </th>
              <th className="head" key={index.key}>
                20 AND ABOVE
              </th>
            </>
          );
        }
        if (key === "उतिर्ण_अनुतिर्ण") {
          return (
            <>
              <th className="head" key={index.key}>
                उतिर्ण
              </th>
              <th className="head" key={index.key}>
                अनुतिर्ण
              </th>
            </>
          );
        }
        if (key === "pass_fail_count") {
          return (
            <>
              <th className="head" key={index.key}>
                PASS
              </th>
              <th className="head" key={index.key}>
                FAIL
              </th>
            </>
          );
        }
        if (Tost) {
          if (key === "SN" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  SN
                </th>
              </>
            );
          }
          if (key === "created_by" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  DATA ENTERED BY
                </th>
              </>
            );
          }
          if (key === "updated_by" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  DATA UPDATED BY
                </th>
              </>
            );
          }
          if (key === "last_updated" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  LAST UPDATED DATE
                </th>
              </>
            );
          }
          if (key === "operations" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  EDIT
                </th>
              </>
            );
          }
        } else {
          if (key === "SN" && "प्रदेश") {
            return (
              <>
                <th className="head" key={index.key}>
                  क्रम सं
                </th>
              </>
            );
          }
        }

        return (
          <>
            <th className="head" key={index.key} rowSpan="2">
              {key.toUpperCase()}
              {key === "प्रदेश" ? (
                <img
                  value={key}
                  onClick={sortProvince}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                  }}
                />
              ) : (
                ""
              )}
              {key === "province" ? (
                <img
                  value={key}
                  onClick={sortProvince}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                  }}
                />
              ) : (
                ""
              )}
              {key === "मिती" ? (
                <img
                  value={key}
                  onClick={sortDate}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                  }}
                />
              ) : (
                ""
              )}
              {key === "date" ? (
                <img
                  value={key}
                  onClick={sortDate}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                  }}
                />
              ) : (
                ""
              )}
              {key === "संख्या" ? (
                <img
                  value={key}
                  onClick={sortCount}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                  }}
                />
              ) : (
                ""
              )}
              {key === "total" ? (
                <img
                  value={key}
                  onClick={sortCount}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                  }}
                />
              ) : (
                ""
              )}
            </th>
          </>
        );
      });
    } else {
      return <p style={{ textAlign: "center" }}>Data Table is Empty</p>;
    }
  };

  const renderTableData = () => {
    if (Indicator_Data.length) {
      return Indicator_Data.map((item, index) => {
        return (
          <tr key={String(item.id)}>
            <td>{(currentPage - 1) * page_size + index + 1}</td>
            <td className="Date">{item.province.title_en}</td>
            <td className="Date">{item.district.title_en}</td>
            <td className="Date">{item.municipality.title_en}</td>
            <td className="Date">{item.date}</td>

            {item.weight ? <td className="Date">{item.weight}</td> : ""}

            {item.gender ? (
              <>
                <td className="Data">{item.gender.male}</td>
                <td className="Data">{item.gender.female}</td>
                <td className="Data">{item.gender.other}</td>
              </>
            ) : (
              ""
            )}
            {item.Type ? <td className="Date">{item.Type}</td> : ""}
            {item.duration ? <td className="Data">{item.duration}</td> : ""}
            {item.medium ? <td className="Date">{item.medium}</td> : ""}
            {item.children_age_group ? (
              <>
                <td className="Data">{item.children_age_group._0_to_16}</td>
                <td className="Data">{item.children_age_group._17_to_18}</td>
              </>
            ) : (
              ""
            )}
            {item.participation_count ? (
              <>
                <td className="Data">{item.participation_count.participate}</td>
                <td className="Data">
                  {item.participation_count.non_participate}
                </td>
              </>
            ) : (
              ""
            )}
            {item.pass_fail_count ? (
              <>
                <td className="Data">{item.pass_fail_count.passed}</td>
                <td className="Data">{item.pass_fail_count.failed}</td>
              </>
            ) : (
              ""
            )}
            {item.ratio ? (
              <>
                <td className="Data">{item.ratio}</td>
              </>
            ) : (
              ""
            )}

            {item.woman_age_group ? (
              <>
                <td className="Data">{item.woman_age_group._0_to_20}</td>
                <td className="Data">{item.woman_age_group._21_or_older}</td>
              </>
            ) : (
              ""
            )}

            {item.amount ? <td>{item.amount}</td> : ""}
            {item.area ? <td>{item.area}</td> : ""}
            <td className="Data">{item.total}</td>
          </tr>
        );
      });
    }
  };

  const renderTableData_Nepali = () => {
    if (Indicator_Data.length) {
      return Indicator_Data.map((item, index) => {
        return (
          <tr key={String(item.id)}>
            <td>
              {englishToNepaliNumber((currentPage - 1) * page_size + index + 1)}
            </td>
            <td className="Date">{item.प्रदेश.title}</td>
            <td className="Date">{item.जिल्ला.title}</td>
            <td className="Date">{item.नगरपालिका.title}</td>
            <td className="Date">{item.मिती}</td>

            {item.तौल् ? <td className="Date">{item.तौल्}</td> : ""}

            {item.लिँग ? (
              <>
                <td className="Data">{item.लिँग.पुरुष}</td>
                <td className="Data">{item.लिँग.महिला}</td>
                <td className="Data">{item.लिँग.अन्य}</td>
              </>
            ) : (
              ""
            )}
            {item.प्रकार ? <td className="Date">{item.प्रकार}</td> : ""}
            {item.अवधि ? <td className="Data">{item.अवधि}</td> : ""}
            {item.माध्यम् ? <td className="Date">{item.माध्यम्}</td> : ""}
            {item.बच्चा_उमेर_समुह ? (
              <>
                <td className="Data">{item.बच्चा_उमेर_समुह._०_देखि_१६}</td>
                <td className="Data">{item.बच्चा_उमेर_समुह._१७_देखि_१८}</td>
              </>
            ) : (
              ""
            )}
            {item.भाग_लिएको_नलिएको ? (
              <>
                <td className="Data">{item.भाग_लिएको_नलिएको.भाग_लिएको}</td>
                <td className="Data">{item.भाग_लिएको_नलिएको.भाग_नलिएको}</td>
              </>
            ) : (
              ""
            )}
            {item.उतिर्ण_अनुतिर्ण ? (
              <>
                <td className="Data">{item.उतिर्ण_अनुतिर्ण.अनुतिर्ण}</td>
                <td className="Data">{item.उतिर्ण_अनुतिर्ण.उतिर्ण}</td>
              </>
            ) : (
              ""
            )}
            {item.अनुपात ? <td className="Data">{item.अनुपात}</td> : ""}

            {item.महिला_उमेर_समुह ? (
              <>
                <td className="Data">{item.महिला_उमेर_समुह._०_देखि_२०}</td>
                <td className="Data">{item.महिला_उमेर_समुह._२१_वा_बढी}</td>
              </>
            ) : (
              ""
            )}

            {item.रकम ? <td className="Data">{item.रकम}</td> : ""}
            {item.क्षेत्रफल ? <td className="Data">{item.क्षेत्रफल}</td> : ""}
            <td className="Data">{item.संख्या}</td>
            {/* <td className="Data"> {item.created_by}</td>
          <td className="Data">{item.updated_by}</td>
          <td className="Data">{item.last_updated}</td> */}
          </tr>
        );
      });
    }
  };

  const handlePageSize = (e) => {
    setPage_size(e.target.value);
    setSecondary_Loading(true);
    setTable_Loader(true);
  };
  const changeCurrentPage = (numPage) => {
    setTable_Loader(true);
    setCurrentPage(numPage);
    setSecondary_Loading(true);
    setPrevious_Page_No(currentPage);
  };
  const handleClose_Data_Add = () => {
    //used to close the data form of adding data
    setAdd_Data(false);
    setEdited(!Edited);
    // SetSubmitting_Data({
    //   province: "",
    //   district: "",
    //   municipality: "",
    //   total: "",
    //   date: "",
    // });
    setDate("");
  };

  useEffect(() => {});

  // useEffect(() => {
  //   httpBrowsing_frontend
  //     .get(`/frontend/get-years/${Tost ? "eng" : "nep"}/`)
  //     .then((res) => {
  //       setFiscal_year(res.data);
  //     })
  //     .catch((err) => {
  //       console.log("This is error>>>", err.response);
  //     });
  // }, []);
  // useEffect(() => {
  //   const Verifying_user = () => {
  //     httpBrowsing.get("/nep/me/").then(res => {
  //       setIs_Supervisor(res.data.is_supervisor);
  //       setIs_Superuser(res.data.is_superuser);
  //       SetSubmitting_Data({
  //         province: res.data.province,
  //         district: res.data.district,
  //         municipality: res.data.municipality,
  //       });
  //     });
  //   };
  //   Verifying_user();
  // }, []);

  // useEffect(() => {
  //   setRightClicked(true);
  //   setRightComponentClicked(true);
  //   setSecondary_Loading(true);
  //   // setInitial_Loading(true);
  //   // if (!Secondary_Loading) {
  //   //   setInitial_Loading(true);
  //   // }
  //   httpBrowsing
  //     .get(
  //       `/${
  //         Tost ? "eng" : "nep"
  //       }/indicator-data/?indicator=${Right_indicator_id}&province=${
  //         Selected_fiscal_year.province
  //       }&district=${Selected_fiscal_year.district}&municipality=${
  //         Selected_fiscal_year.municipality
  //       }&fiscal_year=${
  //         Selected_fiscal_year.fiscal_year
  //       }&start=${Download_Date_from}&end=${Download_Date_to}&page=${currentPage}&page_size=${page_size}`
  //     )
  //     .then(res => {
  //       setNext(res.data.next);
  //       setIndicator_Data(res.data.data);
  //       setIndicator_title(res.data.indicator_title_ne);
  //       setIndicator_title_eng(res.data.indicator_title_en);
  //       setPaginate_Data(res.data);
  //       setInitial_Loading(false);
  //       setGlobal_Loader(false);
  //       setSecondary_Loading(false);
  //       setLoading_Language(false);
  //       setLoading(false);
  //       setLoad_on_Lan_Change(false);
  //       setRight_name_english(res.data.right_en);
  //       setRight_name_nepali(res.data.right_ne);
  //       setRight_component_english(res.data.right_component_en);
  //       setRight_component_nepali(res.data.right_component_ne);
  //     })
  //     .catch(err => {
  //       setSecondary_Loading(false);
  //       setInitial_Loading(false);
  //       setLoad_on_Lan_Change(false);
  //       console.log("This is final error>>>", err.response);
  //     });
  // }, [
  //   Select_Language,
  //   page_size,
  //   currentPage,
  //   show,
  //   Add_Data,
  //   Edit_Data,
  //   Right_indicator_id,
  //   Selected_fiscal_year.fiscal_year,
  // ]);

  return (
    <Tester>
      {Indicator_Data.length !== 0 ? (
        <Table
          style={{ marginLeft: "10px", marginRight: "10px" }}
          striped
          bordered
          hover
          responsive
          size="sm"
          aria-sort
        >
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>

          <tbody>{!Tost ? renderTableData_Nepali() : renderTableData()}</tbody>
        </Table>
      ) : (
        <div>
          <Table
            style={{ marginLeft: "10px", marginRight: "10px" }}
            striped
            bordered
            hover
            responsive
            size="sm"
          >
            <thead>
              <tr>{renderTableHeader()}</tr>
              <tr>
                {Table_Header.male === "" ? (
                  <>
                    <th className="sub-head">Male</th>
                    <th className="sub-head">Female</th>
                    <th className="sub-head">Other</th>
                  </>
                ) : (
                  ""
                )}
                {Table_Header.पुरुष === "" ? (
                  <>
                    <th className="sub-head">पुरुष</th>
                    <th className="sub-head">मह���ला</th>
                    <th className="sub-head">अन्य</th>
                  </>
                ) : (
                  ""
                )}
                {Table_Header.अनुतिर्ण === "" ? (
                  <>
                    <th className="sub-head">उतिर्ण</th>
                    <th className="sub-head">अनुतिर्ण</th>
                  </>
                ) : (
                  ""
                )}
                {Table_Header.passed === "" ? (
                  <>
                    <th className="sub-head">passed</th>
                    <th className="sub-head">failed</th>
                  </>
                ) : (
                  ""
                )}

                {Table_Header._१८_वर्श_मुनी === "" ? (
                  <>
                    <th className="sub-head">_१८_वर्श_मुनी</th>
                    <th className="sub-head">वयस्क</th>
                    <th className="sub-head">जेष्ठ_नागरिक</th>
                  </>
                ) : (
                  ""
                )}
                {Table_Header._0_to_19 === "" ? (
                  <>
                    <th className="sub-head">Below 19</th>
                    <th className="sub-head">20 to 29</th>
                    <th className="sub-head">30 to 39</th>
                    <th className="sub-head">40 to 59</th>
                    <th className="sub-head">60 and above</th>
                  </>
                ) : (
                  ""
                )}
              </tr>
            </thead>
          </Table>
          <div className="empty">
            {Tost ? (
              <p style={{ textAlign: "center" }}>Data Not Available</p>
            ) : (
              <p style={{ textAlign: "center" }}>डाटा उपलब्ध छैन </p>
            )}
          </div>
        </div>
      )}
    </Tester>
  );
};
const Point = styled.div`
  a {
    cursor: pointer;
  }
`;
const Tester = styled.div`
  @media only screen and (max-width: 500px) {
    .head {
      font-size: 14px !important;
    }
    .head img {
      height: 15px !important;
    }
  }
  .head img {
    cursor: pointer !important;
  }
  .row-data {
    margin-top: 10px;
    margin-left: 10px;
  }
  .row-data select {
    margin-left: 10px;
  }
  .table-bordered td,
  .table-bordered th {
    white-space: nowrap;
  }
  .page {
    margin-left: auto;
    padding-right: 20px;
  }
  .date-or-fiscal span {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    margin-top: 5px;
  }
  .date-or-fiscal hr {
    width: 93%;
  }
  .submit-butn {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;
  }
  .submit-butn button {
    border: 1px solid grey;
  }
  .show .dropdown-menu {
    width: 300px;
  }
  .select-fiscal-year {
    margin-left: 10px;
  }
  .date-or-fiscal {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .date-picker-main-div {
    display: flex;
  }
  .date-pick-main {
    margin-left: 10px;
    margin-right: 10px;
  }
  .form-control {
    background-color: white !important;
    border: none;
    cursor: pointer;
    width: 105px;
    border-radius: 5px;
  }
  .date-pick img {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .date-pick {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
  }
  .filter-data {
    height: auto;
    width: auto;
    display: flex;
    top: 10px;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
    margin-top: 40px;
    margin-left: 130px;
    background-color: white;
    box-shadow: 5px 10px 18px #ccc;
  }
  .secondary-sidebar {
    width: 200px;
    padding-top: 30px;
  }
  .secondary-sidebar h5 {
    margin-left: 15px;
  }
  .right-component {
    margin-left: 15px;
  }
  .right-component p {
    font-size: 15px;
  }
  .sidebar-1-design .right-image {
    border: 1px solid #d9dadb !important;
  }
  .right-image {
    margin-left: auto;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: flex;
    flex-direction: column;
  }

  .right-image img {
    margin-left: 5px;
    margin-right: 5px;
  }
  .right-container {
    display: flex;
  }
  .clicking-rights {
    display: flex;
  }
  .main-sidebar-content {
    width: 80px;
  }
  .main-sidebar-content-hide {
    width: 80px;
  }
  .right-name-display-sidebar {
    display: none;
  }
  .sidebar-1-design {
    display: flex;
    flex-direction: column;

    height: 50px;
  }
  .sidebar-1-design .right-image {
    display: flex;
    width: 80px;
    justify-content: center;
  }

  .fiscal-year-filter {
    display: flex;
    margin-left: auto;
  }
  .fiscal-year-filter p {
    margin-top: 16px;
  }
  .fiscal-year button {
    border: none !important;
  }
  .fiscal-year {
    margin-top: 0px !important;
  }
  .main-body {
    width: 100%;
    background-color: #f9fbfc;
  }
  .filter-add-btn {
    margin-left: 15px;
    display: flex;
    position: relative;
  }
  .filter-add-btn button {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .view-data {
    margin-left: auto;
  }
  .view-data button {
    width: -webkit-fill-available;
    margin: 0;
  }
  .dropdown {
    margin-top: 10px;

    margin-right: 10px;

    color: black;
  }
  .dropdown button {
    background-color: white;
    color: black;
    border: 1px solid;
  }
  .filter-div {
    display: flex;
  }
  .filter-div h1 {
    margin-left: 20px;
  }
  .breadcrumb {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .bread-crumb {
    width: fit-content;
    margin-right: 10px;
  }
  .lang-btn {
    margin-top: 20px;
  }
  .account-logo {
    margin-right: 5px;
  }
  .selected-language {
    background-color: #06356a !important;
  }
  .nep {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .eng {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    padding-right: 20px;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .toggle-on-click {
    left: 230px;
  }
  .main-content {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-brand img {
    height: 51px;
  }
  // .Sidebar-menu {
  //   border: none;
  //   background-color: #f8f9fa;
  //   color: black;
  // }
  // button.sidebar-menu :hover {
  //   background-color: transparent !important;
  //   text-decoration: none;
  // }
  #menu-toggle {
    position: absolute;
    top: 50vh;
  }
  #page-content-wrapper {
    height: 100vh;
  }
  /*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

  #wrapper {
    overflow-x: hidden;
  }

  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }

  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }

  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  #sidebar-wrapper .list-group2 {
    width: 0px !important;
  }

  #page-content-wrapper {
    min-width: 100vw;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }

  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }

    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }

    // #wrapper.toggled #sidebar-wrapper {
    //   margin-left: -15rem;
    // }
  }
`;
export default Table_Indicator;
