import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import httpBrowsing from "../../Utilities/httpBrowsing";
import notification from "../../Utilities/Notification";
import Axios from "axios";

const Forget_Password = (props) => {
  const [Loading, setLoading] = useState(false);
  const [Email, setEmail] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail({
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let config = {
      method: "post",
      url: "https://humanrightsapi.yilab.org.np/api/nep/forgot-password/",
      headers: {
        "Content-Type": "application/json",
      },
      data: Email,
    };

    Axios(config)
      .then((res) => {
        setLoading(false);

        notification.showSuccess(
          "Password sent to your email,please check email!!!"
        );
        props.history.push("/");
      })
      .catch((err) => {
        setLoading(false);
        console.log("This is error", err.response);
        notification.showError("Invalid Email Address!!!");
      });
  };

  return (
    <Wrapper>
      <div className="loginwrapper">
        <form method="post" className="form-signin shadow-1">
          <h5 className="form-signin-heading text-center">
            Enter Your Registered Email id
          </h5>
          <div className="input-group">
            <input
              type="text"
              name="email"
              className="form-control"
              placeholder="Email Address"
              onChange={handleChange}
            />
          </div>
          <br />
          {Loading ? (
            <button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              disabled
            >
              <i className="fa fa-refresh fa-spin fa-fw" /> Submitting...
            </button>
          ) : (
            <button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
          <div className="input-group">
            <Link to="/login">Back to Login</Link>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .input-group a {
    margin-top: 10px;
  }
  .loginwrapper {
    margin-top: 40px;
    margin-bottom: 80px;
  }

  .form-signin {
    max-width: 400px;
    padding: 15px 35px 45px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    font-size: 16px !important;
    margin-top: 12%;
  }
  .form-signin .form-signin-heading {
    margin-bottom: 30px;
  }
  .btn-block {
    width: 100% !important;
    display: block !important;
  }
  .form-signin .form-control {
    position: relative;
    height: auto;
    padding: 7px !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }

  .form-signin button[type="submit"] {
    height: 36px !important;
    font-size: 16px !important;
    padding: 0;
    outline: none;
  }

  /*-----------------------------------------------
	submit button
-----------------------------------------------*/
  button[type="submit"],
  .outline-none {
    outline: none !important;
  }

  /*==================================================
 * shadow-1
 * ===============================================*/
  .shadow-1 {
    -webkit-box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    -moz-box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
  }

  /* author */
  .author {
    float: left;
    font-family: "Maven Pro", sans-serif;
    font-size: 18px;
    margin: 15px 20px 30px;
  }
`;

export default Forget_Password;
