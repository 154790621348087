import React, { useContext, useState, useEffect } from "react";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import httpBrowsing from "../Utilities/httpBrowsing";
import styled from "styled-components";
import { useLocalStorage } from "../Utilities/Localstorage";
import { saveAs } from "file-saver";
import notification from "../Utilities/Notification";
import { nepaliToEnglishNumber } from "nepali-number";
import {
  Download_Date_to_context,
  Filter_clicked_Context,
  Download_Date_from_context,
  Fiscal_Year_Context,
  Selected_fiscal_year_Context,
  Right_indicator_id_Context,
  Add_Data_Context,
  Tost_Context,
  Indicator_Data_Context,
  currentPage_Context,
  Paginate_Data_Context,
  Province_Context,
  District_Context,
  Municipality_Context,
  Filter_Data_context,
  Total_Data_Context,
  Filter_First_Data_Context,
  Filter_Second_Data_Context,
  Second_Total_Data_Context,
  Compare_Graph_Context,
  Right_name_english_Context,
  Right_component_english_Context,
  Indicator_title_eng_Context,
  Right_name_nepali_Context,
  Right_component_nepali_Context,
  Indicator_title_Context,
  Compare_Context,
  Filtered_compared_Context,
  Is_chart_data_monthly_Context,
  Chart_x_axis_Context,
  Chart_Data_monthly_context,
} from "../Utilities/Store";
import httpBrowsing_frontend from "../Utilities/httpBrowsing_frontend";
const Graph_Filter = () => {
  const [Filtering, setFiltering] = useState(false);
  const [Paginate_Data, setPaginate_Data] = useContext(Paginate_Data_Context); //used to store pagination data
  const [Indicator_Data, setIndicator_Data] = useContext(
    Indicator_Data_Context
  ); //used to store the fetched data
  const [Total_Data, setTotal_Data] = useContext(Total_Data_Context);
  const [Tost, setTost] = useContext(Tost_Context);
  const [Add_Data, setAdd_Data] = useContext(Add_Data_Context); //when set true a model displays to give entry on data entry form

  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Fiscal_Year, setFiscal_Year] = useContext(Fiscal_Year_Context);
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [Filter_clicked, setFilter_clicked] = useState(false);
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [Filter_Second_Data, setFilter_Second_Data] = useContext(
    Filter_Second_Data_Context
  );
  const [Filtered_compared, setFiltered_compared] = useContext(
    Filtered_compared_Context
  ); //used to set true when data is filtered or compared
  const [currentPage, setCurrentPage] = useContext(currentPage_Context); //used to store the current page number selected in table pagination
  const [Filter_Data, setFilter_Data] = useContext(Filter_Data_context); //when 1st filter of selector buttom is clicked and data is entered the details is stored here.
  const [Province, setProvince] = useContext(Province_Context);
  const [District, setDistrict] = useContext(District_Context);
  const [Municipality, setMunicipality] = useContext(Municipality_Context);
  const [Compare_Graph, setCompare_Graph] = useContext(Compare_Graph_Context); //Is set true when user click  second buttom to compare the graph
  const [Filter_First_Data, setFilter_First_Data] = useContext(
    Filter_First_Data_Context
  );
  const [Compare, setCompare] = useContext(Compare_Context);
  const [chart_x_axis, setChart_x_axis] = useContext(Chart_x_axis_Context); //used to set month name in x-axis of graph
  const [chart_data_monthly, setChart_data_monthly] = useContext(
    Chart_Data_monthly_context
  );
  const [Second_Total_Data, setSecond_Total_Data] = useContext(
    Second_Total_Data_Context
  ); //stores the comparison second entered data
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title
  const [Is_chart_data_monthly, setIs_chart_data_monthly] = useContext(
    Is_chart_data_monthly_Context
  );
  let Selecting_District = District.filter(
    (item, i) => item.province === Number(Filter_Data.province)
  );
  let Selecting_Municipality = Municipality.filter(
    (item) => item.district === Number(Filter_Data.district)
  );
  const handleChangeProvince = (id, name, nepali_name) => {
    setFilter_Data({
      ...Filter_Data,
      province: id,

      district: "",

      municipality: "",

      province_name: name,
      district_name: "",
      municipality_name: "",
      province_name_nep: nepali_name,
      district_name_nep: "",
      municipality_name_nep: "",
    });
  };

  const handleChangeDistrict = (id, name, nepali_name) => {
    setFilter_Data({
      ...Filter_Data,
      district: id,

      municipality: "",
      district_name: name,

      municipality_name: "",

      district_name_nep: nepali_name,
      municipality_name_nep: "",
    });
  };
  const handleChangeMunicipality = (id, name, nepali_name) => {
    setFilter_Data({
      ...Filter_Data,
      municipality: id,
      municipality_name: name,

      municipality_name_nep: nepali_name,
    });
  };

  const handleCancelFilter = (e) => {
    e.preventDefault();
    setFilter_Data({
      province: "",
      municipality: "",
      district: "",
      province_name: "",
      district_name: "",
      municipality_name: "",
      province_name_nep: "",
      district_name_nep: "",
      municipality_name_nep: "",
    });
    setSelected_fiscal_year("");
    setDownload_Date_from("");
    setDownload_Date_to("");
    setFiltered_compared(false);
    setCompare(false);
    setCompare_Graph(false);
    setIs_chart_data_monthly(true);
    setFilter_First_Data({
      province: "",
      municipality: "",
      district: "",
      province_name: "",
      district_name: "",
      municipality_name: "",
    });
    setFilter_Second_Data({
      province: "",
      municipality: "",
      district: "",
      province_name: "",
      district_name: "",
      municipality_name: "",
    });
    setIs_chart_data_monthly(true);
    httpBrowsing_frontend
      .get(
        `/frontend/data/graph/monthly/${Right_indicator_id}/${
          Tost ? "eng" : "nep"
        }/?province=${Filter_Data.province}&district=${
          Filter_Data.district
        }&municipality=${Filter_Data.municipality}`
      )
      .then((res) => {
        setChart_data_monthly(res.data);
        setChart_x_axis(Object.keys(res.data));
      })
      .catch((err) => {
        console.log("Err", err.response);
      });
    // httpBrowsing_frontend
    //   .get(`/frontend/data/graph/?indicator=${Right_indicator_id}`)
    //   .then((res) => {
    //     setTotal_Data(res.data.data);
    //     setFilter_clicked(false);
    //   })
    //   .catch((err) => {
    //     console.log("This is final error>>>", err.response);
    //   });
  };
  const handleFilterDate_For_Graph = (e) => {
    e.preventDefault();
    setFiltering(true);
    httpBrowsing_frontend
      .get(
        `/frontend/data/graph/?indicator=${Right_indicator_id}&province=${
          Filter_Data.province
        }&district=${Filter_Data.district}&municipality=${
          Filter_Data.municipality
        }&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${nepaliToEnglishNumber(
          Selected_fiscal_year
        )}`
      )
      .then((res) => {
        setTotal_Data(res.data.data);
        setFilter_clicked(false);
        setFiltered_compared(true);
        setCompare(false);
        setIs_chart_data_monthly(false);
        setFiltering(false);
      })
      .catch((err) => {
        console.log("Err", err.response);
      });
  };
  if (Selected_fiscal_year) {
    setSelected_fiscal_year(Selected_fiscal_year.substr(0, 4));
  }
  const handleDownload = () => {
    const canvasSave = document.getElementById("capture");
    notification.showSuccess("Downloading PNG !!!");
    canvasSave.toBlob(function (blob) {
      {
        Tost
          ? saveAs(
              blob,
              `${Right_name_english}_${Right_component_english}_${Indicator_title_eng}.png`
            )
          : saveAs(
              blob,
              `${Right_name_nepali}_${Right_component_nepali}_${Indicator_title}.png`
            );
      }
    });
  };
  useEffect(() => {
    const reset_btn = () => {
      if (Filter_Data.province || Selected_fiscal_year || Download_Date_to) {
        setFiltered_compared(true);
      }
    };
    reset_btn();
  }, [Filter_Data.province]);

  return (
    <Wrapper>
      <div
        className="row contain main-table-filter-div"
        style={{ marginLeft: "15px" }}
      >
        <div className=" d-md-flex  flex-md-row bd-highlight">
          <div className="p-2 bd-highlight">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className={Filter_Data.province_name ? "selected" : "select"}
              >
                {Filter_Data.province_name
                  ? Tost
                    ? Filter_Data.province_name
                    : Filter_Data.province_name_nep
                  : Tost
                  ? "SELECT PROVINCE"
                  : "प्रदेश छान्नुहोस् "}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Province.map((item, i) => (
                  <Dropdown.Item
                    key={i}
                    value={item.id}
                    onClick={() => {
                      handleChangeProvince(
                        item.id,
                        item.title_en,
                        item.title_ne
                      );
                      setCompare_Graph(false);
                      setFiltered_compared(true);
                    }}
                  >
                    {Tost ? item.title_en : item.title_ne}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className={
              Filter_Data.province_name
                ? "p-2 bd-highlight"
                : "p-2 bd-highlight default"
            }
          >
            {Filter_Data.province_name ? (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className={Filter_Data.district_name ? "selected" : "select"}
                >
                  {Filter_Data.district_name
                    ? Tost
                      ? Filter_Data.district_name
                      : Filter_Data.district_name_nep
                    : Tost
                    ? "SELECT DISTRICT"
                    : "जिल्ला छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_District.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeDistrict(
                          item.id,
                          item.title_en,
                          item.title_ne
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled
                  className={Filter_Data.district_name ? "selected" : "select"}
                >
                  {Filter_Data.district_name
                    ? Tost
                      ? Filter_Data.district_name
                      : Filter_Data.district_name_nep
                    : Tost
                    ? "SELECT DISTRICT"
                    : "जिल्ला छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_District.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeDistrict(
                          item.id,
                          item.title_en,
                          item.title_ne
                        )
                      }
                    >
                      {item.title_en}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div
            className={
              Filter_Data.district_name
                ? "p-2 bd-highlight"
                : "p-2 bd-highlight default"
            }
          >
            {Filter_Data.district_name ? (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className={
                    Filter_Data.municipality_name ? "selected" : "select"
                  }
                >
                  {Filter_Data.municipality_name
                    ? Tost
                      ? Filter_Data.municipality_name
                      : Filter_Data.municipality_name_nep
                    : Tost
                    ? "SELECT MUNICIPALITY"
                    : "पालिका छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_Municipality.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeMunicipality(
                          item.id,
                          item.title_en,
                          item.title_ne
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled
                  className={
                    Filter_Data.municipality_name ? "selected" : "select"
                  }
                >
                  {Filter_Data.municipality_name
                    ? Tost
                      ? Filter_Data.municipality_name
                      : Filter_Data.municipality_name_nep
                    : Tost
                    ? "SELECT MUNICIPALITY"
                    : "पालिका छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_Municipality.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeMunicipality(
                          item.id,
                          item.title_en,
                          item.title_ne
                        )
                      }
                    >
                      {item.title_en}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className=" d-xl-flex flex-xl-row bd-highlight ">
          <div className="p-2 bd-highlight">
            <div className="filter">
              <img
                src="/filterbtn.png"
                alt="filter-icon"
                className="filter-icon big-screen"
                onClick={() => {
                  // setSelected_clicked(false);
                  setFilter_clicked(!Filter_clicked);
                }}
              />

              <Button
                variant="secondary"
                onClick={() => {
                  // setSelected_clicked(false);
                  setFilter_clicked(!Filter_clicked);
                }}
                className="filter-btn small-screen"
              >
                <img src="/material-filter-list.svg" alt="filter" />{" "}
                {Tost ? "FILTER" : "फिल्टर"}
              </Button>

              {Filter_clicked ? (
                <div className="filter-data">
                  <div className="select-fiscal-year">
                    {Tost ? "Fiscal Year" : "आर्थिक बर्ष"}
                    <DropdownButton
                      id="dropdown-basic-button"
                      className="view-data"
                      title={
                        Selected_fiscal_year
                          ? Selected_fiscal_year
                          : Fiscal_Year[Fiscal_Year.length - 1]
                      }
                    >
                      {Fiscal_Year.map((item) => {
                        return (
                          <Dropdown.Item
                            key={item}
                            value={Selected_fiscal_year}
                            onClick={() => setSelected_fiscal_year(item)}
                          >
                            {item}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                  <div className="date-or-fiscal">
                    <hr />
                    <span>{Tost ? "OR" : "अथवा"}</span>
                  </div>
                  <div className="date-picker-main-div">
                    <div className="date-pick-main">
                      {Tost ? "From" : "देखि"}
                      <div className="date-pick">
                        <img src="/calendar-solid.svg" alt="calender-logo" />
                        <NepaliDatePicker
                          inputClassName="form-control"
                          className="calender"
                          value={Download_Date_from}
                          name="date"
                          onChange={(value) => setDownload_Date_from(value)}
                          options={{
                            calenderLocale: Tost ? "en" : "ne",
                            valueLocale: "en",
                          }}
                        />
                      </div>
                    </div>
                    <div className="dash">-</div>

                    <div className="date-pick-main">
                      {Tost ? "To" : "सम्म"}
                      <div className="date-pick">
                        <img src="/calendar-solid.svg" alt="calender-logo" />
                        <NepaliDatePicker
                          inputClassName="form-control"
                          className="calender"
                          value={Download_Date_to}
                          name="date"
                          onChange={(value) => setDownload_Date_to(value)}
                          options={{
                            calenderLocale: Tost ? "en" : "ne",
                            valueLocale: "en",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="submit-butn">
                    <button
                      className="btn btn-success"
                      type="submit"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      onClick={() => setFilter_clicked(false)}
                    >
                      CANCEL
                    </button>
                    {(setDownload_Date_from && Download_Date_to) ||
                    Selected_fiscal_year ? (
                      Filtering ? (
                        <button
                          className="btn btn-success"
                          type="submit"
                          disabled
                          onClick={handleFilterDate_For_Graph}
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            color: "black",
                          }}
                        >
                          Filtering...
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          type="submit"
                          onClick={handleFilterDate_For_Graph}
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            color: "black",
                          }}
                        >
                          DONE
                        </button>
                      )
                    ) : (
                      <button
                        className="btn "
                        type="submit"
                        disabled
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        DONE
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className=" d-xl-flex flex-xl-row bd-highlight ">
          <div className=" bd-highlight reset-bttn">
            {Filtered_compared ? (
              <img
                src="/reset.svg"
                alt="reset"
                style={{ cursor: "pointer" }}
                onClick={handleCancelFilter}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className=" d-xl-flex flex-xl-row bd-highlight dl-btn">
          <div className="bd-highlight dl-bttn">
            <img
              src="/dnload.svg"
              alt="dl"
              onClick={handleDownload}
              className="big-screen"
            />
            {Tost ? (
              <DropdownButton
                id="dropdown-basic-button"
                title="DOWNLOAD"
                className="down-load small-screen"
                style={{
                  marginLeft: "auto",
                  marginRight: "10px",
                }}
              >
                <Dropdown.Item onClick={handleDownload}>
                  Download PNG
                </Dropdown.Item>
              </DropdownButton>
            ) : (
              <DropdownButton
                id="dropdown-basic-button"
                title="
          डाउनलोड"
                className="down-load small-screen"
                style={{
                  marginLeft: "auto",
                  marginRight: "10px",
                }}
              >
                <Dropdown.Item onClick={handleDownload}>
                  PNG डाउनलोड{" "}
                </Dropdown.Item>
              </DropdownButton>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
    // <Wrapper>
    //   <div className="filter-add-btn">
    //     <div className="selection">
    //       <Dropdown>
    //         <Dropdown.Toggle
    //           variant="success"
    //           id="dropdown-basic"
    //           className={Filter_Data.province_name ? "selected" : "select"}
    //         >
    //           {Filter_Data.province_name
    //             ? Tost
    //               ? Filter_Data.province_name
    //               : Filter_Data.province_name_nep
    //             : Tost
    //             ? "SELECT PROVINCE"
    //             : "प्रदेश छान्नुहोस् "}
    //         </Dropdown.Toggle>
    //         <Dropdown.Menu>
    //           {Province.map((item, i) => (
    //             <Dropdown.Item
    //               key={i}
    //               value={item.id}
    //               onClick={() => {
    //                 handleChangeProvince(item.id, item.title_en, item.title_ne);
    //                 setCompare_Graph(false);
    //                 setFiltered_compared(true);
    //               }}
    //             >
    //               {Tost ? item.title_en : item.title_ne}
    //             </Dropdown.Item>
    //           ))}
    //         </Dropdown.Menu>
    //       </Dropdown>
    //       {Filter_Data.province_name ? (
    //         <Dropdown>
    //           <Dropdown.Toggle
    //             variant="success"
    //             id="dropdown-basic"
    //             className={Filter_Data.district_name ? "selected" : "select"}
    //           >
    //             {Filter_Data.district_name
    //               ? Tost
    //                 ? Filter_Data.district_name
    //                 : Filter_Data.district_name_nep
    //               : Tost
    //               ? "SELECT DISTRICT"
    //               : "जिल्ला छान्नुहोस्"}
    //           </Dropdown.Toggle>
    //           <Dropdown.Menu>
    //             {Selecting_District.map((item, i) => (
    //               <Dropdown.Item
    //                 key={i}
    //                 value={item.id}
    //                 onClick={() =>
    //                   handleChangeDistrict(
    //                     item.id,
    //                     item.title_en,
    //                     item.title_ne
    //                   )
    //                 }
    //               >
    //                 {Tost ? item.title_en : item.title_ne}
    //               </Dropdown.Item>
    //             ))}
    //           </Dropdown.Menu>
    //         </Dropdown>
    //       ) : (
    //         <Dropdown>
    //           <Dropdown.Toggle
    //             variant="success"
    //             id="dropdown-basic"
    //             disabled
    //             className={Filter_Data.district_name ? "selected" : "select"}
    //           >
    //             {Filter_Data.district_name
    //               ? Tost
    //                 ? Filter_Data.district_name
    //                 : Filter_Data.district_name_nep
    //               : Tost
    //               ? "SELECT DISTRICT"
    //               : "जिल्ला छान्नुहोस्"}
    //           </Dropdown.Toggle>
    //           <Dropdown.Menu>
    //             {Selecting_District.map((item, i) => (
    //               <Dropdown.Item
    //                 key={i}
    //                 value={item.id}
    //                 onClick={() =>
    //                   handleChangeDistrict(
    //                     item.id,
    //                     item.title_en,
    //                     item.title_ne
    //                   )
    //                 }
    //               >
    //                 {item.title_en}
    //               </Dropdown.Item>
    //             ))}
    //           </Dropdown.Menu>
    //         </Dropdown>
    //       )}
    //       {Filter_Data.district_name ? (
    //         <Dropdown>
    //           <Dropdown.Toggle
    //             variant="success"
    //             id="dropdown-basic"
    //             className={
    //               Filter_Data.municipality_name ? "selected" : "select"
    //             }
    //           >
    //             {Filter_Data.municipality_name
    //               ? Tost
    //                 ? Filter_Data.municipality_name
    //                 : Filter_Data.municipality_name_nep
    //               : Tost
    //               ? "SELECT MUNICIPALITY"
    //               : "पालिका छान्नुहोस्"}
    //           </Dropdown.Toggle>
    //           <Dropdown.Menu>
    //             {Selecting_Municipality.map((item, i) => (
    //               <Dropdown.Item
    //                 key={i}
    //                 value={item.id}
    //                 onClick={() =>
    //                   handleChangeMunicipality(
    //                     item.id,
    //                     item.title_en,
    //                     item.title_ne
    //                   )
    //                 }
    //               >
    //                 {Tost ? item.title_en : item.title_ne}
    //               </Dropdown.Item>
    //             ))}
    //           </Dropdown.Menu>
    //         </Dropdown>
    //       ) : (
    //         <Dropdown>
    //           <Dropdown.Toggle
    //             variant="success"
    //             id="dropdown-basic"
    //             disabled
    //             className={
    //               Filter_Data.municipality_name ? "selected" : "select"
    //             }
    //           >
    //             {Filter_Data.municipality_name
    //               ? Tost
    //                 ? Filter_Data.municipality_name
    //                 : Filter_Data.municipality_name_nep
    //               : Tost
    //               ? "SELECT MUNICIPALITY"
    //               : "पालिका छान्नुहोस्"}
    //           </Dropdown.Toggle>
    //           <Dropdown.Menu>
    //             {Selecting_Municipality.map((item, i) => (
    //               <Dropdown.Item
    //                 key={i}
    //                 value={item.id}
    //                 onClick={() =>
    //                   handleChangeMunicipality(
    //                     item.id,
    //                     item.title_en,
    //                     item.title_ne
    //                   )
    //                 }
    //               >
    //                 {item.title_en}
    //               </Dropdown.Item>
    //             ))}
    //           </Dropdown.Menu>
    //         </Dropdown>
    //       )}
    //     </div>
    //     <div className="filter-date-2">
    //       <Button
    //         variant="secondary"
    //         onClick={() => setFilter_clicked(!Filter_clicked)}
    //         className="filter-btn"
    //       >
    //         <img src="/material-filter-list.svg" alt="filter" />
    //         {""} {Tost ? "FILTER" : "फिल्टर"}
    //       </Button>

    //       {Filter_clicked ? (
    //         <div className="filter-data">
    //           <div className="select-fiscal-year">
    //             {Tost ? "Fiscal Year" : "आर्थिक बर्ष"}
    //             <DropdownButton
    //               id="dropdown-basic-button"
    //               className="view-data"
    //               title={
    //                 Selected_fiscal_year
    //                   ? Selected_fiscal_year
    //                   : Fiscal_Year[Fiscal_Year.length - 1]
    //               }
    //             >
    //               {Fiscal_Year.map((item) => {
    //                 return (
    //                   <Dropdown.Item
    //                     key={item}
    //                     value={Selected_fiscal_year}
    //                     onClick={() => setSelected_fiscal_year(item)}
    //                   >
    //                     {item}
    //                   </Dropdown.Item>
    //                 );
    //               })}
    //             </DropdownButton>
    //           </div>
    //           <div className="date-or-fiscal">
    //             <hr />
    //             <span>{Tost ? "OR" : "अथवा"}</span>
    //           </div>
    //           <div className="date-picker-main-div">
    //             <div className="date-pick-main">
    //               {Tost ? "From" : "देखि"}
    //               <div className="date-pick">
    //                 <img src="/calendar-solid.svg" alt="calender-logo" />
    //                 <NepaliDatePicker
    //                   inputClassName="form-control"
    //                   className="calender"
    //                   value={Download_Date_from}
    //                   name="date"
    //                   onChange={(value) => setDownload_Date_from(value)}
    //                   options={{
    //                     calenderLocale: Tost ? "en" : "ne",
    //                     valueLocale: "en",
    //                   }}
    //                 />
    //               </div>
    //             </div>
    //             <div style={{ marginTop: 30 }}>-</div>

    //             <div className="date-pick-main">
    //               {Tost ? "To" : "सम्म"}
    //               <div className="date-pick">
    //                 <img src="/calendar-solid.svg" alt="calender-logo" />
    //                 <NepaliDatePicker
    //                   inputClassName="form-control"
    //                   className="calender"
    //                   value={Download_Date_to}
    //                   name="date"
    //                   onChange={(value) => setDownload_Date_to(value)}
    //                   options={{
    //                     calenderLocale: Tost ? "en" : "ne",
    //                     valueLocale: "en",
    //                   }}
    //                 />
    //               </div>
    //             </div>
    //           </div>

    //           <div className="submit-butn">
    //             <button
    //               className="btn btn-success"
    //               type="submit"
    //               style={{
    //                 borderRadius: "10px",
    //                 backgroundColor: "white",
    //                 color: "black",
    //               }}
    //               onClick={() => setFilter_clicked(false)}
    //             >
    //               CANCEL
    //             </button>
    //             {(setDownload_Date_from && Download_Date_to) ||
    //             Selected_fiscal_year ? (
    //               Filtering ? (
    //                 <button
    //                   className="btn btn-success"
    //                   disabled
    //                   type="submit"
    //                   onClick={handleFilterDate_For_Graph}
    //                   style={{
    //                     borderRadius: "10px",
    //                     backgroundColor: "white",
    //                     color: "black",
    //                   }}
    //                 >
    //                   Filtering...
    //                 </button>
    //               ) : (
    //                 <button
    //                   className="btn btn-success"
    //                   type="submit"
    //                   onClick={handleFilterDate_For_Graph}
    //                   style={{
    //                     borderRadius: "10px",
    //                     backgroundColor: "white",
    //                     color: "black",
    //                   }}
    //                 >
    //                   DONE
    //                 </button>
    //               )
    //             ) : (
    //               <button
    //                 className="btn "
    //                 type="submit"
    //                 disabled
    //                 style={{
    //                   borderRadius: "10px",
    //                   backgroundColor: "white",
    //                   color: "black",
    //                 }}
    //               >
    //                 DONE
    //               </button>
    //             )}
    //           </div>
    //         </div>
    //       ) : (
    //         ""
    //       )}
    //     </div>

    //     {Filtered_compared ? (
    //       <img
    //         src="/reset.svg"
    //         alt="reset"
    //         style={{ cursor: "pointer" }}
    //         onClick={handleCancelFilter}
    //       />
    //     ) : (
    //       ""
    //     )}
    //     {Tost ? (
    //       <DropdownButton
    //         id="dropdown-basic-button"
    //         title="DOWNLOAD"
    //         className="down-load"
    //         style={{
    //           marginLeft: "auto",
    //           marginRight: "10px",
    //         }}
    //       >
    //         <Dropdown.Item onClick={handleDownload}>Download PNG</Dropdown.Item>
    //       </DropdownButton>
    //     ) : (
    //       <DropdownButton
    //         id="dropdown-basic-button"
    //         title="
    //       डाउनलोड"
    //         className="down-load"
    //         style={{
    //           marginLeft: "auto",
    //           marginRight: "10px",
    //         }}
    //       >
    //         <Dropdown.Item onClick={handleDownload}>PNG डाउनलोड </Dropdown.Item>
    //       </DropdownButton>
    //     )}
    //   </div>
    // </Wrapper>
  );
};
const Wrapper = styled.div`
  .btn:focus {
    box-shadow: none !important;
  }
  .btn-success.focus {
    box-shadow: none !important;
  }
  .btn-success:focus {
    box-shadow: none !important;
  }
  .dropdown-menu {
    z-index: 99999 !important;
  }
  .view-data button {
    width: 310px;
    justify-content: center;
  }
  .dropdown-item {
    justify-content: center;
  }
  .main-table-filter-div {
    align-items: center !important;
  }
  .main-table-filter-div {
    display: flex;
    align-items: center;
  }
  .small-screen {
    display: inline;
  }
  .big-screen {
    display: none;
  }
  .filter-icon {
    height: 25px;
  }
  .dl-btn {
    margin-left: auto;
  }
  .down-load button {
    border-radius: 10px !important;
    border: 2px solid;
    color: #405467 !important;
    font-size: 12px;
    font-weight: bold;
    background-color: white !important;
    margin-right: 70px !important;
  }
  .dash {
    margin-top: 30px;
  }
  .date-pick img {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .date-pick {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
  }
  .filter-btn {
    display: flex;
    align-items: center !important;
    background: none !important;
    font-size: 14px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #405467 !important;
    border-radius: 10px !important;
  }
  .selected {
    border-radius: 10px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #ffffff !important;

    background-color: #405467 !important;
  }
  .select {
    border-radius: 10px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #405467 !important;
    box-shadow: 5px 10px 18px #dddd !important;
    background-color: white !important;
  }
  .contain {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .date-picker-main-div {
    display: flex;
  }
  .date-or-fiscal {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .date-pick-main {
    margin-left: 10px;
    margin-right: 10px;
  }
  .submit-butn button {
    border: 1px solid grey;
  }
  .filter-add-btn {
    margin-left: 15px;
    display: flex;
    position: relative;
    padding-top: 5px;
  }
  .select-fiscal-year {
    margin-left: 10px;
  }
  .filter-add-btn button {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .submit-butn button {
    margin-left: 5px;
    margin-right: 5px;
  }
  .submit-butn {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;
  }
  .date-or-fiscal span {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    margin-top: 5px;
  }
  .date-or-fiscal hr {
    width: 93%;
  }
  .nepali-date-picker .calender {
    width: max-content;
  }
  .filter-data {
    margin-left: 0px;
    margin-top: 0px;

    height: auto;
    width: 330px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;

    background-color: white;
    box-shadow: 5px 10px 18px #ccc;
  }
  .form-control {
    background-color: white !important;
    border: none;
    cursor: pointer;
    width: 110px !important;
    border-radius: 5px;
  }
  .reset-bttn {
    margin-top: 7px;
  }
  @media only screen and (max-width: 770px) {
    .default {
      display: none;
    }
    .dl-bttn img {
      margin-top: 10px;
      margin-right: 10px;
    }
    .main-table-filter-div {
      align-items: flex-start !important;
    }
  }
  @media only screen and (max-width: 500px) {
    .main-table-filter-div {
      align-items: flex-start !important;
    }
    .big-screen {
      display: inline;
    }
    .small-screen {
      display: none;
    }
    .main-table-filter-div {
      margin-top: 0px !important;
    }
    .dl-bttn img {
      margin-top: 10px;
      margin-right: 20px;
    }
    .reset-bttn img {
      width: 30px !important;
    }
    .filter-data {
      right: 9%;
    }
    .default {
      display: none;
    }
    .dl-btn {
      margin-left: auto !important;
    }
    .dl-btn img {
      height: 25px;
    }
  }
  .filter-data {
    z-index: 9999 !important;
  }
  .view-data button {
    width: 310px;
  }
  @media only screen and (max-width: 1000px) {
    .date-picker-main-div {
      flex-direction: column;
    }
    .filter-data {
      width: 200px !important;
    }
    .view-data button {
      width: 180px !important;
    }
    .dash {
      margin-top: 0px !important;
      margin-left: 10px !important;
    }
  }

  // .btn:focus {
  //   box-shadow: none !important;
  // }
  // .btn-success.focus {
  //   box-shadow: none !important;
  // }
  // .btn-success:focus {
  //   box-shadow: none !important;
  // }
  // .down-load button {
  //   border-radius: 10px !important;
  //   border: 2px solid;
  //   color: #405467 !important;
  //   font-size: 14px;
  //   font-weight: bold;
  // }
  // .select:focus {
  //   background-color: white !important;
  // }

  // .view-data > .show {
  //   width: 300px;
  // }
  // .btn-success.focus,
  // .btn-success:focus {
  //   box-shadow: 5px 10px 18px #dddd !important;
  // }
  // .btn-success.active,
  // .btn-success:active {
  //   background-color: white !important;
  // }
  // .filter-data {
  //   position: absolute !important;
  //   top: 45px !important;
  //   margin-left: 0px !important;
  //   margin-top: 0px !important;
  // }
  // .selection {
  //   display: flex;
  // }
  // .filter-add-btn button {
  //   height: auto !important;
  // }
  // .form-control {
  //   width: 110px !important;
  // }
  // .nepali-date-picker .calender {
  //   width: max-content;
  // }
  // .selected {
  //   border-radius: 10px !important;
  //   border: 2px solid !important;
  //   font-weight: 500 !important;
  //   font-size: small !important;
  //   font-family: Montserrat !important;
  //   color: #ffffff !important;

  //   background-color: #405467 !important;
  // }
  // .select {
  //   border-radius: 10px !important;
  //   border: 2px solid !important;
  //   font-weight: 500 !important;
  //   font-size: small !important;
  //   font-family: Montserrat !important;
  //   color: #405467 !important;
  //   box-shadow: 5px 10px 18px #dddd !important;
  //   background-color: white !important;
  // }

  // .filter-btn {
  //   background: none !important;
  //   font-size: 14px !important;
  //   border: 2px solid !important;
  //   font-weight: 500 !important;
  //   font-size: small !important;
  //   font-family: Montserrat !important;
  //   color: #405467 !important;
  //   border-radius: 10px !important;
  // }

  // .view-data {
  //   margin-left: 0 !important;
  // }
  // .dropdown {
  //   margin-top: 0 !important;
  // }
`;
export default Graph_Filter;
