import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Landing_Page_new from "../Admin.Pannel.Content/Landing_Page_new";
import Component_Display from "../Admin.Pannel.Content/Component_Display";
import Frontend_landing_page from "../Frontend.content/Frontend_landing_page";
import Login from "../Admin.Pannel.Content/Login.components/Login";
import Forget_Password from "../Admin.Pannel.Content/Login.components/Forget_Password";
import Change_Password from "../Admin.Pannel.Content/Login.components/Change_Password";
import Explore from "../Frontend.content/Modules/Explore";
import Compare from "../Frontend.content/Modules/Compare";

import Download from "../Frontend.content/Modules/Download";
const Routing = () => {
  const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        path={rest.path}
        render={
          (props) =>
            localStorage.getItem("token") ? (
              <div>
                <Component {...props} />
              </div>
            ) : (
              <Redirect to="/" />
            ) //To DO additional attributes
        }
      ></Route>
    );
  };

  const PublicRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        path={rest.path}
        render={(props) =>
          localStorage.getItem("token") ? (
            <Redirect to={"/dashboard"} />
          ) : (
            <div>
              <Component {...props} />
            </div>
          )
        }
      ></Route>
    );
  };

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Frontend_landing_page} />
          <Route path="/explore" component={Explore} />
          <Route path="/compare" component={Compare} />
          <Route path="/download" component={Download} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/forget-password" component={Forget_Password} />
          <ProtectedRoute path="/change-password" component={Change_Password} />
          <ProtectedRoute path="/dashboard" component={Landing_Page_new} />
          <ProtectedRoute path="/:slug" component={Component_Display} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default Routing;
