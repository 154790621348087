import React, { useContext, useState, useEffect } from "react";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import httpBrowsing_frontend from "../Utilities/httpBrowsing_frontend";
import styled from "styled-components";
import { useLocalStorage } from "../Utilities/Localstorage";
import { saveAs } from "file-saver";
import notification from "../Utilities/Notification";
import { nepaliToEnglishNumber } from "nepali-number";
import {
  Download_Date_to_context,
  Filter_clicked_Context,
  Download_Date_from_context,
  Fiscal_Year_Context,
  Selected_fiscal_year_Context,
  Right_indicator_id_Context,
  Add_Data_Context,
  Tost_Context,
  Indicator_Data_Context,
  currentPage_Context,
  Paginate_Data_Context,
  Province_Context,
  District_Context,
  Municipality_Context,
  Filter_Data_context,
  Total_Data_Context,
  Filter_First_Data_Context,
  Filter_Second_Data_Context,
  Second_Total_Data_Context,
  Compare_Graph_Context,
  Selected_first_Province_Context,
  Selected_first_District_Context,
  Selected_first_Municipality_Context,
  Municipality_Data_Context,
  province1_Context,
  province2_Context,
  bagmati_Context,
  gandaki_Context,
  sudurpaschim_Context,
  province5_Context,
  karnali_Context,
  Right_name_english_Context,
  Right_component_english_Context,
  Indicator_title_eng_Context,
  Right_name_nepali_Context,
  Right_component_nepali_Context,
  Indicator_title_Context,
  Prov1_Context,
  Prov2_Context,
  Prov3_Context,
  Prov4_Context,
  Prov5_Context,
  Prov6_Context,
  Prov7_Context,
  Province1_total_Context,
  Province2_total_Context,
  Province3_total_Context,
  Province4_total_Context,
  Province5_total_Context,
  Province6_total_Context,
  Province7_total_Context,
  Legend_array_Context,
  Map_filter_clicked_Context,
  Load_map_Context,
  Select_Province_dist_muni_map_Context,
  Is_chart_data_monthly_Context,
  Filtered_compared_Context,
} from "../Utilities/Store";
const Map_Filter = () => {
  const [Filtering, setFiltering] = useState(false);
  const [
    Select_Province_dist_muni_map,
    setSelect_Province_dist_muni_map,
  ] = useContext(Select_Province_dist_muni_map_Context);
  const [Load_map, setLoad_map] = useContext(Load_map_Context);
  const [Selected_clicked, setSelected_clicked] = useState(false); //used to select prov,dist,muni iin mobile mode
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title
  const [Paginate_Data, setPaginate_Data] = useContext(Paginate_Data_Context); //used to store pagination data
  const [Indicator_Data, setIndicator_Data] = useContext(
    Indicator_Data_Context
  ); //used to store the fetched data
  const [Total_Data, setTotal_Data] = useContext(Total_Data_Context);
  const [Tost, setTost] = useContext(Tost_Context);
  const [Add_Data, setAdd_Data] = useContext(Add_Data_Context); //when set true a model displays to give entry on data entry form

  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Fiscal_Year, setFiscal_Year] = useContext(Fiscal_Year_Context);
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [Filter_clicked, setFilter_clicked] = useState(false);
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [Municipality_Data, setMunicipality_Data] = useContext(
    Municipality_Data_Context
  );
  const [Province1_total, setProvince1_total] = useContext(
    Province1_total_Context
  );
  const [Province2_total, setProvince2_total] = useContext(
    Province2_total_Context
  );
  const [Province3_total, setProvince3_total] = useContext(
    Province3_total_Context
  );
  const [Province4_total, setProvince4_total] = useContext(
    Province4_total_Context
  );
  const [Province5_total, setProvince5_total] = useContext(
    Province5_total_Context
  );
  const [Province6_total, setProvince6_total] = useContext(
    Province6_total_Context
  );
  const [Province7_total, setProvince7_total] = useContext(
    Province7_total_Context
  );
  const [Is_chart_data_monthly, setIs_chart_data_monthly] = useContext(
    Is_chart_data_monthly_Context
  ); //used to set chart whether monthly of date wise
  const [Legend_array, setLegend_array] = useContext(Legend_array_Context);
  const [Prov1, setProv1] = useContext(Prov1_Context);
  const [Prov2, setProv2] = useContext(Prov2_Context);
  const [Prov3, setProv3] = useContext(Prov3_Context);
  const [Prov4, setProv4] = useContext(Prov4_Context);
  const [Prov5, setProv5] = useContext(Prov5_Context);
  const [Prov6, setProv6] = useContext(Prov6_Context);
  const [Prov7, setProv7] = useContext(Prov7_Context);
  const [province1, setProvince1] = useContext(province1_Context);
  const [province2, setProvince2] = useContext(province2_Context);
  const [bagmati, setBagmati] = useContext(bagmati_Context);
  const [gandaki, setGandaki] = useContext(gandaki_Context);
  const [province5, setProvince5] = useContext(province5_Context);
  const [karnali, setKarnali] = useContext(karnali_Context);
  const [sudurpaschim, setSudurpaschim] = useContext(sudurpaschim_Context);
  const [Filtered_compared, setFiltered_compared] = useContext(
    Filtered_compared_Context
  ); //used to set true when data is filtered or compared; //used to set true when data is filtered or compared
  const [currentPage, setCurrentPage] = useContext(currentPage_Context); //used to store the current page number selected in table pagination
  const [Filter_Data, setFilter_Data] = useContext(Filter_Data_context); //when 1st filter of selector buttom is clicked and data is entered the details is stored here.
  const [Province, setProvince] = useContext(Province_Context);
  const [District, setDistrict] = useContext(District_Context);
  const [Municipality, setMunicipality] = useContext(Municipality_Context);
  const [Compare_Graph, setCompare_Graph] = useContext(Compare_Graph_Context); //Is set true when user click  second buttom to compare the graph
  const [map_filter_clicked, setMap_filter_clicked] = useContext(
    Map_filter_clicked_Context
  );
  const [Filter_First_Data, setFilter_First_Data] = useContext(
    Filter_First_Data_Context
  );
  const [Filter_Second_Data, setFilter_Second_Data] = useContext(
    Filter_Second_Data_Context
  );
  const [Second_Total_Data, setSecond_Total_Data] = useContext(
    Second_Total_Data_Context
  ); //stores the comparison second entered data
  const [Selected_first_Province, setSelected_first_Province] = useContext(
    Selected_first_Province_Context
  );
  const [Selected_first_District, setSelected_first_District] = useContext(
    Selected_first_District_Context
  );
  const [
    Selected_first_Municipality,
    setSelected_first_Municipality,
  ] = useContext(Selected_first_Municipality_Context);
  let Selecting_District = District.filter(
    (item, i) => item.province === Number(Selected_first_Province.prov_id)
  );
  let Selecting_Municipality = Municipality.filter(
    (item) => item.district === Number(Selected_first_District.dist_id)
  );

  const handleChangeProvince = (id, name, code, bbox, nep_name) => {
    setSelected_first_Province({
      ...Selected_first_Province,
      prov_id: id,
      prov_name: name,

      code: code,

      bbox: bbox,
      prov_nep_name: nep_name,
    });
    setFiltered_compared(true);
    setSelected_first_District({
      dist_name: "",
      dist_id: "",
      dist_nep_name: "",
      code: "",

      bbox: "",
    });
    setSelected_first_Municipality({
      muni_name: "",
      muni_id: "",
      muni_nep_name: "",
      code: "",

      bbox: "",
      district: "",
    });

    setLoad_map(true);

    httpBrowsing_frontend
      .get(
        `/frontend/municipality/data/?indicator=${Right_indicator_id}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}&province=${id}`
      )
      .then((res) => {
        let Province_total_array = [];
        let Legend_array = [];
        setSelect_Province_dist_muni_map(res.data);

        Province_total_array = res.data.map((item) => item.total);
        Province_total_array.sort((a, b) => b - a);

        Legend_array = [
          0,
          Math.round(0 + Province_total_array[0] / 5),
          Math.round((0 + Province_total_array[0] / 5) * 2),
          Math.round((0 + Province_total_array[0] / 5) * 3),
          Math.round(Province_total_array[0]),
        ];
        setLegend_array(Legend_array);
        setLoad_map(false);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
  };

  // useEffect(() => {
  //   httpBrowsing_frontend
  //     .get(
  //       `/frontend/municipality/data/?indicator=${Right_indicator_id}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}&province=${Selected_first_Province.prov_id}`
  //     )
  //     .then((res) => {
  //       let Province_total_array = [];
  //       let Legend_array = [];
  //       setSelect_Province_dist_muni_map(res.data);

  //       Province_total_array = res.data.map((item) => item.total);
  //       Province_total_array.sort((a, b) => b - a);

  //       Legend_array = [
  //         0,
  //         Math.round(0 + Province_total_array[0] / 5),
  //         Math.round((0 + Province_total_array[0] / 5) * 2),
  //         Math.round((0 + Province_total_array[0] / 5) * 3),
  //         Math.round(Province_total_array[0]),
  //       ];
  //       setLegend_array(Legend_array);
  //       setLoad_map(false);
  //     })
  //     .catch((err) => {
  //       console.log("This is error>>>", err.response);
  //     });
  // }, [Selected_first_Province.prov_id]);
  const handleChangeDistrict = (id, name, code, bbox, nep_name) => {
    setSelected_first_District({
      dist_id: id,
      dist_name: name,
      code: code,
      bbox: bbox,
      dist_nep_name: nep_name,
    });
    setSelected_first_Municipality({
      muni_name: "",
      muni_id: "",
      muni_nep_name: "",
      code: "",

      bbox: "",
      district: "",
    });
    setFiltered_compared(true);
    setLoad_map(true);
    httpBrowsing_frontend
      .get(
        `/frontend/municipality/data/?indicator=${Right_indicator_id}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}&province=${Selected_first_Province.prov_id}&district=${id}`
      )
      .then((res) => {
        let Province_total_array = [];

        let Legend_array = [];
        setSelect_Province_dist_muni_map(res.data);

        Province_total_array = res.data.map((item) => item.total);
        Province_total_array.sort((a, b) => b - a);

        Legend_array = [
          0,
          Math.round(0 + Province_total_array[0] / 5),
          Math.round((0 + Province_total_array[0] / 5) * 2),
          Math.round((0 + Province_total_array[0] / 5) * 3),
          Math.round(Province_total_array[0]),
        ];
        setLegend_array(Legend_array);
        setLoad_map(false);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
  };
  const handleChangeMunicipality = (id, name, code, bbox, nep_name) => {
    setSelected_first_Municipality({
      muni_id: id,
      muni_name: name,
      code: code,
      bbox: bbox,
      muni_nep_name: nep_name,
    });
    setFiltered_compared(true);
    httpBrowsing_frontend
      .get(
        `/frontend/municipality/data/?indicator=${Right_indicator_id}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}&province=${Selected_first_Province.prov_id}&district=${Selected_first_District.dist_id}&municipality=${id}`
      )
      .then((res) => {
        let Province_total_array = [];

        let Legend_array = [];
        setSelect_Province_dist_muni_map(res.data);

        Province_total_array = res.data.map((item) => item.total);
        Province_total_array.sort((a, b) => b - a);

        Legend_array = [
          0,
          Math.round(0 + Province_total_array[0] / 5),
          Math.round((0 + Province_total_array[0] / 5) * 2),
          Math.round((0 + Province_total_array[0] / 5) * 3),
          Math.round(Province_total_array[0]),
        ];
        setLegend_array(Legend_array);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
  };

  const handleCancelFilter = (e) => {
    e.preventDefault();
    setSelected_clicked(false);
    setIs_chart_data_monthly(true);
    setSelected_first_Province({
      prov_name: "",
      prov_id: "",
      prov_nep_name: "",
      code: "",

      bbox: "",
    });
    setSelected_first_District({
      dist_name: "",
      dist_id: "",
      dist_nep_name: "",
      code: "",

      bbox: "",
    });
    setSelected_first_Municipality({
      muni_name: "",
      muni_id: "",
      muni_nep_name: "",
      code: "",

      bbox: "",
    });

    setSelected_fiscal_year("");
    setDownload_Date_from("");
    setDownload_Date_to("");
    setFiltered_compared(false);
    setLoad_map(true);
    httpBrowsing_frontend
      .get(`/frontend/municipality/data/?indicator=${Right_indicator_id}`)
      .then((res) => {
        let Province_total_array = [];

        let Legend_array = [];
        setProvince1_total(res.data[0].total);
        setProvince2_total(res.data[1].total);
        setProvince3_total(res.data[2].total);
        setProvince4_total(res.data[3].total);
        setProvince5_total(res.data[4].total);
        setProvince6_total(res.data[5].total);
        setProvince7_total(res.data[6].total);

        Province_total_array.push(res.data[0].total);
        Province_total_array.push(res.data[1].total);
        Province_total_array.push(res.data[2].total);
        Province_total_array.push(res.data[3].total);
        Province_total_array.push(res.data[4].total);
        Province_total_array.push(res.data[5].total);
        Province_total_array.push(res.data[6].total);
        Province_total_array.sort((a, b) => b - a);

        Legend_array = [
          0,
          Math.round(0 + Province_total_array[0] / 5),
          Math.round((0 + Province_total_array[0] / 5) * 2),
          Math.round((0 + Province_total_array[0] / 5) * 3),
          Math.round(Province_total_array[0]),
        ];
        setLegend_array(Legend_array);
        setLoad_map(false);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
  };

  const handleFilterDate_For_Map = (e) => {
    e.preventDefault();

    setFilter_clicked(false);
    setFiltered_compared(true);
    setLoad_map(true);
    setFiltering(true);
    setIs_chart_data_monthly(false);
    httpBrowsing_frontend
      .get(
        `/frontend/municipality/data/?indicator=${Right_indicator_id}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${nepaliToEnglishNumber(
          Selected_fiscal_year
        )}&province=${Selected_first_Province.prov_id}&district=${
          Selected_first_District.dist_id
        }&municipality=${Selected_first_Municipality.muni_id}`
      )
      .then((res) => {
        setFiltering(false);
        if (Selected_first_Province.prov_id) {
          let Province_total_array = [];

          let Legend_array = [];
          setSelect_Province_dist_muni_map(res.data);

          Province_total_array = res.data.map((item) => item.total);
          Province_total_array.sort((a, b) => b - a);

          Legend_array = [
            0,
            Math.round(0 + Province_total_array[0] / 5),
            Math.round((0 + Province_total_array[0] / 5) * 2),
            Math.round((0 + Province_total_array[0] / 5) * 3),
            Math.round(Province_total_array[0]),
          ];
          setLegend_array(Legend_array);
          setMap_filter_clicked(!map_filter_clicked);
          setLoad_map(false);
        } else {
          let Province_total_array = [];

          let Legend_array = [];
          setProvince1_total(res.data[0].total);
          setProvince2_total(res.data[1].total);
          setProvince3_total(res.data[2].total);
          setProvince4_total(res.data[3].total);
          setProvince5_total(res.data[4].total);
          setProvince6_total(res.data[5].total);
          setProvince7_total(res.data[6].total);

          Province_total_array.push(res.data[0].total);
          Province_total_array.push(res.data[1].total);
          Province_total_array.push(res.data[2].total);
          Province_total_array.push(res.data[3].total);
          Province_total_array.push(res.data[4].total);
          Province_total_array.push(res.data[5].total);
          Province_total_array.push(res.data[6].total);
          Province_total_array.sort((a, b) => b - a);

          Legend_array = [
            0,
            Math.round(0 + Province_total_array[0] / 5),
            Math.round((0 + Province_total_array[0] / 5) * 2),
            Math.round((0 + Province_total_array[0] / 5) * 3),
            Math.round(Province_total_array[0]),
          ];
          setLegend_array(Legend_array);
          setLoad_map(false);
        }
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
  };
  if (Selected_fiscal_year) {
    setSelected_fiscal_year(Selected_fiscal_year.substr(0, 4));
  }
  useEffect(() => {
    const reset_btn = () => {
      if (
        Selected_first_Province.prov_id ||
        Selected_fiscal_year ||
        Download_Date_to
      ) {
        setFiltered_compared(true);
      }
    };
    reset_btn();
  }, [Selected_first_Province.prov_id]);

  const handleDownload = () => {
    const canvasSave = document.getElementById("state-legend");
    notification.showSuccess("Downloading MAP !!!");
    canvasSave.toBlob(function (blob) {
      {
        Tost
          ? saveAs(
              blob,
              `${Right_name_english}_${Right_component_english}_${Indicator_title_eng}.png`
            )
          : saveAs(
              blob,
              `${Right_name_nepali}_${Right_component_nepali}_${Indicator_title}.png`
            );
      }
    });
  };

  return (
    <Wrapper>
      <div
        className="row contain main-map-filter-div"
        style={{ marginLeft: "10px" }}
      >
        <div className=" d-md-flex  flex-md-row bd-highlight">
          <div className="p-2 bd-highlight">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className={
                  Selected_first_Province.prov_name ? "selected" : "select "
                }
              >
                {Selected_first_Province.prov_name
                  ? Tost
                    ? Selected_first_Province.prov_name
                    : Selected_first_Province.prov_nep_name
                  : Tost
                  ? "SELECT PROVINCE"
                  : "प्रदेश छान्नुहोस् "}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Province.map((item, i) => (
                  <Dropdown.Item
                    key={i}
                    value={item.id}
                    onClick={() => {
                      handleChangeProvince(
                        item.id,
                        item.title_en,
                        item.code,
                        item.bbox,
                        item.title_ne
                      );
                      setCompare_Graph(false);
                      setFiltered_compared(true);
                    }}
                  >
                    {Tost ? item.title_en : item.title_ne}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className={
              Selected_first_Province.prov_name
                ? "p-2 bd-highlight"
                : "p-2 bd-highlight default"
            }
          >
            {Selected_first_Province.prov_name ? (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id=" dist_name"
                  className={
                    Selected_first_District.dist_name
                      ? "selected"
                      : Selected_first_Province.prov_name
                      ? "select"
                      : "select default"
                  }
                >
                  {Selected_first_District.dist_name
                    ? Tost
                      ? Selected_first_District.dist_name
                      : Selected_first_District.dist_nep_name
                    : Tost
                    ? "SELECT DISTRICT"
                    : "जिल्ला छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_District.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeDistrict(
                          item.id,
                          item.title_en,
                          item.code,
                          item.bbox,
                          item.title_ne
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled
                  className={
                    Selected_first_District.dist_name
                      ? "selected"
                      : Selected_first_Province.prov_name
                      ? "select"
                      : "select default"
                  }
                >
                  {Selected_first_District.dist_name
                    ? Tost
                      ? Selected_first_District.dist_name
                      : Selected_first_District.dist_nep_name
                    : Tost
                    ? "SELECT DISTRICT"
                    : "जिल्ला छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_District.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeDistrict(
                          item.id,
                          item.title_en,
                          item.code,
                          item.bbox,
                          item.title_ne
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div
            className={
              Selected_first_District.dist_name
                ? "p-2 bd-highlight"
                : "p-2 bd-highlight default"
            }
          >
            {Selected_first_District.dist_name ? (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic muni_name"
                  className={
                    Selected_first_Municipality.muni_name
                      ? "selected"
                      : Selected_first_District.dist_name
                      ? "select"
                      : "select default"
                  }
                >
                  {Selected_first_Municipality.muni_name
                    ? Tost
                      ? Selected_first_Municipality.muni_name
                      : Selected_first_Municipality.muni_nep_name
                    : Tost
                    ? "SELECT MUNICIPALITY"
                    : "पालिका छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_Municipality.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeMunicipality(
                          item.id,
                          item.title_en,
                          item.code,
                          item.bbox,
                          item.title_ne
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled
                  className={
                    Selected_first_Municipality.muni_name
                      ? "selected"
                      : Selected_first_District.dist_name
                      ? "select"
                      : "select default"
                  }
                >
                  {Selected_first_Municipality.muni_name
                    ? Tost
                      ? Selected_first_Municipality.muni_name
                      : Selected_first_Municipality.muni_nep_name
                    : Tost
                    ? "SELECT MUNICIPALITY"
                    : "पालिका छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_Municipality.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeMunicipality(
                          item.id,
                          item.title_en,
                          item.code,
                          item.bbox,
                          item.title_ne
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>

        <div className=" d-xl-flex flex-xl-row bd-highlight ">
          <div className="p-2 bd-highlight">
            <div className="filter">
              <img
                src="/filterbtn.png"
                alt="filter-icon"
                className="filter-icon big-screen"
                onClick={() => {
                  // setSelected_clicked(false);
                  setFilter_clicked(!Filter_clicked);
                }}
              />

              <Button
                variant="secondary"
                onClick={() => {
                  setSelected_clicked(false);
                  setFilter_clicked(!Filter_clicked);
                }}
                className="filter-btn small-screen"
              >
                <img src="/material-filter-list.svg" alt="filter" />{" "}
                {Tost ? "FILTER" : "फिल्टर"}
              </Button>

              {Filter_clicked ? (
                <div className="filter-data">
                  <div className="select-fiscal-year">
                    {Tost ? "Fiscal Year" : "आर्थिक बर्ष"}
                    <DropdownButton
                      id="dropdown-basic-button"
                      className="view-data"
                      title={
                        Selected_fiscal_year
                          ? Selected_fiscal_year
                          : Fiscal_Year[Fiscal_Year.length - 1]
                      }
                    >
                      {Fiscal_Year.map((item) => {
                        return (
                          <Dropdown.Item
                            key={item}
                            value={Selected_fiscal_year}
                            onClick={() => setSelected_fiscal_year(item)}
                          >
                            {item}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                  <div className="date-or-fiscal">
                    <hr />
                    <span>{Tost ? "OR" : "अथवा"}</span>
                  </div>
                  <div className="date-picker-main-div">
                    <div className="date-pick-main">
                      {Tost ? "From" : "देखि"}
                      <div className="date-pick">
                        <img src="/calendar-solid.svg" alt="calender-logo" />
                        <NepaliDatePicker
                          inputClassName="form-control"
                          className="calender"
                          value={Download_Date_from}
                          name="date"
                          onChange={(value) => setDownload_Date_from(value)}
                          options={{
                            calenderLocale: Tost ? "en" : "ne",
                            valueLocale: "en",
                          }}
                        />
                      </div>
                    </div>
                    <div className="dash">-</div>

                    <div className="date-pick-main">
                      {Tost ? "To" : "सम्म"}
                      <div className="date-pick">
                        <img src="/calendar-solid.svg" alt="calender-logo" />
                        <NepaliDatePicker
                          inputClassName="form-control"
                          className="calender"
                          value={Download_Date_to}
                          name="date"
                          onChange={(value) => setDownload_Date_to(value)}
                          options={{
                            calenderLocale: Tost ? "en" : "ne",
                            valueLocale: "en",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="submit-butn">
                    <button
                      className="btn btn-success"
                      type="submit"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      onClick={() => setFilter_clicked(false)}
                    >
                      CANCEL
                    </button>
                    {(setDownload_Date_from && Download_Date_to) ||
                    Selected_fiscal_year ? (
                      Filtering ? (
                        <button
                          className="btn btn-success"
                          type="submit"
                          disabled
                          onClick={handleFilterDate_For_Map}
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            color: "black",
                          }}
                        >
                          Filtering...
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          type="submit"
                          onClick={handleFilterDate_For_Map}
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            color: "black",
                          }}
                        >
                          DONE
                        </button>
                      )
                    ) : (
                      <button
                        className="btn "
                        type="submit"
                        disabled
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        DONE
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className=" d-xl-flex flex-xl-row bd-highlight ">
          <div className="bd-highlight" style={{ paddingTop: "5px" }}>
            {Filtered_compared ? (
              <img
                src="/reset.svg"
                alt="reset"
                style={{ cursor: "pointer" }}
                onClick={handleCancelFilter}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .btn:focus {
    box-shadow: none !important;
  }
  .btn-success.focus {
    box-shadow: none !important;
  }
  .btn-success:focus {
    box-shadow: none !important;
  }
  .dropdown-menu {
    z-index: 99999 !important;
  }
  .big-screen {
    display: none;
  }
  .filter-icon {
    height: 25px;
  }
  .dash {
    margin-top: 30px;
  }
  .date-pick img {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .date-pick {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
  }
  .filter-btn {
    display: flex;
    align-items: center;
    background: none !important;
    font-size: 14px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #405467 !important;
    border-radius: 10px !important;
  }
  .selected {
    border-radius: 10px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #ffffff !important;

    background-color: #405467 !important;
  }
  .select {
    border-radius: 10px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #405467 !important;
    box-shadow: 5px 10px 18px #dddd !important;
    background-color: white !important;
  }

  .contain {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .date-picker-main-div {
    display: flex;
  }
  .date-or-fiscal {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .date-pick-main {
    margin-left: 10px;
    margin-right: 10px;
  }
  .submit-butn button {
    border: 1px solid grey;
  }
  .filter-add-btn {
    margin-left: 15px;
    display: flex;
    position: relative;
    padding-top: 5px;
  }
  .select-fiscal-year {
    margin-left: 10px;
  }
  .filter-add-btn button {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .submit-butn button {
    margin-left: 5px;
    margin-right: 5px;
  }
  .submit-butn {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;
  }
  .date-or-fiscal span {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    margin-top: 5px;
  }
  .date-or-fiscal hr {
    width: 93%;
  }
  .nepali-date-picker .calender {
    width: max-content;
  }
  .filter-data {
    margin-left: 0px;
    margin-top: 0px;

    height: auto;
    width: 330px;
    display: flex;

    flex-direction: column;
    border-radius: 5px;
    position: absolute;

    background-color: white;
    box-shadow: 5px 10px 18px #ccc;
  }
  .form-control {
    background-color: white !important;
    border: none;
    cursor: pointer;
    width: 110px !important;
    border-radius: 5px;
  }
  .small-screen {
    display: inline;
  }
  @media only screen and (max-width: 700px) {
    .default {
      display: none;
    }
  }
  @media only screen and (max-width: 500px) {
    .main-map-filter-div {
      margin-top: 0px !important;
    }
    .big-screen {
      display: inline;
    }
    .small-screen {
      display: none;
    }
    .filter-data {
      right: 9%;
    }
    .default {
      display: none;
    }
    dropdown-menu show {
      z-index: 99999;
    }
  }

  @media only screen and (max-width: 1000px) {
    .date-picker-main-div {
      flex-direction: column;
    }
    .filter-data {
      width: 200px !important;
    }
    .view-data button {
      width: 180px !important;
    }
    .dash {
      margin-top: 0px !important;
      margin-left: 10px !important;
    }
  }

  .filter-data {
    z-index: 99999 !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .view-data button {
    width: 310px;
    justify-content: center;
  }
  .dropdown-item {
    justify-content: center;
  }
`;
export default Map_Filter;
