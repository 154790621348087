import React, { useEffect, useState, useContext } from "react";
import httpBrowsing from "../../Utilities/httpBrowsing";
import { Right_indicator_id_Context } from "../../Utilities/Store";
import { Line } from "react-chartjs-2";
import {
  Tost_Context,
  Province_Context,
  District_Context,
  Municipality_Context,
  Filter_Data_context,
  Total_Data_Context,
  Filter_First_Data_Context,
  Filter_Second_Data_Context,
  Second_Total_Data_Context,
  Compare_Graph_Context,
  Loading_Context,
  Table_Loader_Context,
} from "../../Utilities/Store";
import { useLocalStorage } from "../../Utilities/Localstorage";
const Graph = () => {
  const [Table_Loader, setTable_Loader] = useContext(Table_Loader_Context);
  const [Tost, setTost] = useContext(Tost_Context);
  const [Total_Data, setTotal_Data] = useContext(Total_Data_Context); //stores the data coming from fetched data from API
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [Loading, setLoading] = useContext(Loading_Context);
  const [Selected_Parameter, setSelected_Parameter] = useState(""); //this stores the selected parameter value selected from radio button click

  const [Province, setProvince] = useContext(Province_Context);
  const [District, setDistrict] = useContext(District_Context);
  const [Municipality, setMunicipality] = useContext(Municipality_Context);
  const [Second_Total_Data, setSecond_Total_Data] = useContext(
    Second_Total_Data_Context
  ); //stores the comparison second entered data
  const [Selected_Prov_dist_mun, setSelected_Prov_dist_mun] = useState({
    state: "select Province",
  }); //used to show selected prov/dist/muni in first click button
  const [
    Second_Selected_Prov_dist_mun,
    setSecond_Selected_Prov_dist_mun,
  ] = useState({ state: "select Province" }); //used to show selected prov/dist/muni in second click button
  const [Filter_Data, setFilter_Data] = useContext(Filter_Data_context); //when 1st filter of selector buttom is clicked and data is entered the details is stored here.
  const [Filter_First_Data, setFilter_First_Data] = useContext(
    Filter_First_Data_Context
  );
  const [Filter_Second_Data, setFilter_Second_Data] = useContext(
    Filter_Second_Data_Context
  );
  const [Compare_Graph, setCompare_Graph] = useContext(Compare_Graph_Context); //Is set true when user click  second buttom to compare the graph
  const [Right_name_english, setRight_name_english] = useLocalStorage(
    "Right_name_english",
    ""
  );
  const [Right_name_nepali, setRight_name_nepali] = useLocalStorage(
    "Right_name_nepali",
    ""
  );
  const [Indicator_title, setIndicator_title] = useLocalStorage(
    "Indicator_title",
    ""
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useLocalStorage(
    "Indicator_title_eng",
    ""
  ); //used to store the fetched english data title
  const [Right_component_english, setRight_component_english] = useLocalStorage(
    "Right_component_english",
    ""
  );
  const [Right_component_nepali, setRight_component_nepali] = useLocalStorage(
    "Right_component_nepali",
    ""
  );
  let Total_Count = [];
  if (Selected_Parameter === "total") {
    let data = Total_Data ? Total_Data.map((item) => item.total) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "male") {
    let data = Total_Data ? Total_Data.map((item) => item.male) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "female") {
    let data = Total_Data ? Total_Data.map((item) => item.female) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "other") {
    let data = Total_Data ? Total_Data.map((item) => item.other) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "under_18") {
    let data = Total_Data ? Total_Data.map((item) => item.under_18) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "adult") {
    let data = Total_Data ? Total_Data.map((item) => item.adult) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "senior") {
    let data = Total_Data ? Total_Data.map((item) => item.senior) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "passed") {
    let data = Total_Data ? Total_Data.map((item) => item.passed) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "amount") {
    let data = Total_Data ? Total_Data.map((item) => item.amount) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "failed") {
    let data = Total_Data ? Total_Data.map((item) => item.failed) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else {
    let data = Total_Data ? Total_Data.map((item) => item.total) : "";
    Total_Count.push(data);
  }
  let Total_Date = Total_Data ? Total_Data.map((item) => item.date) : ""; //variable map the total dates for chart when first buttom is clicked
  let Second_Total_Count = Compare_Graph
    ? Second_Total_Data
      ? Second_Total_Data.map((item) => item.total)
      : ""
    : "";
  let Second_Total_Date = Compare_Graph
    ? Second_Total_Data
      ? Second_Total_Data.map((item) => item.date)
      : ""
    : "";

  {
    /** This function is run when user select the province/municipality/district from location */
  }
  let Selected_prov_dist_mun_btn;
  if (Selected_Prov_dist_mun.province) {
    Selected_prov_dist_mun_btn = Province.filter(
      (item) => item.id === Number(Selected_Prov_dist_mun.province)
    ).map((item) => item.title_en);
  } else if (Selected_Prov_dist_mun.district) {
    Selected_prov_dist_mun_btn = District.filter(
      (item) => item.id === Number(Selected_Prov_dist_mun.district)
    ).map((item) => item.title_en);
  } else if (Selected_Prov_dist_mun.municipality) {
    Selected_prov_dist_mun_btn = Municipality.filter(
      (item) => item.id === Number(Selected_Prov_dist_mun.municipality)
    ).map((item) => item.title_en);
  } else {
    Selected_prov_dist_mun_btn = Selected_Prov_dist_mun.state;
  }

  {
    /** This function is run when user select the province/municipality/district from location when second compare button is clicked */
  }
  let Second_Selected_prov_dist_mun_btn;
  if (Second_Selected_Prov_dist_mun.province) {
    Second_Selected_prov_dist_mun_btn = Province.filter(
      (item) => item.id === Number(Second_Selected_Prov_dist_mun.province)
    ).map((item) => item.title_en);
  } else if (Second_Selected_Prov_dist_mun.district) {
    Second_Selected_prov_dist_mun_btn = District.filter(
      (item) => item.id === Number(Second_Selected_Prov_dist_mun.district)
    ).map((item) => item.title_en);
  } else if (Second_Selected_Prov_dist_mun.municipality) {
    Second_Selected_prov_dist_mun_btn = Municipality.filter(
      (item) => item.id === Number(Second_Selected_Prov_dist_mun.municipality)
    ).map((item) => item.title_en);
  } else {
    Second_Selected_prov_dist_mun_btn = Second_Selected_Prov_dist_mun.state;
  }

  const Line_Chart = {
    labels: Total_Date,
    datasets: [
      {
        label:
          Filter_Data.province_name &&
          Filter_Data.district_name &&
          Filter_Data.municipality_name
            ? Tost
              ? Filter_Data.municipality_name
              : Filter_Data.municipality_name_nep
            : Filter_Data.province_name && Filter_Data.district_name
            ? Tost
              ? Filter_Data.district_name
              : Filter_Data.district_name_nep
            : Filter_Data.province_name
            ? Tost
              ? Filter_Data.province_name
              : Filter_Data.province_name_nep
            : Tost
            ? "Total Data"
            : "कुल संख्या",
        position: "bottom",
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        data: Total_Count[0],
      },
    ],
  };

  const Selected_Line_Chart = {
    labels:
      Total_Date.length > Second_Total_Date.length
        ? Total_Date
        : Second_Total_Date,
    datasets: [
      {
        label:
          Filter_First_Data.province_name &&
          Filter_First_Data.district_name &&
          Filter_First_Data.municipality_name
            ? Tost
              ? Filter_First_Data.municipality_name
              : Filter_First_Data.municipality_name_nep
            : Filter_First_Data.province_name && Filter_First_Data.district_name
            ? Tost
              ? Filter_First_Data.district_name
              : Filter_First_Data.district_name_nep
            : Filter_First_Data.province_name
            ? Tost
              ? Filter_First_Data.province_name
              : Filter_First_Data.province_name_nep
            : Tost
            ? "Total Data"
            : "कुल संख्या",
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        data: Total_Count[0],
      },
      {
        label:
          Filter_Second_Data.province_name &&
          Filter_Second_Data.district_name &&
          Filter_Second_Data.municipality_name
            ? Tost
              ? Filter_Second_Data.municipality_name
              : Filter_Second_Data.municipality_name_nep
            : Filter_Second_Data.province_name &&
              Filter_Second_Data.district_name
            ? Tost
              ? Filter_Second_Data.district_name
              : Filter_Second_Data.district_name_nep
            : Filter_Second_Data.province_name
            ? Tost
              ? Filter_Second_Data.province_name
              : Filter_Second_Data.province_name_nep
            : Tost
            ? "Total Data"
            : "कुल संख्या",
        fill: false,
        backgroundColor: "green",
        borderColor: "#742774",
        backgroundColor: "#742774",
        data: Second_Total_Count,
      },
    ],
  };

  useEffect(() => {
    httpBrowsing
      .get(
        `/eng/indicator-data/graph?indicator=${Right_indicator_id}&province=${Filter_Data.province}&district=${Filter_Data.district}&municipality=${Filter_Data.municipality}`
      )
      .then((res) => {
        setTotal_Data(res.data.data);

        setRight_name_english(res.data.right_en);
        setRight_name_nepali(res.data.right_ne);
        setRight_component_english(res.data.right_component_en);
        setRight_component_nepali(res.data.right_component_ne);
        setIndicator_title_eng(res.data.indicator_title_en);
        setIndicator_title(res.data.indicator_title_ne);
        setTable_Loader(false);
      })
      .catch((err) => {
        console.log("Err", err.response);
      });
  }, [
    Right_indicator_id,
    Filter_Data.province,
    Filter_Data.district,
    Filter_Data.municipality,
  ]);
  return (
    <div className="row">
      <div className="col-12">
        {" "}
        {Total_Data.length ? (
          <Line
            id="capture"
            data={Compare_Graph ? Selected_Line_Chart : Line_Chart}
            options={{
              responsive: true,
              title: {
                display: true,
                text: Tost
                  ? `${Right_name_english}/${Right_component_english}/${Indicator_title_eng}`
                  : `${Right_name_nepali}/${Right_component_nepali}/${Indicator_title}`,
                position: "top",
              },
              legend: {
                display: true,
                position: "bottom",
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      fontColor: "black",
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      fontColor: "black",
                    },
                  },
                ],
              },
            }}
            width={800}
            height={350}
          />
        ) : (
          <p className="no-data" style={{ textAlign: "center" }}>
            No Data Available
          </p>
        )}
      </div>
    </div>
  );
};

export default Graph;
