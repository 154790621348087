import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import httpBrowsing from "../Utilities/httpBrowsing";
import Loader from "react-loader";
import {
  Right_component_id_Context,
  Right_indicator_id_Context,
  RightID_Context,
  Tost_Context,
  Sidebar_Data_Context,
  Selected_Right_RightComponents_Context,
  Selected_RightComponents_indicator_Context,
  Right_name_Context,
  sidebar_Clicked_Context,
  Loading_Context,
  Table_Loader_Context,
  Right_name_english_Context,
  Right_name_nepali_Context,
} from "../Utilities/Store";
import httpBrowsing_frontend from "../Utilities/httpBrowsing_frontend";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Frontend_Sidebar = (props) => {
  const [Tost, setTost] = useContext(Tost_Context);
  const [Sidebar_Data, setSidebar_Data] = useContext(Sidebar_Data_Context); //all fetched sidebar data is stored here
  const [Show_Right_Components, setShow_Right_Components] = useState(true); //Onclicking right flag changes on choosing right components
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );

  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [sidebar_Clicked, setSidebar_Clicked] = useContext(
    sidebar_Clicked_Context
  ); //first level sidebar clicked
  const [Loading, setLoading] = useContext(Loading_Context);
  const [RightID, setRightID] = useContext(RightID_Context);

  const [Right_component_id, setRight_component_id] = useContext(
    Right_component_id_Context
  );
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [Table_Loader, setTable_Loader] = useContext(Table_Loader_Context);
  const [Right_name, setRight_name] = useContext(Right_name_Context);
  const [
    Selected_Right_RightComponents,
    setSelected_Right_RightComponents,
  ] = useContext(Selected_Right_RightComponents_Context);
  const [
    Selected_RightComponents_indicator,
    setSelected_RightComponents_indicator,
  ] = useContext(Selected_RightComponents_indicator_Context);
  const handleRight = (id, name) => {
    setRightID(id);
    setRight_name(name);
    setRight_name_english(name);
    setRight_name_nepali(name);
    setShow_Right_Components(true);
    setSidebar_Clicked(true);
  };

  useEffect(() => {
    httpBrowsing_frontend
      .get(`/frontend/navbar/${Tost ? "eng" : "nep"}/`)
      .then((res) => {
        setSidebar_Data(res.data.data);
        setRight_name(res.data.data[0].title);
        setLoading(false);
        if (RightID === null) {
          setRightID(res.data.data[0].id);
          setRight_component_id(res.data.data[0].component[0].id);
          setRight_indicator_id(res.data.data[0].component[0].indicator[0].id);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  // useEffect(() => {
  //   if (Sidebar_Data.length) {
  //     setSelected_Right_RightComponents(
  //       Sidebar_Data.filter((item, i) => item.id === RightID).map(
  //         item => item.component
  //       )
  //     );
  //   }
  // }, [RightID]);

  // useEffect(() => {
  //   if (Selected_Right_RightComponents.length) {
  //     setSelected_RightComponents_indicator(
  //       Selected_Right_RightComponents[0]
  //         .filter((item, i) => item.id === Right_component_id)
  //         .map(item => item.indicator)
  //     );
  //   }
  // }, [Selected_Right_RightComponents]);

  let Right_components = [];
  if (Sidebar_Data) {
    Right_components = Sidebar_Data.filter(
      (item, i) => item.id === RightID
    ).map((item) => item.component);
  }
  console.log("Sidebar data>>>", Sidebar_Data);
  let Indicators = [];
  if (Right_components.length) {
    Indicators = Right_components[0]
      .filter((item, i) => item.id === Right_component_id)
      .map((item) => item.indicator);
  }
  const handleIndicatorClicked = (id) => {
    setRight_indicator_id(id);
  };
  return (
    <React.Fragment>
      <Tester>
        <div className="clicking-rights">
          <div
            className={
              Show_Right_Components
                ? "main-sidebar-content"
                : "bg-light border-right"
            }
            id="sidebar-wrapper"
          >
            <div
              className={
                Show_Right_Components
                  ? "main-sidebar-content "
                  : "list-group list-group-flush"
              }
            >
              <div className="sidebar-1-design">
                {Sidebar_Data
                  ? Sidebar_Data.map((item, i) => {
                      return (
                        <div
                          className={
                            RightID === item.id
                              ? "right-container right-container-clicked"
                              : "right-container"
                          }
                          key={item.id}
                        >
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip
                                style={
                                  window.screen.width < 500
                                    ? { display: "none" }
                                    : ""
                                }
                              >
                                {item.title}
                              </Tooltip>
                            }
                          >
                            <a
                              // to={`/${props.datal}`}
                              className="right-image"
                              onClick={() => handleRight(item.id, item.title)}
                            >
                              {" "}
                              <img src={item.icon} alt="img" />
                            </a>
                          </OverlayTrigger>
                          <a
                            onClick={() => handleRight(item.id, item.title)}
                            className={
                              Show_Right_Components
                                ? "right-name-display-sidebar"
                                : "list-group-item list-group-item-action bg-light"
                            }
                          >
                            {item.title}
                          </a>
                        </div>
                      );
                    })
                  : ""}
                {sidebar_Clicked ? (
                  <i
                    className="fa fa-chevron-left"
                    onClick={() => setSidebar_Clicked(!sidebar_Clicked)}
                  ></i>
                ) : (
                  <i
                    className="fa fa-chevron-right"
                    onClick={() => setSidebar_Clicked(!sidebar_Clicked)}
                  ></i>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              sidebar_Clicked ? "secondary-sidebar" : "secondary-sidebar-begin"
            }
          >
            {Tost ? (
              sidebar_Clicked ? (
                <div style={{ display: "flex" }}>
                  <h5 style={{ fontSize: "18px", lineHeight: "1.4" }}>
                    {Right_name_english}
                  </h5>
                  <img
                    src="/information-circle.svg"
                    style={{
                      marginLeft: "auto",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                    alt="info"
                  />
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <h5 style={{ display: "none" }}> {Right_name_english}</h5>
                  <img
                    src="/information-circle.svg"
                    style={{
                      marginLeft: "auto",
                      marginRight: "20px",
                      marginBottom: "20px",
                      display: "none",
                    }}
                    alt="info"
                  />
                </div>
              )
            ) : sidebar_Clicked ? (
              <div style={{ display: "flex" }}>
                <h5 style={{ fontSize: "18px", lineHeight: "1.4" }}>
                  {Right_name_nepali}
                </h5>
                <img
                  src="/information-circle.svg"
                  style={{
                    marginLeft: "auto",
                    marginRight: "20px",
                    marginBottom: "20px",
                  }}
                  alt="info"
                />
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <h5 style={{ display: "none" }}> {Right_name_nepali}</h5>
                <img
                  src="/information-circle.svg"
                  style={{
                    marginLeft: "auto",
                    marginRight: "20px",
                    marginBottom: "20px",
                    display: "none",
                  }}
                  alt="info"
                />
              </div>
            )}

            {Show_Right_Components && Right_components.length
              ? Right_components[0].map((item) => {
                  return sidebar_Clicked ? (
                    <div key={item.id} className="right-component">
                      <p onClick={() => setRight_component_id(item.id)}>
                        {item.title}
                      </p>
                      {item.id === Right_component_id
                        ? Indicators.length
                          ? Indicators[0].map((item) => {
                              return (
                                <div
                                  key={item.id}
                                  className={
                                    Right_indicator_id === item.id
                                      ? "indicator-clicked"
                                      : "indicator-not-clicked"
                                  }
                                >
                                  {/* <li
                                      onClick={() =>
                                        setRight_indicator_id(item.id)
                                      }
                                    > */}
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip
                                        style={
                                          window.screen.width < 500
                                            ? { display: "none" }
                                            : ""
                                        }
                                      >
                                        {Tost
                                          ? item.description_en
                                          : item.description}
                                      </Tooltip>
                                    }
                                  >
                                    <a
                                      onClick={() => {
                                        setRight_indicator_id(item.id);
                                      }}
                                      // to={`/${props.datal}`}
                                      // to={{
                                      //   pathname: `/${props.data}`,
                                      //   state: {
                                      //     indicators_data_id: item.id,
                                      //     indicator_data_name: item.title,
                                      //   },
                                      // }}
                                    >
                                      {" "}
                                      {item.title}
                                    </a>
                                  </OverlayTrigger>
                                  {/* </li> */}
                                </div>
                              );
                            })
                          : ""
                        : ""}
                    </div>
                  ) : (
                    <div
                      key={item.id}
                      className="right-component"
                      style={{ display: "none" }}
                    >
                      <p onClick={() => setRight_component_id(item.id)}>
                        {item.title}
                      </p>

                      {item.id === Right_component_id
                        ? Indicators.length
                          ? Indicators[0].map((item) => {
                              return (
                                <div
                                  key={item.id}
                                  className={
                                    Right_indicator_id === item.id
                                      ? "indicator-clicked"
                                      : "indicator-not-clicked"
                                  }
                                >
                                  {/* <li
                                      onClick={() =>
                                        setRight_indicator_id(item.id)
                                      }
                                    > */}
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip
                                        style={
                                          window.screen.width < 500
                                            ? { display: "none" }
                                            : ""
                                        }
                                      >
                                        {Tost
                                          ? item.description_en
                                          : item.description}
                                      </Tooltip>
                                    }
                                  >
                                    <Link
                                      onClick={() =>
                                        handleIndicatorClicked(item.id)
                                      }
                                      to={{
                                        // pathname: `/${Right_name}`,
                                        pathname: `/${props.datal}`,
                                        // state: {
                                        //   indicators_data_id: item.id,
                                        //   indicator_data_name: item.title,
                                        // },
                                      }}
                                    >
                                      {" "}
                                      {item.title}
                                    </Link>
                                  </OverlayTrigger>
                                  {/* </li> */}
                                </div>
                              );
                            })
                          : ""
                        : ""}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </Tester>
    </React.Fragment>
  );
};
const Tester = styled.div`
  .right-component a {
    cursor: pointer;
  }
  .right-image img {
    max-width: 22px !important;
    max-height: 22px !important;
    margin: 0 auto !important;
  }

  @media screen and (max-width: 414px) {
    .right-container {
      width: 40px !important;
    }
    .right-image img {
      height: -webkit-fill-available !important;
    }
    .main-sidebar-content {
      width: 40px !important;
    }
  }
  @media screen and (max-width: 411px) {
    .right-container {
      width: 40px !important;
    }
    .right-image img {
      max-width: 22px;
      max-height: 22px;
      margin: 0 auto;
    }
    .main-sidebar-content {
      width: 40px !important;
    }
  }
  @media screen and (max-width: 375px) {
    .right-container {
      width: 40px !important;
    }
    .right-image img {
      height: -webkit-fill-available !important;
    }
    .main-sidebar-content {
      width: 40px !important;
    }
  }
  @media screen and (max-width: 360px) {
    .right-container {
      width: 40px !important;
    }
    .right-image img {
      height: -webkit-fill-available !important;
    }
    .main-sidebar-content {
      width: 40px !important;
    }
  }
  #sidebar-wrapper {
    margin-left: 0px !important;
  }

  .right-component p {
    cursor: pointer;
  }
  .indicator-clicked {
    border-left: 2px solid orange;
    margin-left: 5px;
    padding-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .indicator-clicked a {
    font-size: 14px;
    text-decoration: none;
    color: #717171;
  }
  .indicator-not-clicked {
    margin-left: 5px;
    padding-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .indicator-not-clicked a {
    font-size: 14px;
    text-decoration: none;
    color: #949494;
  }
  .right-container-clicked {
    border-left: 4px solid orange;
  }
  .secondary-sidebar-begin {
    width: 0;
    transition: 0.5s width;
  }
  .secondary-sidebar {
    width: 200px !important;
    padding-top: 30px;
    transition: 0.5s width;
  }
  .secondary-sidebar h5 {
    margin-left: 15px;
    font-size: 15px;
    color: #405467;
    font-weight: 600;
  }
  .right-component {
    margin-left: 15px !important;
  }
  .right-component p {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
  }
  .sidebar-1-design .right-image {
    border: 1px solid #d9dadb !important;
  }
  .right-image {
    margin-left: auto;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: flex;
    flex-direction: column;
  }
  .right-image img {
    margin-left: 5px;
    margin-right: 5px;

    max-width: 22px;
    max-height: 22px;
    margin: 0 auto;
  }
  .right-container {
    display: flex;
    background-color: #405467;
    cursor: pointer;
    width: 60px;
    height: 60px;
  }
  .clicking-rights {
    display: flex;
    position: sticky;
    top: 0px;
  }
  // .main-sidebar-content {
  //   width: 80px;
  // }
  .main-sidebar-content-hide {
    width: 80px;
  }
  .right-name-display-sidebar {
    display: none;
  }
  .sidebar-1-design {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .sidebar-1-design .right-image {
    display: flex;
    width: 80px;
    justify-content: center;
  }
  .test-table {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }
  .fiscal-year-filter {
    display: flex;
    margin-left: auto;
  }
  .fiscal-year-filter p {
    margin-top: 16px;
  }
  .fiscal-year button {
    border: none !important;
  }
  .fiscal-year {
    margin-top: 0px !important;
  }
  .main-body {
    width: 100%;
  }
  .filter-add-btn {
    margin-left: 15px;
    display: flex;
  }
  .filter-add-btn button {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .view-data {
    margin-left: auto;
    margin-right: 20px !important;
  }
  .dropdown {
    margin-top: 10px;
    margin-right: 10px;
    color: black;
  }
  .dropdown button {
    background-color: white;
    color: black;
    border: 1px solid;
  }
  .filter-div {
    display: flex;
  }
  .filter-div h1 {
    margin-left: 20px;
  }
  .breadcrumb {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .bread-crumb {
    width: fit-content;
    margin-right: 10px;
  }
  .lang-btn {
    margin-top: 20px;
  }
  .account-logo {
    margin-right: 5px;
  }
  .selected-language {
    background-color: #06356a !important;
  }
  .nep {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .eng {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    padding-right: 20px;
    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .toggle-on-click {
    left: 230px;
  }
  .main-content {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-brand img {
    height: 51px;
  }
  // .Sidebar-menu {
  //   border: none;
  //   background-color: #f8f9fa;
  //   color: black;
  // }
  // button.sidebar-menu :hover {
  //   background-color: transparent !important;
  //   text-decoration: none;
  // }
  #menu-toggle {
    position: absolute;
    top: 50vh;
  }
  #page-content-wrapper {
    height: 100vh;
  }
  /*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
  #wrapper {
    overflow-x: hidden;
  }
  #sidebar-wrapper {
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }
  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  #sidebar-wrapper .list-group2 {
    width: 0px !important;
  }
  #page-content-wrapper {
    min-width: 100vw;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }
  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }
    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }
    // #wrapper.toggled #sidebar-wrapper {
    //   margin-left: -15rem;
    // }
  }
`;
export default Frontend_Sidebar;
