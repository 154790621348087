import React, { useState, useContext, useEffect } from "react";
import { Dropdown, Button, DropdownButton } from "react-bootstrap";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import httpBrowsing_frontend from "../Utilities/httpBrowsing_frontend";
import styled from "styled-components";
import notification from "./../Utilities/Notification";
import {
  Download_Date_to_context,
  Filter_clicked_Context,
  Download_Date_from_context,
  Fiscal_Year_Context,
  Selected_fiscal_year_Context,
  Right_indicator_id_Context,
  Add_Data_Context,
  Tost_Context,
  Indicator_Data_Context,
  currentPage_Context,
  Paginate_Data_Context,
  page_size_Context,
  Filter_Data_context,
  District_Context,
  Municipality_Context,
  Province_Context,
  Selected_first_Municipality_Context,
  Selected_first_Province_Context,
  Selected_first_District_Context,
  Select_Province_dist_muni_map_Context,
  Load_map_Context,
  Legend_array_Context,
  Is_chart_data_monthly_Context,
} from "../Utilities/Store";
const Table_Filter = () => {
  const [Legend_array, setLegend_array] = useContext(Legend_array_Context);
  const [Filtering, setFiltering] = useState(false);
  const [Filter_Data, setFilter_Data] = useContext(Filter_Data_context); //when 1st filter of selector buttom is clicked and data is entered the details is stored here.
  const [Paginate_Data, setPaginate_Data] = useContext(Paginate_Data_Context); //used to store pagination data
  const [Indicator_Data, setIndicator_Data] = useContext(
    Indicator_Data_Context
  ); //used to store the fetched data
  const [Tost, setTost] = useContext(Tost_Context);
  const [Add_Data, setAdd_Data] = useContext(Add_Data_Context); //when set true a model displays to give entry on data entry form
  const [Filter_clicked, setFilter_clicked] = useContext(
    Filter_clicked_Context
  ); //is set true when filter button is clicked
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Fiscal_Year, setFiscal_Year] = useContext(Fiscal_Year_Context);
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [page_size, setPage_size] = useContext(page_size_Context);
  const [Is_chart_data_monthly, setIs_chart_data_monthly] = useContext(
    Is_chart_data_monthly_Context
  ); //used to set chart whether monthly of date wise
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [Load_map, setLoad_map] = useContext(Load_map_Context);
  const [
    Select_Province_dist_muni_map,
    setSelect_Province_dist_muni_map,
  ] = useContext(Select_Province_dist_muni_map_Context);
  const [Filter_Province, setFilter_Province] = useState("");
  const [Filter_District, setFilter_District] = useState("");
  const [Filter_Municipality, setFilter_Municipality] = useState("");
  const [Filtered_compared, setFiltered_compared] = useState(false); //used to set true when data is filtered or compared
  const [currentPage, setCurrentPage] = useContext(currentPage_Context); //used to store the current page number selected in table pagination
  const [Province, setProvince] = useContext(Province_Context);
  const [District, setDistrict] = useContext(District_Context);
  const [Municipality, setMunicipality] = useContext(Municipality_Context);
  const [Selected_first_Province, setSelected_first_Province] = useContext(
    Selected_first_Province_Context
  );
  const [Selected_first_District, setSelected_first_District] = useContext(
    Selected_first_District_Context
  );
  const [
    Selected_first_Municipality,
    setSelected_first_Municipality,
  ] = useContext(Selected_first_Municipality_Context);
  let Selecting_District = District.filter(
    (item, i) => item.province === Number(Filter_Data.province)
  );
  let Selecting_Municipality = Municipality.filter(
    (item) => item.district === Number(Filter_Data.district)
  );
  // useEffect(() => {
  //   httpBrowsing_frontend
  //     .get(`/frontend/get-years/nep/`)
  //     .then((res) => {
  //       setFiscal_Year(res.data);
  //     })
  //     .catch((err) => {
  //       console.log("This is error>>>", err.response);
  //     });
  // }, []);
  const handleFilterDate_For_Table = (e) => {
    e.preventDefault();
    setIs_chart_data_monthly(false);
    // setSelected_fiscal_year("");
    setFiltering(true);
    httpBrowsing_frontend
      .get(
        `/frontend/data/${
          Tost ? "eng" : "nep"
        }/?indicator=${Right_indicator_id}&page=${currentPage}&page_size=${page_size}&fiscal_year=${Selected_fiscal_year}&start=${Download_Date_from}&end=${Download_Date_to}&province=${
          Filter_Data.province
        }&district=${Filter_Data.district}&municipality=${
          Filter_Data.municipality
        }`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setFiltered_compared(true);
        setFilter_clicked(false);
        setPaginate_Data(res.data);
        setFiltering(false);
      })
      .catch((err) => {
        console.log("This is final error>>>", err.response);
      });
  };
  const handleCancelFilter = (e) => {
    e.preventDefault();
    setIs_chart_data_monthly(true);
    setSelected_fiscal_year("");
    setDownload_Date_from("");
    setDownload_Date_to("");

    setFilter_Data({
      province: "",
      municipality: "",
      district: "",
      province_name: "",
      district_name: "",
      municipality_name: "",
      province_name_nep: "",
      district_name_nep: "",
      municipality_name_nep: "",
    });
    setSelected_first_Province({
      prov_name: "",
      prov_id: "",
      prov_nep_name: "",
      code: "",

      bbox: "",
    });
    setSelected_first_District({
      dist_name: "",
      dist_id: "",
      dist_nep_name: "",
      code: "",

      bbox: "",
    });
    setSelected_first_Municipality({
      muni_name: "",
      muni_id: "",
      muni_nep_name: "",
      code: "",
      bbox: "",
    });
    httpBrowsing_frontend
      .get(
        `/frontend/data/${
          Tost ? "eng" : "nep"
        }/?indicator=${Right_indicator_id}&page=${currentPage}&page_size=${page_size}`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setFiltered_compared(false);
        setFilter_clicked(false);
        setPaginate_Data(res.data);
      })
      .catch((err) => {
        console.log("This is final error>>>", err.response);
      });
  };
  // useEffect(() => {
  //   const Verifying_user = () => {
  //     httpBrowsing_frontend.get("/nep/me/").then((res) => {
  //       setFilter_Province(res.data.province);
  //       setFilter_District(res.data.district);
  //       setFilter_Municipality(res.data.municipality);
  //     });
  //   };
  //   Verifying_user();
  // }, []);

  const handleSubmit_to_download = (e) => {
    e.preventDefault();

    httpBrowsing_frontend
      .get(
        `/frontend/data/download/${
          Tost ? "eng" : "nep"
        }/?indicator=${Right_indicator_id}&province=${Filter_Province}&district=${Filter_District}&municipality=${Filter_Municipality}&fiscal_year=${Selected_fiscal_year}&start=${Download_Date_from}&end=${Download_Date_to}`
      )
      .then((res) => {
        window.open(res.data, "_self");

        notification.showSuccess("Downloading CSV!!!");
      })
      .catch((err) => {
        console.log("ths is error>>", err.response);
      });
  };

  const handleChangeProvince = (id, name, nepali_name, code, bbox) => {
    setFilter_Data({
      ...Filter_Data,
      province: id,

      district: "",

      municipality: "",

      province_name: name,
      district_name: "",
      municipality_name: "",
      province_name_nep: nepali_name,
      district_name_nep: "",
      municipality_name_nep: "",
    });
  };

  const handleChangeDistrict = (id, name, nepali_name, code, bbox) => {
    setFilter_Data({
      ...Filter_Data,
      district: id,

      municipality: "",
      district_name: name,

      municipality_name: "",

      district_name_nep: nepali_name,
      municipality_name_nep: "",
    });
  };
  const handleChangeMunicipality = (id, name, nepali_name, code, bbox) => {
    setFilter_Data({
      ...Filter_Data,
      municipality: id,
      municipality_name: name,

      municipality_name_nep: nepali_name,
    });
  };

  if (Selected_fiscal_year) {
    setSelected_fiscal_year(Selected_fiscal_year.substr(0, 4));
  }

  useEffect(() => {
    const reset_btn = () => {
      if (Filter_Data.province || Selected_fiscal_year || Download_Date_to) {
        setFiltered_compared(true);
      }
    };
    reset_btn();
  }, [Filter_Data.province]);
  return (
    <Wrapper>
      <div
        className="row contain main-table-filter-div"
        style={{ marginLeft: "10px" }}
      >
        <div className=" d-md-flex  flex-md-row bd-highlight">
          <div className="p-2 bd-highlight">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className={Filter_Data.province_name ? "selected" : "select"}
              >
                {Filter_Data.province_name
                  ? Tost
                    ? Filter_Data.province_name
                    : Filter_Data.province_name_nep
                  : Tost
                  ? "SELECT PROVINCE"
                  : "प्रदेश छान्नुहोस् "}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Province.map((item, i) => (
                  <Dropdown.Item
                    key={i}
                    value={item.id}
                    onClick={() => {
                      handleChangeProvince(
                        item.id,
                        item.title_en,
                        item.title_ne,
                        item.code,
                        item.bbox
                      );

                      setFiltered_compared(true);
                    }}
                  >
                    {Tost ? item.title_en : item.title_ne}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className={
              Filter_Data.province_name
                ? "p-2 bd-highlight"
                : "p-2 bd-highlight default"
            }
          >
            {Filter_Data.province_name ? (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className={
                    Filter_Data.district_name
                      ? "selected"
                      : Filter_Data.province_name
                      ? "select"
                      : "select default"
                  }
                >
                  {Filter_Data.district_name
                    ? Tost
                      ? Filter_Data.district_name
                      : Filter_Data.district_name_nep
                    : Tost
                    ? "SELECT DISTRICT"
                    : "जिल्ला छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_District.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeDistrict(
                          item.id,
                          item.title_en,
                          item.title_ne,
                          item.code,
                          item.bbox
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled
                  className={
                    Filter_Data.district_name
                      ? "selected"
                      : Filter_Data.province_name
                      ? "select"
                      : "select default"
                  }
                >
                  {Filter_Data.district_name
                    ? Tost
                      ? Filter_Data.district_name
                      : Filter_Data.district_name_nep
                    : Tost
                    ? "SELECT DISTRICT"
                    : "जिल्ला छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_District.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeDistrict(
                          item.id,
                          item.title_en,
                          item.title_ne,
                          item.code,
                          item.bbox
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div
            className={
              Filter_Data.district_name
                ? "p-2 bd-highlight"
                : "p-2 bd-highlight default"
            }
          >
            {Filter_Data.district_name ? (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className={
                    Filter_Data.municipality_name
                      ? "selected"
                      : Filter_Data.district_name
                      ? "select"
                      : "select default"
                  }
                >
                  {Filter_Data.municipality_name
                    ? Tost
                      ? Filter_Data.municipality_name
                      : Filter_Data.municipality_name_nep
                    : Tost
                    ? "SELECT MUNICIPALITY"
                    : "पालिका छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_Municipality.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeMunicipality(
                          item.id,
                          item.title_en,
                          item.title_ne,
                          item.code,
                          item.bbox
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled
                  className={
                    Filter_Data.municipality_name
                      ? "selected"
                      : Filter_Data.district_name
                      ? "select"
                      : "select default"
                  }
                >
                  {Filter_Data.municipality_name
                    ? Tost
                      ? Filter_Data.municipality_name
                      : Filter_Data.municipality_name_nep
                    : Tost
                    ? "SELECT MUNICIPALITY"
                    : "पालिका छान्नुहोस्"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Selecting_Municipality.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      value={item.id}
                      onClick={() =>
                        handleChangeMunicipality(
                          item.id,
                          item.title_en,
                          item.title_ne,
                          item.code,
                          item.bbox
                        )
                      }
                    >
                      {Tost ? item.title_en : item.title_ne}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className=" d-xl-flex flex-xl-row bd-highlight ">
          <div className="p-2 bd-highlight">
            <div className="filter">
              <img
                src="/filterbtn.png"
                alt="filter-icon"
                className="filter-icon big-screen"
                onClick={() => {
                  // setSelected_clicked(false);
                  setFilter_clicked(!Filter_clicked);
                }}
              />

              <Button
                variant="secondary"
                onClick={() => {
                  // setSelected_clicked(false);
                  setFilter_clicked(!Filter_clicked);
                }}
                className="filter-btn small-screen"
              >
                <img src="/material-filter-list.svg" alt="filter" />{" "}
                {Tost ? "FILTER" : "फिल्टर"}
              </Button>

              {Filter_clicked ? (
                <div className="filter-data">
                  <div className="select-fiscal-year">
                    {Tost ? "Fiscal Year" : "आर्थिक बर्ष"}
                    <DropdownButton
                      id="dropdown-basic-button"
                      className="view-data"
                      title={
                        Selected_fiscal_year
                          ? Selected_fiscal_year
                          : Fiscal_Year[Fiscal_Year.length - 1]
                      }
                    >
                      {Fiscal_Year.map((item) => {
                        return (
                          <Dropdown.Item
                            key={item}
                            value={Selected_fiscal_year}
                            onClick={() => setSelected_fiscal_year(item)}
                          >
                            {item}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                  <div className="date-or-fiscal">
                    <hr />
                    <span>{Tost ? "OR" : "अथवा"}</span>
                  </div>
                  <div className="date-picker-main-div">
                    <div className="date-pick-main">
                      {Tost ? "From" : "देखि"}
                      <div className="date-pick">
                        <img src="/calendar-solid.svg" alt="calender-logo" />
                        <NepaliDatePicker
                          inputClassName="form-control"
                          className="calender"
                          value={Download_Date_from}
                          name="date"
                          onChange={(value) => setDownload_Date_from(value)}
                          options={{
                            calenderLocale: Tost ? "en" : "ne",
                            valueLocale: "en",
                          }}
                        />
                      </div>
                    </div>
                    <div className="dash">-</div>

                    <div className="date-pick-main">
                      {Tost ? "To" : "सम्म"}
                      <div className="date-pick">
                        <img src="/calendar-solid.svg" alt="calender-logo" />
                        <NepaliDatePicker
                          inputClassName="form-control"
                          className="calender"
                          value={Download_Date_to}
                          name="date"
                          onChange={(value) => setDownload_Date_to(value)}
                          options={{
                            calenderLocale: Tost ? "en" : "ne",
                            valueLocale: "en",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="submit-butn">
                    <button
                      className="btn btn-success"
                      type="submit"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      onClick={() => setFilter_clicked(false)}
                    >
                      CANCEL
                    </button>
                    {(setDownload_Date_from && Download_Date_to) ||
                    Selected_fiscal_year ? (
                      Filtering ? (
                        <button
                          className="btn btn-success"
                          type="submit"
                          disabled
                          onClick={handleFilterDate_For_Table}
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            color: "black",
                          }}
                        >
                          Filtering...
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          type="submit"
                          onClick={handleFilterDate_For_Table}
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            color: "black",
                          }}
                        >
                          DONE
                        </button>
                      )
                    ) : (
                      <button
                        className="btn "
                        type="submit"
                        disabled
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "white",
                          color: "black",
                        }}
                      >
                        DONE
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className=" d-xl-flex flex-xl-row bd-highlight ">
          <div className=" bd-highlight reset-bttn">
            {Filtered_compared ? (
              <img
                src="/reset.svg"
                alt="reset"
                style={{ cursor: "pointer" }}
                onClick={handleCancelFilter}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className=" d-xl-flex flex-xl-row bd-highlight dl-btn">
          <div className="bd-highlight dl-bttn">
            <img
              src="/dnload.svg"
              alt="dl"
              onClick={handleSubmit_to_download}
              className="big-screen"
            />
            {Tost ? (
              <DropdownButton
                id="dropdown-basic-button"
                title="DOWNLOAD"
                className="down-load small-screen"
                style={{
                  marginLeft: "auto",
                  marginRight: "10px",
                }}
              >
                <Dropdown.Item onClick={handleSubmit_to_download}>
                  CSV Download
                </Dropdown.Item>
              </DropdownButton>
            ) : (
              <DropdownButton
                id="dropdown-basic-button"
                title="
          डाउनलोड"
                className="down-load small-screen"
                style={{
                  marginLeft: "auto",
                  marginRight: "10px",
                }}
              >
                <Dropdown.Item onClick={handleSubmit_to_download}>
                  CSV डाउनलोड{" "}
                </Dropdown.Item>
              </DropdownButton>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .btn:focus {
    box-shadow: none !important;
  }
  .btn-success.focus {
    box-shadow: none !important;
  }
  .btn-success:focus {
    box-shadow: none !important;
  }
  .dropdown-menu {
    z-index: 99999 !important;
  }
  .view-data button {
    width: 310px;
    justify-content: center;
  }
  .dropdown-item {
    justify-content: center;
  }
  .main-table-filter-div {
    align-items: center !important;
  }
  .main-table-filter-div {
    display: flex;
    align-items: center;
  }
  .small-screen {
    display: inline;
  }
  .big-screen {
    display: none;
  }
  .filter-icon {
    height: 25px;
  }
  .dl-btn {
    margin-left: auto;
  }
  .down-load button {
    border-radius: 10px !important;
    border: 2px solid;
    color: #405467 !important;
    font-size: 12px;
    font-weight: bold;
    background-color: white !important;
    margin-right: 70px !important;
  }
  .dash {
    margin-top: 30px;
  }
  .date-pick img {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .date-pick {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
  }
  .filter-btn {
    display: flex;
    align-items: center !important;
    background: none !important;
    font-size: 14px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #405467 !important;
    border-radius: 10px !important;
  }
  .selected {
    border-radius: 10px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #ffffff !important;

    background-color: #405467 !important;
  }
  .select {
    border-radius: 10px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #405467 !important;
    box-shadow: 5px 10px 18px #dddd !important;
    background-color: white !important;
  }
  .contain {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .date-picker-main-div {
    display: flex;
  }
  .date-or-fiscal {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .date-pick-main {
    margin-left: 10px;
    margin-right: 10px;
  }
  .submit-butn button {
    border: 1px solid grey;
  }
  .filter-add-btn {
    margin-left: 15px;
    display: flex;
    position: relative;
    padding-top: 5px;
  }
  .select-fiscal-year {
    margin-left: 10px;
  }
  .filter-add-btn button {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .submit-butn button {
    margin-left: 5px;
    margin-right: 5px;
  }
  .submit-butn {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;
  }
  .date-or-fiscal span {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    margin-top: 5px;
  }
  .date-or-fiscal hr {
    width: 93%;
  }
  .nepali-date-picker .calender {
    width: max-content;
  }
  .filter-data {
    margin-left: 0px;
    margin-top: 0px;

    height: auto;
    width: 330px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;

    background-color: white;
    box-shadow: 5px 10px 18px #ccc;
  }
  .form-control {
    background-color: white !important;
    border: none;
    cursor: pointer;
    width: 110px !important;
    border-radius: 5px;
  }
  .reset-bttn {
    margin-top: 7px;
  }
  @media only screen and (max-width: 770px) {
    .default {
      display: none;
    }
    .dl-bttn img {
      margin-top: 10px;
      margin-right: 10px;
    }
    .main-table-filter-div {
      align-items: flex-start !important;
    }
  }
  @media only screen and (max-width: 500px) {
    .main-table-filter-div {
      align-items: flex-start !important;
    }
    .big-screen {
      display: inline;
    }
    .small-screen {
      display: none;
    }
    .main-table-filter-div {
      margin-top: 0px !important;
    }
    .dl-bttn img {
      margin-top: 10px;
      margin-right: 20px;
    }
    .reset-bttn img {
      width: 30px !important;
    }
    .filter-data {
      right: 9%;
    }
    .default {
      display: none;
    }
    .dl-btn {
      margin-left: auto !important;
    }
    .dl-btn img {
      height: 25px;
    }
  }
  .filter-data {
    z-index: 9999 !important;
  }
  .view-data button {
    width: 310px;
  }
  @media only screen and (max-width: 1000px) {
    .date-picker-main-div {
      flex-direction: column;
    }
    .filter-data {
      width: 200px !important;
    }
    .view-data button {
      width: 180px !important;
    }
    .dash {
      margin-top: 0px !important;
      margin-left: 10px !important;
    }
  }
`;

export default Table_Filter;
