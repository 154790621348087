import React, { useState, useContext, useEffect } from "react";
import { Dropdown, Button, DropdownButton } from "react-bootstrap";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import httpBrowsing from "../Utilities/httpBrowsing";
import styled from "styled-components";
import notification from "./../Utilities/Notification";
import { nepaliToEnglishNumber } from "nepali-number";
import {
  Download_Date_to_context,
  Filter_clicked_Context,
  Download_Date_from_context,
  Fiscal_Year_Context,
  Selected_fiscal_year_Context,
  Right_indicator_id_Context,
  Add_Data_Context,
  Tost_Context,
  Indicator_Data_Context,
  currentPage_Context,
  Paginate_Data_Context,
  page_size_Context,
  Selected_first_Province_Context,
  Selected_first_District_Context,
  Selected_first_Municipality_Context,
  Filter_compare_graph_Context,
  Filter_compare_map_Context,
  Filter_compare_table_Context,
  Table_Loader_Context,
} from "../Utilities/Store";
const Table_Filter = () => {
  const [Filtering, setFiltering] = useState(false);
  const [Filter_compare_graph, setFilter_compare_graph] = useContext(
    Filter_compare_graph_Context
  );
  const [Table_Loader, setTable_Loader] = useContext(Table_Loader_Context);
  const [Filter_compare_map, setFilter_compare_map] = useContext(
    Filter_compare_map_Context
  );
  const [Filter_compare_table, setFilter_compare_table] = useContext(
    Filter_compare_table_Context
  );
  const [Paginate_Data, setPaginate_Data] = useContext(Paginate_Data_Context); //used to store pagination data
  const [Indicator_Data, setIndicator_Data] = useContext(
    Indicator_Data_Context
  ); //used to store the fetched data
  const [Tost, setTost] = useContext(Tost_Context);
  const [Add_Data, setAdd_Data] = useContext(Add_Data_Context); //when set true a model displays to give entry on data entry form
  const [Filter_clicked, setFilter_clicked] = useContext(
    Filter_clicked_Context
  ); //is set true when filter button is clicked
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Fiscal_Year, setFiscal_Year] = useContext(Fiscal_Year_Context);
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [page_size, setPage_size] = useContext(page_size_Context);

  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [Selected_first_Province, setSelected_first_Province] = useContext(
    Selected_first_Province_Context
  );
  const [Selected_first_District, setSelected_first_District] = useContext(
    Selected_first_District_Context
  );
  const [
    Selected_first_Municipality,
    setSelected_first_Municipality,
  ] = useContext(Selected_first_Municipality_Context);

  const [Filter_Province, setFilter_Province] = useState("");
  const [Filter_District, setFilter_District] = useState("");
  const [Filter_Municipality, setFilter_Municipality] = useState("");
  const [Filtered_compared, setFiltered_compared] = useState(false); //used to set true when data is filtered or compared
  const [currentPage, setCurrentPage] = useContext(currentPage_Context); //used to store the current page number selected in table pagination
  useEffect(() => {
    httpBrowsing
      .get(`/nep/indicator-data/get-years/`)
      .then((res) => {
        setFiscal_Year(res.data);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
  }, []);
  const handleFilterDate_For_Table = (e) => {
    e.preventDefault();
    setFiltering(true);
    // setSelected_fiscal_year("");

    httpBrowsing
      .get(
        `/${
          Tost ? "eng" : "nep"
        }/indicator-data/?indicator=${Right_indicator_id}&fiscal_year=${nepaliToEnglishNumber(
          Selected_fiscal_year
        )}&start=${Download_Date_from}&end=${Download_Date_to}`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setFiltered_compared(true);
        setFilter_clicked(false);
        setPaginate_Data(res.data);
        setFilter_compare_table(true);
        setTable_Loader(false);
        setFiltering(false);
      })
      .catch((err) => {
        console.log("This is final error>>>", err.response);
      });
  };
  const handleCancelFilter = (e) => {
    e.preventDefault();

    setSelected_fiscal_year("");
    setDownload_Date_from("");
    setDownload_Date_to("");
    setTable_Loader(true);
    httpBrowsing
      .get(
        `/${
          Tost ? "eng" : "nep"
        }/indicator-data/?indicator=${Right_indicator_id}&page=${currentPage}&page_size=${page_size}`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setFiltered_compared(false);
        setFilter_clicked(false);
        setPaginate_Data(res.data);
        setFilter_compare_table(false);
        setTable_Loader(false);
      })
      .catch((err) => {
        console.log("This is final error>>>", err.response);
      });
  };
  if (Selected_fiscal_year) {
    setSelected_fiscal_year(Selected_fiscal_year.substr(0, 4));
  }
  useEffect(() => {
    const Verifying_user = () => {
      httpBrowsing.get("/nep/me/").then((res) => {
        setFilter_Province(res.data.province);
        setFilter_District(res.data.district);
        setFilter_Municipality(res.data.municipality);
      });
    };
    Verifying_user();
  }, []);
  const handleSubmit_to_download = (e) => {
    e.preventDefault();

    httpBrowsing
      .get(
        `/${
          Tost ? "eng" : "nep"
        }/indicator-data/download?indicator=${Right_indicator_id}&province=${Filter_Province}&district=${Filter_District}&municipality=${Filter_Municipality}&fiscal_year=${Selected_fiscal_year}&start=${Download_Date_from}&end=${Download_Date_to}`
      )
      .then((res) => {
        window.open(res.data, "_self");

        notification.showSuccess("Downloading CSV!!!");
      })
      .catch((err) => {
        console.log("ths is error>>", err.response);
      });
  };
  useEffect(() => {
    setSelected_first_Province({
      prov_name: "",
      prov_id: "",
      prov_nep_name: "",
      code: "",

      bbox: "",
    });
    setSelected_first_District({
      dist_name: "",
      dist_id: "",
      dist_nep_name: "",
      code: "",

      bbox: "",
    });
    setSelected_first_Municipality({
      muni_name: "",
      muni_id: "",
      muni_nep_name: "",
      code: "",

      bbox: "",
    });

    setSelected_fiscal_year("");
    setDownload_Date_from("");
    setDownload_Date_to("");
    setFilter_compare_graph(false);
    setFilter_compare_map(false);
    setFilter_compare_table(false);
  }, []);
  return (
    <Wrapper>
      <div className="filter-add-btn">
        <Button
          variant="primary"
          onClick={() => setAdd_Data(true)}
          className="add-bttn"
          style={{
            backgroundColor: "#405467",
            borderRadius: "10px",
            color: "white",
            border: "2px solid",
          }}
        >
          + ADD DATA
        </Button>{" "}
        <Button
          variant="secondary"
          onClick={() => setFilter_clicked(!Filter_clicked)}
          className="filter-btn"
        >
          <img src="/material-filter-list.svg" alt="filter" /> {""}{" "}
          {Tost ? "FILTER" : "फिल्टर"}
        </Button>
        {Filter_compare_table ? (
          <img
            src="/reset.svg"
            alt="reset"
            style={{ cursor: "pointer" }}
            onClick={handleCancelFilter}
          />
        ) : (
          ""
        )}
        {Tost ? (
          <DropdownButton
            id="dropdown-basic-button"
            title="DOWNLOAD"
            className="down-load"
            style={{
              marginLeft: "auto",
              marginRight: "10px",
            }}
          >
            <Dropdown.Item onClick={handleSubmit_to_download}>
              Download CSV
            </Dropdown.Item>
          </DropdownButton>
        ) : (
          <DropdownButton
            id="dropdown-basic-button"
            title="
          डाउनलोड"
            className="down-load down"
            style={{
              marginLeft: "auto",
              marginRight: "10px",
              fontSize: "18px !important",
            }}
          >
            <Dropdown.Item onClick={handleSubmit_to_download}>
              CSV डाउनलोड{" "}
            </Dropdown.Item>
          </DropdownButton>
        )}
        {Filter_clicked ? (
          <div className="filter-data">
            <div className="select-fiscal-year">
              {Tost ? "Fiscal Year" : "आर्थिक बर्ष"}
              <DropdownButton
                id="dropdown-basic-button"
                className="view-data"
                title={
                  Selected_fiscal_year
                    ? Selected_fiscal_year
                    : Fiscal_Year[Fiscal_Year.length - 1]
                }
              >
                {Fiscal_Year.map((item) => {
                  return (
                    <Dropdown.Item
                      key={item}
                      value={Selected_fiscal_year}
                      onClick={() => setSelected_fiscal_year(item)}
                    >
                      {item}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </div>
            <div className="date-or-fiscal">
              <hr />
              <span>{Tost ? "OR" : "अथवा"}</span>
            </div>
            <div className="date-picker-main-div">
              <div className="date-pick-main">
                {Tost ? "From" : "देखि"}
                <div className="date-pick">
                  <img src="/calendar-solid.svg" alt="calender-logo" />
                  <NepaliDatePicker
                    inputClassName="form-control"
                    className="calender"
                    value={Download_Date_from}
                    name="date"
                    onChange={(value) => setDownload_Date_from(value)}
                    options={{
                      calenderLocale: Tost ? "en" : "ne",
                      valueLocale: "en",
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: 30 }}>-</div>

              <div className="date-pick-main">
                {Tost ? "To" : "सम्म"}
                <div className="date-pick">
                  <img src="/calendar-solid.svg" alt="calender-logo" />
                  <NepaliDatePicker
                    inputClassName="form-control"
                    className="calender"
                    value={Download_Date_to}
                    name="date"
                    onChange={(value) => setDownload_Date_to(value)}
                    options={{
                      calenderLocale: Tost ? "en" : "ne",
                      valueLocale: "en",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="submit-butn">
              <button
                className="btn btn-success"
                type="submit"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  color: "black",
                }}
                onClick={() => setFilter_clicked(false)}
              >
                CANCEL
              </button>
              {(setDownload_Date_from && Download_Date_to) ||
              Selected_fiscal_year ? (
                Filtering ? (
                  <button
                    className="btn btn-success"
                    type="submit"
                    disabled
                    onClick={handleFilterDate_For_Table}
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    Filtering...
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    type="submit"
                    onClick={handleFilterDate_For_Table}
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    DONE
                  </button>
                )
              ) : (
                <button
                  className="btn "
                  type="submit"
                  disabled
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  DONE
                </button>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* {Filter_clicked ? (
          <div className="filter-data">
            <div className="date-picker-main-div">
              <div className="date-pick-main">
                From
                <div className="date-pick">
                  <img src="/calendar-solid.svg" alt="calender-logo" />
                  <NepaliDatePicker
                    inputClassName="form-control"
                    className="calender"
                    value={Download_Date_from}
                    name="date"
                    onChange={(value) => setDownload_Date_from(value)}
                    options={{
                      calenderLocale: "en",
                      valueLocale: "en",
                    }}
                  />
                </div>
              </div>
              <div style={{ marginTop: 30 }}>-</div>

              <div className="date-pick-main">
                To
                <div className="date-pick">
                  <img src="/calendar-solid.svg" alt="calender-logo" />
                  <NepaliDatePicker
                    inputClassName="form-control"
                    className="calender"
                    value={Download_Date_to}
                    name="date"
                    onChange={(value) => setDownload_Date_to(value)}
                    options={{
                      calenderLocale: "en",
                      valueLocale: "en",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="date-or-fiscal">
              <hr />
              <span>OR</span>
            </div>
            <div className="select-fiscal-year">
              Select Fiscal Year
              <DropdownButton
                id="dropdown-basic-button"
                className="view-data"
                style={{ width: "97%" }}
                title={
                  Selected_fiscal_year
                    ? Selected_fiscal_year
                    : Fiscal_Year[Fiscal_Year.length - 1]
                }
              >
                {Fiscal_Year.map((item) => {
                  return (
                    <Dropdown.Item
                      key={item}
                      value={Selected_fiscal_year}
                      onClick={() => setSelected_fiscal_year(item)}
                    >
                      {item}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </div>
            <div className="submit-butn">
              <button
                className="btn btn-success"
                type="submit"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "white",
                  color: "black",
                }}
                onClick={() => setFilter_clicked(false)}
              >
                CANCEL
              </button>
              {(setDownload_Date_from && Download_Date_to) ||
              Selected_fiscal_year ? (
                <button
                  className="btn btn-success"
                  type="submit"
                  onClick={handleFilterDate_For_Table}
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  DONE
                </button>
              ) : (
                <button
                  className="btn "
                  type="submit"
                  disabled
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "white",
                    color: "black",
                  }}
                >
                  DONE
                </button>
              )}
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .form-control {
    width: 110px !important;
  }
  .nepali-date-picker .calender {
    width: max-content;
  }
  .down button {
    font-size: 18px !important;
  }
  .selected {
    border-radius: 10px !important;
    border: 2px solid !important;
    font-weight: 500 !important;
    font-size: small !important;
    font-family: Montserrat !important;
    color: #ffffff !important;

    background-color: #405467 !important;
  }

  .down-load button {
    border-radius: 10px !important;
    border: 2px solid;
    color: #405467 !important;
    font-size: 14px;
    font-weight: bold;
  }
  .filter-btn {
    background: none !important;
    font-size: 14px !important;
    border: 2px solid !important;
    font-weight: bold !important;
    font-family: Montserrat !important;
    color: #405467 !important;
    border-radius: 10px !important;
  }
  .add-bttn {
    border-radius: 10px;
    border: 2px solid;
    color: white;
    background-color: #405467;
  }

  // .btn-primary:hover {
  //   background-color: white !important;
  //   border-color: black !important;
  //   color: #405467 !important;
  // }
  // .btn-primary.focus,
  // .btn-primary:focus {
  //   box-shadow: 5px 10px 18px #dddd !important;
  //   background-color: white !important;
  //   color: #405467 !important;
  //   box-shadow: 5px 10px 18px #dddd !important;
  //   border-color: none !important;
  // }
  .view-data > .show {
    width: 300px;
  }
`;

export default Table_Filter;
