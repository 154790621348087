import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import { Link } from "react-router-dom";
import Loader from "react-loader";
import httpBrowsing_frontend from "../../Utilities/httpBrowsing_frontend";
import Table_Indicator from "../../Frontend.content/Sub-modules/Table";

import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";

import {
  Button,
  Modal,
  Form,
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Table,
} from "react-bootstrap";

import notification from "./../../Utilities/Notification";
import Map from "../../Frontend.content/Sub-modules/Map";
import Frontend_Sidebar from "../../Page.components/Frontend_Sidebar";
import Frontend_Navbar from "../../Page.components/Frontend_Navbar";
import Percentage_Filter from "../../Frontend.Filters/Percentage-filter";
import {
  Tost_Context,
  Indicator_title_Context,
  Indicator_title_eng_Context,
  Right_component_nepali_Context,
  Right_component_english_Context,
  Right_name_nepali_Context,
  Right_name_english_Context,
  Filter_clicked_Context,
  Download_Date_from_context,
  Download_Date_to_context,
  sidebar_Clicked_Context,
  Selected_fiscal_year_Context,
  Province_Context,
  District_Context,
  Municipality_Context,
  Filter_Second_Data_Context,
  Filter_First_Data_Context,
  Right_indicator_id_Context,
  Total_Data_Context,
  Second_Total_Data_Context,
  Compare_Graph_Context,
  Loading_Context,
  Change_Language_Context,
  Indicator_Data_Context,
  Paginate_Data_Context,
  page_size_Context,
  currentPage_Context,
  Table_Loader_Context,
  Edit_Data_Context,
  Add_Data_Context,
  Loading_Language_Context,
  Item_delete_check_Context,
  province1_Context,
  province2_Context,
  bagmati_Context,
  gandaki_Context,
  province5_Context,
  karnali_Context,
  sudurpaschim_Context,
  Edited_Context,
  Prov1_Context,
  Prov2_Context,
  Prov3_Context,
  Prov4_Context,
  Prov5_Context,
  Prov6_Context,
  Prov7_Context,
  Municipality_Data_Context,
  Fiscal_Year_Context,
  Filter_Data_context,
  Table_Header_Context,
} from "../../Utilities/Store";
import Graph from "../../Frontend.content/Sub-modules/Chart";
import Graph_Filter from "../../Frontend.Filters/Graph-Filter";
import Map_Filter from "../../Frontend.Filters/Map-filter";
import Percentage from "../Sub-modules/Percentage";
const Download = (props) => {
  const ref = React.createRef();

  const [Municipality_Data, setMunicipality_Data] = useContext(
    Municipality_Data_Context
  );
  const [Filter_Data, setFilter_Data] = useContext(Filter_Data_context);
  const [Fiscal_Year, setFiscal_Year] = useContext(Fiscal_Year_Context);

  const [Edited, setEdited] = useContext(Edited_Context);
  const [Loading_Language, setLoading_Language] = useContext(
    Loading_Language_Context
  ); //used to disable language switch untill response is obtained
  const [Table_Loader, setTable_Loader] = useContext(Table_Loader_Context);
  const [Indicator_Data, setIndicator_Data] = useContext(
    Indicator_Data_Context
  );
  const [Item_delete_check, setItem_delete_check] = useContext(
    Item_delete_check_Context
  );
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Edit_Data, SetEdit_Data] = useContext(Edit_Data_Context); //when set true a model displays to edit the data
  const [Add_Data, setAdd_Data] = useContext(Add_Data_Context); //when set true a model displays to give entry on data entry form
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title
  const [Paginate_Data, setPaginate_Data] = useContext(Paginate_Data_Context);
  const [Total_Data, setTotal_Data] = useContext(Total_Data_Context);
  const [Tost, setTost] = useContext(Tost_Context); //used to store boolean value during language switch
  const [Sidebar_Data, setSidebar_Data] = useState([]); //all fetched sidebar data is stored here
  // const [Test, setTest] = useState(false); //testing the function on click
  const [Province, setProvince] = useContext(Province_Context);
  const [District, setDistrict] = useContext(District_Context);
  const [Municipality, setMunicipality] = useContext(Municipality_Context);
  const [Loading_Display, setLoading_Display] = useState(true);
  const [Loading, setLoading] = useContext(Loading_Context);
  const [RightID, setRightID] = useState("");
  const [page_size, setPage_size] = useContext(page_size_Context);
  const [currentPage, setCurrentPage] = useContext(currentPage_Context);
  const [sidebar_Clicked, setSidebar_Clicked] = useContext(
    sidebar_Clicked_Context
  ); //first level sidebar clicked
  const [Right_component_id, setRight_component_id] = useState("");

  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );

  const [Compare, setCompare] = useState(false);

  const [Right_name, setRight_name] = useState("");
  const [Filter_clicked, setFilter_clicked] = useContext(
    Filter_clicked_Context
  ); //is set true when filter button is clicked
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Second_Total_Data, setSecond_Total_Data] = useContext(
    Second_Total_Data_Context
  ); //stores the comparison second entered data
  const [Compare_Graph, setCompare_Graph] = useContext(Compare_Graph_Context); //Is set true when user click  second buttom to compare the graph
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [Filter_First_Data, setFilter_First_Data] = useContext(
    Filter_First_Data_Context
  );
  const [Filter_Second_Data, setFilter_Second_Data] = useContext(
    Filter_Second_Data_Context
  );
  const [Table_Header, setTable_Header] = useContext(Table_Header_Context); //used to store header of the table
  const [Change_Language, setChange_Language] = useContext(
    Change_Language_Context
  ); //used to change the toggle button for language

  const [Select_Data_type, setSelect_Data_type] = useState("PERCENTAGE"); //used to store selected data type table,chart or map
  const [Select_Data_type_image, setSelect_Data_type_image] = useState(
    "/awesome-table.svg"
  ); //used to store image of selected data type table,chart or map
  const handleRight = (id, name) => {
    setRightID(id);
    setRight_name(name);
    // setTest(true);
  };
  const [Previous_Page_No, setPrevious_Page_No] = useState(0); //used to store initial page number
  let Selecting_First_District = District.filter(
    (item, i) => item.province === Number(Filter_First_Data.province)
  );
  let Selecting_First_Municipality = Municipality.filter(
    (item) => item.district === Number(Filter_First_Data.district)
  );
  let Selecting_Second_District = District.filter(
    (item, i) => item.province === Number(Filter_Second_Data.province)
  );
  let Selecting_Second_Municipality = Municipality.filter(
    (item) => item.district === Number(Filter_Second_Data.district)
  );

  let Right_components = [];
  Right_components = Sidebar_Data.filter((item, i) => item.id === RightID).map(
    (item) => item.component
  );
  let Indicators = [];
  if (Right_components.length) {
    Indicators = Right_components[0]
      .filter((item, i) => item.id === Right_component_id)
      .map((item) => item.indicator);
  }
  const handleChangeFirstProvince_Compare = (id, name) => {
    setFilter_First_Data({
      ...Filter_First_Data,
      province: id,
      district: "",
      municipality: "",
      province_name: name,
      district_name: "",
      municipality_name: "",
    });
  };

  const handleChangeFirstDistrict_Compare = (id, name) => {
    setFilter_First_Data({
      ...Filter_First_Data,
      district: id,
      municipality: "",
      district_name: name,
      municipality_name: "",
    });
  };
  const handleChangeFirstMunicipality_Compare = (id, name) => {
    setFilter_First_Data({
      ...Filter_First_Data,
      municipality: id,
      municipality_name: name,
    });
  };
  const handleChangeSecondProvince_Compare = (id, name) => {
    setFilter_Second_Data({
      ...Filter_Second_Data,
      province: id,
      district: "",
      municipality: "",
      province_name: name,
      district_name: "",
      municipality_name: "",
    });
  };

  const handleChangeSecondDistrict_Compare = (id, name) => {
    setFilter_Second_Data({
      ...Filter_Second_Data,
      district: id,
      municipality: "",
      district_name: name,
      municipality_name: "",
    });
  };
  const handleChangeSecondMunicipality_Compare = (id, name) => {
    setFilter_Second_Data({
      ...Filter_Second_Data,
      municipality: id,
      municipality_name: name,
    });
  };
  const changeCurrentPage = (numPage) => {
    setTable_Loader(true);
    setCurrentPage(numPage);

    setPrevious_Page_No(currentPage);
  };
  useEffect(() => {
    httpBrowsing_frontend
      .get(`/frontend/get-years/${Tost ? "eng" : "nep"}/`)
      .then((res) => {
        setFiscal_Year(res.data);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
  }, [Tost]);
  const handleSubmit = (e) => {
    e.preventDefault();

    httpBrowsing_frontend
      .get(
        `/frontend/data/graph/monthly/${Right_indicator_id}&province=${Filter_First_Data.province}&district=${Filter_First_Data.district}&municipality=${Filter_First_Data.municipality}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
      )
      .then((res) => {
        setTotal_Data(res.data.data);
        setCompare(false);
      })
      // setShow(false);
      // setFilter_Data({
      //   province: "",
      //   district: "",
      //   municipality: "",
      // });

      .catch((err) => {
        console.log("what is error>>>", err.response);

        // setFilter_Data({
        //   province: "",
        //   district: "",
        //   municipality: "",
        // });
      });
  };
  const handlePageSize = (e) => {
    setPage_size(e.target.value);

    setTable_Loader(true);
  };
  useEffect(() => {
    httpBrowsing_frontend
      .get(
        `/frontend/indicators/${Right_indicator_id}/get-fields/${
          Tost ? "eng" : "nep"
        }/`
      )
      .then((res) => {
        delete res.data.created_by;
        delete res.data.last_updated;
        delete res.data.operations;
        delete res.data.updated_by;
        setTable_Header(res.data);
      })
      .catch((err) => {
        console.log("this is error>>>", err);
      });
  }, [Tost, Right_indicator_id]);
  useEffect(() => {
    httpBrowsing_frontend
      .get(
        `/frontend/data/${
          Tost ? "eng" : "nep"
        }/?indicator=${Right_indicator_id}&page=${currentPage}&page_size=${page_size}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}&province=${
          Filter_Data.province
        }&district=${Filter_Data.district}&municipality=${
          Filter_Data.municipality
        }`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setIndicator_title(res.data.indicator_title_ne);
        setIndicator_title_eng(res.data.indicator_title_en);
        setPaginate_Data(res.data);
        if (window.screen.width < 990) {
          setSidebar_Clicked(false);
        }
        setLoading(false);
        setTable_Loader(false);
        setLoading_Language(false);
        setRight_name_english(res.data.right_en);
        setRight_name_nepali(res.data.right_ne);
        setRight_component_english(res.data.right_component_en);
        setRight_component_nepali(res.data.right_component_ne);
      })
      .catch((err) => {
        console.log("This is final error>>>", err.response);
      });
  }, [
    Tost,
    page_size,
    currentPage,
    Right_indicator_id,
    Filter_Data.province,
    Filter_Data.district,
    Filter_Data.municipality,
    Edited,
    Item_delete_check,
  ]);

  let id = "map-capture";
  return (
    <React.Fragment>
      <Tester>
        <div
          className={sidebar_Clicked ? "d-flex toggled" : "d-flex"}
          id="wrapper"
        >
          <div id="page-content-wrapper">
            <Frontend_Navbar data={props} />
            {Loading ? (
              <Loader />
            ) : (
              <div className="container-fluid main-content">
                <div className="d-flex  flex-row bd-highlight">
                  <div
                    className={
                      sidebar_Clicked
                        ? " bd-highlight sidebar-div-open"
                        : " bd-highlight sidebar-div-close"
                    }
                  >
                    <Frontend_Sidebar datal={props.history.location.state} />
                  </div>
                  <div
                    style={{ backgroundColor: "#f9fbfc", height: "100vh" }}
                    className={
                      sidebar_Clicked
                        ? "p-2 bd-highlight sidebar-div1-open"
                        : " bd-highlight sidebar-div1-close"
                    }
                    style={{ backgroundColor: "#f9fbfc", height: "100vh" }}
                  >
                    <div className="row contain ">
                      {Tost ? (
                        <Breadcrumb className="bread-crumb">
                          <Breadcrumb.Item>
                            {Right_name_english}
                          </Breadcrumb.Item>

                          <Breadcrumb.Item>
                            {Right_component_english}
                          </Breadcrumb.Item>

                          <Breadcrumb.Item>
                            {Indicator_title_eng}
                          </Breadcrumb.Item>
                        </Breadcrumb>
                      ) : (
                        <Breadcrumb className="bread-crumb">
                          <Breadcrumb.Item>{Right_name_nepali}</Breadcrumb.Item>

                          <Breadcrumb.Item>
                            {Right_component_nepali}
                          </Breadcrumb.Item>

                          <Breadcrumb.Item>{Indicator_title}</Breadcrumb.Item>
                        </Breadcrumb>
                      )}
                    </div>
                    <div className="row contain indicator-name-option">
                      {Tost ? (
                        <h1>{Indicator_title_eng}</h1>
                      ) : (
                        <h1>{Indicator_title}</h1>
                      )}
                    </div>
                    {Select_Data_type === "PERCENTAGE" ? (
                      <Percentage_Filter id={id} />
                    ) : (
                      ""
                    )}
                    {Table_Loader ? (
                      <Loader />
                    ) : (
                      <>
                        <div
                          ref={ref}
                          className={
                            sidebar_Clicked
                              ? "test-table"
                              : "test-table full-screen"
                          }
                          id={id}
                        >
                          {/* <div
                            className="row"
                            style={{
                              height: "400px",
                              width: "200px",
                              backgroundColor: "red",
                            }}
                          ></div> */}
                          {Select_Data_type === "PERCENTAGE" ? (
                            <Percentage />
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Tester>
    </React.Fragment>
  );
};
const Tester = styled.div`
  .main-content {
    height: 100vh;
  }
  .sidebar-1-design {
    height: 100% !important;
  }

  .sidebar-div1-open {
    width: 90% !important;
  }
  .sidebar-div1-close {
    width: 95% !important;
  }
  #page-content-wrapper {
    width: 100%;
  }
  @media only screen and (max-width: 500px) {
    .right-container {
      height: 60px;
      width: 40px;
    }
    .right-image {
      width: 60px;
      height: 60px;
    }
    .fa {
      height: 40px !important;
      width: 40px !important;
    }
    .sidebar-div-open {
      width: 62% !important;
    }
    .sidebar-div1-close {
      width: 90% !important;
    }

    #page-content-wrapper {
      width: 100%;
    }
    .indicator-name-option {
      display: flex;
      flex-direction: column;
    }
    .heading {
      width: fit-content;
    }
    .contain h1 {
      font-size: 16px !important;
    }
    .view-data {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
    .view-data button {
      font-size: 12px !important;
    }
    .view-data button img {
      height: 15px !important;
      margin-top: -10px !important;
    }
    .breadcrumb-item {
      font-size: 10px !important;
    }
    .contain {
      margin-left: 20px;
      margin-right: 50px !important;
    }
  }

  .view-data img {
    margin-right: 10px;
    height: 18px;
  }

  .view-data {
    margin-left: auto;
    margin-right: 80px;
  }
  .view-data button {
    background-color: #ffffff;
    color: #405467;
    opacity: 1;
    border-radius: 10px;
    border: 2px solid #405467;
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #ffffff;
    color: #405467;
    opacity: 1;
    border-radius: 10px;
  }
  .breadcrumb {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .bread-crumb {
    width: fit-content;
  }
  .bread-crumb a {
    color: #333333;
    opacity: 0.61;
    text-decoration: none;
  }

  .main-content {
    padding: 0;
  }
  .contain {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .contain h1 {
    color: #405467;
    font-size: 30px;
    font-weight: bold;
  }

  .fa {
    background-color: white;
    font-size: 30px !important;
    cursor: pointer !important;
    box-shadow: 5px 10px 18px #888888;
    border-radius: 10%;
    font-size: 15px !important;
    padding: 15px;
    display: flex;
    justify-content: center;
    height: 60px;
    width: 60px;
    align-items: center;
  }

  .secondary-sidebar {
    width: 200px;
    padding-top: 30px;
  }
  .secondary-sidebar h5 {
    margin-left: 15px;
  }

  .sidebar-1-design {
    display: flex;
    flex-direction: column;
    height: 50px;
  }
  .sidebar-1-design .right-image {
    display: flex;
    width: 80px;
    justify-content: center;
  }

  #sidebar-wrapper {
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }
  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  #sidebar-wrapper .list-group2 {
    width: 0px !important;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }
`;
const Wrapper = styled.div`
  .but {
    padding: 0px;
  }
  #earth-logo {
    height: 25px;
  }
  .overall-popup {
    overflow-y: scroll;
    height: 300px;
  }
  .popup-card {
    background-color: lightblue;
    color: black;
    margin-top: 5px;
    margin-bottom: 15px;
    height: 150px;
  }
  .mapboxgl-popup-content {
    height: 500px;
  }
  .btn {
    border: none;
  }
  .nav-control {
    width: 30px;
    margin-left: 10px;
    margin-top: 10px;
  }
  // .dot {
  // height: 25px;
  // width: 25px;
  // background-color: orange;
  // border-radius: 50%;
  // display: inline-block;
  // }
  .container-fluid {
    height: 100vh;
    width: 100vw;
  }
  .col-sm-8 {
    margin-top: 140px;
  }
  .container {
    margin-left: 300px;
  }
  .container h3 {
    color: #405467;
  }
  .card {
    margin-left: 15px;
    margin-right: 15px;
    width: 250px !important;
    height: 120px !important;
  }
  .card img {
    display: flex;
  }
  .card-text {
    display: flex;
    margin-top: 10px;
  }
`;
export default Download;
