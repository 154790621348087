import { toast } from 'react-toastify';


function showError(msg) {
    toast.error(msg);
}

function showSuccess(msg) {
    toast.success(msg);
}


export default { showSuccess, showError };