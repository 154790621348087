import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import styled from "styled-components";
import Loader from "react-loader";
import notification from "../../Utilities/Notification";
import httpBrowsing from "../../Utilities/httpBrowsing";
import { useLocalStorage } from "../../Utilities/Localstorage";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { englishToNepaliNumber, nepaliToEnglishNumber } from "nepali-number";
import BikramSambat, { ADToBS, BSToAD } from "bikram-sambat-js";
import {
  Tost_Context,
  RightClicked_Context,
  RightComponentClicked_Context,
  Select_Language_Context,
  Loading_Language_Context,
  Loading_Context,
  Load_on_Lan_Change_Context,
  RightID_Context,
  Right_component_id_Context,
  Right_indicator_id_Context,
  Right_component_nepali_Context,
  Indicator_title_Context,
  Indicator_title_eng_Context,
  Right_component_english_Context,
  Right_name_nepali_Context,
  Right_name_english_Context,
  Add_Data_Context,
  Edit_Data_Context,
  Indicator_Data_Context,
  Paginate_Data_Context,
  page_size_Context,
  currentPage_Context,
  Download_Date_to_context,
  Download_Date_from_context,
  Change_Language_Context,
  Table_Loader_Context,
  Item_delete_check_Context,
  Edited_Context,
  Table_Header_Context,
} from "../../Utilities/Store";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
// import { NepaliDatePicker } from "datepicker-nepali-reactjs";
const Table_Indicator = (props) => {
  const [Username, setUsername] = useLocalStorage("Username", ""); //used to set username to localstorage
  const [Edited, setEdited] = useContext(Edited_Context);
  const [Tost, setTost] = useContext(Tost_Context); //used to store boolean value during language switch
  const [Item_delete_check, setItem_delete_check] = useContext(
    Item_delete_check_Context
  );
  const [Table_Header, setTable_Header] = useContext(Table_Header_Context); //used to store header of the table
  const [Indicator_Data, setIndicator_Data] = useContext(
    Indicator_Data_Context
  ); //used to store the fetched data
  const [IsSort, setIsSort] = useState(true); //boolean is set when user click sort button
  const [currentPage, setCurrentPage] = useContext(currentPage_Context); //used to store the current page number selected in table pagination
  const [page_size, setPage_size] = useContext(page_size_Context); //used to store the size of data in selected page
  const [is_Supervisor, setIs_Supervisor] = useState(null);
  const [is_Superuser, setIs_Superuser] = useState(null);
  const [Editing_Data_id, setEditing_Data_id] = useState(""); //used to store the id of editing data
  const [Change_Language, setChange_Language] = useContext(
    Change_Language_Context
  ); //used to change the toggle button for language
  const [Loading_Add_Button, setLoading_Add_Button] = useState(false);
  const [Deleting_Data_id, setDeleting_Data_id] = useState(""); //used to store the id of deleting data
  const [Fiscal_Year, setFiscal_year] = useState([]); //this is used to store overall fiscal years.
  const [RightClicked, setRightClicked] = useContext(RightClicked_Context);
  const [RightComponentClicked, setRightComponentClicked] = useContext(
    RightComponentClicked_Context
  );
  const [Table_Loader, setTable_Loader] = useContext(Table_Loader_Context);
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Secondary_Loading, setSecondary_Loading] = useState(false); //used as secondary loader in table content change
  const [Select_Language, setSelect_Language] = useContext(
    Select_Language_Context
  ); //switched when user select english or nepali language
  const [Next, setNext] = useState(""); //this is used to store the next fetching link of total data fetched

  const [Paginate_Data, setPaginate_Data] = useContext(Paginate_Data_Context); //used to store pagination data
  const [Initial_Loading, setInitial_Loading] = useState(false); //used to set loader during initialization
  const [Global_Loader, setGlobal_Loader] = useState(true);
  const [Loading_Language, setLoading_Language] = useContext(
    Loading_Language_Context
  );

  const [Loading, setLoading] = useContext(Loading_Context); //used to set loader during initialization
  const [Load_on_Lan_Change, setLoad_on_Lan_Change] = useContext(
    Load_on_Lan_Change_Context
  ); //this value is changed only when language is changed
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title

  const [RightID, setRightID] = useContext(RightID_Context);
  const [Previous_Page_No, setPrevious_Page_No] = useState(0); //used to store initial page number
  const [Right_component_id, setRight_component_id] = useContext(
    Right_component_id_Context
  );
  const [show, setShow] = useState(false); //boolean set to show/hide delete alert form
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [date, setDate] = useState(""); //calender value date is selected
  const [nepaliDate, setNepaliDate] = useState("");
  const [Edit_Data, SetEdit_Data] = useContext(Edit_Data_Context); //when set true a model displays to edit the data
  const [Add_Data, setAdd_Data] = useContext(Add_Data_Context); //when set true a model displays to give entry on data entry form
  const [Selected_fiscal_year, setSelected_fiscal_year] = useState({
    fiscal_year: "",
    province: "",
    district: "",
    municipality: "",
    prov_name: "",
    dist_name: "",
    muni_name: "",
    filter_date_from: "",
    filter_date_to: "",
  }); //this is used to store selected fiscal year from secondary navbar
  const [Submitting_Data, SetSubmitting_Data] = useState({
    province: null,
    district: null,
    municipality: null,
    total: null,
    date: "",
    male: null,
    female: null,
    other: null,
    _0_to_20: null,
    _21_or_older: null,

    _0_to_19: null,
    _20_to_29: null,
    _30_to_39: null,
    _40_to_59: null,
    _60_or_older: null,
    amount: null,
    age: null,
    area: null,
    duration: null,
    medium: null,
    ratio: null,
    weight: null,
    Type: null,
    passed: null,
    failed: null,
    _0_to_16: null,
    _17_to_18: null,
    participate: null,
    non_participate: null,
  });

  const sortProvince = () => {
    if (!Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return b.प्रदेश.id - a.प्रदेश.id;
      });
    } else if (!Tost && !IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return a.प्रदेश.id - b.प्रदेश.id;
      });
    } else if (Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return b.province.id - a.province.id;
      });
    } else {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return a.province.id - b.province.id;
      });
    }
  };
  const sortDate = () => {
    if (!Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return (
          new Date(nepaliToEnglishNumber(b.मिती)) -
          new Date(nepaliToEnglishNumber(a.मिती))
        );
      });
    } else if (!Tost && !IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return (
          new Date(nepaliToEnglishNumber(a.मिती)) -
          new Date(nepaliToEnglishNumber(b.मिती))
        );
      });
    } else if (Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    } else {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    }
  };
  const sortCount = () => {
    if (!Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return (
          nepaliToEnglishNumber(b.संख्या) - nepaliToEnglishNumber(a.संख्या)
        );
      });
    } else if (!Tost && !IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return (
          nepaliToEnglishNumber(a.संख्या) - nepaliToEnglishNumber(b.संख्या)
        );
      });
    } else if (Tost && IsSort) {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return b.total - a.total;
      });
    } else {
      setIsSort(!IsSort);
      Indicator_Data.sort(function (a, b) {
        return a.total - b.total;
      });
    }
  };

  const handleEdit = (id, index) => {
    setEditing_Data_id(id);

    if (Tost) {
      SetSubmitting_Data({
        ...Submitting_Data,
        province: Indicator_Data[index].province.id,
        district: Indicator_Data[index].district.id,
        municipality: Indicator_Data[index].municipality.id,
        total: Indicator_Data[index].total,
        date: Indicator_Data[index].date,
        male: Table_Header.gender
          ? String(Indicator_Data[index].gender.male)
          : null,
        female: Table_Header.gender
          ? String(Indicator_Data[index].gender.female)
          : null,
        other: Table_Header.gender
          ? String(Indicator_Data[index].gender.other)
          : null,
        _0_to_20: Table_Header.woman_age_group
          ? Indicator_Data[index].woman_age_group._0_to_20
          : null,
        _21_or_older: Table_Header.woman_age_group
          ? Indicator_Data[index].woman_age_group._21_or_older
          : null,

        _0_to_19: null,
        _20_to_29: null,
        _30_to_39: null,
        _40_to_59: null,
        _60_or_older: null,
        participate: Table_Header.participation_count
          ? String(Indicator_Data[index].participation_count.participate)
          : null,
        non_participate: Table_Header.participation_count
          ? String(Indicator_Data[index].participation_count.non_participate)
          : null,
        amount:
          Table_Header.amount === "" ? Indicator_Data[index].amount : null,
        duration:
          Table_Header.duration === "" ? Indicator_Data[index].duration : null,
        age: Table_Header.age === "" ? Indicator_Data[index].age : null,
        Type: Table_Header.Type === "" ? Indicator_Data[index].Type : null,
        area: Table_Header.area === "" ? Indicator_Data[index].area : null,
        medium:
          Table_Header.medium === "" ? Indicator_Data[index].medium : null,
        ratio: Table_Header.ratio === "" ? Indicator_Data[index].ratio : null,
        _0_to_16: Table_Header.children_age_group
          ? Indicator_Data[index].children_age_group._0_to_16
          : null,
        _17_to_18: Table_Header.children_age_group
          ? Indicator_Data[index].children_age_group._17_to_18
          : null,

        weight:
          Table_Header.weight === "" ? Indicator_Data[index].weight : null,
        passed: Table_Header.pass_fail_count
          ? Indicator_Data[index].pass_fail_count.passed
          : null,
        failed: Table_Header.pass_fail_count
          ? Indicator_Data[index].pass_fail_count.failed
          : null,
      });
    } else {
      SetSubmitting_Data({
        ...Submitting_Data,
        province: Indicator_Data[index].प्रदेश.id,
        district: Indicator_Data[index].जिल्ला.id,
        municipality: Indicator_Data[index].नगरपालिका.id,
        total: Indicator_Data[index].संख्या,
        date: Indicator_Data[index].मिती,
        male: Table_Header.लिँग ? Indicator_Data[index].लिँग.पुरुष : null,
        female: Table_Header.लिँग ? Indicator_Data[index].लिँग.महिला : null,
        other: Table_Header.लिँग ? Indicator_Data[index].लिँग.अन्य : null,
        _0_to_20: Table_Header.महिला_उमेर_समुह
          ? Indicator_Data[index].महिला_उमेर_समुह._०_देखि_२०
          : null,
        _21_or_older: Table_Header.महिला_उमेर_समुह
          ? Indicator_Data[index].महिला_उमेर_समुह._२१_वा_बढी
          : null,

        participate: Table_Header.भाग_लिएको_नलिएको
          ? Indicator_Data[index].भाग_लिएको_नलिएको.भाग_लिएको
          : null,
        non_participate: Table_Header.भाग_लिएको_नलिएको
          ? Indicator_Data[index].भाग_लिएको_नलिएको.भाग_नलिएको
          : null,
        _0_to_19: null,
        _20_to_29: null,
        _30_to_39: null,
        _40_to_59: null,
        _60_or_older: null,
        amount: Table_Header.रकम === "" ? Indicator_Data[index].रकम : null,
        duration: Table_Header.अवधि === "" ? Indicator_Data[index].अवधि : null,
        age: Table_Header.age ? Indicator_Data[index].age : null,
        Type: Table_Header.प्रकार === "" ? Indicator_Data[index].प्रकार : null,

        area:
          Table_Header.क्षेत्रफल === ""
            ? Indicator_Data[index].क्षेत्रफल
            : null,
        passed: Table_Header.उतिर्ण_अनुतिर्ण
          ? Indicator_Data[index].उतिर्ण_अनुतिर्ण.उतिर्ण
          : null,
        failed: Table_Header.उतिर्ण_अनुतिर्ण
          ? Indicator_Data[index].उतिर्ण_अनुतिर्ण.अनुतिर्ण
          : null,
        medium:
          Table_Header.माध्यम् === "" ? Indicator_Data[index].माध्यम् : null,
        _0_to_16: Table_Header.बच्चा_उमेर_समुह
          ? Indicator_Data[index].बच्चा_उमेर_समुह._०_देखि_१६
          : null,
        _17_to_18: Table_Header.बच्चा_उमेर_समुह
          ? Indicator_Data[index].बच्चा_उमेर_समुह._१७_देखि_१८
          : null,

        ratio: Table_Header.अनुपात === "" ? Indicator_Data[index].अनुपात : null,
        weight: Table_Header.तौल् === "" ? Indicator_Data[index].तौल् : null,
      });
    }

    SetEdit_Data(true);
  };

  {
    /** Used to close the editing data form after editing data */
  }
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let year = today.getFullYear();
  let final_date = ADToBS(year + "-" + mm + "-" + dd);

  const handleSubmit = (e) => {
    e.preventDefault();
    let letters = /^[A-Za-z]+$/;
    let neg_integer_validation = /^[-+]?[0-9]+\.[0-9]+$/;
    let today_date = final_date.replace(/[^\w\s]/gi, "");

    let entered_date = Submitting_Data.date.replace(/[^\w\s]/gi, "");
    let decimal = /^(\-(\d*))$/;
    if (!Tost) {
      if (Submitting_Data.province === null) {
        notification.showError("Please enter Province!!!");
      } else if (
        Submitting_Data.district === null ||
        Submitting_Data.district === ""
      ) {
        notification.showError("Please enter District!!!");
      } else if (
        Submitting_Data.municipality === null ||
        Submitting_Data.municipality === ""
      ) {
        notification.showError("Please enter Municipality!!!");
      } else if (!Submitting_Data.date) {
        notification.showError("Please enter valid date");
      } else if (entered_date > today_date) {
        notification.showError("Future Date Cannot Be Entered !!!");
      } else if (
        !Table_Header.बच्चा_उमेर_समुह &&
        !Table_Header.महिला_उमेर_समुह &&
        !Table_Header.लिँग &&
        !Table_Header.भाग_लिएको_नलिएको &&
        !Submitting_Data.total
      ) {
        notification.showError("Please enter the total count");
      } else if (
        !Table_Header.महिला_उमेर_समुह &&
        !Table_Header.लिँग &&
        !Table_Header.उतिर्ण_अनुतिर्ण &&
        !Table_Header.बच्चा_उमेर_समुह &&
        !Table_Header.भाग_लिएको_नलिएको &&
        Submitting_Data.total.match(letters)
      ) {
        notification.showError("Total Count Must be number");
      } else if (
        Table_Header.लिँग &&
        (Submitting_Data.male === null || !Submitting_Data.male)
      ) {
        notification.showError("Please Enter Male Count");
      } else if (Table_Header.लिँग && Submitting_Data.male.match(letters)) {
        notification.showError("Male count must be number");
      } else if (
        (Table_Header.लिँग &&
          Submitting_Data.male.match(neg_integer_validation)) ||
        (Table_Header.लिँग && Submitting_Data.male.match(decimal))
      ) {
        notification.showError(
          "Male count cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.लिँग &&
        (Submitting_Data.female === null || !Submitting_Data.female)
      ) {
        notification.showError("Please Enter Female Count");
      } else if (Table_Header.लिँग && Submitting_Data.female.match(letters)) {
        notification.showError("Female count must be number");
      } else if (
        (Table_Header.लिँग &&
          Submitting_Data.female.match(neg_integer_validation)) ||
        (Table_Header.लिँग && Submitting_Data.female.match(decimal))
      ) {
        notification.showError(
          "Female count cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.लिँग &&
        (Submitting_Data.other === null || !Submitting_Data.other)
      ) {
        notification.showError("Please Enter Other's Count");
      } else if (Table_Header.लिँग && Submitting_Data.other.match(letters)) {
        notification.showError("Other's count must be number");
      } else if (
        (Table_Header.लिँग &&
          Submitting_Data.other.match(neg_integer_validation)) ||
        (Table_Header.लिँग && Submitting_Data.other.match(decimal))
      ) {
        notification.showError(
          "Other count cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.महिला_उमेर_समुह &&
        (Submitting_Data._0_to_20 === null || !Submitting_Data._0_to_20)
      ) {
        notification.showError("Please Enter Age group below 20 Years");
      } else if (
        Table_Header.महिला_उमेर_समुह &&
        Submitting_Data._0_to_20.match(letters)
      ) {
        notification.showError("Age group below 20 year must be number");
      } else if (
        (Table_Header.महिला_उमेर_समुह &&
          Submitting_Data._0_to_20.match(neg_integer_validation)) ||
        (Table_Header.महिला_उमेर_समुह &&
          Submitting_Data._0_to_20.match(decimal))
      ) {
        notification.showError(
          "Age group below 20 year cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.महिला_उमेर_समुह &&
        (Submitting_Data._21_or_older === null || !Submitting_Data._21_or_older)
      ) {
        notification.showError("Please enter age group above 20 years");
      } else if (
        Table_Header.महिला_उमेर_समुह &&
        Submitting_Data._21_or_older.match(letters)
      ) {
        notification.showError("Age group above 20 years must be number");
      } else if (
        (Table_Header.महिला_उमेर_समुह &&
          Submitting_Data._21_or_older.match(neg_integer_validation)) ||
        (Table_Header.महिला_उमेर_समुह &&
          Submitting_Data._21_or_older.match(decimal))
      ) {
        notification.showError(
          "Age group above 20 years cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.बच्चा_उमेर_समुह &&
        (Submitting_Data._0_to_16 === null || !Submitting_Data._0_to_16)
      ) {
        notification.showError(
          "Please enter child age group count below 16 years"
        );
      } else if (
        Table_Header.बच्चा_उमेर_समुह &&
        Submitting_Data._0_to_16.match(letters)
      ) {
        notification.showError(
          "Child age group count below 16 years must be number"
        );
      } else if (
        (Table_Header.बच्चा_उमेर_समुह &&
          Submitting_Data._0_to_16.match(neg_integer_validation)) ||
        (Table_Header.बच्चा_उमेर_समुह &&
          Submitting_Data._0_to_16.match(decimal))
      ) {
        notification.showError(
          "Child age group count below 16 years cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.बच्चा_उमेर_समुह &&
        (Submitting_Data._17_to_18 === null || !Submitting_Data._17_to_18)
      ) {
        notification.showError(
          "Please enter child age group count between 16 to 18 years"
        );
      } else if (
        Table_Header.बच्चा_उमेर_समुह &&
        Submitting_Data._17_to_18.match(letters)
      ) {
        notification.showError(
          "Child age group count between 16 to 18 years must be number"
        );
      } else if (
        (Table_Header.बच्चा_उमेर_समुह &&
          Submitting_Data._17_to_18.match(neg_integer_validation)) ||
        (Table_Header.बच्चा_उमेर_समुह &&
          Submitting_Data._17_to_18.match(decimal))
      ) {
        notification.showError(
          "Child age group count between 16 to 18 years cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.भाग_लिएको_नलिएको &&
        (Submitting_Data.participate === null || !Submitting_Data.participate)
      ) {
        notification.showError("Please Enter Participated Child Count");
      } else if (
        Table_Header.भाग_लिएको_नलिएको &&
        Submitting_Data.participate.match(letters)
      ) {
        notification.showError("Participated Child Count must be number");
      } else if (
        (Table_Header.भाग_लिएको_नलिएको &&
          Submitting_Data.participate.match(neg_integer_validation)) ||
        (Table_Header.भाग_लिएको_नलिएको &&
          Submitting_Data.participate.match(decimal))
      ) {
        notification.showError(
          "Participated Child Count cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.भाग_लिएको_नलिएको &&
        (Submitting_Data.non_participate === null ||
          !Submitting_Data.non_participate)
      ) {
        notification.showError("Please Enter Non-Participated Child Count");
      } else if (
        Table_Header.भाग_लिएको_नलिएको &&
        Submitting_Data.non_participate.match(letters)
      ) {
        notification.showError("Non-Participated Child Count must be number");
      } else if (
        (Table_Header.भाग_लिएको_नलिएको &&
          Submitting_Data.non_participate.match(neg_integer_validation)) ||
        (Table_Header.भाग_लिएको_नलिएको &&
          Submitting_Data.non_participate.match(decimal))
      ) {
        notification.showError(
          "Non-Participated Child Count cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.रकम === "" &&
        (Submitting_Data.amount === null || Submitting_Data.amount === "")
      ) {
        notification.showError("Please enter the amount");
      } else if (
        Table_Header.रकम === "" &&
        Submitting_Data.amount.match(letters)
      ) {
        notification.showError("Amount must be number");
      } else if (
        Table_Header.रकम === "" &&
        Submitting_Data.amount.match(neg_integer_validation)
      ) {
        notification.showError("Amount cannot be negative number");
      } else if (
        Table_Header.क्षेत्रफल === "" &&
        (Submitting_Data.area === null || Submitting_Data.area === "")
      ) {
        notification.showError("Please enter the Area Details");
      } else if (
        Table_Header.क्षेत्रफल === "" &&
        Submitting_Data.area.match(neg_integer_validation)
      ) {
        notification.showError("Area cannot be negative number");
      } else if (
        Table_Header.अवधि === "" &&
        (Submitting_Data.duration === null || Submitting_Data.duration === "")
      ) {
        notification.showError("Please enter the training duration");
      } else if (
        Table_Header.अवधि === "" &&
        Submitting_Data.duration.match(neg_integer_validation)
      ) {
        notification.showError("Training duration cannot be negative number");
      } else if (
        Table_Header.माध्यम् === "" &&
        (Submitting_Data.medium === null || Submitting_Data.medium === "")
      ) {
        notification.showError("Please enter the medium of Information flow");
      } else if (
        Table_Header.अनुपात === "" &&
        (Submitting_Data.ratio === null || Submitting_Data.ratio === "")
      ) {
        notification.showError("Please enter the Ratio");
      } else if (
        Table_Header.तौल् === "" &&
        (Submitting_Data.weight === null || Submitting_Data.weight === "")
      ) {
        notification.showError("Please enter the Weight Quantity");
      } else if (
        Table_Header.तौल् === "" &&
        Submitting_Data.weight.match(neg_integer_validation)
      ) {
        notification.showError("Weight cannot be negative number");
      } else if (
        Table_Header.प्रकार === "" &&
        (Submitting_Data.Type === null || Submitting_Data.Type === "")
      ) {
        notification.showError("Please enter the types");
      } else if (
        Table_Header.प्रकार === "" &&
        Submitting_Data.Type.match(neg_integer_validation)
      ) {
        notification.showError("Types cannot be negative number");
      } else if (
        Table_Header.उतिर्ण_अनुतिर्ण &&
        (Submitting_Data.passed === null || Submitting_Data.passed === "")
      ) {
        notification.showError("Please enter passed number of students");
      } else if (
        Table_Header.उतिर्ण_अनुतिर्ण &&
        Submitting_Data.passed.match(letters)
      ) {
        notification.showError("Passed students count must be number ");
      } else if (
        (Table_Header.उतिर्ण_अनुतिर्ण &&
          Submitting_Data.passed.match(neg_integer_validation)) ||
        (Table_Header.उतिर्ण_अनुतिर्ण && Submitting_Data.passed.match(decimal))
      ) {
        notification.showError(
          "Passed students count cannot be negative or decimal number !!! "
        );
      } else if (
        Table_Header.उतिर्ण_अनुतिर्ण &&
        (Submitting_Data.failed === null || Submitting_Data.failed === "")
      ) {
        notification.showError("Please enter failed number of students");
      } else if (
        Table_Header.उतिर्ण_अनुतिर्ण &&
        Submitting_Data.failed.match(letters)
      ) {
        notification.showError("Failed students count must be number ");
      } else if (
        (Table_Header.उतिर्ण_अनुतिर्ण &&
          Submitting_Data.failed.match(neg_integer_validation)) ||
        (Table_Header.उतिर्ण_अनुतिर्ण && Submitting_Data.failed.match(decimal))
      ) {
        notification.showError(
          "Failed students count cannot be negative or decimal number !!! "
        );
      } else if (Table_Header.लिँग && Table_Header.भाग_लिएको_नलिएको) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let Participate_non_participate =
          parseInt(Submitting_Data.participate) +
          parseInt(Submitting_Data.non_participate);
        if (gender_total !== Participate_non_participate) {
          notification.showError(
            "Gender total and Participate/Non-participate Child Count total does not match!!!"
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .post(
              `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data added!!!");
              let element = document.getElementById("top");
              element.scrollIntoView();
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.लिँग ? "" : null,
                female: Table_Header.लिँग ? "" : null,
                other: Table_Header.लिँग ? "" : null,
                _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
                _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

                participate: Table_Header.भाग_लिएको_नलिएको ? "" : null,
                non_participate: Table_Header.भाग_लिएको_नलिएको ? "" : null,
                amount: Table_Header.रकम === "" ? "" : null,
                duration: Table_Header.अवधि === "" ? "" : null,
                area: Table_Header.क्षेत्रफल === "" ? "" : null,
                medium: Table_Header.माध्यम् === "" ? "" : null,
                ratio: Table_Header.अनुपात === "" ? "" : null,
                weight: Table_Header.तौल् === "" ? "" : null,
                Type: Table_Header.प्रकार === "" ? "" : null,
                passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                _0_to_16: Table_Header.बच्चा_उमेर_समुह ? "" : null,
                _17_to_18: Table_Header.बच्चा_उमेर_समुह ? "" : null,
              });
            });
        }
      } else if (Table_Header.लिँग && Table_Header.बच्चा_उमेर_समुह) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let child_age_group_total =
          parseInt(Submitting_Data._0_to_16) +
          parseInt(Submitting_Data._17_to_18);

        if (gender_total !== child_age_group_total) {
          notification.showError(
            "Gender total and Child age group total count does not match!!!"
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .post(
              `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data added!!!");
              let element = document.getElementById("top");
              element.scrollIntoView();
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.लिँग ? "" : null,
                female: Table_Header.लिँग ? "" : null,
                other: Table_Header.लिँग ? "" : null,
                _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
                _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

                amount: Table_Header.रकम === "" ? "" : null,
                duration: Table_Header.अवधि === "" ? "" : null,
                area: Table_Header.क्षेत्रफल === "" ? "" : null,
                medium: Table_Header.माध्यम् === "" ? "" : null,
                ratio: Table_Header.अनुपात === "" ? "" : null,
                weight: Table_Header.तौल् === "" ? "" : null,
                Type: Table_Header.प्रकार === "" ? "" : null,
                passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                _0_to_16: Table_Header.बच्चा_उमेर_समुह ? "" : null,
                _17_to_18: Table_Header.बच्चा_उमेर_समुह ? "" : null,
              });
            });
        }
      } else if (Table_Header.लिँग && Table_Header.महिला_उमेर_समुह) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let age_group_total =
          parseInt(Submitting_Data._0_to_20) +
          parseInt(Submitting_Data._21_to_40) +
          parseInt(Submitting_Data._41_or_older);

        if (gender_total !== age_group_total) {
          notification.showError(
            "Gender total count and age group total count does not match!!! "
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .post(
              `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data added!!!");
              let element = document.getElementById("top");
              element.scrollIntoView();
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.लिँग ? "" : null,
                female: Table_Header.लिँग ? "" : null,
                other: Table_Header.लिँग ? "" : null,
                _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
                _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

                amount: Table_Header.रकम === "" ? "" : null,
                duration: Table_Header.अवधि === "" ? "" : null,
                area: Table_Header.क्षेत्रफल === "" ? "" : null,
                medium: Table_Header.माध्यम् === "" ? "" : null,
                ratio: Table_Header.अनुपात === "" ? "" : null,
                weight: Table_Header.तौल् === "" ? "" : null,
                Type: Table_Header.प्रकार === "" ? "" : null,
                passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              });
            });
        }
      } else if (Table_Header.लिँग && Table_Header.उतिर्ण_अनुतिर्ण) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let pass_fail_total =
          parseInt(Submitting_Data.passed) + parseInt(Submitting_Data.failed);
        if (gender_total !== pass_fail_total) {
          notification.showError(
            "Gender total count and pass fail total count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .post(
              `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data added!!!");
              let element = document.getElementById("top");
              element.scrollIntoView();
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.लिँग ? "" : null,
                female: Table_Header.लिँग ? "" : null,
                other: Table_Header.लिँग ? "" : null,
                _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
                _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

                amount: Table_Header.रकम === "" ? "" : null,
                duration: Table_Header.अवधि === "" ? "" : null,
                area: Table_Header.क्षेत्रफल === "" ? "" : null,
                medium: Table_Header.माध्यम् === "" ? "" : null,
                ratio: Table_Header.अनुपात === "" ? "" : null,
                weight: Table_Header.तौल् === "" ? "" : null,
                Type: Table_Header.प्रकार === "" ? "" : null,
                passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              });
            });
        }
      } else if (Table_Header.महिला_उमेर_समुह) {
        setLoading_Add_Button(true);
        httpBrowsing
          .post(
            `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
            Submitting_Data
          )
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data Added!!!");

            SetSubmitting_Data({
              ...Submitting_Data,
              total: null,

              male: Table_Header.लिँग ? "" : null,
              female: Table_Header.लिँग ? "" : null,
              other: Table_Header.लिँग ? "" : null,
              _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
              _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

              amount: Table_Header.रकम === "" ? "" : null,
              duration: Table_Header.अवधि === "" ? "" : null,
              area: Table_Header.क्षेत्रफल === "" ? "" : null,
              medium: Table_Header.माध्यम् === "" ? "" : null,
              ratio: Table_Header.अनुपात === "" ? "" : null,
              weight: Table_Header.तौल् === "" ? "" : null,
              Type: Table_Header.प्रकार === "" ? "" : null,
              passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
            });
          });
      } else if (
        !Table_Header.महिला_उमेर_समुह &&
        !Table_Header.लिँग &&
        !Table_Header.उतिर्ण_अनुतिर्ण &&
        !Table_Header.बच्चा_उमेर_समुह &&
        !Table_Header.भाग_लिएको_नलिएको
      ) {
        setLoading_Add_Button(true);

        httpBrowsing
          .post(
            `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
            Submitting_Data
          )
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data added!!!");
            let element = document.getElementById("top");
            element.scrollIntoView();
            SetSubmitting_Data({
              ...Submitting_Data,
              total: "",

              male: Table_Header.लिँग ? "" : null,
              female: Table_Header.लिँग ? "" : null,
              other: Table_Header.लिँग ? "" : null,
              _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
              _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

              amount: Table_Header.रकम === "" ? "" : null,
              duration: Table_Header.अवधि === "" ? "" : null,
              area: Table_Header.क्षेत्रफल === "" ? "" : null,
              medium: Table_Header.माध्यम् === "" ? "" : null,
              ratio: Table_Header.अनुपात === "" ? "" : null,
              weight: Table_Header.तौल् === "" ? "" : null,
              Type: Table_Header.प्रकार === "" ? "" : null,
              passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
            });
          });
      } else {
        setLoading_Add_Button(true);

        httpBrowsing
          .post(
            `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
            Submitting_Data
          )
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data added!!!");
            let element = document.getElementById("top");
            element.scrollIntoView();
            SetSubmitting_Data({
              ...Submitting_Data,
              total: null,

              male: Table_Header.लिँग ? "" : null,
              female: Table_Header.लिँग ? "" : null,
              other: Table_Header.लिँग ? "" : null,
              _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
              _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

              amount: Table_Header.रकम === "" ? "" : null,
              duration: Table_Header.अवधि === "" ? "" : null,
              area: Table_Header.क्षेत्रफल === "" ? "" : null,
              medium: Table_Header.माध्यम् === "" ? "" : null,
              ratio: Table_Header.अनुपात === "" ? "" : null,
              weight: Table_Header.तौल् === "" ? "" : null,
              Type: Table_Header.प्रकार === "" ? "" : null,
              passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
            });
          });
      }
    } else {
      if (Submitting_Data.province === null) {
        notification.showError("Please enter Province!!!");
      } else if (
        Submitting_Data.district === null ||
        Submitting_Data.district === ""
      ) {
        notification.showError("Please enter District!!!");
      } else if (
        Submitting_Data.municipality === null ||
        Submitting_Data.municipality === ""
      ) {
        notification.showError("Please enter Municipality!!!");
      } else if (!Submitting_Data.date) {
        notification.showError("Please enter valid date");
      } else if (entered_date > today_date) {
        notification.showError("Future Date Cannot Be Entered !!!");
      } else if (
        !Table_Header.woman_age_group &&
        !Table_Header.gender &&
        !Submitting_Data.total
      ) {
        notification.showError("Please enter the total count");
      } else if (
        !Table_Header.woman_age_group &&
        !Table_Header.gender &&
        !Table_Header.pass_fail_count &&
        !Table_Header.children_age_group &&
        !Table_Header.participation_count &&
        Submitting_Data.total.match(letters)
      ) {
        notification.showError("Total Count Must be number");
      } else if (
        !Table_Header.woman_age_group &&
        !Table_Header.gender &&
        !Table_Header.pass_fail_count &&
        !Table_Header.children_age_group &&
        !Table_Header.participation_count &&
        Submitting_Data.total.match(neg_integer_validation)
      ) {
        notification.showError(
          "Total Count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.gender &&
        (Submitting_Data.male === null || !Submitting_Data.male)
      ) {
        notification.showError("Please Enter Male Count");
      } else if (Table_Header.gender && Submitting_Data.male.match(letters)) {
        notification.showError("Male count must be number");
      } else if (
        (Table_Header.gender &&
          Submitting_Data.male.match(neg_integer_validation)) ||
        (Table_Header.gender && Submitting_Data.male.match(decimal))
      ) {
        notification.showError(
          "Male count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.gender &&
        (Submitting_Data.female === null || !Submitting_Data.female)
      ) {
        notification.showError("Please Enter Female Count");
      } else if (Table_Header.gender && Submitting_Data.female.match(letters)) {
        notification.showError("Female count must be number");
      } else if (
        (Table_Header.gender &&
          Submitting_Data.female.match(neg_integer_validation)) ||
        (Table_Header.gender && Submitting_Data.female.match(decimal))
      ) {
        notification.showError(
          "Female count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.gender &&
        (Submitting_Data.other === null || !Submitting_Data.other)
      ) {
        notification.showError("Please Enter Other's Count");
      } else if (Table_Header.gender && Submitting_Data.other.match(letters)) {
        notification.showError("Other's count must be number");
      } else if (
        (Table_Header.gender &&
          Submitting_Data.other.match(neg_integer_validation)) ||
        (Table_Header.gender && Submitting_Data.other.match(decimal))
      ) {
        notification.showError(
          "Other's count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.children_age_group &&
        (Submitting_Data._0_to_16 === null || !Submitting_Data._0_to_16)
      ) {
        notification.showError("Please Enter Child Age group below 16 years");
      } else if (
        Table_Header.children_age_group &&
        Submitting_Data._0_to_16.match(letters)
      ) {
        notification.showError("Child age group below 16 years must be number");
      } else if (
        (Table_Header.children_age_group &&
          Submitting_Data._0_to_16.match(neg_integer_validation)) ||
        (Table_Header.children_age_group &&
          Submitting_Data._0_to_16.match(decimal))
      ) {
        notification.showError(
          "Child age group below 16 years cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.children_age_group &&
        (Submitting_Data._17_to_18 === null || !Submitting_Data._17_to_18)
      ) {
        notification.showError(
          "Please Enter Child Age group between 16 to 18 years"
        );
      } else if (
        Table_Header.children_age_group &&
        Submitting_Data._17_to_18.match(letters)
      ) {
        notification.showError(
          "Child age group between 16 to 18 years must be number"
        );
      } else if (
        (Table_Header.children_age_group &&
          Submitting_Data._17_to_18.match(neg_integer_validation)) ||
        (Table_Header.children_age_group &&
          Submitting_Data._17_to_18.match(decimal))
      ) {
        notification.showError(
          "Child age group between 16 to 18 years cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.woman_age_group &&
        (Submitting_Data._0_to_20 === null || !Submitting_Data._0_to_20)
      ) {
        notification.showError("Please Enter Age group below 20 Years");
      } else if (
        Table_Header.woman_age_group &&
        Submitting_Data._0_to_20.match(letters)
      ) {
        notification.showError("Age group below 20 year must be number");
      } else if (
        (Table_Header.woman_age_group &&
          Submitting_Data._0_to_20.match(neg_integer_validation)) ||
        (Table_Header.woman_age_group &&
          Submitting_Data._0_to_20.match(decimal))
      ) {
        notification.showError(
          "Age group below 20 year cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.woman_age_group &&
        (Submitting_Data._21_or_older === null || !Submitting_Data._21_or_older)
      ) {
        notification.showError("Please enter age group above 20 years");
      } else if (
        Table_Header.woman_age_group &&
        Submitting_Data._21_or_older.match(letters)
      ) {
        notification.showError("Age group above 20 years must be number");
      } else if (
        (Table_Header.woman_age_group &&
          Submitting_Data._21_or_older.match(neg_integer_validation)) ||
        (Table_Header.woman_age_group &&
          Submitting_Data._21_or_older.match(decimal))
      ) {
        notification.showError(
          "Age group above 20 years cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.participation_count &&
        (Submitting_Data.participate === null || !Submitting_Data.participate)
      ) {
        notification.showError("Please Enter Participated Child Count");
      } else if (
        Table_Header.participation_count &&
        Submitting_Data.participate.match(letters)
      ) {
        notification.showError("Participate Child Count must be number");
      } else if (
        (Table_Header.participation_count &&
          Submitting_Data.participate.match(neg_integer_validation)) ||
        (Table_Header.participation_count &&
          Submitting_Data.participate.match(decimal))
      ) {
        notification.showError(
          "Participate Child Count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.participation_count &&
        (Submitting_Data.non_participate === null ||
          !Submitting_Data.non_participate)
      ) {
        notification.showError("Please Enter Non-Participated Child Count");
      } else if (
        Table_Header.participation_count &&
        Submitting_Data.non_participate.match(letters)
      ) {
        notification.showError("Non-Participate Child Count must be number");
      } else if (
        (Table_Header.participation_count &&
          Submitting_Data.non_participate.match(neg_integer_validation)) ||
        (Table_Header.participation_count &&
          Submitting_Data.non_participate.match(decimal))
      ) {
        notification.showError(
          "Non-Participate Child Count cannot be negative or decimal number!!!"
        );
      } else if (
        Table_Header.amount === "" &&
        (Submitting_Data.amount === null || Submitting_Data.amount === "")
      ) {
        notification.showError("Please enter the amount");
      } else if (
        Table_Header.amount === "" &&
        Submitting_Data.amount.match(letters)
      ) {
        notification.showError("Amount must be number");
      } else if (
        Table_Header.amount === "" &&
        Submitting_Data.amount.match(neg_integer_validation)
      ) {
        notification.showError("Amount cannot be negative number !!!");
      } else if (
        Table_Header.area === "" &&
        (Submitting_Data.area === null || Submitting_Data.area === "")
      ) {
        notification.showError("Please enter the Area Details");
      } else if (
        Table_Header.area === "" &&
        Submitting_Data.area.match(neg_integer_validation)
      ) {
        notification.showError("Area cannot be negative number !!!");
      } else if (
        Table_Header.duration === "" &&
        (Submitting_Data.duration === null || Submitting_Data.duration === "")
      ) {
        notification.showError("Please enter the training duration");
      } else if (
        Table_Header.duration === "" &&
        Submitting_Data.duration.match(neg_integer_validation)
      ) {
        notification.showError(
          "Training duration cannot be negative number !!!"
        );
      } else if (
        Table_Header.medium === "" &&
        (Submitting_Data.medium === null || Submitting_Data.medium === "")
      ) {
        notification.showError("Please enter the medium of Information flow");
      } else if (
        Table_Header.ratio === "" &&
        (Submitting_Data.ratio === null || Submitting_Data.ratio === "")
      ) {
        notification.showError("Please enter the Ratio");
      } else if (
        Table_Header.weight === "" &&
        (Submitting_Data.weight === null || Submitting_Data.weight === "")
      ) {
        notification.showError("Please enter the Weight Quantity");
      } else if (
        Table_Header.weight === "" &&
        Submitting_Data.weight.match(neg_integer_validation)
      ) {
        notification.showError("Weight cannot be negative number !!!");
      } else if (
        Table_Header.Type === "" &&
        (Submitting_Data.Type === null || Submitting_Data.Type === "")
      ) {
        notification.showError("Please enter the Type");
      } else if (
        Table_Header.pass_fail_count &&
        (Submitting_Data.passed === null || Submitting_Data.passed === "")
      ) {
        notification.showError("Please enter passed number of students");
      } else if (
        Table_Header.pass_fail_count &&
        Submitting_Data.passed.match(letters)
      ) {
        notification.showError("Passed students count must be number ");
      } else if (
        (Table_Header.pass_fail_count &&
          Submitting_Data.passed.match(neg_integer_validation)) ||
        (Table_Header.pass_fail_count && Submitting_Data.passed.match(decimal))
      ) {
        notification.showError(
          "Passed students count cannot be negative or decimal number!!! "
        );
      } else if (
        Table_Header.pass_fail_count &&
        (Submitting_Data.failed === null || Submitting_Data.failed === "")
      ) {
        notification.showError("Please enter failed number of students");
      } else if (
        Table_Header.pass_fail_count &&
        Submitting_Data.failed.match(letters)
      ) {
        notification.showError("Failed students count must be number ");
      } else if (
        Table_Header.pass_fail_count &&
        Submitting_Data.failed.match(neg_integer_validation) &&
        Table_Header.pass_fail_count &&
        Submitting_Data.failed.match(decimal)
      ) {
        notification.showError(
          "Failed students count cannot be negative or decimal number !!! "
        );
      } else if (Table_Header.gender && Table_Header.participation_count) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);

        let participate_non_patricipate =
          parseInt(Submitting_Data.participate) +
          parseInt(Submitting_Data.non_participate);
        if (gender_total !== participate_non_patricipate) {
          notification.showError(
            "Gender total and Participate/Non Participate Child Count Does Not Match"
          );
        } else {
          setLoading_Add_Button(true);

          httpBrowsing
            .post(
              `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data added!!!");
              let element = document.getElementById("top");
              element.scrollIntoView();
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.gender ? "" : null,
                female: Table_Header.gender ? "" : null,
                other: Table_Header.gender ? "" : null,
                _0_to_20: Table_Header.woman_age_group ? "" : null,
                _21_or_older: Table_Header.woman_age_group ? "" : null,

                participate: Table_Header.participation_count ? "" : null,
                non_participate: Table_Header.participation_count ? "" : null,
                amount: Table_Header.amount === "" ? "" : null,
                duration: Table_Header.duration === "" ? "" : null,
                area: Table_Header.area === "" ? "" : null,
                medium: Table_Header.medium === "" ? "" : null,
                ratio: Table_Header.ratio === "" ? "" : null,
                weight: Table_Header.weight === "" ? "" : null,
                Type: Table_Header.Type === "" ? "" : null,
                passed: Table_Header.pass_fail_count ? "" : null,
                failed: Table_Header.pass_fail_count ? "" : null,
                _0_to_16: Table_Header.children_age_group ? "" : null,
                _17_to_18: Table_Header.children_age_group ? "" : null,
              });
            });
        }
      } else if (Table_Header.gender && Table_Header.children_age_group) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let children_age_group_total =
          parseInt(Submitting_Data._0_to_16) +
          parseInt(Submitting_Data._17_to_18);

        if (gender_total !== children_age_group_total) {
          notification.showError(
            "Gender total count and Child age group total count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);

          httpBrowsing
            .post(
              `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data added!!!");
              let element = document.getElementById("top");
              element.scrollIntoView();
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.gender ? "" : null,
                female: Table_Header.gender ? "" : null,
                other: Table_Header.gender ? "" : null,
                _0_to_20: Table_Header.woman_age_group ? "" : null,
                _21_or_older: Table_Header.woman_age_group ? "" : null,

                amount: Table_Header.amount === "" ? "" : null,
                duration: Table_Header.duration === "" ? "" : null,
                area: Table_Header.area === "" ? "" : null,
                medium: Table_Header.medium === "" ? "" : null,
                ratio: Table_Header.ratio === "" ? "" : null,
                weight: Table_Header.weight === "" ? "" : null,
                Type: Table_Header.Type === "" ? "" : null,
                passed: Table_Header.pass_fail_count ? "" : null,
                failed: Table_Header.pass_fail_count ? "" : null,
                _0_to_16: Table_Header.children_age_group ? "" : null,
                _17_to_18: Table_Header.children_age_group ? "" : null,
              });
            });
        }
      } else if (Table_Header.gender && Table_Header.age_group) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let age_group_total =
          parseInt(Submitting_Data._0_to_20) +
          parseInt(Submitting_Data._21_or_older);

        if (gender_total !== age_group_total) {
          notification.showError(
            "Gender total count and age group total count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);

          httpBrowsing
            .post(
              `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data added!!!");
              let element = document.getElementById("top");
              element.scrollIntoView();
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.gender ? "" : null,
                female: Table_Header.gender ? "" : null,
                other: Table_Header.gender ? "" : null,
                _0_to_20: Table_Header.woman_age_group ? "" : null,
                _21_or_older: Table_Header.woman_age_group ? "" : null,

                amount: Table_Header.amount === "" ? "" : null,
                duration: Table_Header.duration === "" ? "" : null,
                area: Table_Header.area === "" ? "" : null,
                medium: Table_Header.medium === "" ? "" : null,
                ratio: Table_Header.ratio === "" ? "" : null,
                weight: Table_Header.weight === "" ? "" : null,
                Type: Table_Header.Type === "" ? "" : null,
                passed: Table_Header.pass_fail_count ? "" : null,
                failed: Table_Header.pass_fail_count ? "" : null,
              });
            });
        }
      } else if (Table_Header.gender && Table_Header.pass_fail_count) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let pass_fail_total =
          parseInt(Submitting_Data.passed) + parseInt(Submitting_Data.failed);
        if (gender_total !== pass_fail_total) {
          notification.showError(
            "Gender total count and pass fail total count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);

          httpBrowsing
            .post(
              `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data added!!!");
              let element = document.getElementById("top");
              element.scrollIntoView();
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.gender ? "" : null,
                female: Table_Header.gender ? "" : null,
                other: Table_Header.gender ? "" : null,
                _0_to_20: Table_Header.woman_age_group ? "" : null,
                _21_or_older: Table_Header.woman_age_group ? "" : null,

                amount: Table_Header.amount === "" ? "" : null,
                duration: Table_Header.duration === "" ? "" : null,
                area: Table_Header.area === "" ? "" : null,
                medium: Table_Header.medium === "" ? "" : null,
                ratio: Table_Header.ratio === "" ? "" : null,
                weight: Table_Header.weight === "" ? "" : null,
                Type: Table_Header.Type === "" ? "" : null,
                passed: Table_Header.pass_fail_count ? "" : null,
                failed: Table_Header.pass_fail_count ? "" : null,
              });
            });
        }
      } else if (Table_Header.woman_age_group) {
        setLoading_Add_Button(true);
        httpBrowsing
          .post(
            `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
            Submitting_Data
          )
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data Added!!!");

            SetSubmitting_Data({
              ...Submitting_Data,
              total: null,

              male: Table_Header.gender ? "" : null,
              female: Table_Header.gender ? "" : null,
              other: Table_Header.gender ? "" : null,
              _0_to_20: Table_Header.woman_age_group ? "" : null,
              _21_or_older: Table_Header.woman_age_group ? "" : null,

              amount: Table_Header.amount === "" ? "" : null,
              duration: Table_Header.duration === "" ? "" : null,
              area: Table_Header.area === "" ? "" : null,
              medium: Table_Header.medium === "" ? "" : null,
              ratio: Table_Header.ratio === "" ? "" : null,
              weight: Table_Header.weight === "" ? "" : null,
              Type: Table_Header.Type === "" ? "" : null,
              passed: Table_Header.pass_fail_count ? "" : null,
              failed: Table_Header.pass_fail_count ? "" : null,
            });
          });
      } else if (
        !Table_Header.pass_fail_count &&
        !Table_Header.woman_age_group &&
        !Table_Header.gender &&
        !Table_Header.children_age_group &&
        !Table_Header.pass_fail_count
      ) {
        setLoading_Add_Button(true);

        httpBrowsing
          .post(
            `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
            Submitting_Data
          )
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data added!!!");
            let element = document.getElementById("top");
            element.scrollIntoView();
            SetSubmitting_Data({
              ...Submitting_Data,
              total: "",

              male: Table_Header.gender ? "" : null,
              female: Table_Header.gender ? "" : null,
              other: Table_Header.gender ? "" : null,
              _0_to_20: Table_Header.woman_age_group ? "" : null,
              _21_or_older: Table_Header.woman_age_group ? "" : null,

              amount: Table_Header.amount === "" ? "" : null,
              duration: Table_Header.duration === "" ? "" : null,
              area: Table_Header.area === "" ? "" : null,
              medium: Table_Header.medium === "" ? "" : null,
              ratio: Table_Header.ratio === "" ? "" : null,
              weight: Table_Header.weight === "" ? "" : null,
              Type: Table_Header.Type === "" ? "" : null,
              passed: Table_Header.pass_fail_count ? "" : null,
              failed: Table_Header.pass_fail_count ? "" : null,
            });
          });
      } else {
        setLoading_Add_Button(true);

        httpBrowsing
          .post(
            `/eng/indicator-data/add?indicator=${Right_indicator_id}`,
            Submitting_Data
          )
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data added!!!");
            let element = document.getElementById("top");
            element.scrollIntoView();
            SetSubmitting_Data({
              ...Submitting_Data,
              total: null,

              male: Table_Header.gender ? "" : null,
              female: Table_Header.gender ? "" : null,
              other: Table_Header.gender ? "" : null,
              _0_to_20: Table_Header.woman_age_group ? "" : null,
              _21_or_older: Table_Header.woman_age_group ? "" : null,

              amount: Table_Header.amount === "" ? "" : null,
              duration: Table_Header.duration === "" ? "" : null,
              area: Table_Header.area === "" ? "" : null,
              medium: Table_Header.medium === "" ? "" : null,
              ratio: Table_Header.ratio === "" ? "" : null,
              weight: Table_Header.weight === "" ? "" : null,
              Type: Table_Header.Type === "" ? "" : null,
              passed: Table_Header.pass_fail_count ? "" : null,
              failed: Table_Header.pass_fail_count ? "" : null,
            });
          });
      }
    }
  };

  const handleClose_Data_Edit = () => {
    SetEdit_Data(false);
    SetSubmitting_Data({
      total: "",

      male: Table_Header.gender ? "" : null,
      female: Table_Header.gender ? "" : null,
      other: Table_Header.gender ? "" : null,
      under_18: Table_Header.age_group ? "" : null,
      adult: Table_Header.age_group ? "" : null,
      senior: Table_Header.age_group ? "" : null,
      amount: Table_Header.amount === "" ? "" : null,
      duration: Table_Header.duration === "" ? "" : null,
    });
  };

  {
    /** Used to submit the edited data */
  }
  useEffect(() => {
    const Verifying_user = () => {
      httpBrowsing.get("/nep/me/").then((res) => {
        setIs_Supervisor(res.data.is_supervisor);
        setIs_Superuser(res.data.is_superuser);

        setUsername(res.data.username);
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let year = today.getFullYear();

        let final_date = ADToBS(year + "-" + mm + "-" + dd);
        SetSubmitting_Data({
          province: res.data.province,
          district: res.data.district,
          municipality: res.data.municipality,
          date: final_date,
        });
      });
    };
    Verifying_user();
  }, []);
  const handleSubmit_Edited_Data = (e) => {
    e.preventDefault();
    let neg_integer_validation = /^[-+]?[0-9]+\.[0-9]+$/;
    let decimal = /^(\-(\d*))$/;
    let letters = /^[A-Za-z]+$/;
    let today_date = final_date.replace(/[^\w\s]/gi, "");

    let entered_date = Submitting_Data.date.replace(/[^\w\s]/gi, "");
    if (!Tost) {
      if (Submitting_Data.province === null) {
        notification.showError("Please enter Province!!!");
      } else if (
        Submitting_Data.district === null ||
        Submitting_Data.district === ""
      ) {
        notification.showError("Please enter District!!!");
      } else if (
        Submitting_Data.municipality === null ||
        Submitting_Data.municipality === ""
      ) {
        notification.showError("Please enter Municipality!!!");
      } else if (Submitting_Data.date === "") {
        notification.showError("Please enter valid date");
      } else if (entered_date > today_date) {
        notification.showError("Future Date Cannot Be Entered !!!");
      } else if (
        !Table_Header.महिला_उमेर_समुह &&
        !Table_Header.लिँग &&
        !Table_Header.बच्चा_उमेर_समुह &&
        (Submitting_Data.total === null || Submitting_Data.total === "")
      ) {
        notification.showError("Please enter the total count");
      } else if (
        !Table_Header.महिला_उमेर_समुह &&
        !Table_Header.लिँग &&
        !Table_Header.उतिर्ण_अनुतिर्ण &&
        !Table_Header.बच्चा_उमेर_समुह &&
        !Table_Header.भाग_लिएको_नलिएको &&
        Submitting_Data.total.match(letters)
      ) {
        notification.showError("Total Count Must be number");
      } else if (
        Table_Header.लिँग &&
        (Submitting_Data.male === null || Submitting_Data.male === "")
      ) {
        notification.showError("Please Enter Male Count");
      } else if (Table_Header.लिँग && Submitting_Data.male.match(letters)) {
        notification.showError("Male count must be number");
      } else if (
        (Table_Header.लिँग &&
          Submitting_Data.male.match(neg_integer_validation)) ||
        (Table_Header.लिँग && Submitting_Data.male.match(decimal))
      ) {
        notification.showError(
          "Male count cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.लिँग &&
        (Submitting_Data.female === null || Submitting_Data.female === "")
      ) {
        notification.showError("Please Enter Female Count");
      } else if (Table_Header.लिँग && Submitting_Data.female.match(letters)) {
        notification.showError("Female count must be number");
      } else if (
        (Table_Header.लिँग &&
          Submitting_Data.female.match(neg_integer_validation)) ||
        (Table_Header.लिँग && Submitting_Data.female.match(decimal))
      ) {
        notification.showError(
          "Female count cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.लिँग &&
        (Submitting_Data.other === null || Submitting_Data.other === "")
      ) {
        notification.showError("Please Enter Other's Count");
      } else if (Table_Header.लिँग && Submitting_Data.other.match(letters)) {
        notification.showError("Other's count must be number");
      } else if (
        (Table_Header.लिँग &&
          Submitting_Data.other.match(neg_integer_validation)) ||
        (Table_Header.लिँग && Submitting_Data.other.match(decimal))
      ) {
        notification.showError(
          "Female count cannot be negative number or decimal number !!!"
        );
      } else if (
        Table_Header.महिला_उमेर_समुह &&
        (Submitting_Data._0_to_20 === null || !Submitting_Data._0_to_20)
      ) {
        notification.showError("Please Enter Age group below 20 Years");
      } else if (
        Table_Header.महिला_उमेर_समुह &&
        Submitting_Data._0_to_20.match(letters)
      ) {
        notification.showError("Age group below 20 must be number");
      } else if (
        (Table_Header.महिला_उमेर_समुह &&
          Submitting_Data._0_to_20.match(neg_integer_validation)) ||
        (Table_Header.महिला_उमेर_समुह &&
          Submitting_Data._0_to_20.match(decimal))
      ) {
        notification.showError(
          "Age group below 20 cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.महिला_उमेर_समुह &&
        (Submitting_Data._21_or_older === null || !Submitting_Data._21_or_older)
      ) {
        notification.showError("Please enter age group above 20 years");
      } else if (
        Table_Header.महिला_उमेर_समुह &&
        Submitting_Data._21_or_older.match(letters)
      ) {
        notification.showError("Age group above 20 years must be number");
      } else if (
        (Table_Header.महिला_उमेर_समुह &&
          Submitting_Data._21_or_older.match(neg_integer_validation)) ||
        (Table_Header.महिला_उमेर_समुह &&
          Submitting_Data._21_or_older.match(decimal))
      ) {
        notification.showError(
          "Age group above 20 years cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.बच्चा_उमेर_समुह &&
        (Submitting_Data._0_to_16 === null || !Submitting_Data._0_to_16)
      ) {
        notification.showError(
          "Please enter child age group count below 16 years"
        );
      } else if (
        Table_Header.बच्चा_उमेर_समुह &&
        Submitting_Data._0_to_16.match(letters)
      ) {
        notification.showError(
          "Child age group count below 16 years must be number"
        );
      } else if (
        (Table_Header.बच्चा_उमेर_समुह &&
          Submitting_Data._0_to_16.match(neg_integer_validation)) ||
        (Table_Header.बच्चा_उमेर_समुह &&
          Submitting_Data._0_to_16.match(decimal))
      ) {
        notification.showError(
          "Child age group count below 16 years cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.बच्चा_उमेर_समुह &&
        (Submitting_Data._17_to_18 === null || !Submitting_Data._17_to_18)
      ) {
        notification.showError(
          "Please enter child age group count between 16 to 18 years"
        );
      } else if (
        Table_Header.बच्चा_उमेर_समुह &&
        Submitting_Data._17_to_18.match(letters)
      ) {
        notification.showError(
          "Child age group count between 16 to 18 years must be number"
        );
      } else if (
        (Table_Header.बच्चा_उमेर_समुह &&
          Submitting_Data._17_to_18.match(neg_integer_validation)) ||
        (Table_Header.बच्चा_उमेर_समुह &&
          Submitting_Data._17_to_18.match(decimal))
      ) {
        notification.showError(
          "Child age group count between 16 to 18 years cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.भाग_लिएको_नलिएको &&
        (Submitting_Data.participate === null || !Submitting_Data.participate)
      ) {
        notification.showError("Please Enter Participated Child Count");
      } else if (
        Table_Header.भाग_लिएको_नलिएको &&
        Submitting_Data.participate.match(letters)
      ) {
        notification.showError("Participated Child Count must be number");
      } else if (
        (Table_Header.भाग_लिएको_नलिएको &&
          Submitting_Data.participate.match(neg_integer_validation)) ||
        (Table_Header.भाग_लिएको_नलिएको &&
          Submitting_Data.participate.match(decimal))
      ) {
        notification.showError(
          "Participated Child Count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.भाग_लिएको_नलिएको &&
        (Submitting_Data.non_participate === null ||
          !Submitting_Data.non_participate)
      ) {
        notification.showError("Please Enter Non-Participated Child Count");
      } else if (
        Table_Header.भाग_लिएको_नलिएको &&
        Submitting_Data.non_participate.match(letters)
      ) {
        notification.showError("Non-Participated Child Count must be number");
      } else if (
        (Table_Header.भाग_लिएको_नलिएको &&
          Submitting_Data.non_participate.match(neg_integer_validation)) ||
        (Table_Header.भाग_लिएको_नलिएको &&
          Submitting_Data.non_participate.match(decimal))
      ) {
        notification.showError(
          "Non-Participated Child Count cannot be negative or decimal !!!"
        );
      } else if (
        Table_Header.रकम === "" &&
        (Submitting_Data.amount === null || Submitting_Data.amount === "")
      ) {
        notification.showError("Please enter the amount");
      } else if (
        Table_Header.रकम === "" &&
        Submitting_Data.amount.match(letters)
      ) {
        notification.showError("Amount must be number");
      } else if (
        Table_Header.रकम === "" &&
        Submitting_Data.amount.match(neg_integer_validation)
      ) {
        notification.showError("Amount cannot be negative number !!!");
      } else if (
        Table_Header.क्षेत्रफल === "" &&
        (Submitting_Data.area === null || Submitting_Data.area === "")
      ) {
        notification.showError("Please enter the Area Details");
      } else if (
        Table_Header.क्षेत्रफल === "" &&
        Submitting_Data.area.match(neg_integer_validation)
      ) {
        notification.showError("Area cannot be negative number !!!");
      } else if (
        Table_Header.अवधि === "" &&
        (Submitting_Data.duration === null || Submitting_Data.duration === "")
      ) {
        notification.showError("Please enter the training duration");
      } else if (
        Table_Header.अवधि === "" &&
        Submitting_Data.duration.match(neg_integer_validation)
      ) {
        notification.showError(
          "Training Duration cannot be negative number !!!"
        );
      } else if (
        Table_Header.माध्यम् === "" &&
        (Submitting_Data.medium === null || Submitting_Data.medium === "")
      ) {
        notification.showError("Please enter the medium of Information flow");
      } else if (
        Table_Header.अनुपात === "" &&
        (Submitting_Data.ratio === null || Submitting_Data.ratio === "")
      ) {
        notification.showError("Please enter the Ratio");
      } else if (
        Table_Header.तौल् === "" &&
        (Submitting_Data.weight === null || Submitting_Data.weight === "")
      ) {
        notification.showError("Please enter the Weight Quantity");
      } else if (
        Table_Header.प्रकार === "" &&
        (Submitting_Data.Type === null || Submitting_Data.Type === "")
      ) {
        notification.showError("Please enter the Weight Quantity");
      } else if (
        Table_Header.उतिर्ण_अनुतिर्ण &&
        (Submitting_Data.passed === null || Submitting_Data.passed === "")
      ) {
        notification.showError("Please enter passed number of students");
      } else if (
        Table_Header.उतिर्ण_अनुतिर्ण &&
        Submitting_Data.passed.match(letters)
      ) {
        notification.showError("Passed students count must be number ");
      } else if (
        (Table_Header.उतिर्ण_अनुतिर्ण &&
          Submitting_Data.passed.match(neg_integer_validation)) ||
        (Table_Header.उतिर्ण_अनुतिर्ण && Submitting_Data.passed.match(decimal))
      ) {
        notification.showError(
          "Passed students count cannot be negative or decimal number !!! "
        );
      } else if (
        Table_Header.उतिर्ण_अनुतिर्ण &&
        (Submitting_Data.failed === null || Submitting_Data.failed === "")
      ) {
        notification.showError("Please enter failed number of students");
      } else if (
        Table_Header.उतिर्ण_अनुतिर्ण &&
        Submitting_Data.failed.match(letters)
      ) {
        notification.showError("Failed students count must be number ");
      } else if (
        (Table_Header.उतिर्ण_अनुतिर्ण &&
          Submitting_Data.failed.match(neg_integer_validation)) ||
        (Table_Header.उतिर्ण_अनुतिर्ण && Submitting_Data.failed.match(decimal))
      ) {
        notification.showError(
          "Failed students count cannot be negative or decimal number !!! "
        );
      } else if (Table_Header.लिँग && Table_Header.भाग_लिएको_नलिएको) {
        let male = parseInt(nepaliToEnglishNumber(Submitting_Data.male));
        let female = parseInt(nepaliToEnglishNumber(Submitting_Data.female));
        let other = parseInt(nepaliToEnglishNumber(Submitting_Data.other));
        let gender_total = male + female + other;
        let participated = parseInt(
          nepaliToEnglishNumber(Submitting_Data.participate)
        );
        let non_participated = parseInt(
          nepaliToEnglishNumber(Submitting_Data.non_participate)
        );
        let Participate_non_participate = participated + non_participated;
        if (gender_total !== Participate_non_participate) {
          notification.showError(
            "Gender total count and Participate/Non-Participate Child count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .put(
              `/eng/indicator-data/${Editing_Data_id}/update`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data Updated Successfully!!!");
              SetEdit_Data(false);
              setEdited(!Edited);
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.लिँग ? "" : null,
                female: Table_Header.लिँग ? "" : null,
                other: Table_Header.लिँग ? "" : null,
                _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
                _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

                participate: Table_Header.भाग_लिएको_नलिएको ? "" : null,
                non_participate: Table_Header.भाग_लिएको_नलिएको ? "" : null,
                amount: Table_Header.रकम === "" ? "" : null,
                duration: Table_Header.अवधि === "" ? "" : null,
                area: Table_Header.क्षेत्रफल === "" ? "" : null,
                medium: Table_Header.माध्यम् === "" ? "" : null,
                ratio: Table_Header.अनुपात === "" ? "" : null,
                weight: Table_Header.तौल् === "" ? "" : null,
                Type: Table_Header.प्रकार === "" ? "" : null,
                passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              });
            });
        }
      } else if (Table_Header.लिँग && Table_Header.उमेर_समुह) {
        let male = parseInt(nepaliToEnglishNumber(Submitting_Data.male));
        let female = parseInt(nepaliToEnglishNumber(Submitting_Data.female));
        let other = parseInt(nepaliToEnglishNumber(Submitting_Data.other));
        let gender_total = male + female + other;
        let under_20 = parseInt(
          nepaliToEnglishNumber(Submitting_Data._0_to_20)
        );
        let Age_20_to_40 = parseInt(
          nepaliToEnglishNumber(Submitting_Data._21_to_40)
        );
        let Age_40_to_above = parseInt(
          nepaliToEnglishNumber(Submitting_Data._41_or_older)
        );

        let age_group_total = under_20 + Age_20_to_40 + Age_40_to_above;

        if (gender_total !== age_group_total) {
          notification.showError(
            "Gender total count and age group total count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .put(
              `/eng/indicator-data/${Editing_Data_id}/update`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data Updated Successfully!!!");
              SetEdit_Data(false);
              setEdited(!Edited);
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.लिँग ? "" : null,
                female: Table_Header.लिँग ? "" : null,
                other: Table_Header.लिँग ? "" : null,
                _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
                _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

                amount: Table_Header.रकम === "" ? "" : null,
                duration: Table_Header.अवधि === "" ? "" : null,
                area: Table_Header.क्षेत्रफल === "" ? "" : null,
                medium: Table_Header.माध्यम् === "" ? "" : null,
                ratio: Table_Header.अनुपात === "" ? "" : null,
                weight: Table_Header.तौल् === "" ? "" : null,
                Type: Table_Header.प्रकार === "" ? "" : null,
                passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              });
            });
        }
      } else if (Table_Header.लिँग && Table_Header.बच्चा_उमेर_समुह) {
        let male = parseInt(nepaliToEnglishNumber(Submitting_Data.male));
        let female = parseInt(nepaliToEnglishNumber(Submitting_Data.female));
        let other = parseInt(nepaliToEnglishNumber(Submitting_Data.other));
        let gender_total = male + female + other;

        let Age_0_to_16 = parseInt(
          nepaliToEnglishNumber(Submitting_Data._0_to_16)
        );
        let Age_17_to_18 = parseInt(
          nepaliToEnglishNumber(Submitting_Data._17_to_18)
        );

        let Child_age_group_total = Age_0_to_16 + Age_17_to_18;

        if (gender_total !== Child_age_group_total) {
          notification.showError(
            "Gender total count and Child age group total count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .put(
              `/eng/indicator-data/${Editing_Data_id}/update`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data Updated Successfully!!!");
              SetEdit_Data(false);
              setEdited(!Edited);
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.लिँग ? "" : null,
                female: Table_Header.लिँग ? "" : null,
                other: Table_Header.लिँग ? "" : null,
                _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
                _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

                amount: Table_Header.रकम === "" ? "" : null,
                duration: Table_Header.अवधि === "" ? "" : null,
                area: Table_Header.क्षेत्रफल === "" ? "" : null,
                medium: Table_Header.माध्यम् === "" ? "" : null,
                ratio: Table_Header.अनुपात === "" ? "" : null,
                weight: Table_Header.तौल् === "" ? "" : null,
                Type: Table_Header.प्रकार === "" ? "" : null,
                passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                _0_to_16: Table_Header.बच्चा_उमेर_समुह ? "" : null,
                _17_to_18: Table_Header.बच्चा_उमेर_समुह ? "" : null,
              });
            });
        }
      } else if (Table_Header.लिँग && Table_Header.उतिर्ण_अनुतिर्ण) {
        let male = parseInt(nepaliToEnglishNumber(Submitting_Data.male));
        let female = parseInt(nepaliToEnglishNumber(Submitting_Data.female));
        let other = parseInt(nepaliToEnglishNumber(Submitting_Data.other));
        let gender_total = male + female + other;
        let passed = parseInt(nepaliToEnglishNumber(Submitting_Data.passed));
        let failed = parseInt(nepaliToEnglishNumber(Submitting_Data.failed));

        let pass_fail_total = passed + failed;

        if (gender_total !== pass_fail_total) {
          notification.showError(
            "Gender total count and pass fail total count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .put(
              `/eng/indicator-data/${Editing_Data_id}/update`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data Updated Successfully!!!");
              SetEdit_Data(false);
              setEdited(!Edited);
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.लिँग ? "" : null,
                female: Table_Header.लिँग ? "" : null,
                other: Table_Header.लिँग ? "" : null,
                _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
                _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

                amount: Table_Header.रकम === "" ? "" : null,
                duration: Table_Header.अवधि === "" ? "" : null,
                area: Table_Header.क्षेत्रफल === "" ? "" : null,
                medium: Table_Header.माध्यम् === "" ? "" : null,
                ratio: Table_Header.अनुपात === "" ? "" : null,
                weight: Table_Header.तौल् === "" ? "" : null,
                Type: Table_Header.प्रकार === "" ? "" : null,
                passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
                failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              });
            });
        }
      } else if (Table_Header.महिला_उमेर_समुह) {
        setLoading_Add_Button(true);
        httpBrowsing
          .put(`/eng/indicator-data/${Editing_Data_id}/update`, Submitting_Data)
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data Updated Successfully!!!");
            SetEdit_Data(false);
            setEdited(!Edited);
            SetSubmitting_Data({
              ...Submitting_Data,
              total: null,

              male: Table_Header.लिँग ? "" : null,
              female: Table_Header.लिँग ? "" : null,
              other: Table_Header.लिँग ? "" : null,
              _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
              _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

              amount: Table_Header.रकम === "" ? "" : null,
              duration: Table_Header.अवधि === "" ? "" : null,
              area: Table_Header.क्षेत्रफल === "" ? "" : null,
              medium: Table_Header.माध्यम् === "" ? "" : null,
              ratio: Table_Header.अनुपात === "" ? "" : null,
              weight: Table_Header.तौल् === "" ? "" : null,
              Type: Table_Header.प्रकार === "" ? "" : null,
              passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
            });
          });
      } else {
        setLoading_Add_Button(true);
        httpBrowsing
          .put(`/eng/indicator-data/${Editing_Data_id}/update`, Submitting_Data)
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data Updated Successfully!!!");
            SetEdit_Data(false);
            setEdited(!Edited);
            SetSubmitting_Data({
              ...Submitting_Data,
              total: null,

              male: Table_Header.लिँग ? "" : null,
              female: Table_Header.लिँग ? "" : null,
              other: Table_Header.लिँग ? "" : null,
              _0_to_20: Table_Header.महिला_उमेर_समुह ? "" : null,
              _21_or_older: Table_Header.महिला_उमेर_समुह ? "" : null,

              amount: Table_Header.रकम === "" ? "" : null,
              duration: Table_Header.अवधि === "" ? "" : null,
              area: Table_Header.क्षेत्रफल === "" ? "" : null,
              medium: Table_Header.माध्यम् === "" ? "" : null,
              ratio: Table_Header.अनुपात === "" ? "" : null,
              weight: Table_Header.तौल् === "" ? "" : null,
              Type: Table_Header.प्रकार === "" ? "" : null,
              passed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
              failed: Table_Header.उतिर्ण_अनुतिर्ण ? "" : null,
            });
          });
      }
    } else {
      if (Submitting_Data.province === null) {
        notification.showError("Please enter Province!!!");
      } else if (
        Submitting_Data.district === null ||
        Submitting_Data.district === ""
      ) {
        notification.showError("Please enter District!!!");
      } else if (
        Submitting_Data.municipality === null ||
        Submitting_Data.municipality === ""
      ) {
        notification.showError("Please enter Municipality!!!");
      } else if (Submitting_Data.date === "") {
        notification.showError("Please enter valid date");
      } else if (entered_date > today_date) {
        notification.showError("Future Date Cannot Be Entered !!!");
      } else if (
        !Table_Header.woman_age_group &&
        !Table_Header.gender &&
        (Submitting_Data.total === null || Submitting_Data.total === "")
      ) {
        notification.showError("Please enter the total count");
      } else if (
        !Table_Header.woman_age_group &&
        !Table_Header.gender &&
        !Table_Header.pass_fail_count &&
        !Table_Header.participation_count &&
        Submitting_Data.total.match(letters)
      ) {
        notification.showError("Total Count Must be number");
      } else if (
        Table_Header.gender &&
        (Submitting_Data.male === null || Submitting_Data.male === "")
      ) {
        notification.showError("Please Enter Male Count");
      } else if (Table_Header.gender && Submitting_Data.male.match(letters)) {
        notification.showError("Male count must be number");
      } else if (
        (Table_Header.gender &&
          Submitting_Data.male.match(neg_integer_validation)) ||
        (Table_Header.gender && Submitting_Data.male.match(decimal))
      ) {
        notification.showError(
          "Male count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.gender &&
        (Submitting_Data.female === null || Submitting_Data.female === "")
      ) {
        notification.showError("Please Enter Female Count");
      } else if (Table_Header.gender && Submitting_Data.female.match(letters)) {
        notification.showError("Female count must be number");
      } else if (
        (Table_Header.gender &&
          Submitting_Data.female.match(neg_integer_validation)) ||
        (Table_Header.gender && Submitting_Data.female.match(decimal))
      ) {
        notification.showError(
          "Female count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.gender &&
        (Submitting_Data.other === null || Submitting_Data.other === "")
      ) {
        notification.showError("Please Enter Other's Count");
      } else if (Table_Header.gender && Submitting_Data.other.match(letters)) {
        notification.showError("Other's count must be number");
      } else if (
        (Table_Header.gender &&
          Submitting_Data.other.match(neg_integer_validation)) ||
        (Table_Header.gender && Submitting_Data.other.match(decimal))
      ) {
        notification.showError(
          "Other's count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.woman_age_group &&
        (Submitting_Data._0_to_20 === null || !Submitting_Data._0_to_20)
      ) {
        notification.showError("Please Enter Age group below 20 Years");
      } else if (
        Table_Header.woman_age_group &&
        Submitting_Data._0_to_20.match(letters)
      ) {
        notification.showError("Age group below 20 year must be number");
      } else if (
        (Table_Header.woman_age_group &&
          Submitting_Data._0_to_20.match(neg_integer_validation)) ||
        (Table_Header.woman_age_group &&
          Submitting_Data._0_to_20.match(decimal))
      ) {
        notification.showError(
          "Age group below 20 year cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.woman_age_group &&
        (Submitting_Data._21_or_older === null || !Submitting_Data._21_or_older)
      ) {
        notification.showError("Please enter age group above 20 years");
      } else if (
        Table_Header.woman_age_group &&
        Submitting_Data._21_or_older.match(letters)
      ) {
        notification.showError("Age group above 20 years must be number");
      } else if (
        (Table_Header.woman_age_group &&
          Submitting_Data._21_or_older.match(neg_integer_validation)) ||
        (Table_Header.woman_age_group &&
          Submitting_Data._21_or_older.match(decimal))
      ) {
        notification.showError(
          "Age group above 20 years cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.participation_count &&
        (Submitting_Data.participate === null || !Submitting_Data.participate)
      ) {
        notification.showError("Please Enter Participated Child Count");
      } else if (
        Table_Header.participation_count &&
        Submitting_Data.participate.match(letters)
      ) {
        notification.showError("Participate Child Count must be number");
      } else if (
        (Table_Header.participation_count &&
          Submitting_Data.participate.match(neg_integer_validation)) ||
        (Table_Header.participation_count &&
          Submitting_Data.participate.match(decimal))
      ) {
        notification.showError(
          "Participate Child Count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.participation_count &&
        (Submitting_Data.non_participate === null ||
          !Submitting_Data.non_participate)
      ) {
        notification.showError("Please Enter Non-Participated Child Count");
      } else if (
        Table_Header.participation_count &&
        Submitting_Data.non_participate.match(letters)
      ) {
        notification.showError("Non-Participate Child Count must be number");
      } else if (
        (Table_Header.participation_count &&
          Submitting_Data.non_participate.match(neg_integer_validation)) ||
        (Table_Header.participation_count &&
          Submitting_Data.non_participate.match(decimal))
      ) {
        notification.showError(
          "Non-Participate Child Count cannot be negative or decimal number !!!"
        );
      } else if (
        Table_Header.amount === "" &&
        (Submitting_Data.amount === null || Submitting_Data.amount === "")
      ) {
        notification.showError("Please enter the amount");
      } else if (
        Table_Header.amount === "" &&
        Submitting_Data.amount.match(letters)
      ) {
        notification.showError("Amount must be number");
      } else if (
        Table_Header.amount === "" &&
        Submitting_Data.amount.match(neg_integer_validation)
      ) {
        notification.showError("Amount cannot be negative number !!!");
      } else if (
        Table_Header.area === "" &&
        (Submitting_Data.area === null || Submitting_Data.area === "")
      ) {
        notification.showError("Please enter the Area Details");
      } else if (
        Table_Header.area === "" &&
        Submitting_Data.area.match(neg_integer_validation)
      ) {
        notification.showError("Area cannot be negative number !!!");
      } else if (
        Table_Header.duration === "" &&
        (Submitting_Data.duration === null || Submitting_Data.duration === "")
      ) {
        notification.showError("Please enter the training duration");
      } else if (
        Table_Header.duration === "" &&
        Submitting_Data.duration.match(neg_integer_validation)
      ) {
        notification.showError(
          "Training duration cannot be negative number !!!"
        );
      } else if (
        Table_Header.medium === "" &&
        (Submitting_Data.medium === null || Submitting_Data.medium === "")
      ) {
        notification.showError("Please enter the medium of Information flow");
      } else if (
        Table_Header.ratio === "" &&
        (Submitting_Data.ratio === null || Submitting_Data.ratio === "")
      ) {
        notification.showError("Please enter the Ratio");
      } else if (
        Table_Header.weight === "" &&
        (Submitting_Data.weight === null || Submitting_Data.weight === "")
      ) {
        notification.showError("Please enter the Weight Quantity");
      } else if (
        Table_Header.Type === "" &&
        (Submitting_Data.Type === null || Submitting_Data.Type === "")
      ) {
        notification.showError("Please enter the Type ");
      } else if (
        Table_Header.pass_fail_count &&
        (Submitting_Data.passed === null || Submitting_Data.passed === "")
      ) {
        notification.showError("Please enter passed number of students");
      } else if (
        Table_Header.pass_fail_count &&
        Submitting_Data.passed.match(letters)
      ) {
        notification.showError("Passed students count must be number ");
      } else if (
        (Table_Header.pass_fail_count &&
          Submitting_Data.passed.match(neg_integer_validation)) ||
        (Table_Header.pass_fail_count && Submitting_Data.passed.match(decimal))
      ) {
        notification.showError(
          "Passed students count cannot be negative or decimal number !!! "
        );
      } else if (
        Table_Header.pass_fail_count &&
        (Submitting_Data.failed === null || Submitting_Data.failed === "")
      ) {
        notification.showError("Please enter failed number of students");
      } else if (
        Table_Header.pass_fail_count &&
        Submitting_Data.failed.match(letters)
      ) {
        notification.showError("Failed students count must be number ");
      } else if (
        (Table_Header.pass_fail_count &&
          Submitting_Data.failed.match(neg_integer_validation)) ||
        (Table_Header.pass_fail_count && Submitting_Data.failed.match(decimal))
      ) {
        notification.showError(
          "Failed students count cannot be negative or decimal number !!! "
        );
      } else if (Table_Header.gender && Table_Header.participation_count) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let participate_non_participate_total =
          parseInt(Submitting_Data.participate) +
          parseInt(Submitting_Data.non_participate);
        if (gender_total !== participate_non_participate_total) {
          notification.showError(
            "Gender total count and Participate/Non-Participate total count does not match!!"
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .put(
              `/eng/indicator-data/${Editing_Data_id}/update`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data Updated Successfully!!!");
              SetEdit_Data(false);
              setEdited(!Edited);
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.gender ? "" : null,
                female: Table_Header.gender ? "" : null,
                other: Table_Header.gender ? "" : null,
                _0_to_20: Table_Header.woman_age_group ? "" : null,
                _21_or_older: Table_Header.woman_age_group ? "" : null,

                participate: Table_Header.participation_count ? "" : null,
                non_participate: Table_Header.participation_count ? "" : null,
                amount: Table_Header.amount === "" ? "" : null,
                duration: Table_Header.duration === "" ? "" : null,
                area: Table_Header.area === "" ? "" : null,
                medium: Table_Header.medium === "" ? "" : null,
                ratio: Table_Header.ratio === "" ? "" : null,
                weight: Table_Header.weight === "" ? "" : null,
                Type: Table_Header.Type === "" ? "" : null,
                passed: Table_Header.pass_fail_count ? "" : null,
                failed: Table_Header.pass_fail_count ? "" : null,
              });
            });
        }
      } else if (Table_Header.gender && Table_Header.woman_age_group) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let age_group_total =
          parseInt(Submitting_Data._0_to_20) +
          parseInt(Submitting_Data._21_or_older);

        if (gender_total !== age_group_total) {
          notification.showError(
            "Gender total count and age group total count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .put(
              `/eng/indicator-data/${Editing_Data_id}/update`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data Updated Successfully!!!");
              SetEdit_Data(false);
              setEdited(!Edited);
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.gender ? "" : null,
                female: Table_Header.gender ? "" : null,
                other: Table_Header.gender ? "" : null,
                _0_to_20: Table_Header.woman_age_group ? "" : null,
                _21_or_older: Table_Header.woman_age_group ? "" : null,

                amount: Table_Header.amount === "" ? "" : null,
                duration: Table_Header.duration === "" ? "" : null,
                area: Table_Header.area === "" ? "" : null,
                medium: Table_Header.medium === "" ? "" : null,
                ratio: Table_Header.ratio === "" ? "" : null,
                weight: Table_Header.weight === "" ? "" : null,
                Type: Table_Header.Type === "" ? "" : null,
                passed: Table_Header.pass_fail_count ? "" : null,
                failed: Table_Header.pass_fail_count ? "" : null,
              });
            });
        }
      } else if (Table_Header.gender && Table_Header.pass_fail_count) {
        let gender_total =
          parseInt(Submitting_Data.male) +
          parseInt(Submitting_Data.female) +
          parseInt(Submitting_Data.other);
        let pass_fail_total =
          parseInt(Submitting_Data.passed) + parseInt(Submitting_Data.failed);
        if (gender_total !== pass_fail_total) {
          notification.showError(
            "Gender total count and pass fail total count does not match!! "
          );
        } else {
          setLoading_Add_Button(true);
          httpBrowsing
            .put(
              `/eng/indicator-data/${Editing_Data_id}/update`,
              Submitting_Data
            )
            .then((res) => {
              setLoading_Add_Button(false);
              notification.showSuccess("Data Updated Successfully!!!");
              SetEdit_Data(false);
              setEdited(!Edited);
              SetSubmitting_Data({
                ...Submitting_Data,
                total: null,

                male: Table_Header.gender ? "" : null,
                female: Table_Header.gender ? "" : null,
                other: Table_Header.gender ? "" : null,
                _0_to_20: Table_Header.woman_age_group ? "" : null,
                _21_or_older: Table_Header.woman_age_group ? "" : null,

                amount: Table_Header.amount === "" ? "" : null,
                duration: Table_Header.duration === "" ? "" : null,
                area: Table_Header.area === "" ? "" : null,
                medium: Table_Header.medium === "" ? "" : null,
                ratio: Table_Header.ratio === "" ? "" : null,
                weight: Table_Header.weight === "" ? "" : null,
                Type: Table_Header.Type === "" ? "" : null,
                passed: Table_Header.pass_fail_count ? "" : null,
                failed: Table_Header.pass_fail_count ? "" : null,
              });
            });
        }
      } else if (Table_Header.woman_age_group) {
        setLoading_Add_Button(true);
        httpBrowsing
          .put(`/eng/indicator-data/${Editing_Data_id}/update`, Submitting_Data)
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data Updated Successfully!!!");
            SetEdit_Data(false);
            setEdited(!Edited);
            SetSubmitting_Data({
              ...Submitting_Data,
              total: null,

              male: Table_Header.gender ? "" : null,
              female: Table_Header.gender ? "" : null,
              other: Table_Header.gender ? "" : null,
              _0_to_20: Table_Header.woman_age_group ? "" : null,
              _21_or_older: Table_Header.woman_age_group ? "" : null,

              amount: Table_Header.amount === "" ? "" : null,
              duration: Table_Header.duration === "" ? "" : null,
              area: Table_Header.area === "" ? "" : null,
              medium: Table_Header.medium === "" ? "" : null,
              ratio: Table_Header.ratio === "" ? "" : null,
              weight: Table_Header.weight === "" ? "" : null,
              Type: Table_Header.Type === "" ? "" : null,
              passed: Table_Header.pass_fail_count ? "" : null,
              failed: Table_Header.pass_fail_count ? "" : null,
            });
          });
      } else {
        setLoading_Add_Button(true);
        httpBrowsing
          .put(`/eng/indicator-data/${Editing_Data_id}/update`, Submitting_Data)
          .then((res) => {
            setLoading_Add_Button(false);
            notification.showSuccess("Data Updated Successfully!!!");
            SetEdit_Data(false);
            setEdited(!Edited);
            SetSubmitting_Data({
              ...Submitting_Data,
              total: null,

              male: Table_Header.gender ? "" : null,
              female: Table_Header.gender ? "" : null,
              other: Table_Header.gender ? "" : null,
              _0_to_20: Table_Header.woman_age_group ? "" : null,
              _21_or_older: Table_Header.woman_age_group ? "" : null,

              amount: Table_Header.amount === "" ? "" : null,
              duration: Table_Header.duration === "" ? "" : null,
              area: Table_Header.area === "" ? "" : null,
              medium: Table_Header.medium === "" ? "" : null,
              ratio: Table_Header.ratio === "" ? "" : null,
              weight: Table_Header.weight === "" ? "" : null,
              Type: Table_Header.Type === "" ? "" : null,
              passed: Table_Header.pass_fail_count ? "" : null,
              failed: Table_Header.pass_fail_count ? "" : null,
            });
          });
      }
    }
  };

  const renderTableHeader = () => {
    if (Table_Header.length !== 0) {
      let header = Object.keys(Table_Header);

      return header.map((key, index) => {
        if (key === "gender") {
          return (
            // <th className="head" key={index.key} colSpan="3">
            //   Gender
            // </th>
            <>
              <th className="head" key={index.key}>
                MALE
              </th>
              <th className="head" key={index.key}>
                FEMALE
              </th>
              <th className="head" key={index.key}>
                OTHER
              </th>
            </>
          );
        }

        if (key === "लिँग") {
          return (
            <>
              <th className="head" key={index.key}>
                पुरुष
              </th>
              <th className="head" key={index.key}>
                महिला
              </th>
              <th className="head" key={index.key}>
                अन्य
              </th>
            </>
          );
        }
        if (key === "महिला_उमेर_समुह") {
          return (
            // <th className="head" key={index.key} colSpan="3">
            //   उमेर_समुह
            // </th>
            <>
              <th className="head" key={index.key}>
                २० बर्ष मुनी
              </th>
              <th className="head" key={index.key}>
                २० बर्ष वा बढी
              </th>
            </>
          );
        }
        if (key === "बच्चा_उमेर_समुह") {
          return (
            // <th className="head" key={index.key} colSpan="2">
            //   बच्चा उमेर समुह
            // </th>
            <>
              <th className="head" key={index.key}>
                १६ बर्ष भन्दा कम
              </th>
              <th className="head" key={index.key}>
                १६ बर्ष देखी १८ बर्ष
              </th>
            </>
          );
        }
        if (key === "children_age_group") {
          return (
            // <th className="head" key={index.key} colSpan="2">
            //   Children Age Group
            // </th>
            <>
              <th className="head" key={index.key}>
                BELOW 16 YEARS
              </th>
              <th className="head" key={index.key}>
                16 TO 18 YEARS
              </th>
            </>
          );
        }
        if (key === "भाग_लिएको_नलिएको") {
          return (
            <>
              <th className="head" key={index.key}>
                भाग लिएको शिशु
              </th>
              <th className="head" key={index.key}>
                भाग नलिएको शिशु
              </th>
            </>
          );
        }
        if (key === "participation_count") {
          return (
            <>
              <th className="head" key={index.key}>
                PARTICIPATED CHILD
              </th>
              <th className="head" key={index.key}>
                NON_PARTICIPATED CHILD
              </th>
            </>
          );
        }
        if (key === "woman_age_group") {
          return (
            // <th className="head" key={index.key} colSpan="3">
            //   Age Group
            // </th>
            <>
              <th className="head" key={index.key}>
                BELOW 20 YEARS
              </th>
              <th className="head" key={index.key}>
                20 AND ABOVE
              </th>
            </>
          );
        }
        if (key === "उतिर्ण_अनुतिर्ण") {
          return (
            // <th className="head" key={index.key} colSpan="2">
            //   उतिर्ण_अनुतिर्ण
            // </th>
            <>
              <th className="head" key={index.key}>
                उतिर्ण
              </th>
              <th className="head" key={index.key}>
                अनुतिर्ण
              </th>
            </>
          );
        }
        if (key === "pass_fail_count") {
          return (
            // <th className="head" key={index.key} colSpan="2">
            //   Pass Fail Count
            // </th>
            <>
              <th className="head" key={index.key}>
                PASS
              </th>
              <th className="head" key={index.key}>
                FAIL
              </th>
            </>
          );
        }
        if (Tost) {
          if (key === "SN" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  SN
                </th>
              </>
            );
          }
          if (key === "created_by" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  DATA ENTERED BY
                </th>
              </>
            );
          }
          if (key === "updated_by" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  DATA UPDATED BY
                </th>
              </>
            );
          }
          if (key === "last_updated" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  LAST UPDATED DATE
                </th>
              </>
            );
          }
          if (key === "operations" && "province") {
            return (
              <>
                <th className="head" key={index.key}>
                  EDIT
                </th>
              </>
            );
          }
        } else {
          if (key === "SN" && "प्रदेश") {
            return (
              <>
                <th className="head" key={index.key}>
                  क्रम सं
                </th>
              </>
            );
          }
          if (key === "created_by" && "प्रदेश") {
            return (
              <>
                <th className="head" key={index.key}>
                  डाटा भर्ने
                </th>
              </>
            );
          }
          if (key === "updated_by" && "प्रदेश") {
            return (
              <>
                <th className="head" key={index.key}>
                  सम्पादन गर्ने
                </th>
              </>
            );
          }
          if (key === "last_updated" && "प्रदेश") {
            return (
              <>
                <th className="head" key={index.key}>
                  पछिल्लो सम्पादित मिती
                </th>
              </>
            );
          }
          if (key === "operations" && "प्रदेश") {
            return (
              <>
                <th className="head" key={index.key}>
                  सम्पादन
                </th>
              </>
            );
          }
        }

        return (
          <>
            <th className="head" key={index.key} rowSpan="2">
              {key.toUpperCase()}

              {key === "मिती" ? (
                <img
                  value={key}
                  onClick={sortDate}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                ""
              )}
              {key === "date" ? (
                <img
                  value={key}
                  onClick={sortDate}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                ""
              )}
              {key === "संख्या" ? (
                <img
                  value={key}
                  onClick={sortCount}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                ""
              )}
              {key === "total" ? (
                <img
                  value={key}
                  onClick={sortCount}
                  src="/sort-solid.svg"
                  alt="sort-btn"
                  style={{
                    height: "20px",
                    marginLeft: "20px",
                    marginBottom: "2px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                ""
              )}
            </th>
          </>
        );
      });
    } else {
      return <p style={{ textAlign: "center" }}>Data Table is Empty</p>;
    }
  };

  const renderTableData = () => {
    return Indicator_Data.map((item, index) => {
      return (
        <tr key={String(item.id)}>
          <td>{(currentPage - 1) * page_size + index + 1}</td>
          <td className="Date">{item.province.title_en}</td>
          <td className="Date">{item.district.title_en}</td>
          <td className="Date">{item.municipality.title_en}</td>
          <td className="Date">{item.date}</td>

          {item.weight ? <td className="Date">{item.weight}</td> : ""}

          {item.gender ? (
            <>
              <td className="Data">{item.gender.male}</td>
              <td className="Data">{item.gender.female}</td>
              <td className="Data">{item.gender.other}</td>
            </>
          ) : (
            ""
          )}
          {item.Type ? <td className="Date">{item.Type}</td> : ""}
          {item.duration ? <td className="Data">{item.duration}</td> : ""}
          {item.medium ? <td className="Date">{item.medium}</td> : ""}
          {item.children_age_group ? (
            <>
              <td className="Data">{item.children_age_group._0_to_16}</td>
              <td className="Data">{item.children_age_group._17_to_18}</td>
            </>
          ) : (
            ""
          )}
          {item.participation_count ? (
            <>
              <td className="Data">{item.participation_count.participate}</td>
              <td className="Data">
                {item.participation_count.non_participate}
              </td>
            </>
          ) : (
            ""
          )}
          {item.pass_fail_count ? (
            <>
              <td className="Data">{item.pass_fail_count.passed}</td>
              <td className="Data">{item.pass_fail_count.failed}</td>
            </>
          ) : (
            ""
          )}
          {item.ratio ? (
            <>
              <td className="Data">{item.ratio}</td>
            </>
          ) : (
            ""
          )}

          {item.woman_age_group ? (
            <>
              <td className="Data">{item.woman_age_group._0_to_20}</td>
              <td className="Data">{item.woman_age_group._21_or_older}</td>
            </>
          ) : (
            ""
          )}

          {item.amount ? <td>{item.amount}</td> : ""}
          {item.area ? <td>{item.area}</td> : ""}
          <td className="Data">{item.total}</td>
          <td className="Data">{item.created_by}</td>
          <td className="Data">{item.updated_by}</td>
          <td className="Data">{item.last_updated}</td>
          <td>
            <Point>
              {is_Supervisor ? (
                <>
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Edit</Tooltip>}
                  >
                    <a
                      className="curs-pointer"
                      onClick={() => handleEdit(item.id, index)}
                    >
                      <img src="/icon_awesome-edit.svg" />
                    </a>
                  </OverlayTrigger>
                  {""}
                  {""}{" "}
                  {/* <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Delete</Tooltip>}
                  >
                    <a
                      className="curs-pointer"
                      onClick={() => handleShow(item.id)}
                    >
                      <img src="/icon_material-delete.svg" />
                    </a>
                  </OverlayTrigger> */}
                </>
              ) : is_Superuser ? (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>Edit</Tooltip>}
                >
                  <a
                    className="curs-pointer"
                    onClick={() => handleEdit(item.id, index)}
                  >
                    <img src="/icon_awesome-edit.svg" />
                  </a>
                </OverlayTrigger>
              ) : (
                "---"
              )}
              {/* &ensp;
              {is_Supervisor ? (
                ""
              ) : is_Superuser ? (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Delete</Tooltip>}
                >
                  <a
                    className="curs-pointer"
                    onClick={() => handleShow(item.id)}
                  >
                    <img src="/icon_material-delete.svg" />
                  </a>
                </OverlayTrigger>
              ) : (
                ""
              )} */}
            </Point>
          </td>
        </tr>
      );
    });
  };

  const handleClose = () => setShow(false);
  // useEffect(() => {
  //   setDate(final_date);
  //   SetSubmitting_Data({
  //     ...Submitting_Data,
  //     date: final_date,
  //   });
  // }, []);

  useEffect(() => {
    SetSubmitting_Data({
      ...Submitting_Data,
      date: date,
    });
  }, [date]);
  useEffect(() => {
    httpBrowsing
      .get(
        `/${Tost ? "eng" : "nep"}/indicators/${Right_indicator_id}/get-fields/`
      )
      .then((res) => {
        setTable_Header(res.data);
      })
      .catch((err) => {
        console.log("this is error>>>", err);
      });
  }, [Tost, Right_indicator_id]);
  const handleDelete = () => {
    setLoading_Add_Button(true);
    httpBrowsing
      .remove(`/eng/indicator-data/${Deleting_Data_id}/delete`)
      .then((res) => {
        setShow(false);
        setLoading_Add_Button(false);
        setItem_delete_check(!Item_delete_check);
      })
      .catch((err) => {
        console.log("this is error?>>>", err.response);
        setLoading_Add_Button(false);
      });
  };
  const renderTableData_Nepali = () => {
    return Indicator_Data.map((item, index) => {
      return (
        <tr key={String(item.id)}>
          <td>
            {englishToNepaliNumber((currentPage - 1) * page_size + index + 1)}
          </td>
          <td className="Date">{item.प्रदेश.title}</td>
          <td className="Date">{item.जिल्ला.title}</td>
          <td className="Date">{item.नगरपालिका.title}</td>
          <td className="Date">{item.मिती}</td>

          {item.तौल् ? <td className="Date">{item.तौल्}</td> : ""}

          {item.लिँग ? (
            <>
              <td className="Data">{item.लिँग.पुरुष}</td>
              <td className="Data">{item.लिँग.महिला}</td>
              <td className="Data">{item.लिँग.अन्य}</td>
            </>
          ) : (
            ""
          )}
          {item.प्रकार ? <td className="Date">{item.प्रकार}</td> : ""}
          {item.अवधि ? <td className="Data">{item.अवधि}</td> : ""}
          {item.माध्यम् ? <td className="Date">{item.माध्यम्}</td> : ""}
          {item.बच्चा_उमेर_समुह ? (
            <>
              <td className="Data">{item.बच्चा_उमेर_समुह._०_देखि_१६}</td>
              <td className="Data">{item.बच्चा_उमेर_समुह._१७_देखि_१८}</td>
            </>
          ) : (
            ""
          )}
          {item.भाग_लिएको_नलिएको ? (
            <>
              <td className="Data">{item.भाग_लिएको_नलिएको.भाग_लिएको}</td>
              <td className="Data">{item.भाग_लिएको_नलिएको.भाग_नलिएको}</td>
            </>
          ) : (
            ""
          )}
          {item.उतिर्ण_अनुतिर्ण ? (
            <>
              <td className="Data">{item.उतिर्ण_अनुतिर्ण.अनुतिर्ण}</td>
              <td className="Data">{item.उतिर्ण_अनुतिर्ण.उतिर्ण}</td>
            </>
          ) : (
            ""
          )}
          {item.अनुपात ? <td className="Data">{item.अनुपात}</td> : ""}

          {item.महिला_उमेर_समुह ? (
            <>
              <td className="Data">{item.महिला_उमेर_समुह._०_देखि_२०}</td>
              <td className="Data">{item.महिला_उमेर_समुह._२१_वा_बढी}</td>
            </>
          ) : (
            ""
          )}

          {item.रकम ? <td className="Data">{item.रकम}</td> : ""}
          {item.क्षेत्रफल ? <td className="Data">{item.क्षेत्रफल}</td> : ""}
          <td className="Data">{item.संख्या}</td>
          <td className="Data"> {item.created_by}</td>
          <td className="Data">{item.updated_by}</td>
          <td className="Data">{item.last_updated}</td>
          <td>
            <Point>
              {is_Supervisor ? (
                <>
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Edit</Tooltip>}
                  >
                    <a
                      className="curs-pointer"
                      onClick={() => handleEdit(item.id, index)}
                    >
                      <img src="/icon_awesome-edit.svg" />
                    </a>
                  </OverlayTrigger>
                  {""}
                  {""}{" "}
                  {/* <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Delete</Tooltip>}
                  >
                    <a
                      className="curs-pointer"
                      onClick={() => handleShow(item.id)}
                    >
                      <img src="/icon_material-delete.svg" />
                    </a>
                  </OverlayTrigger> */}
                </>
              ) : is_Superuser ? (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip>Edit</Tooltip>}
                >
                  <a
                    className="curs-pointer"
                    onClick={() => handleEdit(item.id, index)}
                  >
                    <img src="/icon_awesome-edit.svg" />
                  </a>
                </OverlayTrigger>
              ) : (
                "---"
              )}
              {/* &ensp;
              {is_Supervisor ? (
                ""
              ) : is_Superuser ? (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Delete</Tooltip>}
                >
                  <a
                    className="curs-pointer"
                    onClick={() => handleShow(item.id)}
                  >
                    <img src="/icon_material-delete.svg" />
                  </a>
                </OverlayTrigger>
              ) : (
                ""
              )} */}
            </Point>
          </td>
        </tr>
      );
    });
  };

  const handlePageSize = (e) => {
    setPage_size(e.target.value);
    setSecondary_Loading(true);
    setTable_Loader(true);
  };
  const changeCurrentPage = (numPage) => {
    setTable_Loader(true);
    setCurrentPage(numPage);
    setSecondary_Loading(true);
    setPrevious_Page_No(currentPage);
  };
  const handleClose_Data_Add = () => {
    //used to close the data form of adding data
    setAdd_Data(false);
    setEdited(!Edited);
    // SetSubmitting_Data({
    //   province: "",
    //   district: "",
    //   municipality: "",
    //   total: "",
    //   date: "",
    // });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    SetSubmitting_Data({
      ...Submitting_Data,
      [name]: value,
    });
  };
  const handleShow = (id) => {
    setShow(true);
    setDeleting_Data_id(id);
  };

  useEffect(() => {
    httpBrowsing
      .get(`/${Tost ? "eng" : "nep"}/indicator-data/get-years/`)
      .then((res) => {
        setFiscal_year(res.data);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
  }, [Tost]);

  useEffect(() => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let year = today.getFullYear();

    let final_date = ADToBS(year + "-" + mm + "-" + dd);
    SetSubmitting_Data({
      ...Submitting_Data,
      total: null,
      date: final_date,
      male: null,
      female: null,
      other: null,
      _0_to_20: null,
      _21_or_older: null,

      _0_to_19: null,
      _20_to_29: null,
      _30_to_39: null,
      _40_to_59: null,
      _60_or_older: null,
      amount: null,
      age: null,
      area: null,
      duration: null,
      medium: null,
      ratio: null,
      weight: null,
      Type: null,
      passed: null,
      failed: null,
      _0_to_16: null,
      _17_to_18: null,
      participate: null,
      non_participate: null,
    });
  }, [Right_indicator_id]);

  return (
    <Tester>
      {Indicator_Data.length !== 0 ? (
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>{renderTableHeader()}</tr>
            <tr>
              {/* {Indicator_Data[0].gender ? (
                <>
                  <th className="sub-head">Male</th>
                  <th className="sub-head">Female</th>
                  <th className="sub-head">Other</th>
                </>
              ) : (
                ""
              )} */}
              {/* {Indicator_Data[0].लिँग ? (
                <>
                  <th className="sub-head">पुरुष</th>
                  <th className="sub-head">महिला</th>
                  <th className="sub-head">अन्य</th>
                </>
              ) : (
                ""
              )} */}
              {/* {Indicator_Data[0].उतिर्ण_अनुतिर्ण ? (
                <>
                  <th className="sub-head">उतिर्ण</th>
                  <th className="sub-head">अनुतिर्ण</th>
                </>
              ) : (
                ""
              )} */}
              {/* {Indicator_Data[0].pass_fail_count ? (
                <>
                  <th className="sub-head">Pass</th>
                  <th className="sub-head">Fail</th>
                </>
              ) : (
                ""
              )} */}
              {/* {Indicator_Data[0].महिला_उमेर_समुह ? (
                <>
                  <th className="sub-head">२० भन्दा कम</th>
                  <th className="sub-head">२०-४०</th>

                  <th className="sub-head">४० वा बढी</th>
                </>
              ) : (
                ""
              )} */}
              {/* {Indicator_Data[0].बच्चा_उमेर_समुह ? (
                <>
                  <th className="sub-head">१६ भन्दा कम</th>
                  <th className="sub-head">१६ देखी १८</th>
                </>
              ) : (
                ""
              )} */}

              {/* {Indicator_Data[0].woman_age_group ? (
                <>
                  <th className="sub-head">Below 20</th>
                  <th className="sub-head">20 to 40</th>

                  <th className="sub-head">40 and above</th>
                </>
              ) : (
                ""
              )} */}
              {/* {Indicator_Data[0].children_age_group ? (
                <>
                  <th className="sub-head">Below 16</th>
                  <th className="sub-head">16 to 18</th>
                </>
              ) : (
                ""
              )} */}
            </tr>
          </thead>

          <tbody>{!Tost ? renderTableData_Nepali() : renderTableData()}</tbody>
        </Table>
      ) : (
        <div>
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>{renderTableHeader()}</tr>
              <tr>
                {Table_Header.male === "" ? (
                  <>
                    <th className="sub-head">Male</th>
                    <th className="sub-head">Female</th>
                    <th className="sub-head">Other</th>
                  </>
                ) : (
                  ""
                )}
                {Table_Header.पुरुष === "" ? (
                  <>
                    <th className="sub-head">पुरुष</th>
                    <th className="sub-head">मह���ला</th>
                    <th className="sub-head">अन्य</th>
                  </>
                ) : (
                  ""
                )}
                {Table_Header.अनुतिर्ण === "" ? (
                  <>
                    <th className="sub-head">उतिर्ण</th>
                    <th className="sub-head">अनुतिर्ण</th>
                  </>
                ) : (
                  ""
                )}
                {Table_Header.passed === "" ? (
                  <>
                    <th className="sub-head">passed</th>
                    <th className="sub-head">failed</th>
                  </>
                ) : (
                  ""
                )}

                {Table_Header._१८_वर्श_मुनी === "" ? (
                  <>
                    <th className="sub-head">_१८_वर्श_मुनी</th>
                    <th className="sub-head">वयस्क</th>
                    <th className="sub-head">जेष्ठ_नागरिक</th>
                  </>
                ) : (
                  ""
                )}
                {Table_Header._0_to_19 === "" ? (
                  <>
                    <th className="sub-head">Below 19</th>
                    <th className="sub-head">20 to 29</th>
                    <th className="sub-head">30 to 39</th>
                    <th className="sub-head">40 to 59</th>
                    <th className="sub-head">60 and above</th>
                  </>
                ) : (
                  ""
                )}
              </tr>
            </thead>
          </Table>
          <div className="empty">
            <p style={{ textAlign: "center" }}>Table is empty</p>
          </div>
        </div>
      )}

      <Modal show={Add_Data} onHide={handleClose_Data_Add} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div id="top">
              {Tost
                ? `${Indicator_title_eng} (Fill Data)`
                : `${Indicator_title}${""} (विवरण भर्नुहोस्)`}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.date === "" ? (
                <>
                  <Form.Label>Date</Form.Label>
                  <NepaliDatePicker
                    inputClassName="form-control"
                    className=""
                    value={Submitting_Data.date}
                    name="date"
                    onChange={(value) => setDate(value)}
                    options={{ calenderLocale: "en", valueLocale: "en" }}
                  />
                </>
              ) : (
                ""
              )}

              {Table_Header.मिती === "" ? (
                <>
                  <Form.Label>मिती</Form.Label>
                  <NepaliDatePicker
                    inputClassName="form-control"
                    className=""
                    value={Submitting_Data.date}
                    name="date"
                    onChange={(value) => setDate(value)}
                    options={{ calenderLocale: "ne", valueLocale: "en" }}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.medium === "" ? (
                <>
                  <Form.Label>Medium</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Medium"
                    name="medium"
                    value={Submitting_Data.medium}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
              {Table_Header.माध्यम् === "" ? (
                <>
                  <Form.Label>माध्यम्</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="माध्यम्"
                    name="medium"
                    value={Submitting_Data.medium}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.Type === "" ? (
                <>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type"
                    name="Type"
                    value={Submitting_Data.Type}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
              {Table_Header.प्रकार === "" ? (
                <>
                  <Form.Label>प्रकार</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="प्रकार"
                    name="Type"
                    value={Submitting_Data.Type}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.weight === "" ? (
                <>
                  <Form.Label>weight</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Weight"
                    name="weight"
                    value={Submitting_Data.weight}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
              {Table_Header.तौल् === "" ? (
                <>
                  <Form.Label>तौल्</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="तौल्"
                    name="weight"
                    value={Submitting_Data.weight}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.area === "" ? (
                <>
                  {" "}
                  (<Form.Label>Area</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Area"
                    name="area"
                    value={Submitting_Data.area}
                    onChange={handleChange}
                  />
                  )
                </>
              ) : (
                ""
              )}
              {Table_Header.क्षेत्रफल === "" ? (
                <>
                  <Form.Label>क्षेत्रफल</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="क्षेत्रफल"
                    name="area"
                    value={Submitting_Data.area}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>

            {Table_Header.gender ? (
              <>
                <Form.Label>Gender</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>male</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="male"
                    name="male"
                    value={Submitting_Data.male}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>female</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="female"
                    name="female"
                    value={Submitting_Data.female}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>other</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="other"
                    name="other"
                    value={Submitting_Data.other}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {Table_Header.लिँग ? (
              <>
                <Form.Label>लिँग</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>पुरुष</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="पुरुष"
                    name="male"
                    value={Submitting_Data.male}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>महिला</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="महिला"
                    name="female"
                    value={Submitting_Data.female}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>अन्य</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="अन्य"
                    name="other"
                    value={Submitting_Data.other}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            {Table_Header.pass_fail_count ? (
              <>
                <Form.Label>Pass/Fail Student Count</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Passed</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="passed"
                    name="passed"
                    value={Submitting_Data.passed}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Failed</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Failed"
                    name="failed"
                    value={Submitting_Data.failed}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {Table_Header.उतिर्ण_अनुतिर्ण ? (
              <>
                <Form.Label>उतिर्ण/अनुतिर्ण</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>उतिर्ण</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="उतिर्ण"
                    name="passed"
                    value={Submitting_Data.passed}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>अनुतिर्ण</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="अनुतिर्ण"
                    name="failed"
                    value={Submitting_Data.failed}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {Table_Header.participation_count ? (
              <>
                <Form.Label>
                  Participated/Non-Participated Child Count
                </Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Participated Child</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Participated Child Count"
                    name="participate"
                    value={Submitting_Data.participate}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Non-Participated Child</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Non-Participated Child Count"
                    name="non_participate"
                    value={Submitting_Data.non_participate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {Table_Header.भाग_लिएको_नलिएको ? (
              <>
                <Form.Label>भाग लिएको नलिएको शिशुको संख्या</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>भाग लिएको शिशुको </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="भाग लिएको शिशुको संख्या"
                    name="participate"
                    value={Submitting_Data.participate}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>भाग नलिएको शिशु</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="भाग नलिएको शिशुको संख्या"
                    name="non_participate"
                    value={Submitting_Data.non_participate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.ratio === "" ? (
                <>
                  {" "}
                  <Form.Label>Ratio</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ratio"
                    name="ratio"
                    value={Submitting_Data.ratio}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
              {Table_Header.अनुपात === "" ? (
                <>
                  <Form.Label>अनुपात</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="अनुपात"
                    name="ratio"
                    value={Submitting_Data.ratio}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>
            {Table_Header.woman_age_group ? (
              <>
                <Form.Label>Age Group</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Age Below 20 Year</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Age below 20yrs"
                    name="_0_to_20"
                    value={Submitting_Data._0_to_20}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Age Above 20 years</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Age group above 20 years"
                    name="_21_or_older"
                    value={Submitting_Data._21_or_older}
                    onChange={handleChange}
                  />
                </Form.Group>
                {/* <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Age Group above 40 years years</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Age group above 40 years"
                    name="_41_or_older"
                    value={Submitting_Data._41_or_older}
                    onChange={handleChange}
                  />
                </Form.Group> */}
              </>
            ) : (
              ""
            )}
            {Table_Header.महिला_उमेर_समुह ? (
              <>
                <Form.Label>उमेर_समुह</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>२० बर्ष मुनी</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="२० बर्ष मुनी"
                    name="_0_to_20"
                    value={Submitting_Data._0_to_20}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>२० बर्ष देखि माथि</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="२० बर्ष देखि माथि"
                    name="_21_or_older"
                    value={Submitting_Data._21_or_older}
                    onChange={handleChange}
                  />
                </Form.Group>
                {/* <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>४० बर्ष वा बढी</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="४० बर्ष वा बढी"
                    name="_41_or_older"
                    value={Submitting_Data._41_or_older}
                    onChange={handleChange}
                  />
                </Form.Group> */}
              </>
            ) : (
              ""
            )}
            {Table_Header.बच्चा_उमेर_समुह ? (
              <>
                <Form.Label>बच्चा उमेर समुह</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>१६ बर्ष भन्दा कम</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="१६ बर्ष भन्दा कम"
                    name="_0_to_16"
                    value={Submitting_Data._0_to_16}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>१६ बर्ष देखी १८ बर्ष</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="१६ बर्ष देखी १८ बर्ष"
                    name="_17_to_18"
                    value={Submitting_Data._17_to_18}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {Table_Header.children_age_group ? (
              <>
                <Form.Label>children Age Group</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Below 16 Year</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Below 16 year"
                    name="_0_to_16"
                    value={Submitting_Data._0_to_16}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>16 to 18 years</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="16 to 18 years"
                    name="_17_to_18"
                    value={Submitting_Data._17_to_18}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            {Table_Header.amount === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Amount"
                  name="amount"
                  value={Submitting_Data.amount}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {Table_Header.रकम === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>रकम</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="रकम"
                  name="amount"
                  value={Submitting_Data.amount}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}

            {Table_Header.duration === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Duration"
                  name="duration"
                  value={Submitting_Data.duration}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {Table_Header.अवधि === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>अवधि</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="अवधि"
                  name="duration"
                  value={Submitting_Data.duration}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {Table_Header.लिँग || Table_Header.महिला_उमेर_समुह ? (
              ""
            ) : Table_Header.total === "" ? (
              ""
            ) : (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>संख्या</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="संख्या"
                  name="total"
                  value={Submitting_Data.total}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
            {Table_Header.gender || Table_Header.woman_age_group ? (
              ""
            ) : Table_Header.संख्या === "" ? (
              ""
            ) : (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Total</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total"
                  name="total"
                  value={Submitting_Data.total}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose_Data_Add}>
            Close
          </Button> */}
          {Loading_Add_Button ? (
            <Button variant="primary" disabled>
              ADDING DATA...
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              DONE
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={Edit_Data} onHide={handleClose_Data_Edit} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {Tost
              ? `${Indicator_title_eng} (Update Data)`
              : `${Indicator_title}${""} (अद्यावधिक गर्नुहोस्)`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {Table_Header.date === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Date</Form.Label>
                <NepaliDatePicker
                  inputClassName="form-control"
                  className=""
                  value={Submitting_Data.date}
                  name="date"
                  onChange={(value) => setDate(value)}
                  options={{ calenderLocale: "en", valueLocale: "en" }}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {Table_Header.मिती === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>मिती</Form.Label>
                <NepaliDatePicker
                  inputClassName="form-control"
                  className=""
                  value={Submitting_Data.date}
                  name="date"
                  onChange={(value) => setDate(value)}
                  options={{ calenderLocale: "ne", valueLocale: "en" }}
                />
              </Form.Group>
            ) : (
              ""
            )}
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.प्रकार === "" ? (
                <>
                  <Form.Label>प्रकार</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="प्रकार"
                    name="Type"
                    value={Submitting_Data.Type}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.Type === "" ? (
                <>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type"
                    name="Type"
                    value={Submitting_Data.Type}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.area === "" ? (
                <>
                  <Form.Label>Area</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Area"
                    name="area"
                    value={Submitting_Data.area}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlInput1">
              {Table_Header.क्षेत्रफल === "" ? (
                <>
                  <Form.Label>क्षेत्रफल</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="क्षेत्रफल"
                    name="area"
                    value={Submitting_Data.area}
                    onChange={handleChange}
                  />
                </>
              ) : (
                ""
              )}
            </Form.Group>

            {/* <Form.Control type="text" placeholder="date" name="date" value={Submitting_Data.date} onChange={handleChange} /> */}

            {Table_Header.gender ? (
              <>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>male</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="male"
                    name="male"
                    value={Submitting_Data.male}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>female</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="female"
                    name="female"
                    value={Submitting_Data.female}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>other</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="other"
                    name="other"
                    value={Submitting_Data.other}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            {Table_Header.लिँग ? (
              <>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>पुरुष</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="पुरुष"
                    name="male"
                    value={Submitting_Data.male}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>महिला</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="महिला"
                    name="female"
                    value={Submitting_Data.female}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>अन्य</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="अन्य"
                    name="other"
                    value={Submitting_Data.other}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            {Table_Header.बच्चा_उमेर_समुह ? (
              <>
                <Form.Label>बच्चा उमेर समुह</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>१६ बर्ष भन्दा कम </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="१६ बर्ष भन्दा कम"
                    name="_0_to_16"
                    value={Submitting_Data._0_to_16}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>१६ बर्ष देखी १८ बर्ष</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="१६ बर्ष देखी १८ बर्ष"
                    name="_17_to_18"
                    value={Submitting_Data._17_to_18}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            {Table_Header.children_age_group ? (
              <>
                <Form.Label>children age group</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Below 16 Year </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Below 16 year"
                    name="_0_to_16"
                    value={Submitting_Data._0_to_16}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>16 to 18 year</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="16 to 18 year"
                    name="_17_to_18"
                    value={Submitting_Data._17_to_18}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            {Table_Header.woman_age_group ? (
              <>
                <Form.Label>Age Group</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Age Group Below 20 Year</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Age below 20 yrs"
                    name="_0_to_20"
                    value={Submitting_Data._0_to_20}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Age Group above 20 years</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Age above 20 years"
                    name="_21_or_older"
                    value={Submitting_Data._21_or_older}
                    onChange={handleChange}
                  />
                </Form.Group>
                {/* <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Age Group above 40 years years</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Age above 40 years"
                    name="_41_or_older"
                    value={Submitting_Data._41_or_older}
                    onChange={handleChange}
                  />
                </Form.Group> */}
              </>
            ) : (
              ""
            )}
            {Table_Header.महिला_उमेर_समुह ? (
              <>
                <Form.Label>उमेर_समुह</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>२० बर्ष मुनी</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="२० बर्ष मुनी"
                    name="_0_to_20"
                    value={Submitting_Data._0_to_20}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>२० बर्ष देखि माथि</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="२० बर्ष देखि माथि"
                    name="_21_or_older"
                    value={Submitting_Data._21_or_older}
                    onChange={handleChange}
                  />
                </Form.Group>
                {/* <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>४० बर्ष वा बढी</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="४० बर्ष वा बढी"
                    name="_41_or_older"
                    value={Submitting_Data._41_or_older}
                    onChange={handleChange}
                  />
                </Form.Group> */}
              </>
            ) : (
              ""
            )}
            {Table_Header.gender || Table_Header.woman_age_group ? (
              ""
            ) : Table_Header.संख्या === "" ? (
              ""
            ) : (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Total</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Total"
                  name="total"
                  value={Submitting_Data.total}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
            {Table_Header.लिँग || Table_Header.महिला_उमेर_समुह ? (
              ""
            ) : Table_Header.total === "" ? (
              ""
            ) : (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>संख्या</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="संख्या"
                  name="total"
                  value={Submitting_Data.total}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
            {Table_Header.उतिर्ण_अनुतिर्ण ? (
              <>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>उतिर्ण</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="उतिर्ण"
                    name="उतिर्ण"
                    value={Submitting_Data.passed}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>अनुतिर्ण</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="अनुतिर्ण"
                    name="अनुतिर्ण"
                    value={Submitting_Data.failed}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {Table_Header.अनुपात === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>अनुपात</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="अनुपात"
                  name="ratio"
                  value={Submitting_Data.ratio}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}

            {Table_Header.pass_fail_count ? (
              <>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Pass</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Pass"
                    name="passed"
                    value={Submitting_Data.passed}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Fail</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Fail"
                    name="failed"
                    value={Submitting_Data.failed}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {Table_Header.weight === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Weight</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Weight"
                  name="weight"
                  value={Submitting_Data.weight}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {Table_Header.participation_count ? (
              <>
                <Form.Label>
                  Participated/Non-Participated Child Count
                </Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Participated Child</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Participated Child Count"
                    name="participate"
                    value={Submitting_Data.participate}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Non-Participated Child</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Non-Participated Child Count"
                    name="non_participate"
                    value={Submitting_Data.non_participate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {Table_Header.भाग_लिएको_नलिएको ? (
              <>
                <Form.Label>भाग लिएको नलिएको शिशुको संख्या</Form.Label>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>भाग लिएको शिशुको </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="भाग लिएको शिशुको संख्या"
                    name="participate"
                    value={Submitting_Data.participate}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>भाग नलिएको शिशु</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="भाग नलिएको शिशुको संख्या"
                    name="non_participate"
                    value={Submitting_Data.non_participate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}
            {Table_Header.तौल् === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>तौल्</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="तौल्"
                  name="weight"
                  value={Submitting_Data.weight}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {Table_Header.ratio === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Ratio</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ratio"
                  name="ratio"
                  value={Submitting_Data.ratio}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}

            {Table_Header.amount === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Amount"
                  name="amount"
                  value={Submitting_Data.amount}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {Table_Header.duration === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Duration"
                  name="duration"
                  value={Submitting_Data.duration}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}
            {Table_Header.अवधि === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>अवधि</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="अवधि"
                  name="duration"
                  value={Submitting_Data.duration}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}

            {Table_Header.रकम === "" ? (
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>रकम</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="रकम"
                  name="amount"
                  value={Submitting_Data.amount}
                  onChange={handleChange}
                />
              </Form.Group>
            ) : (
              ""
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose_Data_Edit}>
            Close
          </Button> */}
          {Loading_Add_Button ? (
            <Button variant="primary" disabled>
              UPDATING...
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit_Edited_Data}>
              UPDATE
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={show} backdrop="static" onHide={handleClose}>
        <Modal.Body>Are you sure to delete the data?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {Loading_Add_Button ? (
            <Button variant="primary" disabled>
              Deleting..
            </Button>
          ) : (
            <Button variant="primary" onClick={handleDelete}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Tester>
  );
};
const Point = styled.div`
  a {
    cursor: pointer;
  }
`;
const Tester = styled.div`
  .row-data {
    margin-top: 10px;
    margin-left: 10px;
  }
  .row-data select {
    margin-left: 10px;
  }
  .table-bordered td,
  .table-bordered th {
    white-space: nowrap;
  }
  .page {
    margin-left: auto;
    padding-right: 20px;
  }
  .date-or-fiscal span {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    margin-top: 5px;
  }
  .date-or-fiscal hr {
    width: 93%;
  }
  .submit-butn {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;
  }
  .submit-butn button {
    border: 1px solid grey;
  }
  .show .dropdown-menu {
    width: 300px;
  }
  .select-fiscal-year {
    margin-left: 10px;
  }
  .date-or-fiscal {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .date-picker-main-div {
    display: flex;
  }
  .date-pick-main {
    margin-left: 10px;
    margin-right: 10px;
  }
  .form-control {
    background-color: white !important;
    border: none;
    cursor: pointer;
    width: 105px;
    border-radius: 5px;
  }
  .date-pick img {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .date-pick {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
  }
  .filter-data {
    height: auto;
    width: auto;
    display: flex;
    top: 10px;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
    margin-top: 40px;
    margin-left: 130px;
    background-color: white;
    box-shadow: 5px 10px 18px #ccc;
  }
  .secondary-sidebar {
    width: 200px;
    padding-top: 30px;
  }
  .secondary-sidebar h5 {
    margin-left: 15px;
  }
  .right-component {
    margin-left: 15px;
  }
  .right-component p {
    font-size: 15px;
  }
  .sidebar-1-design .right-image {
    border: 1px solid #d9dadb !important;
  }
  .right-image {
    margin-left: auto;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: flex;
    flex-direction: column;
  }

  .right-image img {
    margin-left: 5px;
    margin-right: 5px;
  }
  .right-container {
    display: flex;
  }
  .clicking-rights {
    display: flex;
  }
  .main-sidebar-content {
    width: 80px;
  }
  .main-sidebar-content-hide {
    width: 80px;
  }
  .right-name-display-sidebar {
    display: none;
  }
  .sidebar-1-design {
    display: flex;
    flex-direction: column;

    height: 50px;
  }
  .sidebar-1-design .right-image {
    display: flex;
    width: 80px;
    justify-content: center;
  }

  .fiscal-year-filter {
    display: flex;
    margin-left: auto;
  }
  .fiscal-year-filter p {
    margin-top: 16px;
  }
  .fiscal-year button {
    border: none !important;
  }
  .fiscal-year {
    margin-top: 0px !important;
  }
  .main-body {
    width: 100%;
    background-color: #f9fbfc;
  }
  .filter-add-btn {
    margin-left: 15px;
    display: flex;
    position: relative;
  }
  .filter-add-btn button {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .view-data {
    margin-left: auto;
  }
  .view-data button {
    width: -webkit-fill-available;
    margin: 0;
  }
  .dropdown {
    margin-top: 10px;

    margin-right: 10px;

    color: black;
  }
  .dropdown button {
    background-color: white;
    color: black;
    border: 1px solid;
  }
  .filter-div {
    display: flex;
  }
  .filter-div h1 {
    margin-left: 20px;
  }
  .breadcrumb {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .bread-crumb {
    width: fit-content;
    margin-right: 10px;
  }
  .lang-btn {
    margin-top: 20px;
  }
  .account-logo {
    margin-right: 5px;
  }
  .selected-language {
    background-color: #06356a !important;
  }
  .nep {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .eng {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    padding-right: 20px;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .toggle-on-click {
    left: 230px;
  }
  .main-content {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-brand img {
    height: 51px;
  }

  // .Sidebar-menu {
  //   border: none;
  //   background-color: #f8f9fa;
  //   color: black;
  // }
  // button.sidebar-menu :hover {
  //   background-color: transparent !important;
  //   text-decoration: none;
  // }
  #menu-toggle {
    position: absolute;
    top: 50vh;
  }
  #page-content-wrapper {
    height: 100vh;
  }
  /*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

  #wrapper {
    overflow-x: hidden;
  }

  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }

  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }

  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  #sidebar-wrapper .list-group2 {
    width: 0px !important;
  }

  #page-content-wrapper {
    min-width: 100vw;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }

  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }

    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }

    // #wrapper.toggled #sidebar-wrapper {
    //   margin-left: -15rem;
    // }
  }
`;
export default Table_Indicator;
