import React, { useEffect, useState, useContext } from "react";
import httpBrowsing_frontend from "../../Utilities/httpBrowsing_frontend";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import {
  Tost_Context,
  Province_Context,
  District_Context,
  Municipality_Context,
  Filter_Data_context,
  Total_Data_Context,
  Filter_First_Data_Context,
  Filter_Second_Data_Context,
  Second_Total_Data_Context,
  Compare_Graph_Context,
  Right_indicator_id_Context,
  Right_component_nepali_Context,
  Indicator_title_Context,
  Indicator_title_eng_Context,
  Right_component_english_Context,
  Right_name_nepali_Context,
  Right_name_english_Context,
  Is_chart_data_monthly_Context,
  Chart_Data_monthly_context,
  chart_monthly_compare_data_context,
  Chart_x_axis_Context,
  Download_Date_from_context,
  Download_Date_to_context,
  Selected_fiscal_year_Context,
} from "../../Utilities/Store";

const Graph = () => {
  const [Tost, setTost] = useContext(Tost_Context);
  const [Total_Data, setTotal_Data] = useContext(Total_Data_Context); //stores the data coming from fetched data from API
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );

  const [Selected_Parameter, setSelected_Parameter] = useState(""); //this stores the selected parameter value selected from radio button click
  const [Is_chart_data_monthly, setIs_chart_data_monthly] = useContext(
    Is_chart_data_monthly_Context
  ); //used to set chart whether monthly of date wise
  const [Province, setProvince] = useContext(Province_Context);
  const [District, setDistrict] = useContext(District_Context);
  const [Municipality, setMunicipality] = useContext(Municipality_Context);
  const [Second_Total_Data, setSecond_Total_Data] = useContext(
    Second_Total_Data_Context
  ); //stores the comparison second entered data
  const [Selected_Prov_dist_mun, setSelected_Prov_dist_mun] = useState({
    state: "select Province",
  }); //used to show selected prov/dist/muni in first click button
  const [
    Second_Selected_Prov_dist_mun,
    setSecond_Selected_Prov_dist_mun,
  ] = useState({ state: "select Province" }); //used to show selected prov/dist/muni in second click button
  const [Filter_Data, setFilter_Data] = useContext(Filter_Data_context); //when 1st filter of selector buttom is clicked and data is entered the details is stored here.
  const [Filter_First_Data, setFilter_First_Data] = useContext(
    Filter_First_Data_Context
  );
  const [Filter_Second_Data, setFilter_Second_Data] = useContext(
    Filter_Second_Data_Context
  );
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [Compare_Graph, setCompare_Graph] = useContext(Compare_Graph_Context); //Is set true when user click  second buttom to compare the graph
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title

  const [chart_x_axis, setChart_x_axis] = useContext(Chart_x_axis_Context); //used to set month name in x-axis of graph
  const [chart_data_monthly, setChart_data_monthly] = useContext(
    Chart_Data_monthly_context
  );
  const [
    chart_monthly_compare_data,
    setChart_monthly_compare_data,
  ] = useContext(chart_monthly_compare_data_context);
  let Total_Count = [];
  if (Selected_Parameter === "total") {
    let data = Total_Data ? Total_Data.map((item) => item.total) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "male") {
    let data = Total_Data ? Total_Data.map((item) => item.male) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "female") {
    let data = Total_Data ? Total_Data.map((item) => item.female) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "other") {
    let data = Total_Data ? Total_Data.map((item) => item.other) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "under_18") {
    let data = Total_Data ? Total_Data.map((item) => item.under_18) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "adult") {
    let data = Total_Data ? Total_Data.map((item) => item.adult) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "senior") {
    let data = Total_Data ? Total_Data.map((item) => item.senior) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "passed") {
    let data = Total_Data ? Total_Data.map((item) => item.passed) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "amount") {
    let data = Total_Data ? Total_Data.map((item) => item.amount) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else if (Selected_Parameter === "failed") {
    let data = Total_Data ? Total_Data.map((item) => item.failed) : ""; //variable map the total count when first buttom is clicked and selected
    Total_Count.push(data);
  } else {
    let data = Total_Data ? Total_Data.map((item) => item.total) : "";
    Total_Count.push(data);
  }
  let Total_Date = Total_Data ? Total_Data.map((item) => item.date) : ""; //variable map the total dates for chart when first buttom is clicked
  let Second_Total_Count = Compare_Graph
    ? Second_Total_Data
      ? Second_Total_Data.map((item) => item.total)
      : ""
    : "";
  let Second_Total_Date = Compare_Graph
    ? Second_Total_Data
      ? Second_Total_Data.map((item) => item.date)
      : ""
    : "";

  {
    /** This function is run when user select the province/municipality/district from location */
  }
  let Selected_prov_dist_mun_btn;
  if (Selected_Prov_dist_mun.province) {
    Selected_prov_dist_mun_btn = Province.filter(
      (item) => item.id === Number(Selected_Prov_dist_mun.province)
    ).map((item) => item.title_en);
  } else if (Selected_Prov_dist_mun.district) {
    Selected_prov_dist_mun_btn = District.filter(
      (item) => item.id === Number(Selected_Prov_dist_mun.district)
    ).map((item) => item.title_en);
  } else if (Selected_Prov_dist_mun.municipality) {
    Selected_prov_dist_mun_btn = Municipality.filter(
      (item) => item.id === Number(Selected_Prov_dist_mun.municipality)
    ).map((item) => item.title_en);
  } else {
    Selected_prov_dist_mun_btn = Selected_Prov_dist_mun.state;
  }

  {
    /** This function is run when user select the province/municipality/district from location when second compare button is clicked */
  }
  let Second_Selected_prov_dist_mun_btn;
  if (Second_Selected_Prov_dist_mun.province) {
    Second_Selected_prov_dist_mun_btn = Province.filter(
      (item) => item.id === Number(Second_Selected_Prov_dist_mun.province)
    ).map((item) => item.title_en);
  } else if (Second_Selected_Prov_dist_mun.district) {
    Second_Selected_prov_dist_mun_btn = District.filter(
      (item) => item.id === Number(Second_Selected_Prov_dist_mun.district)
    ).map((item) => item.title_en);
  } else if (Second_Selected_Prov_dist_mun.municipality) {
    Second_Selected_prov_dist_mun_btn = Municipality.filter(
      (item) => item.id === Number(Second_Selected_Prov_dist_mun.municipality)
    ).map((item) => item.title_en);
  } else {
    Second_Selected_prov_dist_mun_btn = Second_Selected_Prov_dist_mun.state;
  }

  const Line_Chart = {
    labels: Total_Date,
    datasets: [
      {
        label:
          Filter_Data.province_name &&
          Filter_Data.district_name &&
          Filter_Data.municipality_name
            ? Filter_Data.municipality_name
            : Filter_Data.province_name && Filter_Data.district_name
            ? Filter_Data.district_name
            : Filter_Data.province_name
            ? Filter_Data.province_name
            : "Overall Data",
        position: "bottom",
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        data: Total_Count[0],
      },
    ],
  };

  const Selected_Line_Chart = {
    labels:
      Total_Date.length > Second_Total_Date.length
        ? Total_Date
        : Second_Total_Date,
    datasets: [
      {
        label:
          Filter_First_Data.province_name &&
          Filter_First_Data.district_name &&
          Filter_First_Data.municipality_name
            ? Filter_First_Data.municipality_name
            : Filter_First_Data.province_name && Filter_First_Data.district_name
            ? Filter_First_Data.district_name
            : Filter_First_Data.province_name
            ? Filter_First_Data.province_name
            : "Overall Data",
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        data: Total_Count[0],
      },
      {
        label:
          Filter_Second_Data.province_name &&
          Filter_Second_Data.district_name &&
          Filter_Second_Data.municipality_name
            ? Filter_Second_Data.municipality_name
            : Filter_Second_Data.province_name &&
              Filter_Second_Data.district_name
            ? Filter_Second_Data.district_name
            : Filter_Second_Data.province_name
            ? Filter_Second_Data.province_name
            : "Overall Data",
        fill: false,
        backgroundColor: "green",
        borderColor: "#742774",
        backgroundColor: "#742774",
        data: Second_Total_Count,
      },
    ],
  };

  useEffect(() => {
    httpBrowsing_frontend
      .get(
        `/frontend/data/graph/?indicator=${Right_indicator_id}&province=${Filter_Data.province}&district=${Filter_Data.district}&municipality=${Filter_Data.municipality}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
      )
      .then((res) => {
        setTotal_Data(res.data.data);

        setRight_name_english(res.data.right_en);
        setRight_name_nepali(res.data.right_ne);
        setRight_component_english(res.data.right_component_en);
        setRight_component_nepali(res.data.right_component_ne);
        setIndicator_title_eng(res.data.indicator_title_en);
        setIndicator_title(res.data.indicator_title_ne);
      })
      .catch((err) => {
        console.log("Err", err.response);
      });
  }, [
    Right_indicator_id,
    Filter_Data.province,
    Filter_Data.district,
    Filter_Data.municipality,
  ]);
  useEffect(() => {
    httpBrowsing_frontend
      .get(
        `/frontend/data/graph/monthly/${Right_indicator_id}/${
          Tost ? "eng" : "nep"
        }/?province=${Filter_Data.province}&district=${
          Filter_Data.district
        }&municipality=${
          Filter_Data.municipality
        }&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
      )
      .then((res) => {
        setChart_data_monthly(res.data);
        setChart_x_axis(Object.keys(res.data));
      })
      .catch((err) => {
        console.log("Err", err.response);
      });
  }, [
    Right_indicator_id,
    Filter_Data.province,
    Filter_Data.district,
    Filter_Data.municipality,
  ]);
  let monthly_chart_value = [];
  let monthly_chart_value_compare = [];
  if (chart_data_monthly) {
    let monthly_data = Object.values(chart_data_monthly);
    monthly_chart_value = monthly_data.map((item) => item.total_count);
  }
  if (chart_monthly_compare_data) {
    let monthly_data_compare = Object.values(chart_monthly_compare_data);
    monthly_chart_value_compare = monthly_data_compare.map(
      (item) => item.total_count
    );
  }

  const monthly_data_graph = {
    labels: chart_x_axis,
    datasets: [
      {
        label:
          Filter_Data.province_name &&
          Filter_Data.district_name &&
          Filter_Data.municipality_name
            ? Tost
              ? `${Filter_Data.municipality_name} (Current Fiscal Year's Monthly Total Data) `
              : `${Filter_Data.municipality_name_nep} (चालु आर्थिक बर्षको मासिक संख्या) `
            : Filter_Data.province_name && Filter_Data.district_name
            ? Tost
              ? `${Filter_Data.district_name} (Current Fiscal Year's Monthly Total Data) `
              : `${Filter_Data.district_name_nep} (चालु आर्थिक बर्षको मासिक संख्या) `
            : Filter_Data.province_name
            ? Tost
              ? `${Filter_Data.province_name} (Current Fiscal Year's Monthly Total Data) `
              : `${Filter_Data.province_name_nep} (चालु आर्थिक बर्षको मासिक संख्या) `
            : Tost
            ? "Total Data (Current Fiscal Year's Monthly Total Data) "
            : "कुल संख्या (चालु आर्थिक बर्षको मासिक संख्या)",

        position: "bottom",
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        data: monthly_chart_value,
      },
    ],
  };
  const monthly_data_graph_compare = {
    labels: chart_x_axis,
    datasets: [
      {
        label:
          Filter_First_Data.province_name &&
          Filter_First_Data.district_name &&
          Filter_First_Data.municipality_name
            ? Tost
              ? Filter_First_Data.municipality_name
              : Filter_First_Data.municipality_name_nep
            : Filter_First_Data.province_name && Filter_First_Data.district_name
            ? Tost
              ? Filter_First_Data.district_name
              : Filter_First_Data.district_name_nep
            : Filter_First_Data.province_name
            ? Tost
              ? Filter_First_Data.province_name
              : Filter_First_Data.province_name_nep
            : Tost
            ? "Total Data"
            : "कुल संख्या",
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        data: monthly_chart_value,
      },
      {
        label:
          Filter_Second_Data.province_name &&
          Filter_Second_Data.district_name &&
          Filter_Second_Data.municipality_name
            ? Tost
              ? `${Filter_Second_Data.municipality_name} (Current Fiscal Year's Monthly Total Data) `
              : `${Filter_Second_Data.municipality_name_nep} (चालु आर्थिक बर्षको मासिक संख्या) `
            : Filter_Second_Data.province_name &&
              Filter_Second_Data.district_name
            ? Tost
              ? `${Filter_Second_Data.district_name} (Current Fiscal Year's Monthly Total Data)`
              : `${Filter_Second_Data.district_name_nep} (चालु आर्थिक बर्षको मासिक संख्या) `
            : Filter_Second_Data.province_name
            ? Tost
              ? `${Filter_Second_Data.province_name} (Current Fiscal Year's Monthly Total Data) `
              : `${Filter_Second_Data.province_name_nep} (चालु आर्थिक बर्षको मासिक संख्या) `
            : Tost
            ? "Total Data (Current Fiscal Year's Monthly Total Data)"
            : "कुल संख्या (चालु आर्थिक बर्षको मासिक संख्या)",
        fill: false,
        backgroundColor: "green",
        borderColor: "#742774",
        backgroundColor: "#742774",
        data: monthly_chart_value_compare,
      },
    ],
  };

  return (
    <div>
      <Wrapper>
        <div className="row graph" style={{ width: "100%" }} id="hell">
          <div className="col-sm-12 explore-title-bar">
            {Is_chart_data_monthly ? (
              chart_data_monthly ? (
                <Line
                  id="capture"
                  data={
                    Compare_Graph
                      ? monthly_data_graph_compare
                      : monthly_data_graph
                  }
                  options={{
                    responsive: true,
                    title: {
                      display: true,
                      text: Tost
                        ? `${Right_name_english}/${Right_component_english}/${Indicator_title_eng}`
                        : `${Right_name_nepali}/${Right_component_nepali}/${Indicator_title}`,
                      position: "top",
                    },

                    legend: {
                      display: true,
                      position: "bottom",
                    },

                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            fontColor: "black",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "black",
                          },
                        },
                      ],
                    },
                  }}
                  width={800}
                  height={350}
                />
              ) : (
                <p className="no-data" style={{ textAlign: "center" }}>
                  No Data Available For Selected Filter
                </p>
              )
            ) : Total_Data.length ? (
              <Line
                id="capture"
                data={Compare_Graph ? Selected_Line_Chart : Line_Chart}
                options={{
                  responsive: true,
                  title: {
                    display: true,
                    text: Tost
                      ? `${Right_name_english}/${Right_component_english}/${Indicator_title_eng}`
                      : `${Right_name_nepali}/${Right_component_nepali}/${Indicator_title}`,
                    position: "top",
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          fontColor: "black",
                        },
                      },
                    ],
                    xAxes: [
                      {
                        ticks: {
                          fontColor: "black",
                        },
                      },
                    ],
                  },
                }}
                width={800}
                height={350}
              />
            ) : (
              <p className="no-data" style={{ textAlign: "center" }}>
                No Data Available For Selected Filter
              </p>
            )}
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
const Wrapper = styled.div`
  .explore-title-bar {
    min-height: 240px;
    min-width: 680px;
  }
  @media only screen and (max-width: 720px) {
    overflow-x: scroll;
  }
`;
export default Graph;
