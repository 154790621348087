import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Routing from "./../src/Utilities/Routing";
import Store from "./Utilities/Store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Store>
      <Routing />
      <ToastContainer />
    </Store>
  );
}

export default App;
