import React, { useContext, useState } from "react";
import httpBrowsing from "../../Utilities/httpBrowsing";
import styled from "styled-components";
import { Link } from "react-router-dom";
import notification from "../../Utilities/Notification";
import { Loading_Context } from "../../Utilities/Store";

const Change_Password = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [setLoading] = useContext(Loading_Context);
  const [change_password, setChange_password] = useState({
    current_password: "",
    new_password: "",
    new_password_confirm: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChange_password({
      ...change_password,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (change_password.new_password !== change_password.new_password_confirm) {
      notification.showError(
        "New password and confirm password does not match!!"
      );
      setIsLoading(false);
    } else if (
      change_password.new_password !== change_password.new_password_confirm &&
      change_password.new_password.length < 8
    ) {
      notification.showError("Password must be atlease 8 character long!!");
      setIsLoading(false);
    } else if (change_password.new_password === "") {
      notification.showError("Please enter new password!!");
      setIsLoading(false);
    } else if (change_password.new_password_confirm === "") {
      notification.showError("Please enter confirm password!!");
      setIsLoading(false);
    } else if (change_password.current_password === "") {
      notification.showError("Please enter current password!!");
      setIsLoading(false);
    } else {
      httpBrowsing
        .post("/nep/change-password/", change_password)
        .then((res) => {
          notification.showSuccess("Password changed successfully!!");
          setIsLoading(false);
          localStorage.clear();
          props.history.push("/login");
        })
        .catch((err) => {
          console.log("This is error>>>", err.response);
          notification.showError("Please enter a valid current password!!");
          setIsLoading(false);
        });
    }
  };

  const handleClick = (e) => {
    setLoading(true);
  };

  return (
    <Wrapper>
      <div className="loginwrapper">
        <form method="post" className="form-signin shadow-1">
          <h5 className="form-signin-heading text-center">Change Password</h5>
          <div className="input-group">
            <input
              type="password"
              name="current_password"
              className="form-control"
              placeholder="Current Password"
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              name="new_password"
              className="form-control"
              placeholder="New Password"
              onChange={handleChange}
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              name="new_password_confirm"
              className="form-control"
              placeholder="Confirm Password"
              onChange={handleChange}
            />
          </div>
          <br />
          {isLoading ? (
            <button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              disabled
            >
              <i className="fa fa-refresh fa-spin fa-fw" /> Submitting...
            </button>
          ) : (
            <button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
          <div className="input-group">
            <Link to="/dashboard">Back</Link>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .input-group a {
    margin-top: 10px;
  }
  .input-group {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .loginwrapper {
    margin-top: 40px;
    margin-bottom: 80px;
  }

  .form-signin {
    max-width: 400px;
    padding: 15px 35px 45px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    font-size: 16px !important;
    margin-top: 12%;
  }
  .form-signin .form-signin-heading {
    margin-bottom: 30px;
  }
  .btn-block {
    width: 100% !important;
    display: block !important;
  }
  .form-signin .form-control {
    position: relative;
    height: auto;
    padding: 7px !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }

  .form-signin button[type="submit"] {
    height: 36px !important;
    font-size: 16px !important;
    padding: 0;
    outline: none;
  }

  /*-----------------------------------------------
	submit button
-----------------------------------------------*/
  button[type="submit"],
  .outline-none {
    outline: none !important;
  }

  /*==================================================
 * shadow-1
 * ===============================================*/
  .shadow-1 {
    -webkit-box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    -moz-box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
  }

  /* author */
  .author {
    float: left;
    font-family: "Maven Pro", sans-serif;
    font-size: 18px;
    margin: 15px 20px 30px;
  }
`;
export default Change_Password;
