import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import {
  Select_Language_Context,
  Loading_Context,
  Initial_Loading_Context,
  Tost_Context,
  Load_on_Lan_Change_Context,
  Loading_Language_Context,
} from "../Utilities/Store";

import httpBrowsing from "../Utilities/httpBrowsing";

import { Link } from "react-router-dom";

const Frontend_Navbar = (props) => {
  // const [Username, setUsername] = useLocalStorage("Username", ""); //used to set username to localstorage
  // // const [checked, setchecked] = useContext(CheckedContext);
  // const [Change_Language, setChange_Language] = useContext(
  //   Change_Language_Context
  // ); //used to check the condition of toggle switch
  const [Select_Language, setSelect_Language] = useContext(
    Select_Language_Context
  );
  const [Loading, setLoading] = useContext(Loading_Context);
  const [Initial_Loading, setInitial_Loading] = useContext(
    Initial_Loading_Context
  );

  // const [checked, setChecked] = useState(false);
  // const [Refresh_Token, setRefresh_Token] = useContext(Refresh_Token_Context);
  const [Tost, setTost] = useContext(Tost_Context); //used to store boolean value during language switch
  const [Load_on_Lan_Change, setLoad_on_Lan_Change] = useContext(
    Load_on_Lan_Change_Context
  ); //this value is changed only when language is changed
  const [Loading_Language, setLoading_Language] = useContext(
    Loading_Language_Context
  ); //used to disable language switch untill response is obtained
  const handleChange = (checked) => {
    // setLoading(true);
    // setchecked(checked);
  };
  const Logout = (e) => {
    e.preventDefault();
    let closing_Token = JSON.stringify({
      refresh: localStorage.getItem("refresh"),
    });
    setInitial_Loading(true);

    httpBrowsing
      .post("/nep/logout/", closing_Token)
      .then((res) => {
        localStorage.clear();
        setLoading(false);
        props.data.history.push("/");
      })
      .catch((err) => {
        console.log("this is error", err.response);
        setInitial_Loading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Tost) {
      setSelect_Language("eng");
    } else {
      setSelect_Language("nep");
    }
    setLoading_Language(false);
  }, [Tost]);
  return (
    <Tester>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link to="/" className="navbar-brand">
          <img src="/gon.png" style={{ height: "50px" }} alt="logo" />
        </Link>
        {Tost ? (
          <span className="navbar-text">humanrightsdata.gov.np</span>
        ) : (
          <span className="navbar-text">humanrightsdata.gov.np</span>
        )}
        {/* <a className="navbar-brand" href="#">
          Navbar
        </a> */}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul
            className="navbar-nav ml-auto mt-2 mt-lg-0"
            style={{ display: "block" }}
          >
            <li className="nav-item" style={{ marginLeft: "25px" }}>
              <div className="lang-btn">
                {Loading_Language ? (
                  Tost ? (
                    <button
                      className={Tost ? "eng selected-language" : "eng"}
                      onClick={() => {
                        setTost(true);
                        setLoad_on_Lan_Change(true);
                        setLoading_Language(true);
                        setLoading(true);
                      }}
                      disabled
                    >
                      EN
                    </button>
                  ) : (
                    <button
                      className={Tost ? "eng selected-language" : "eng"}
                      onClick={() => {
                        setTost(true);
                        setLoad_on_Lan_Change(true);
                        setLoading_Language(true);
                        setLoading(true);
                      }}
                      disabled
                    >
                      EN
                    </button>
                  )
                ) : Tost ? (
                  <button
                    className={Tost ? "eng selected-language" : "eng"}
                    onClick={() => {
                      setTost(true);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                    disabled
                  >
                    EN
                  </button>
                ) : (
                  <button
                    className={Tost ? "eng selected-language" : "eng"}
                    onClick={() => {
                      setTost(true);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                  >
                    EN
                  </button>
                )}
                {Loading_Language ? (
                  Tost ? (
                    <button
                      className={Tost ? "nep" : "nep selected-language "}
                      onClick={() => {
                        setTost(false);
                        setLoad_on_Lan_Change(true);
                        setLoading_Language(true);
                        setLoading(true);
                      }}
                      disabled
                    >
                      ने
                    </button>
                  ) : (
                    <button
                      className={Tost ? "nep" : "nep selected-language "}
                      onClick={() => {
                        setTost(false);
                        setLoad_on_Lan_Change(true);
                        setLoading_Language(true);
                        setLoading(true);
                      }}
                      disabled
                    >
                      ने
                    </button>
                  )
                ) : Tost ? (
                  <button
                    className={Tost ? "nep" : "nep selected-language "}
                    onClick={() => {
                      setTost(false);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                  >
                    ने
                  </button>
                ) : (
                  <button
                    className={Tost ? "nep" : "nep selected-language "}
                    onClick={() => {
                      setTost(false);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                    disabled
                  >
                    ने
                  </button>
                )}
              </div>
            </li>
            {!Tost ? (
              <li className="nav-item">
                <Link to="/login" className="nav-link">
                  <span>लग ईन</span>{" "}
                  <img src="/login-icon.svg" alt="login-icon" />
                </Link>
              </li>
            ) : (
              <li className="nav-item">
                <Link to="/login" className="nav-link">
                  <span> Login</span>
                  <img src="/login-icon.svg" alt="login-icon" />
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </Tester>
  );
  {
    /* <nav className="navbar navbar-expand-lg navbar-light bg-light ">
        <Link to="/" className="navbar-brand">
          <img src="/gon.png" alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        {Tost ? (
          <span className="navbar-text">humanrightsdata.gov.np</span>
        ) : (
         
          <span className="navbar-text">humanrightsdata.gov.np</span>
        
        )}
       

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto combine-lang-login">
              {Loading_Language ? (
                Tost ? (
                  <button
                    className={Tost ? "eng selected-language" : "eng"}
                    onClick={() => {
                      setTost(true);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                    disabled
                  >
                    EN
                  </button>
                ) : (
                  <button
                    className={Tost ? "eng selected-language" : "eng"}
                    onClick={() => {
                      setTost(true);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                    disabled
                  >
                    EN
                  </button>
                )
              ) : Tost ? (
                <button
                  className={Tost ? "eng selected-language" : "eng"}
                  onClick={() => {
                    setTost(true);
                    setLoad_on_Lan_Change(true);
                    setLoading_Language(true);
                    setLoading(true);
                  }}
                  disabled
                >
                  EN
                </button>
              ) : (
                <button
                  className={Tost ? "eng selected-language" : "eng"}
                  onClick={() => {
                    setTost(true);
                    setLoad_on_Lan_Change(true);
                    setLoading_Language(true);
                    setLoading(true);
                  }}
                >
                  EN
                </button>
              )}
              {Loading_Language ? (
                Tost ? (
                  <button
                    className={Tost ? "nep" : "nep selected-language "}
                    onClick={() => {
                      setTost(false);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                    disabled
                  >
                    ने
                  </button>
                ) : (
                  <button
                    className={Tost ? "nep" : "nep selected-language "}
                    onClick={() => {
                      setTost(false);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                    disabled
                  >
                    ने
                  </button>
                )
              ) : Tost ? (
                <button
                  className={Tost ? "nep" : "nep selected-language "}
                  onClick={() => {
                    setTost(false);
                    setLoad_on_Lan_Change(true);
                    setLoading_Language(true);
                    setLoading(true);
                  }}
                >
                  ने
                </button>
              ) : (
                <button
                  className={Tost ? "nep" : "nep selected-language "}
                  onClick={() => {
                    setTost(false);
                    setLoad_on_Lan_Change(true);
                    setLoading_Language(true);
                    setLoading(true);
                  }}
                  disabled
                >
                  ने
                </button>
              )}
              {!Tost ? (
                <li className="nav-item">
                  <Link to="/login" className="nav-link">
                    <span>लग ईन</span>{" "}
                    <img src="/login-icon.svg" alt="login-icon" />
                  </Link>
                </li>
              ) : (
                <li className="nav-item">
                  <Link to="/login" className="nav-link">
                    <span> Login</span>
                    <img src="/login-icon.svg" alt="login-icon" />
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav> */
  }
};
const Tester = styled.div`
  @media screen and (max-width: 500px) {
    .navbar-text {
      font-size: 16px !important;
      margin-left: -30px !important;
    }
    .lang-btn {
      display: flex;
      justify-content: flex-end;
    }
    .nav-link {
      margin-left: auto !important;
      padding-left: 20px !important;
    }
  }

  .navbar-text {
    font-size: 20px;
    font-weight: bold;
    opacity: 1;
    color: #06356a !important;
  }
  .nav-item a {
    width: 100px;
    margin-left: 5px;
    font-size: 18px;
  }
  .navbar {
    padding-left: 6%;
    padding-right: 6%;
    border-bottom: 1px solid #e9ecef;
  }
  // @media screen and (max-width: 500px) {
  //   .navbar-brand img {
  //     margin-left: 0px !important;
  //   }
  // }
  // .navbar {
  //   height: 120px;
  // }
  // .combine-lang-login {
  //   display: block !important;
  //   margin-right: 50px;
  // }
  // .eng {
  //   margin-left: 30px;
  // }
  // .nav-item img {
  //   height: 20px;
  //   margin-top: -4px;
  //   margin-left: 5px;
  // }
  // .butn-logout {
  //   margin-right: 0px !important;
  // }
  // .navbar-text {
  //   font-size: 20px;
  //   font-weight: bold;
  //   opacity: 1;
  //   color: #06356a !important;
  // }
  // .secondary-sidebar {
  //   width: 200px;
  //   padding-top: 30px;
  // }
  // .secondary-sidebar h5 {
  //   margin-left: 15px;
  // }
  // .right-component {
  //   margin-left: 15px;
  // }
  // .right-component p {
  //   font-size: 15px;
  // }
  // .sidebar-1-design .right-image {
  //   border: 1px solid #d9dadb !important;
  // }
  // .right-image {
  //   margin-left: auto;
  //   padding-top: 20px !important;
  //   padding-bottom: 20px !important;
  //   display: flex;
  //   flex-direction: column;
  // }
  // .right-image img {
  //   margin-left: 5px;
  //   margin-right: 5px;
  // }
  // .right-container {
  //   display: flex;
  // }
  // .clicking-rights {
  //   display: flex;
  // }
  // .main-sidebar-content {
  //   width: 80px;
  // }
  // .main-sidebar-content-hide {
  //   width: 80px;
  // }
  // .right-name-display-sidebar {
  //   display: none;
  // }
  // .sidebar-1-design {
  //   display: flex;
  //   flex-direction: column;
  //   height: 50px;
  // }
  // .sidebar-1-design .right-image {
  //   display: flex;
  //   width: 80px;
  //   justify-content: center;
  // }
  // .show .dropdown-menu {
  //   width: auto !important;
  // }
  // .test-table {
  //   margin-left: 20px;
  //   margin-right: 20px;
  //   margin-top: 20px;
  // }
  // .fiscal-year-filter {
  //   display: flex;
  //   margin-left: auto;
  // }
  // .fiscal-year-filter p {
  //   margin-top: 16px;
  // }
  // .fiscal-year button {
  //   border: none !important;
  // }
  // .fiscal-year {
  //   margin-top: 0px !important;
  // }
  // .main-body {
  //   width: 100%;
  // }
  // .filter-add-btn {
  //   margin-left: 15px;
  //   display: flex;
  // }
  // .filter-add-btn button {
  //   height: 100%;
  //   margin-left: 5px;
  //   margin-right: 5px;
  // }
  // .view-data {
  //   margin-left: auto;
  //   margin-right: 20px !important;
  // }
  // .dropdown {
  //   margin-right: 10px;
  //   color: black;
  // }
  // .dropdown button {
  //   background-color: white;
  //   color: black;
  //   border: 1px solid;
  // }
  // .filter-div {
  //   display: flex;
  // }
  // .filter-div h1 {
  //   margin-left: 20px;
  // }
  // .breadcrumb {
  //   border-top-right-radius: 25px;
  //   border-bottom-right-radius: 25px;
  //   margin-left: 20px;
  //   margin-top: 30px;
  // }
  // .bread-crumb {
  //   width: fit-content;
  //   margin-right: 10px;
  // }
  // .lang-btn {
  //   margin-top: 10px;
  // }
  // .account-logo {
  //   margin-right: 5px;
  // }
  .selected-language {
    background-color: #06356a !important;
  }
  .nep {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .eng {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    padding-right: 20px;
    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  // .toggle-on-click {
  //   left: 230px;
  // }
  // .main-content {
  //   display: flex;
  //   margin: 0;
  //   padding: 0;
  // }
  // .navbar-brand img {
  //   height: 51px;
  //   margin-left: 70px;
  // }
  // // .Sidebar-menu {
  // //   border: none;
  // //   background-color: #f8f9fa;
  // //   color: black;
  // // }
  // // button.sidebar-menu :hover {
  // //   background-color: transparent !important;
  // //   text-decoration: none;
  // // }
  // #menu-toggle {
  //   position: absolute;
  //   top: 50vh;
  // }
  // #page-content-wrapper {
  //   height: 100vh;
  // }
`;
export default Frontend_Navbar;
