import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Switch from "react-switch";
import {
  Change_Language_Context,
  Select_Language_Context,
  Loading_Context,
  Initial_Loading_Context,
  Tost_Context,
  Load_on_Lan_Change_Context,
  Loading_Language_Context,
  Download_Date_from_context,
  Download_Date_to_context,
  Selected_fiscal_year_Context,
} from "../Utilities/Store";
import Login from "../Admin.Pannel.Content/Login.components/Login";
import httpBrowsing from "../Utilities/httpBrowsing";
import { Refresh_Token_Context } from "./../Utilities/Store";
import { Link } from "react-router-dom";
import { useLocalStorage } from "./../Utilities/Localstorage";
import { NavDropdown } from "react-bootstrap";
const Navbar_new = (props) => {
  const [Username, setUsername] = useLocalStorage("Username", ""); //used to set username to localstorage
  // const [checked, setchecked] = useContext(CheckedContext);
  const [Change_Language, setChange_Language] = useContext(
    Change_Language_Context
  ); //used to check the condition of toggle switch
  const [Select_Language, setSelect_Language] = useContext(
    Select_Language_Context
  );
  const [Loading, setLoading] = useContext(Loading_Context);
  const [Initial_Loading, setInitial_Loading] = useContext(
    Initial_Loading_Context
  );
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [checked, setChecked] = useState(false);
  const [Refresh_Token, setRefresh_Token] = useContext(Refresh_Token_Context);
  const [Tost, setTost] = useContext(Tost_Context); //used to store boolean value during language switch
  const [Load_on_Lan_Change, setLoad_on_Lan_Change] = useContext(
    Load_on_Lan_Change_Context
  ); //this value is changed only when language is changed
  const [Loading_Language, setLoading_Language] = useContext(
    Loading_Language_Context
  ); //used to disable language switch untill response is obtained
  const[download_file,setDownload_file]=useState(null);
  const handleChange = (checked) => {
    // setLoading(true);
    // setchecked(checked);
  };

  const Logout = (e) => {
    e.preventDefault();
    let closing_Token = JSON.stringify({
      refresh: localStorage.getItem("refresh"),
    });
    setInitial_Loading(true);
    setDownload_Date_from("");
    setDownload_Date_to("");
    setSelected_fiscal_year("");
    httpBrowsing
      .post("/nep/logout/", closing_Token)
      .then((res) => {
        localStorage.clear();
        setLoading(false);
        props.data.history.push("/login");
      })
      .catch((err) => {
        console.log("this is error", err.response);
        setInitial_Loading(false);
        setLoading(false);
      });
  };
  useEffect(() => {
    httpBrowsing.get("/nep/me/").then((res) => {
      setUsername(res.data.username);
    });
   
  }, [setUsername]);
  useEffect(()=>{
    httpBrowsing.get('/nep/publications/')
    .then(res=>{
      setDownload_file(res.data[0].file)
    
    })
    .catch(err=>{
      console.log("This is error",err.response)
    })
  },[])
  useEffect(() => {
    if (Tost) {
      setSelect_Language("eng");
    } else {
      setSelect_Language("nep");
    }
    setLoading_Language(false);
  }, [Tost, setLoading_Language, setSelect_Language]);
  const handleDownload = () => {
    window.open("/HRIMS_Technical_Manual.pdf", "_blank");
  };
  return (
    <Tester>
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
        <Link to="/dashboard" className="navbar-brand">
          <img src="/gon.png" alt="logo" />
        </Link>

        {Tost ? (
          <span className="navbar-text">
            Government of Nepal
            <br /> Office of the Prime Minister and Council of Ministers
            <br />
            <b> Human Rights Information Management System</b>
          </span>
        ) : (
          <span className="navbar-text">
            नेपाल सरकार
            <br /> प्रधानमन्त्री तथा मन्त्रिपरिषद्को कार्यालय
            <br />
            <b> मानव अधिकार सूचना व्यवस्थापन प्रणाली</b>
          </span>
        )}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0 ">
            <NavDropdown
              title={Tost?"Download":'डाउनलोड '}
              id="basic-nav-dropdown"
              className="down-bttn"
            >
              <NavDropdown.Item  onClick={() => {
                                    window.open(download_file, "_blank");
                                  }}>
                {Tost?'TECHNICAL HANDBOOK':'प्राविधिक पुस्तिका'}
              </NavDropdown.Item>
            </NavDropdown>
            <div className="d-flex flex-column">
              <li className="nav-item lang-btn">
                {Loading_Language ? (
                  Tost ? (
                    <button
                      className={Tost ? "eng selected-language" : "eng"}
                      onClick={() => {
                        setTost(true);
                        setLoad_on_Lan_Change(true);
                        setLoading_Language(true);
                        setLoading(true);
                      }}
                      disabled
                    >
                      EN
                    </button>
                  ) : (
                    <button
                      className={Tost ? "eng selected-language" : "eng"}
                      onClick={() => {
                        setTost(true);
                        setLoad_on_Lan_Change(true);
                        setLoading_Language(true);
                        setLoading(true);
                      }}
                      disabled
                    >
                      EN
                    </button>
                  )
                ) : Tost ? (
                  <button
                    className={Tost ? "eng selected-language" : "eng"}
                    onClick={() => {
                      setTost(true);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                    disabled
                  >
                    EN
                  </button>
                ) : (
                  <button
                    className={Tost ? "eng selected-language" : "eng"}
                    onClick={() => {
                      setTost(true);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                  >
                    EN
                  </button>
                )}
                {Loading_Language ? (
                  Tost ? (
                    <button
                      className={Tost ? "nep" : "nep selected-language "}
                      onClick={() => {
                        setTost(false);
                        setLoad_on_Lan_Change(true);
                        setLoading_Language(true);
                        setLoading(true);
                      }}
                      disabled
                    >
                      ने
                    </button>
                  ) : (
                    <button
                      className={Tost ? "nep" : "nep selected-language "}
                      onClick={() => {
                        setTost(false);
                        setLoad_on_Lan_Change(true);
                        setLoading_Language(true);
                        setLoading(true);
                      }}
                      disabled
                    >
                      ने
                    </button>
                  )
                ) : Tost ? (
                  <button
                    className={Tost ? "nep" : "nep selected-language "}
                    onClick={() => {
                      setTost(false);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                  >
                    ने
                  </button>
                ) : (
                  <button
                    className={Tost ? "nep" : "nep selected-language "}
                    onClick={() => {
                      setTost(false);
                      setLoad_on_Lan_Change(true);
                      setLoading_Language(true);
                      setLoading(true);
                    }}
                    disabled
                  >
                    ने
                  </button>
                )}
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle butn-logout"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ marginRight: "0px !important" }}
                >
                  <img className="account-logo" src="/admin.svg" alt="admin" />
                  {Username}
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdown"
                >
                  {Tost ? (
                    <Link to="/change-password" className="dropdown-item">
                      <img
                        className="account-logo"
                        src="/chnge-password.svg"
                        alt="change-password"
                      />
                      Change Password
                    </Link>
                  ) : (
                    <Link to="/change-password" className="dropdown-item">
                      <img
                        className="account-logo"
                        src="/chnge-password.svg"
                        alt="change-password"
                      />
                      पासवर्ड परिवर्तन
                    </Link>
                  )}
                  <div className="dropdown-divider" />
                  {Tost ? (
                    <Link to="/" className="dropdown-item" onClick={Logout}>
                      <img
                        className="account-logo"
                        src="/logout.svg"
                        alt="logout"
                      />
                      Logout
                    </Link>
                  ) : (
                    <Link to="/" className="dropdown-item" onClick={Logout}>
                      <img
                        className="account-logo"
                        src="/logout.svg"
                        alt="logout"
                      />
                      लगआउट
                    </Link>
                  )}
                </div>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </Tester>
  );
};
const Tester = styled.div`
  .down-bttn {
    padding-top: 25px;
  }
  // .navbar {
  //   position: absolute !important;
  //   top: 0;
  //   width: 100vw;
  // }
  .butn-logout {
    margin-right: 0px !important;
  }
  .navbar-text {
    font-size: 12px;
    font-weight: 500;
  }
  .secondary-sidebar {
    width: 200px;
    padding-top: 30px;
  }
  .secondary-sidebar h5 {
    margin-left: 15px;
  }
  .right-component {
    margin-left: 15px;
  }
  .right-component p {
    font-size: 15px;
  }
  .sidebar-1-design .right-image {
    border: 1px solid #d9dadb !important;
  }
  .right-image {
    margin-left: auto;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: flex;
    flex-direction: column;
  }

  .right-image img {
    margin-left: 5px;
    margin-right: 5px;
  }
  .right-container {
    display: flex;
  }
  .clicking-rights {
    display: flex;
  }
  .main-sidebar-content {
    width: 80px;
  }
  .main-sidebar-content-hide {
    width: 80px;
  }
  .right-name-display-sidebar {
    display: none;
  }
  .sidebar-1-design {
    display: flex;
    flex-direction: column;

    height: 50px;
  }
  .sidebar-1-design .right-image {
    display: flex;
    width: 80px;
    justify-content: center;
  }
  .show .dropdown-menu {
    width: auto !important;
  }
  .test-table {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }
  .fiscal-year-filter {
    display: flex;
    margin-left: auto;
  }
  .fiscal-year-filter p {
    margin-top: 16px;
  }
  .fiscal-year button {
    border: none !important;
  }
  .fiscal-year {
    margin-top: 0px !important;
  }
  .main-body {
    width: 100%;
  }
  .filter-add-btn {
    margin-left: 15px;
    display: flex;
  }
  .filter-add-btn button {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .view-data {
    margin-left: auto;
    margin-right: 20px !important;
  }
  .dropdown {
    margin-right: 10px;

    color: black;
  }
  .dropdown button {
    background-color: white;
    color: black;
    border: 1px solid;
  }
  .filter-div {
    display: flex;
  }
  .filter-div h1 {
    margin-left: 20px;
  }
  .breadcrumb {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .bread-crumb {
    width: fit-content;
    margin-right: 10px;
  }
  .lang-btn {
    margin-left: auto;
    margin-right: 20px;
  }
  .account-logo {
    margin-right: 5px;
  }
  .selected-language {
    background-color: #06356a !important;
  }
  .nep {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .eng {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    padding-right: 20px;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .toggle-on-click {
    left: 230px;
  }
  .main-content {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-brand img {
    height: 51px;
  }
  // .Sidebar-menu {
  //   border: none;
  //   background-color: #f8f9fa;
  //   color: black;
  // }
  // button.sidebar-menu :hover {
  //   background-color: transparent !important;
  //   text-decoration: none;
  // }
  #menu-toggle {
    position: absolute;
    top: 50vh;
  }
  #page-content-wrapper {
    height: 100vh;
  }
  /*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

  #wrapper {
    overflow-x: hidden;
  }

  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }

  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }

  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  #sidebar-wrapper .list-group2 {
    width: 0px !important;
  }

  #page-content-wrapper {
    min-width: 100vw;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }

  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }

    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }

    // #wrapper.toggled #sidebar-wrapper {
    //   margin-left: -15rem;
    // }
  }
`;
export default Navbar_new;
