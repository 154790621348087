import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import httpBrowsing from "../../Utilities/httpBrowsing";
import notification from "../../Utilities/Notification";
import { Refresh_Token_Context } from "../../Utilities/Store";
import { Link } from "react-router-dom";
import { useLocalStorage } from "../../Utilities/Localstorage";
import { Loading_Context } from "../../Utilities/Store";
const Login = (props) => {
  const [Loading, setLoading] = useState(false);
  const [Login, setLogin] = useState({
    username: "",
    password: "",
  });
  const [Username, setUsername] = useLocalStorage("Username", ""); //used to set username to localstorage
  const [Refresh_Token, setRefresh_Token] = useContext(Refresh_Token_Context);
  const [Token_Granted, setToken_Granted] = useState(false); //used to check whether token is granted or not
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLogin({
      ...Login,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    httpBrowsing
      .post("/token/", Login)
      .then((res) => {
        localStorage.setItem("token", res.data.access);
        localStorage.setItem("refresh", res.data.refresh);
        setToken_Granted(true);
        // setUsername(Login.username);
        // setLoading(false);
        // props.history.push("/dashboard");
        // notification.showSuccess("Welcome!!!");
      })
      .catch((err) => {
        console.log("this is error", err.response);
        notification.showError("Invalid Username/password");
        setLoading(false);
      });
  };

  useEffect(() => {
    const Verifying_user = () => {
      if (Token_Granted) {
        httpBrowsing.get("/nep/me/").then((res) => {
          if (res.data.first_time_login) {
            setUsername(Login.username);
            setLoading(false);
            props.history.push("/change-password");
          } else {
            setUsername(Login.username);
            setLoading(false);
            props.history.push("/dashboard");
            notification.showSuccess("Welcome!!!");
          }
        });
      }
    };
    Verifying_user();
  }, [Token_Granted]);

  return (
    <React.Fragment>
      <Wrapper>
        <div className="loginwrapper">
          <form method="post" className="form-signin shadow-1">
            <h3 className="form-signin-heading text-center">Login</h3>
            <div className="input-group">
              <input
                type="text"
                name="username"
                className="form-control"
                placeholder="Username"
                onChange={handleChange}
              />
            </div>
            <br />
            <div className="input-group">
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
                onChange={handleChange}
              />
            </div>
            <br />
            {Loading ? (
              <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
                disabled
              >
                <i className="fa fa-refresh fa-spin fa-fw" /> Logging in...
              </button>
            ) : (
              <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
                onClick={handleSubmit}
              >
                Login
              </button>
            )}
            <div className="input-group">
              <Link to="/forget-password">Forgot Password?</Link>
            </div>
            <div className="input-group">
              <Link to="/dashboard">Back</Link>
            </div>
          </form>
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  .input-group a {
    margin-top: 10px;
  }
  .loginwrapper {
    margin-top: 40px;
    margin-bottom: 80px;
  }

  .form-signin {
    max-width: 400px;
    padding: 15px 35px 45px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    font-size: 16px !important;
  }
  .form-signin .form-signin-heading {
    margin-bottom: 30px;
  }
  .btn-block {
    width: 100% !important;
    display: block !important;
  }
  .form-signin .form-control {
    position: relative;
    height: auto;
    padding: 7px !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }

  .form-signin button[type="submit"] {
    height: 36px !important;
    font-size: 16px !important;
    padding: 0;
    outline: none;
  }

  /*-----------------------------------------------
	submit button
-----------------------------------------------*/
  button[type="submit"],
  .outline-none {
    outline: none !important;
  }

  /*==================================================
 * shadow-1
 * ===============================================*/
  .shadow-1 {
    -webkit-box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    -moz-box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
    box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
  }

  /* author */
  .author {
    float: left;
    font-family: "Maven Pro", sans-serif;
    font-size: 18px;
    margin: 15px 20px 30px;
  }
`;

export default Login;
