import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import Loader from "react-loader";
import Frontend_Navbar from "../Page.components/Frontend_Navbar";
import Frontend_Footer from "../Page.components/Frontend_Footer";
import { Carousel } from "react-bootstrap";
import { ViewCarouselSharp } from "@material-ui/icons";
import {
  Municipality_Context,
  Tost_Context,
  Right_indicator_id_Context,
} from "../Utilities/Store";
import httpBrowsing_frontend from "../Utilities/httpBrowsing_frontend";
const Frontend_landing_page = () => {
  const [Tost, setTost] = useContext(Tost_Context);
  const [cover_image, setCover_image] = useState("");
  const [Publication_data, setPublication_data] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [Municipality, setMunicipality] = useContext(Municipality_Context);
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  useEffect(() => {
    httpBrowsing_frontend
      .get(`/frontend/cover-images/`)
      .then((res) => {
        setCover_image(res.data.data[0].image);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);
  useEffect(() => {
    httpBrowsing_frontend
      .get(`/frontend/publications/`)
      .then((res) => {
        setPublication_data(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });

    httpBrowsing_frontend
      .get(`/frontend/navbar/${Tost ? "eng" : "nep"}/`)
      .then((res) => {
        setRight_indicator_id(res.data.data[0].component[0].indicator[0].id);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [Tost, setRight_indicator_id]);

  return (
    <React.Fragment>
      <Wrapper>
        <div className="container-fluid">
          <Frontend_Navbar />

          {Loading ? (
            <Loader />
          ) : (
            <div className="image-section">
              {Tost ? (
                <div className="module-background-image">
                  <h5>Inside this Portal.</h5>
                  <p>
                    An integrated and data driven human rights information
                    management system developed to track human rights progress
                    from all three tiers of Governments.
                  </p>
                  <a href="#learnaboutSystem">
                    <button
                      id="getStarted"
                      className="btn btn-primary"
                      style={{
                        marginLeft: "20px",
                        marginRight: "20px",
                        fontSize: "15px",
                        backgroundColor: "#06356A",
                        opacity: "1",
                        width: "230px",
                      }}
                      type="submit"
                    >
                      Learn more about the system.
                    </button>
                  </a>
                  <p
                    style={{
                      marginTop: "20px",
                      marginBottom: "10px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    Explore, compare, and download human rights related data.
                    Compare and contrast the progress of various institutions.
                  </p>
                  <a href="#getStarted">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        marginLeft: "20px",
                        width: "230px",
                        marginRight: "20px",
                        backgroundColor: "#06356A",
                        opacity: "1",
                        marginTop: "10px",
                        marginBottom: "20px",
                        fontSize: "15px",
                      }}
                    >
                      Get Started.
                    </button>
                  </a>
                </div>
              ) : (
                <div className="module-background-image">
                  <h5>यस पोर्टल भित्र.</h5>
                  <p>
                    नेपाल सरकारको तीनै तहबाट मानव अधिकार सम्बन्धित सूचकहरु मापन
                    गर्नको लागी विकास गरिएको तथ्याङ्कमा आधारित एकिकृत मानव
                    अधिकार सूचना व्यवस्थापन प्रणाली.
                  </p>
                  <a href="#learnaboutSystem">
                    <button
                      id="target"
                      className="btn btn-primary"
                      style={{
                        marginLeft: "20px",
                        marginRight: "20px",
                        fontSize: "15px",
                        backgroundColor: "#06356A",
                        opacity: "1",
                        width: "230px",
                      }}
                      type="submit"
                    >
                      यस प्रणालीको बारेमा विस्तारमा जान्नुहोस्.
                    </button>
                  </a>
                  <p>
                    मानव अधिकार सम्बन्धी तथ्याङ्क हेर्न, डाउनलोड गर्न र संस्थागत
                    प्रगति मूल्यांकन र तुलना गर्न.
                  </p>
                  <a href="#target">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        marginLeft: "20px",
                        width: "230px",
                        marginRight: "20px",
                        backgroundColor: "#06356A",
                        opacity: "1",
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontSize: "15px",
                      }}
                    >
                      सुरू गर्नुहोस्.
                    </button>
                  </a>
                </div>
              )}

              <img
                src={cover_image}
                alt="cover-image"
                style={{ objectFit: "cover", width: "100%", height: "550px" }}
              />
            </div>
          )}
          {Tost ? (
            <div className="row select-option">
              <div className="col-md-4  col-12  ">
                <div className="data-type-selection">
                  <Link to={{ pathname: "/explore", state: "explore" }}>
                    <div className="image-heading">
                      <img src="/search.svg" alt="search" />
                      <h2>Explore</h2>
                    </div>
                    <p>
                      Powerful visualization of human rights data in choropleth
                      map. Check out the progress in local, district, and
                      provincial level in map.
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-4  col-12 ">
                <div className="data-type-selection">
                  <Link to={{ pathname: "/compare", state: "compare" }}>
                    <div className="image-heading">
                      <img src="/compare-front.svg" alt="compare" />
                      <h2>Compare</h2>
                    </div>
                    <p>
                      Data among local, district, and provincial level
                      governments and see the progress made in fiscal or custom
                      layer and get contrast.
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-4  col-12">
                <div className="data-type-selection">
                  <Link to={{ pathname: "/download", state: "download" }}>
                    <div className="image-heading">
                      <img src="/dnload.svg" alt="download" />
                      <h2>Download</h2>
                    </div>
                    <p>
                      Datasets of various tiers of governments in available
                      formats. The data can also be used for further analysis.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="row select-option">
              <div className="col-md-4  col-12  ">
                <div className="data-type-selection">
                  <Link to={{ pathname: "/explore", state: "explore" }}>
                    <div className="image-heading">
                      <img src="/search.svg" alt="search" />
                      <h2 style={{ fontSize: "20px" }}>एक्सप्लोर गर्नुहोस्.</h2>
                    </div>
                    <p>
                      नक्शामा आधारित मानव अधिकार सम्बन्धित तथ्याङ्क हेर्न।
                      स्थानीयतह, जिल्ला, र प्रदेश स्तरमा भएको प्रगति मूल्यांकन
                      गर्न.
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-4  col-12 ">
                <div className="data-type-selection">
                  <Link to={{ pathname: "/compare", state: "compare" }}>
                    <div className="image-heading">
                      <img src="/compare-front.svg" alt="compare" />
                      <h2 style={{ fontSize: "20px" }}>तुलना गर्नुहोस्.</h2>
                    </div>
                    <p>
                      स्थानीयतह, जिल्ला, र प्रदेश सरकारले आर्थिक वर्षमा गरेको
                      प्रगति हेर्न,तुलना गर्न र इन्फोग्राफिक्समा हेर्न.
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-4  col-12">
                <div className="data-type-selection">
                  <Link to={{ pathname: "/download", state: "download" }}>
                    <div className="image-heading">
                      <img src="/dnload.svg" alt="download" />
                      <h2 style={{ fontSize: "20px" }}>डाउनलोड गर्नुहोस्.</h2>
                    </div>
                    <p>
                      नेपाल सरकारका विभिन्न तहहरूको प्रगति दृश्यमा हेर्न र
                      डाउनलोड गर्न.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <Carousel>
              {Publication_data
                ? Publication_data.map((item) => {
                    return (
                      <Carousel.Item interval={500} key={item.image}>
                        <div
                          style={{
                            height: "50px",
                            backgroundColor: "white",
                            position: "absolute",
                            width: "100vw",
                            zIndex: "1",
                          }}
                        ></div>
                        <div className="row carosel-container">
                          <div
                            id="learnaboutSystem"
                            className="col-md-4 col-sm-12 col-12"
                            style={{ zIndex: "2" }}
                          >
                            <img
                              src={item.image}
                              alt="Image"
                              style={{
                                maxHeight: "400px",
                                paddingBottom: "50px",
                                objectFit: "cover",
                              }}
                              className="img-fluid"
                            />
                          </div>

                          <div className="col-md-8 col-sm-12  col-12">
                            <Carousel.Caption className="carosal-caption-mobile">
                              {Tost ? (
                                <h3>{item.header_en}</h3>
                              ) : (
                                <h3>{item.header_ne}</h3>
                              )}
                              {Tost ? (
                                <p>{item.description_en}</p>
                              ) : (
                                <p>{item.description_ne}</p>
                              )}
                              <div
                                className="dropdown"
                                style={{ marginLeft: "auto" }}
                              >
                                <button
                                  className="btn dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton "
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  style={{ fontWeight: "600" }}
                                >
                                  {Tost ? "DOWNLOAD" : "डाउनलोड गर्नुहोस्."}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    window.open(item.file, "_blank");
                                  }}
                                >
                                  <a className="dropdown-item">
                                    {Tost ? "Download PDF" : "डाउनलोड PDF "}
                                  </a>
                                </div>
                              </div>
                            </Carousel.Caption>
                          </div>
                        </div>
                      </Carousel.Item>
                    );
                  })
                : ""}
            </Carousel>
          </div>
          <div className="row" style={{ paddingTop: "20px" }}>
            <div
              className="col-md-8  col-12"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingTop: "40px",
                paddingBottom: "40px",
              }}
            >
              <img
                src="/gonbw.png"
                id="background-logo"
                alt="background-logo"
                style={{
                  maxHeight: "400px",
                  opacity: "0.10",
                  objectFit: "contain",
                }}
                className="img-fluid"
              />
            </div>
            <div
              className="col-md-4  col-12"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div
                className="gov-logo-address"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {Tost ? (
                  <div
                    style={{
                      textAlign: "right",
                      marginRight: "10px",
                      color: "#4B4B4B",
                      opacity: "0.9",
                    }}
                  >
                    <span id="gov-name"> Government of Nepal</span>
                    <br />
                    <span id="gov-office">
                      {" "}
                      Office of the Prime Minister and Council of Ministers
                    </span>
                    <br />
                    <span id="system-name" style={{ fontWeight: "bold" }}>
                      {" "}
                      Human Rights Information Management System
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "right",
                      marginRight: "10px",
                      color: "#4B4B4B",
                      opacity: "0.9",
                    }}
                  >
                    <span id="gov-name"> नेपाल सरकार</span>
                    <br />
                    <span id="gov-office">
                      {" "}
                      प्रधानमन्त्री तथा मन्त्रिपरिषद्को कार्यालय
                    </span>
                    <br />
                    <span id="system-name" style={{ fontWeight: "bold" }}>
                      {" "}
                      मानव अधिकार सूचना व्यवस्थापन प्रणाली
                    </span>
                  </div>
                )}

                <img
                  src="/gon.png"
                  id="gov-detail-logo"
                  style={{ maxHeight: "80px" }}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>

          <div
            className="row footer"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
              marginRight: "20px",
              marginTop: "20px",
            }}
          >
            <a href="https://www.opmcm.gov.np/" target="_blank">
              OPMCM.GOV.NP <img src="/link-login.svg" alt="link-login" />{" "}
            </a>
            <a href="https://www.mofaga.gov.np/" target="_blank">
              MOFAGA.GOV.NP <img src="/link-login.svg" alt="link-login" />{" "}
            </a>
            <a href="https://www.nhrcnepal.org/" target="_blank">
              NHRCNEPAL.ORG <img src="/link-login.svg" alt="link-login" />{" "}
            </a>
            <a href="http://nepal.gov.np/" target="_blank">
              NEPAL.GOV.NP <img src="/link-login.svg" alt="link-login" />{" "}
            </a>
          </div>
        </div>
      </Wrapper>
    </React.Fragment>
  );
};
const Wrapper = styled.div`
  .carousel-caption {
    position: static !important;
    bottom: 0px !important;
    text-align: left;
    padding-top: 50px;
  }
  .carousel-caption h3 {
    padding-top: 40px;
    color: #06356a;
    font-size: 25px;
    font-weight: bold;
    opacity: 1;
  }
  .carousel-caption p {
    max-width: 500px;
    padding-top: 30px;
    margin-left: 0px;
    margin-right: 0px;
    color: #333333;
    opacity: 0.67;
    font-size: 16px;
    font-weight: 600;
  }
  .row {
    margin: 0;
  }
  .dropdown button {
    padding-left: 0px;
    margin-bottom: 50px;
    color: #06356a;
    opacity: 1;
    font-size: 16px;
    font-weight: 600;
  }
  .carosel-container {
    padding-left: 12% !important;
    padding-right: 12% !important;
  }
  .container-fluid {
    padding: 0;
  }
  .select-option {
    width: 100vw;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .image-heading {
    display: flex;
  }
  .data-type-selection {
    margin-left: 30px;
    margin-right: 30px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 380px;
    height: 100%;
  }
  .image-heading img {
    margin-right: 10px;
    margin-left: 10px;
  }
  .data-type-selection a {
    text-decoration: none;
  }
  .data-type-selection p {
    color: #333333;
    opacity: 0.67;
    margin-left: 50px;
    font-size: 16px;
    font-weight: 600;
  }
  .image-heading h2 {
    font-size: 25px;
    font-weight: bold;
    color: #06356a;
    opacity: 1;
  }
  .data-type-selection:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.1);
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 5px 10px 18px #ddd;
  }
  .slide {
    width: 100vw;
    background-color: #d8d9dd;
  }
  .footer {
    padding-right: 90px;
  }
  .footer a {
    margin-left: 10px;
    margin-bottom: 10px;
    color: #06356a;
    font-weight: 600;
    font-size: 16px;
  }
  .footer a:hover {
    text-decoration: none;
  }
  .carousel-control-prev {
    padding-top: 50px !important;
  }
  .carousel-control-next {
    padding-top: 50px !important;
  }

  .module-background-image {
    position: absolute;

    width: 270px;
    background-color: white;
    top: 25px;
    left: 6%;
    right: 6%;
  }
  .module-background-image h5 {
    color: #06356a;
    opacity: 1;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .module-background-image p {
    color: #22241b;
    opacity: 0.87;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 450;
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .image-section {
    position: relative;
    width: 100%;
    height: 525px;
  }
  p {
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    font-size: 14px;
    text-decoration: none;
  }

  .gov-logo-address {
    margin-right: 90px;
  }
  @media screen and (max-width: 500px) {
    .gov-logo-address {
      margin-right: 0px;
    }
    .footer {
      padding-right: 0px;
    }
    .footer a {
      font-size: 12px;
    }
    #gov-name {
      font-size: small;
    }
    #gov-office {
      font-size: small;
    }
    #system-name {
      font-size: small;
    }
  }
  @media screen and (max-width: 1410px) {
    .gov-logo-address {
      margin-right: 0px;
    }
    .footer {
      padding-right: 0px;
    }
    .footer a {
      font-size: 12px;
    }
  }
`;
export default Frontend_landing_page;
