import React, { useState, useEffect, useContext } from "react";

import Navbar_new from "../Page.components/Navbar_new";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Loader from "react-loader";
import httpBrowsing from "../Utilities/httpBrowsing";
import Table_Indicator from "./Sub-module/Table";
import { useLocalStorage } from "../Utilities/Localstorage";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";

import {
  Button,
  Modal,
  Form,
  Breadcrumb,
  Dropdown,
  DropdownButton,
  Table,
} from "react-bootstrap";

import notification from "../Utilities/Notification";
import Map from "./Sub-module/Map";
import Sidebar from "../Page.components/Sidebar";
import Table_Filter from "../Filters/Table-Filter";
import {
  Tost_Context,
  Indicator_title_Context,
  Indicator_title_eng_Context,
  Right_component_nepali_Context,
  Right_component_english_Context,
  Right_name_nepali_Context,
  Right_name_english_Context,
  Filter_clicked_Context,
  Download_Date_from_context,
  Download_Date_to_context,
  sidebar_Clicked_Context,
  Selected_fiscal_year_Context,
  Province_Context,
  District_Context,
  Municipality_Context,
  Filter_Second_Data_Context,
  Filter_First_Data_Context,
  Right_indicator_id_Context,
  Total_Data_Context,
  Second_Total_Data_Context,
  Compare_Graph_Context,
  Loading_Context,
  Change_Language_Context,
  Indicator_Data_Context,
  Paginate_Data_Context,
  page_size_Context,
  currentPage_Context,
  Table_Loader_Context,
  Edit_Data_Context,
  Add_Data_Context,
  Loading_Language_Context,
  Item_delete_check_Context,
  province1_Context,
  province2_Context,
  bagmati_Context,
  gandaki_Context,
  province5_Context,
  karnali_Context,
  sudurpaschim_Context,
  Edited_Context,
  Prov1_Context,
  Prov2_Context,
  Prov3_Context,
  Prov4_Context,
  Prov5_Context,
  Prov6_Context,
  Prov7_Context,
  Municipality_Data_Context,
  Load_map_Context,
  Province1_total_Context,
  Province2_total_Context,
  Province3_total_Context,
  Province4_total_Context,
  Province5_total_Context,
  Province6_total_Context,
  Province7_total_Context,
  Legend_array_Context,
  Selected_first_Province_Context,
  Selected_first_District_Context,
  Selected_first_Municipality_Context,
  Select_Province_dist_muni_map_Context,
  Filter_compare_graph_Context,
  Table_Header_Context,
  Filter_Data_context,
} from "../Utilities/Store";
import Graph from "./Sub-module/Chart";
import Graph_Filter from "../Filters/Graph-Filter";
import Map_Filter from "../Filters/Map-filter";
const Component_Display = (props) => {
  const [Filtering, setFiltering] = useState(false);
  const [Filter_Data, setFilter_Data] = useContext(Filter_Data_context); //when 1st filter of selector buttom is clicked and data is entered the details is stored here.
  const [Filter_compare_graph, setFilter_compare_graph] = useContext(
    Filter_compare_graph_Context
  );
  const [Load_map, setLoad_map] = useContext(Load_map_Context);
  const [Municipality_Data, setMunicipality_Data] = useContext(
    Municipality_Data_Context
  );
  const [
    Select_Province_dist_muni_map,
    setSelect_Province_dist_muni_map,
  ] = useContext(Select_Province_dist_muni_map_Context);
  const [Legend_array, setLegend_array] = useContext(Legend_array_Context);
  const [province1, setProvince1] = useContext(province1_Context);
  const [province2, setProvince2] = useContext(province2_Context);
  const [bagmati, setBagmati] = useContext(bagmati_Context);
  const [gandaki, setGandaki] = useContext(gandaki_Context);
  const [province5, setProvince5] = useContext(province5_Context);
  const [karnali, setKarnali] = useContext(karnali_Context);
  const [sudurpaschim, setSudurpaschim] = useContext(sudurpaschim_Context);
  const [Edited, setEdited] = useContext(Edited_Context);
  const [Loading_Language, setLoading_Language] = useContext(
    Loading_Language_Context
  ); //used to disable language switch untill response is obtained
  const [Table_Loader, setTable_Loader] = useContext(Table_Loader_Context);
  const [Indicator_Data, setIndicator_Data] = useContext(
    Indicator_Data_Context
  );
  const [Item_delete_check, setItem_delete_check] = useContext(
    Item_delete_check_Context
  );
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Edit_Data, SetEdit_Data] = useContext(Edit_Data_Context); //when set true a model displays to edit the data
  const [Add_Data, setAdd_Data] = useContext(Add_Data_Context); //when set true a model displays to give entry on data entry form
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title
  const [Paginate_Data, setPaginate_Data] = useContext(Paginate_Data_Context);
  const [Total_Data, setTotal_Data] = useContext(Total_Data_Context);
  const [Tost, setTost] = useContext(Tost_Context); //used to store boolean value during language switch
  const [Sidebar_Data, setSidebar_Data] = useState([]); //all fetched sidebar data is stored here
  // const [Test, setTest] = useState(false); //testing the function on click
  const [Province, setProvince] = useContext(Province_Context);
  const [District, setDistrict] = useContext(District_Context);
  const [Municipality, setMunicipality] = useContext(Municipality_Context);
  const [Loading_Display, setLoading_Display] = useState(true);
  const [Loading, setLoading] = useContext(Loading_Context);
  const [RightID, setRightID] = useState("");
  const [page_size, setPage_size] = useContext(page_size_Context);
  const [currentPage, setCurrentPage] = useContext(currentPage_Context);
  const [sidebar_Clicked, setSidebar_Clicked] = useContext(
    sidebar_Clicked_Context
  ); //first level sidebar clicked
  const [Right_component_id, setRight_component_id] = useState("");

  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );

  const [Compare, setCompare] = useState(false);

  const [Right_name, setRight_name] = useState("");
  const [Filter_clicked, setFilter_clicked] = useContext(
    Filter_clicked_Context
  ); //is set true when filter button is clicked
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Second_Total_Data, setSecond_Total_Data] = useContext(
    Second_Total_Data_Context
  ); //stores the comparison second entered data
  const [Compare_Graph, setCompare_Graph] = useContext(Compare_Graph_Context); //Is set true when user click  second buttom to compare the graph
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [Filter_First_Data, setFilter_First_Data] = useContext(
    Filter_First_Data_Context
  );
  const [Filter_Second_Data, setFilter_Second_Data] = useContext(
    Filter_Second_Data_Context
  );
  const [Table_Header, setTable_Header] = useContext(Table_Header_Context); //used to store header of the table
  const [Change_Language, setChange_Language] = useContext(
    Change_Language_Context
  ); //used to change the toggle button for language
  const [Prov1, setProv1] = useContext(Prov1_Context);
  const [Prov2, setProv2] = useContext(Prov2_Context);
  const [Prov3, setProv3] = useContext(Prov3_Context);
  const [Prov4, setProv4] = useContext(Prov4_Context);
  const [Prov5, setProv5] = useContext(Prov5_Context);
  const [Prov6, setProv6] = useContext(Prov6_Context);
  const [Prov7, setProv7] = useContext(Prov7_Context);
  const [Province1_total, setProvince1_total] = useContext(
    Province1_total_Context
  );
  const [Province2_total, setProvince2_total] = useContext(
    Province2_total_Context
  );
  const [Province3_total, setProvince3_total] = useContext(
    Province3_total_Context
  );
  const [Province4_total, setProvince4_total] = useContext(
    Province4_total_Context
  );
  const [Province5_total, setProvince5_total] = useContext(
    Province5_total_Context
  );
  const [Province6_total, setProvince6_total] = useContext(
    Province6_total_Context
  );
  const [Province7_total, setProvince7_total] = useContext(
    Province7_total_Context
  );
  const [Selected_first_Province, setSelected_first_Province] = useContext(
    Selected_first_Province_Context
  );
  const [Selected_first_District, setSelected_first_District] = useContext(
    Selected_first_District_Context
  );
  const [
    Selected_first_Municipality,
    setSelected_first_Municipality,
  ] = useContext(Selected_first_Municipality_Context);
  const [Select_Data_type, setSelect_Data_type] = useState("TABLE"); //used to store selected data type table,chart or map
  const [Select_Data_type_image, setSelect_Data_type_image] = useState(
    "/awesome-table.svg"
  ); //used to store image of selected data type table,chart or map
  const handleRight = (id, name) => {
    setRightID(id);
    setRight_name(name);
    // setTest(true);
  };
  const [Previous_Page_No, setPrevious_Page_No] = useState(0); //used to store initial page number
  let Selecting_First_District = District.filter(
    (item, i) => item.province === Number(Filter_First_Data.province)
  );
  let Selecting_First_Municipality = Municipality.filter(
    (item) => item.district === Number(Filter_First_Data.district)
  );
  let Selecting_Second_District = District.filter(
    (item, i) => item.province === Number(Filter_Second_Data.province)
  );
  let Selecting_Second_Municipality = Municipality.filter(
    (item) => item.district === Number(Filter_Second_Data.district)
  );

  let Right_components = [];
  Right_components = Sidebar_Data.filter((item, i) => item.id === RightID).map(
    (item) => item.component
  );
  let Indicators = [];
  if (Right_components.length) {
    Indicators = Right_components[0]
      .filter((item, i) => item.id === Right_component_id)
      .map((item) => item.indicator);
  }
  const handleChangeFirstProvince_Compare = (id, name, nep_name) => {
    setFilter_First_Data({
      ...Filter_First_Data,
      province: id,
      district: "",
      municipality: "",
      province_name: name,
      district_name: "",
      municipality_name: "",
      province_name_nep: nep_name,
      district_name_nep: "",
      municipality_name_nep: "",
    });
  };

  const handleChangeFirstDistrict_Compare = (id, name, nep_name) => {
    setFilter_First_Data({
      ...Filter_First_Data,
      district: id,
      municipality: "",
      district_name: name,
      municipality_name: "",

      district_name_nep: nep_name,
      municipality_name_nep: "",
    });
  };
  const handleChangeFirstMunicipality_Compare = (id, name, nep_name) => {
    setFilter_First_Data({
      ...Filter_First_Data,
      municipality: id,
      municipality_name: name,

      municipality_name_nep: nep_name,
    });
  };
  const handleChangeSecondProvince_Compare = (id, name, nep_name) => {
    setFilter_Second_Data({
      ...Filter_Second_Data,
      province: id,
      district: "",
      municipality: "",
      province_name: name,
      district_name: "",
      municipality_name: "",
      province_name_nep: nep_name,
      district_name_nep: "",
      municipality_name_nep: "",
    });
  };

  const handleChangeSecondDistrict_Compare = (id, name, nep_name) => {
    setFilter_Second_Data({
      ...Filter_Second_Data,
      district: id,
      municipality: "",
      district_name: name,
      municipality_name: "",

      district_name_nep: nep_name,
      municipality_name_nep: "",
    });
  };
  const handleChangeSecondMunicipality_Compare = (id, name, nep_name) => {
    setFilter_Second_Data({
      ...Filter_Second_Data,
      municipality: id,
      municipality_name: name,

      municipality_name_nep: nep_name,
    });
  };
  const changeCurrentPage = (numPage) => {
    setTable_Loader(true);
    setCurrentPage(numPage);

    setPrevious_Page_No(currentPage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFiltering(true);
    httpBrowsing
      .get(
        `/eng/indicator-data/graph?indicator=${Right_indicator_id}&province=${Filter_First_Data.province}&district=${Filter_First_Data.district}&municipality=${Filter_First_Data.municipality}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
      )
      .then((res) => {
        setTotal_Data(res.data.data);
        setCompare(false);
        setFilter_compare_graph(true);
        setFiltering(false);
      })
      // setShow(false);
      // setFilter_Data({
      //   province: "",
      //   district: "",
      //   municipality: "",
      // });

      .catch((err) => {
        console.log("what is error>>>", err.response);

        // setFilter_Data({
        //   province: "",
        //   district: "",
        //   municipality: "",
        // });
      });
  };
  const handlePageSize = (e) => {
    if (Paginate_Data.next === null) {
      setPage_size(page_size);
    } else {
      setPage_size(e.target.value);
    }

    setTable_Loader(true);
    httpBrowsing
      .get(
        `/${
          Tost ? "eng" : "nep"
        }/indicator-data/?indicator=${Right_indicator_id}&page=${currentPage}&page_size=${page_size}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setIndicator_title(res.data.indicator_title_ne);
        setIndicator_title_eng(res.data.indicator_title_en);
        setPaginate_Data(res.data);
        setLoading(false);
        setTable_Loader(false);
        setLoading_Language(false);
        setRight_name_english(res.data.right_en);
        setRight_name_nepali(res.data.right_ne);
        setRight_component_english(res.data.right_component_en);
        setRight_component_nepali(res.data.right_component_ne);
      })
      .catch((err) => {
        console.log("This is final error>>>", err.response);
      });
  };
  // useEffect(() => {
  //   httpBrowsing
  //     .get(
  //       `/eng/indicator-data/graph?indicator=${Right_indicator_id}&province=${Filter_Data.province}&district=${Filter_Data.district}&municipality=${Filter_Data.municipality}`
  //     )
  //     .then((res) => {
  //       setTotal_Data(res.data.data);

  //       setRight_name_english(res.data.right_en);
  //       setRight_name_nepali(res.data.right_ne);
  //       setRight_component_english(res.data.right_component_en);
  //       setRight_component_nepali(res.data.right_component_ne);
  //       setIndicator_title_eng(res.data.indicator_title_en);
  //       setIndicator_title(res.data.indicator_title_ne);
  //       setTable_Loader(false);
  //     })
  //     .catch((err) => {
  //       console.log("Err", err.response);
  //     });
  // }, [Table_Loader === true]);
  useEffect(() => {
    httpBrowsing
      .get(
        `/eng/indicator-data/graph?indicator=${Right_indicator_id}&province=${Filter_Second_Data.province}&district=${Filter_Second_Data.district}&municipality=${Filter_Second_Data.municipality}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
      )
      .then((res) => {
        setSecond_Total_Data(res.data.data);
      })
      .catch((err) => {
        console.log("what is error>>>", err.response);
      });
  }, [Total_Data]);

  useEffect(() => {
    httpBrowsing
      .get(
        `/${Tost ? "eng" : "nep"}/indicators/${Right_indicator_id}/get-fields/`
      )
      .then((res) => {
        setTable_Header(res.data);
      })
      .catch((err) => {
        console.log("this is error>>>", err);
      });

    httpBrowsing
      .get(
        `/${
          Tost ? "eng" : "nep"
        }/indicator-data/?indicator=${Right_indicator_id}&page=${currentPage}&page_size=${page_size}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}`
      )
      .then((res) => {
        setIndicator_Data(res.data.data);
        setIndicator_title(res.data.indicator_title_ne);
        setIndicator_title_eng(res.data.indicator_title_en);
        setPaginate_Data(res.data);
        setLoading(false);
        setTable_Loader(false);
        setLoading_Language(false);
        setRight_name_english(res.data.right_en);
        setRight_name_nepali(res.data.right_ne);
        setRight_component_english(res.data.right_component_en);
        setRight_component_nepali(res.data.right_component_ne);
      })
      .catch((err) => {
        console.log("This is final error>>>", err.response);
      });
  }, [Tost, currentPage, Right_indicator_id, Edited, Item_delete_check]);

  useEffect(() => {
    const Route_Link = () => {
      if (!Right_name_nepali) {
        props.history.push("/अपराध पीडितको अधिकार");
      } else {
        if (Tost) {
          props.history.push(`/${Right_name_english}`);
        } else {
          props.history.push(`/${Right_name_nepali}`);
        }
      }
    };
    Route_Link();
  }, [Tost]);
  useEffect(() => {
    setLoad_map(true);
    httpBrowsing
      .get(
        `/nep/indicator-data/map/?indicator=${Right_indicator_id}&start=${Download_Date_from}&end=${Download_Date_to}&fiscal_year=${Selected_fiscal_year}&province=${Selected_first_Province.prov_id}&district=${Selected_first_District.dist_id}&municipality=${Selected_first_Municipality.muni_id}`
      )
      .then((res) => {
        if (Selected_first_Province.prov_id) {
          let Province_total_array = [];

          let Legend_array = [];
          setSelect_Province_dist_muni_map(res.data);

          Province_total_array = res.data.map((item) => item.total);
          Province_total_array.sort((a, b) => b - a);

          Legend_array = [
            0,
            Math.round(0 + Province_total_array[0] / 5),
            Math.round((0 + Province_total_array[0] / 5) * 2),
            Math.round((0 + Province_total_array[0] / 5) * 3),
            Math.round(Province_total_array[0]),
          ];
          setLegend_array(Legend_array);
          // setMap_filter_clicked(!map_filter_clicked);
          setLoad_map(false);
        } else {
          let Province_total_array = [];

          let Legend_array = [];
          setProvince1_total(res.data[0].total);
          setProvince2_total(res.data[1].total);
          setProvince3_total(res.data[2].total);
          setProvince4_total(res.data[3].total);
          setProvince5_total(res.data[4].total);
          setProvince6_total(res.data[5].total);
          setProvince7_total(res.data[6].total);

          Province_total_array.push(res.data[0].total);
          Province_total_array.push(res.data[1].total);
          Province_total_array.push(res.data[2].total);
          Province_total_array.push(res.data[3].total);
          Province_total_array.push(res.data[4].total);
          Province_total_array.push(res.data[5].total);
          Province_total_array.push(res.data[6].total);
          Province_total_array.sort((a, b) => b - a);

          Legend_array = [
            0,
            Math.round(0 + Province_total_array[0] / 5),
            Math.round((0 + Province_total_array[0] / 5) * 2),
            Math.round((0 + Province_total_array[0] / 5) * 3),
            Math.round(Province_total_array[0]),
          ];
          setLegend_array(Legend_array);
          setLoad_map(false);
        }
      })
      .catch((err) => {
        console.log("This is error>>>", err.response);
      });
  }, [Right_indicator_id]);
  useEffect(() => {
    setTable_Loader(true);
    httpBrowsing
      .get(
        `/eng/indicator-data/graph?indicator=${Right_indicator_id}&province=${Filter_Data.province}&district=${Filter_Data.district}&municipality=${Filter_Data.municipality}`
      )
      .then((res) => {
        setTotal_Data(res.data.data);

        setRight_name_english(res.data.right_en);
        setRight_name_nepali(res.data.right_ne);
        setRight_component_english(res.data.right_component_en);
        setRight_component_nepali(res.data.right_component_ne);
        setIndicator_title_eng(res.data.indicator_title_en);
        setIndicator_title(res.data.indicator_title_ne);
        setTable_Loader(false);
      })
      .catch((err) => {
        console.log("Err", err.response);
      });
  }, [Select_Data_type]);
  return (
    <React.Fragment>
      <Tester>
        <div
          className={sidebar_Clicked ? "d-flex toggled" : "d-flex"}
          id="wrapper"
        >
          {/* Sidebar */}

          {/* Page Content */}
          <div id="page-content-wrapper">
            <Navbar_new data={props} />
            {Loading ? (
              <Loader />
            ) : (
              <div className="container-fluid main-content">
                <div className={sidebar_Clicked ? "flexible-sidebar" : ""}>
                  <Sidebar />
                </div>
                <div
                  className={
                    sidebar_Clicked
                      ? "main-body flexible-content"
                      : "main-body flexible-content-closed"
                  }
                >
                  {Tost ? (
                    <Breadcrumb className="bread-crumb">
                      <Breadcrumb.Item>{Right_name_english}</Breadcrumb.Item>

                      <Breadcrumb.Item>
                        {Right_component_english}
                      </Breadcrumb.Item>

                      <Breadcrumb.Item>{Indicator_title_eng}</Breadcrumb.Item>
                    </Breadcrumb>
                  ) : (
                    <Breadcrumb className="bread-crumb">
                      <Breadcrumb.Item>{Right_name_nepali}</Breadcrumb.Item>

                      <Breadcrumb.Item>
                        {Right_component_nepali}
                      </Breadcrumb.Item>

                      <Breadcrumb.Item>{Indicator_title}</Breadcrumb.Item>
                    </Breadcrumb>
                  )}

                  <div className="filter-div">
                    {Tost ? (
                      <h1>{Indicator_title_eng}</h1>
                    ) : (
                      <h1>{Indicator_title}</h1>
                    )}
                    <div className="data-type-select-comparison">
                      {Select_Data_type === "GRAPH" ? (
                        <Button
                          variant="primary"
                          className="compare-button"
                          onClick={() => {
                            setCompare(true);
                            setCompare_Graph(true);
                          }}
                        >
                          <img src="/compare.svg" alt="compare" />
                          {""} {Tost ? "COMPARE" : "तुलना गर्नुहोस्"}
                        </Button>
                      ) : (
                        ""
                      )}

                      <DropdownButton
                        id="dropdown-basic-button"
                        className={
                          sidebar_Clicked
                            ? "view-data data-type-media-query"
                            : "view-data"
                        }
                        style={{ backgroundColor: "red !important" }}
                        title={[
                          <img src={Select_Data_type_image} alt="table" />,
                          <span>{Select_Data_type}</span>,
                        ]}
                      >
                        {Select_Data_type === "TABLE" ? (
                          ""
                        ) : (
                          <Dropdown.Item
                            value="TABLE"
                            onClick={() => {
                              setSelect_Data_type("TABLE");
                              setSelect_Data_type_image("/awesome-table.svg");

                              setFilter_clicked(false);
                            }}
                          >
                            <img src="/awesome-table.svg" alt="table" />
                            TABLE
                          </Dropdown.Item>
                        )}
                        {/* {Select_Data_type === "तालिका" ||
                        Select_Data_type === "TABLE" ? (
                          ""
                        ) : (
                          <Dropdown.Item
                            value="तालिका"
                            onClick={() => {
                              setSelect_Data_type("तालिका");
                              setSelect_Data_type_image("/awesome-table.svg");

                              setFilter_clicked(false);
                            }}
                          >
                            <img src="/awesome-table.svg" alt="table" /> TABLE
                          </Dropdown.Item>
                        )} */}

                        {Select_Data_type === "GRAPH" ? (
                          ""
                        ) : (
                          <Dropdown.Item
                            onClick={() => {
                              setSelect_Data_type("GRAPH");
                              setSelect_Data_type_image("/open-bar-chart.svg");
                              setTable_Loader(true);
                              setFilter_clicked(false);
                            }}
                          >
                            <img src="/open-bar-chart.svg" alt="chart" /> GRAPH
                          </Dropdown.Item>
                        )}
                        {Select_Data_type === "MAP" ? (
                          ""
                        ) : (
                          <Dropdown.Item
                            onClick={() => {
                              setSelect_Data_type("MAP");
                              setSelect_Data_type_image("/metro-earth.svg");

                              setFilter_clicked(false);
                            }}
                          >
                            <img src="/metro-earth.svg" alt="map" /> MAP
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </div>
                  </div>

                  {Select_Data_type === "TABLE" ? <Table_Filter /> : ""}
                  {Select_Data_type === "GRAPH" ? <Graph_Filter /> : ""}
                  {Select_Data_type === "MAP" ? <Map_Filter /> : ""}
                  {Table_Loader ? (
                    <Loader />
                  ) : (
                    <>
                      <div
                        className={
                          sidebar_Clicked
                            ? "test-table"
                            : "test-table full-screen"
                        }
                        id="map-capture"
                      >
                        {Select_Data_type === "TABLE" ? (
                          <Table_Indicator />
                        ) : (
                          ""
                        )}
                        {Select_Data_type === "GRAPH" ? <Graph /> : ""}
                        {Load_map ? (
                          <Loader />
                        ) : Select_Data_type === "MAP" ? (
                          <Map />
                        ) : (
                          ""
                        )}
                      </div>

                      {Select_Data_type === "TABLE" ? (
                        Indicator_Data.length === 0 ? (
                          ""
                        ) : Paginate_Data.count > 20 ? (
                          <div className="row" style={{ marginTop: "10px" }}>
                            <div className="pagination">
                              <form className="row-data">
                                <label className="show" htmlFor="Show">
                                  <b>Show Data:</b>
                                </label>
                                <select
                                  value={page_size}
                                  onChange={handlePageSize}
                                >
                                  <option value="20">20</option>
                                  <option value="40">40</option>
                                  <option value="60">60</option>
                                  <option value="80">80</option>
                                  <option value="100">100</option>
                                </select>
                              </form>
                            </div>
                            <div className="page">
                              <Pagination
                                currentPage={currentPage}
                                totalSize={Paginate_Data.count}
                                sizePerPage={page_size}
                                changeCurrentPage={changeCurrentPage}
                                theme="bootstrap"
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* /#page-content-wrapper */}
        </div>
        <Modal
          show={Compare}
          onHide={() => {
            setCompare(false);
            setFilter_First_Data({
              province: "",
              municipality: "",
              district: "",
              province_name: "",
              district_name: "",
              municipality_name: "",
              province_name_nep: "",
              district_name_nep: "",
              municipality_name_nep: "",
            });
            setFilter_Second_Data({
              province: "",
              municipality: "",
              district: "",
              province_name: "",
              district_name: "",
              municipality_name: "",
              province_name_nep: "",
              district_name_nep: "",
              municipality_name_nep: "",
            });
            setCompare(false);
            setCompare_Graph(false);
            // setFiltered_compared(false);
          }}
          size="lg"
        >
          <Modal.Body>
            <div style={{ display: "flex" }}>
              {Tost ? "COMPARE" : "तुलना गर्नुहोस्"}
              <span
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={() => {
                  setFilter_First_Data({
                    province: "",
                    municipality: "",
                    district: "",
                    province_name: "",
                    district_name: "",
                    municipality_name: "",
                    province_name_nep: "",
                    district_name_nep: "",
                    municipality_name_nep: "",
                  });
                  setFilter_Second_Data({
                    province: "",
                    municipality: "",
                    district: "",
                    province_name: "",
                    district_name: "",
                    municipality_name: "",
                    province_name_nep: "",
                    district_name_nep: "",
                    municipality_name_nep: "",
                  });
                }}
              >
                <img
                  src={
                    Filter_Second_Data.province_name ||
                    Filter_First_Data.province_name
                      ? "material-layers-clearly.svg"
                      : "/material-layers-clear.svg"
                  }
                  alt="clear all"
                />{" "}
                CLEAR ALL
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginRight: "60px", marginTop: "10px" }}>
                <p>{Tost ? "Pick Province" : "प्रदेश छान्नुहोस् "}</p>
                <p style={{ marginTop: "25px" }}>
                  {Tost ? "Pick District" : "जिल्ला छान्नुहोस्"}
                </p>
                <p style={{ marginTop: "25px" }}>
                  {Tost ? "Pick Municipality" : "पालिका छान्नुहोस्"}
                </p>
              </div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      backgroundColor: Filter_First_Data.province_name
                        ? "#405467"
                        : "white",
                      color: Filter_First_Data.province_name
                        ? "white"
                        : "#405467",
                      border: "2px solid",
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    {Tost
                      ? Filter_First_Data.province_name
                        ? Filter_First_Data.province_name
                        : "Select Province"
                      : Filter_First_Data.province_name_nep
                      ? Filter_First_Data.province_name_nep
                      : "प्रदेश छान्नुहोस् "}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Province.map((item, i) => (
                      <Dropdown.Item
                        key={i}
                        value={item.id}
                        onClick={() =>
                          handleChangeFirstProvince_Compare(
                            item.id,
                            item.title_en,
                            item.title_ne
                          )
                        }
                      >
                        {Tost ? item.title_en : item.title_ne}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {Filter_First_Data.province_name ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_First_Data.district_name
                          ? "#405467"
                          : "white",
                        color: Filter_First_Data.district_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {Tost
                        ? Filter_First_Data.district_name
                          ? Filter_First_Data.district_name
                          : "Select District"
                        : Filter_First_Data.district_name_nep
                        ? Filter_First_Data.district_name_nep
                        : "जिल्ला छान्नुहोस्"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_First_District.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeFirstDistrict_Compare(
                              item.id,
                              item.title_en,
                              item.title_ne
                            )
                          }
                        >
                          {Tost ? item.title_en : item.title_ne}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_First_Data.district_name
                          ? "#405467"
                          : "white",
                        color: Filter_First_Data.district_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      disabled
                    >
                      {Tost
                        ? Filter_First_Data.district_name
                          ? Filter_First_Data.district_name
                          : "Select District"
                        : Filter_First_Data.district_name_nep
                        ? Filter_First_Data.district_name_nep
                        : "जिल्ला छान्नुहोस्"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_First_District.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeFirstDistrict_Compare(
                              item.id,
                              item.title_en,
                              item.title_ne
                            )
                          }
                        >
                          {Tost ? item.title_en : item.title_ne}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {Filter_First_Data.district_name ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_First_Data.municipality_name
                          ? "#405467"
                          : "white",
                        color: Filter_First_Data.municipality_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {Tost
                        ? Filter_First_Data.municipality_name
                          ? Filter_First_Data.municipality_name
                          : "Select Municipality"
                        : Filter_First_Data.municipality_name_nep
                        ? Filter_First_Data.municipality_name_nep
                        : "पालिका छान्नुहोस्"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_First_Municipality.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeFirstMunicipality_Compare(
                              item.id,
                              item.title_en,
                              item.title_ne
                            )
                          }
                        >
                          {Tost ? item.title_en : item.title_ne}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_First_Data.municipality_name
                          ? "#405467"
                          : "white",
                        color: Filter_First_Data.municipality_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      disabled
                    >
                      {Tost
                        ? Filter_First_Data.municipality_name
                          ? Filter_First_Data.municipality_name
                          : "Select Municipality"
                        : Filter_First_Data.municipality_name_nep
                        ? Filter_First_Data.municipality_name_nep
                        : "पालिका छान्नुहोस्"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_First_Municipality.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeFirstMunicipality_Compare(
                              item.id,
                              item.title_en,
                              item.title_ne
                            )
                          }
                        >
                          {Tost ? item.title_en : item.title_ne}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              <div
                style={{
                  border: "1px solid #00000029",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              ></div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      backgroundColor: Filter_Second_Data.province_name
                        ? "#405467"
                        : "white",
                      color: Filter_Second_Data.province_name
                        ? "white"
                        : "#405467",
                      border: "2px solid",
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    {Tost
                      ? Filter_Second_Data.province_name
                        ? Filter_Second_Data.province_name
                        : "Select Province"
                      : Filter_Second_Data.province_name_nep
                      ? Filter_Second_Data.province_name_nep
                      : "प्रदेश छान्नुहोस् "}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Province.map((item, i) => (
                      <Dropdown.Item
                        key={i}
                        value={item.id}
                        onClick={() =>
                          handleChangeSecondProvince_Compare(
                            item.id,
                            item.title_en,
                            item.title_ne
                          )
                        }
                      >
                        {Tost ? item.title_en : item.title_ne}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {Filter_Second_Data.province_name ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_Second_Data.district_name
                          ? "#405467"
                          : "white",
                        color: Filter_Second_Data.district_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {Tost
                        ? Filter_Second_Data.district_name
                          ? Filter_Second_Data.district_name
                          : "Select District"
                        : Filter_Second_Data.district_name_nep
                        ? Filter_Second_Data.district_name_nep
                        : "जिल्ला छान्नुहोस्"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_Second_District.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeSecondDistrict_Compare(
                              item.id,
                              item.title_en,
                              item.title_ne
                            )
                          }
                        >
                          {Tost ? item.title_en : item.title_ne}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_Second_Data.district_name
                          ? "#405467"
                          : "white",
                        color: Filter_Second_Data.district_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      disabled
                    >
                      {Tost
                        ? Filter_Second_Data.district_name
                          ? Filter_Second_Data.district_name
                          : "Select District"
                        : Filter_Second_Data.district_name_nep
                        ? Filter_Second_Data.district_name_nep
                        : "जिल्ला छान्नुहोस्"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_Second_District.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeSecondDistrict_Compare(
                              item.id,
                              item.title_en,
                              item.title_ne
                            )
                          }
                        >
                          {Tost ? item.title_en : item.title_ne}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {Filter_Second_Data.district_name ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_Second_Data.municipality_name
                          ? "#405467"
                          : "white",
                        color: Filter_Second_Data.municipality_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {Tost
                        ? Filter_Second_Data.municipality_name
                          ? Filter_Second_Data.municipality_name
                          : "Select Municipality"
                        : Filter_Second_Data.municipality_name_nep
                        ? Filter_Second_Data.municipality_name_nep
                        : "पालिका छान्नुहोस्"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_Second_Municipality.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeSecondMunicipality_Compare(
                              item.id,
                              item.title_en,
                              item.title_ne
                            )
                          }
                        >
                          {Tost ? item.title_en : item.title_ne}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_Second_Data.municipality_name
                          ? "#405467"
                          : "white",
                        color: Filter_Second_Data.municipality_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      disabled
                    >
                      {Tost
                        ? Filter_Second_Data.municipality_name
                          ? Filter_Second_Data.municipality_name
                          : "Select Municipality"
                        : Filter_Second_Data.municipality_name_nep
                        ? Filter_Second_Data.municipality_name_nep
                        : "पालिका छान्नुहोस्"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_Second_Municipality.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeSecondMunicipality_Compare(
                              item.id,
                              item.title_en,
                              item.title_ne
                            )
                          }
                        >
                          {Tost ? item.title_en : item.title_ne}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setCompare(false);
                setCompare_Graph(false);
              }}
              style={{
                backgroundColor: "#d9dde1",
                color: "#405467",
                opacity: "0.37",
                border: "1px solid",
                borderRadius: "10px ",
              }}
            >
              CANCEL
            </Button>
            {Filtering ? (
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled
                style={{
                  backgroundColor: "white",
                  color: "#405467",
                  borderRadius: "10px ",
                  border: "1px solid",
                }}
              >
                Comparing...
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={handleSubmit}
                style={{
                  backgroundColor: "white",
                  color: "#405467",
                  borderRadius: "10px ",
                  border: "1px solid",
                }}
              >
                DONE
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        {/* <Modal show={Compare} onHide={() => setCompare(false)} size="lg">
          <Modal.Body>
            <div style={{ display: "flex" }}>
              <p>COMPARE</p>
              <span
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={() => {
                  setFilter_First_Data({
                    province: "",
                    municipality: "",
                    district: "",
                    province_name: "",
                    district_name: "",
                    municipality_name: "",
                  });
                  setFilter_Second_Data({
                    province: "",
                    municipality: "",
                    district: "",
                    province_name: "",
                    district_name: "",
                    municipality_name: "",
                  });
                }}
              >
                <img
                  src={
                    Filter_Second_Data.province_name ||
                    Filter_First_Data.province_name
                      ? "material-layers-clearly.svg"
                      : "/material-layers-clear.svg"
                  }
                  alt="clear all"
                />{" "}
                CLEAR ALL
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginRight: "60px", marginTop: "10px" }}>
                <p>Pick Province</p>
                <p>Pick District</p>
                <p>Pick Municipality</p>
              </div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      backgroundColor: Filter_First_Data.province_name
                        ? "#405467"
                        : "white",
                      color: Filter_First_Data.province_name
                        ? "white"
                        : "#405467",
                      border: "2px solid",
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    {Filter_First_Data.province_name
                      ? Filter_First_Data.province_name
                      : "Select Province"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Province.map((item, i) => (
                      <Dropdown.Item
                        key={i}
                        value={item.id}
                        onClick={() =>
                          handleChangeFirstProvince_Compare(
                            item.id,
                            item.title_en
                          )
                        }
                      >
                        {item.title_en}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {Filter_First_Data.province_name ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_First_Data.district_name
                          ? "#405467"
                          : "white",
                        color: Filter_First_Data.district_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {Filter_First_Data.district_name
                        ? Filter_First_Data.district_name
                        : "Select District"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_First_District.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeFirstDistrict_Compare(
                              item.id,
                              item.title_en
                            )
                          }
                        >
                          {item.title_en}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_First_Data.district_name
                          ? "#405467"
                          : "white",
                        color: Filter_First_Data.district_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      disabled
                    >
                      {Filter_First_Data.district_name
                        ? Filter_First_Data.district_name
                        : "Select District"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_First_District.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeFirstDistrict_Compare(
                              item.id,
                              item.title_en
                            )
                          }
                        >
                          {item.title_en}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {Filter_First_Data.district_name ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_First_Data.municipality_name
                          ? "#405467"
                          : "white",
                        color: Filter_First_Data.municipality_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {Filter_First_Data.municipality_name
                        ? Filter_First_Data.municipality_name
                        : "Select Municipality"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_First_Municipality.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeFirstMunicipality_Compare(
                              item.id,
                              item.title_en
                            )
                          }
                        >
                          {item.title_en}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_First_Data.municipality_name
                          ? "#405467"
                          : "white",
                        color: Filter_First_Data.municipality_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      disabled
                    >
                      {Filter_First_Data.municipality_name
                        ? Filter_First_Data.municipality_name
                        : "Select Municipality"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_First_Municipality.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeFirstMunicipality_Compare(
                              item.id,
                              item.title_en
                            )
                          }
                        >
                          {item.title_en}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              <div
                style={{
                  border: "1px solid #00000029",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              ></div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      backgroundColor: Filter_Second_Data.province_name
                        ? "#405467"
                        : "white",
                      color: Filter_Second_Data.province_name
                        ? "white"
                        : "#405467",
                      border: "2px solid",
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    {Filter_Second_Data.province_name
                      ? Filter_Second_Data.province_name
                      : "Select Province"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Province.map((item, i) => (
                      <Dropdown.Item
                        key={i}
                        value={item.id}
                        onClick={() =>
                          handleChangeSecondProvince_Compare(
                            item.id,
                            item.title_en
                          )
                        }
                      >
                        {item.title_en}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {Filter_Second_Data.province_name ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_Second_Data.district_name
                          ? "#405467"
                          : "white",
                        color: Filter_Second_Data.district_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {Filter_Second_Data.district_name
                        ? Filter_Second_Data.district_name
                        : "Select District"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_Second_District.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeSecondDistrict_Compare(
                              item.id,
                              item.title_en
                            )
                          }
                        >
                          {item.title_en}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_Second_Data.district_name
                          ? "#405467"
                          : "white",
                        color: Filter_Second_Data.district_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      disabled
                    >
                      {Filter_Second_Data.district_name
                        ? Filter_Second_Data.district_name
                        : "Select District"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_Second_District.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeSecondDistrict_Compare(
                              item.id,
                              item.title_en
                            )
                          }
                        >
                          {item.title_en}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {Filter_Second_Data.district_name ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_Second_Data.municipality_name
                          ? "#405467"
                          : "white",
                        color: Filter_Second_Data.municipality_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      {Filter_Second_Data.municipality_name
                        ? Filter_Second_Data.municipality_name
                        : "Select Municipality"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_Second_Municipality.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeSecondMunicipality_Compare(
                              item.id,
                              item.title_en
                            )
                          }
                        >
                          {item.title_en}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        backgroundColor: Filter_Second_Data.municipality_name
                          ? "#405467"
                          : "white",
                        color: Filter_Second_Data.municipality_name
                          ? "white"
                          : "#405467",
                        border: "2px solid",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      disabled
                    >
                      {Filter_Second_Data.municipality_name
                        ? Filter_Second_Data.municipality_name
                        : "Select Municipality"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Selecting_Second_Municipality.map((item, i) => (
                        <Dropdown.Item
                          key={i}
                          value={item.id}
                          onClick={() =>
                            handleChangeSecondMunicipality_Compare(
                              item.id,
                              item.title_en
                            )
                          }
                        >
                          {item.title_en}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setCompare(false);
                setCompare_Graph(false);
              }}
              style={{
                backgroundColor: "#d9dde1",
                color: "#405467",
                opacity: "0.37",
                border: "1px solid",
                borderRadius: "10px ",
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              style={{
                backgroundColor: "white",
                color: "#405467",
                borderRadius: "10px ",
                border: "1px solid",
              }}
            >
              DONE
            </Button>
          </Modal.Footer>
        </Modal> */}
      </Tester>
    </React.Fragment>
  );
};
const Tester = styled.div`
  .flexible-content-closed {
    width: 98%;
  }
  .flexible-sidebar-closed {
    width: 2%;
  }
  .main-body {
    background-color: #f9fbfc;
  }
  .flexible-sidebar {
    width: 20%;
  }
  .flexible-content {
    width: 80%;
  }
  .row-data select {
    margin-left: 10px;
  }
  .pagination {
    margin-left: 30px;
  }
  .page {
    margin-left: auto;
  }

  .full-screen {
    width: auto !important;
  }
  @media only screen and (max-width: 1366px) {
    .test-table {
      width: 1050px;
      margin-left: 60px;
    }
    // .data-type-media-query {
    //   margin-right: 40px !important;
    // }
    .react-pagination-js-bootstrap {
      margin-right: 40px;
    }
  }
  .data-type-select-comparison {
    margin-left: auto;
    display: flex;
  }
  .compare-button {
    height: 40px;
    background-color: white;
    margin-right: 10px;
    color: #405467;
    border-radius: 10px;
    border: 2px solid;
    font-weight: bold;
    font-family: Montserrat !important;
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: white !important;
    border-color: black !important;
    color: black;
  }

  .show hr {
    margin-top: 5px;
  }
  .fa {
    // position: absolute !important;
    // top: 50% !important;
    font-size: 30px !important;
    cursor: pointer !important;
    box-shadow: 5px 10px 18px #888888;
    border-radius: 10%;
    font-size: 15px !important;
    padding: 15px;
  }
  .date-or-fiscal span {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    margin-top: 5px;
  }
  .date-or-fiscal hr {
    width: 93%;
  }
  .submit-butn {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;
  }
  .submit-butn button {
    border: 1px solid grey;
  }

  .select-fiscal-year {
    margin-left: 10px;
  }
  .date-or-fiscal {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .date-picker-main-div {
    display: flex;
  }
  .date-pick-main {
    margin-left: 10px;
    margin-right: 10px;
  }
  .form-control {
    background-color: white !important;
    border: none;
    cursor: pointer;
    width: 105px;
    border-radius: 5px;
  }
  .date-pick img {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .date-pick {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
  }
  .filter-data {
    height: auto;
    width: auto;
    display: flex;
    top: 10px;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
    margin-top: 40px;
    margin-left: 130px;
    background-color: white;
    box-shadow: 5px 10px 18px #ccc;
  }
  .secondary-sidebar {
    padding-top: 30px;
  }
  .secondary-sidebar h5 {
    margin-left: 15px;
  }
  .right-component {
    margin-left: 15px;
  }
  .right-component p {
    font-size: 15px;
  }
  .sidebar-1-design .right-image {
    border: 1px solid #d9dadb !important;
  }
  .right-image {
    margin-left: auto;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: flex;
    flex-direction: column;
  }

  .right-image img {
    margin-left: 5px;
    margin-right: 5px;
  }
  .right-container {
    display: flex;
    height: 60px;
    width: 60px;
  }
  .clicking-rights {
    display: flex;
  }

  .main-sidebar-content-hide {
    width: 80px;
  }
  .right-name-display-sidebar {
    display: none;
  }
  .sidebar-1-design {
    display: flex;
    flex-direction: column;
  }
  .sidebar-1-design .right-image {
    display: flex;
    width: 80px;
    justify-content: center;
  }

  .test-table {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }
  .fiscal-year-filter {
    display: flex;
    margin-left: auto;
  }
  .fiscal-year-filter p {
    margin-top: 16px;
  }
  .fiscal-year button {
    border: none !important;
  }
  .fiscal-year {
    margin-top: 0px !important;
  }

  .filter-add-btn {
    margin-left: 15px;
    display: flex;
    position: relative;

    padding-top: 5px;
  }
  .filter-add-btn button {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .btn-primary:hover {
    background-color: white;
    color: #405467;
    border: 2px solid;
  }
  .view-data button {
    border-radius: 10px;
    border: 2px solid;
    color: #405467;
    font-weight: bold;
  }
  .view-data .btn-primary.focus,
  .btn-primary:focus {
    box-shadow: 5px 10px 18px #dddd;
    background-color: white;
    color: #000000;
  }
  .view-data .btn-primary.active,
  .btn-primary:active {
    background-color: white !important;
  }
  .view-data img {
    margin-right: 10px;
    height: 18px;
  }
  .view-data button {
    width: -webkit-fill-available;
    margin: 0;
  }
  .view-data {
    background-color: white !important;
  }
  .dropdown {
    margin-right: 15px;

    color: black;
  }
  .dropdown button {
    background-color: white;
    color: black;
  }
  .filter-div {
    display: flex;
  }
  .filter-div h1 {
    margin-left: 20px;
    color: #405467;
    font-size: 30px;
    font-weight: bold;
  }
  .breadcrumb {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .bread-crumb {
    width: fit-content;
    margin-right: 10px;
  }
  .bread-crumb a {
    color: #333333;
    opacity: 0.61;
    text-decoration: none;
  }

  .account-logo {
    margin-right: 5px;
  }
  .selected-language {
    background-color: #06356a !important;
  }
  .nep {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .eng {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    padding-right: 20px;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .toggle-on-click {
    left: 230px;
  }
  .main-content {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .navbar-brand img {
    height: 51px;
  }

  #menu-toggle {
    position: absolute;
    top: 50vh;
  }
  #page-content-wrapper {
    height: 100vh;
    position: relative;
  }
  /*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

  #wrapper {
    overflow-x: hidden;
  }

  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }

  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }

  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  #sidebar-wrapper .list-group2 {
    width: 0px !important;
  }

  #page-content-wrapper {
    min-width: 100vw;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }

  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }

    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }

    // #wrapper.toggled #sidebar-wrapper {
    //   margin-left: -15rem;
    // }
  }
`;
const Wrapper = styled.div`
  .but {
    padding: 0px;
  }
  #earth-logo {
    height: 25px;
  }
  .overall-popup {
    overflow-y: scroll;
    height: 300px;
  }
  .popup-card {
    background-color: lightblue;
    color: black;
    margin-top: 5px;
    margin-bottom: 15px;
    height: 150px;
  }
  .mapboxgl-popup-content {
    height: 500px;
  }
  .btn {
    border: none;
  }
  .nav-control {
    width: 30px;
    margin-left: 10px;
    margin-top: 10px;
  }
  // .dot {
  // height: 25px;
  // width: 25px;
  // background-color: orange;
  // border-radius: 50%;
  // display: inline-block;
  // }

  .container-fluid {
    height: 100vh;
    width: 100vw;
  }
  .col-sm-8 {
    margin-top: 140px;
  }
  .container {
    margin-left: 300px;
  }

  .container h3 {
    color: #405467;
  }
  .card {
    margin-left: 15px;
    margin-right: 15px;
    width: 250px !important;
    height: 120px !important;
  }
  .card img {
    display: flex;
  }
  .card-text {
    display: flex;
    margin-top: 10px;
  }
`;
export default Component_Display;
