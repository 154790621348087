import React, { useState, useEffect, useContext } from "react";

import Navbar_new from "../Page.components/Navbar_new";
import styled from "styled-components";
import { Link } from "react-router-dom";

import httpBrowsing from "./../Utilities/httpBrowsing";

import { Loading_Context } from "./../Utilities/Store";

import {
  Tost_Context,
  Indicator_title_Context,
  Indicator_title_eng_Context,
  Right_component_nepali_Context,
  Right_component_english_Context,
  Right_name_nepali_Context,
  Right_name_english_Context,
  Filter_clicked_Context,
  Download_Date_from_context,
  Download_Date_to_context,
  Data_Type_Context,
  RightID_Context,
  Right_component_id_Context,
  Sidebar_Data_Context,
  Selected_RightComponents_indicator_Context,
  Selected_Right_RightComponents_Context,
  Right_indicator_id_Context,
  Right_name_Context,
} from "../Utilities/Store";
const Landing_Page_new = (props) => {
  const [Tost, setTost] = useContext(Tost_Context); //used to store boolean value during language switch
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [Test, setTest] = useState(false); //testing the function on click
  const [sidebar_Clicked, setSidebar_Clicked] = useState(true); //first level sidebar clicked
  const [Loading, setLoading] = useContext(Loading_Context);
  const [RightID, setRightID] = useContext(RightID_Context);
  const [Right_component_id, setRight_component_id] = useContext(
    Right_component_id_Context
  );
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title

  const [Filter_clicked, setFilter_clicked] = useContext(
    Filter_clicked_Context
  ); //is set true when filter button is clicked
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the data
  const [Fundamental_Rights, setFundamental_Rights] = useState();
  const [Data_Type, setData_Type] = useContext(Data_Type_Context);
  const [Sidebar_Data, setSidebar_Data] = useContext(Sidebar_Data_Context); //all fetched sidebar data is stored here
  const [
    Selected_Right_RightComponents,
    setSelected_Right_RightComponents,
  ] = useContext(Selected_Right_RightComponents_Context);
  const [
    Selected_RightComponents_indicator,
    setSelected_RightComponents_indicator,
  ] = useContext(Selected_RightComponents_indicator_Context);
  const [Right_name, setRight_name] = useContext(Right_name_Context);

  useEffect(() => {
    setData_Type("table");
    httpBrowsing
      .get(`/${Tost ? "eng" : "nep"}/rights/`)
      .then((res) => {
        setFundamental_Rights(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
    httpBrowsing
      .get(`/${Tost ? "eng" : "nep"}/navbar/`)
      .then((res) => {
        setSidebar_Data(res.data.data);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [Tost]);

  const handleClick = (id, name) => {
    setRightID(id);
    setRight_name(name);
    setRight_name_english(name);
    setRight_name_nepali(name);
    let Right_components = [];
    Right_components = Sidebar_Data.filter((item, i) => item.id === id).map(
      (item) => item.component
    );

    if (Right_components.length) {
      setRight_indicator_id(Right_components[0][0].indicator[0].id);
    }
    if (Right_components.length) {
      setRight_component_id(Right_components[0][0].id);
    }
  };

  return (
    <React.Fragment>
      <Tester>
        <div
          className={sidebar_Clicked ? "d-flex toggled" : "d-flex"}
          id="wrapper"
        >
          {/* Sidebar */}

          {/* Page Content */}
          <div id="page-content-wrapper">
            <Navbar_new data={props} />
            <div className="container-fluid main-content">
              <div className="main-body">
                {Sidebar_Data
                  ? Sidebar_Data.map((item, i) => {
                      return (
                        <Link
                          to={{
                            pathname: `/${item.title}`,
                            state: {
                              name: item.title,
                              description: item.description,
                            },
                          }}
                          key={i}
                          onClick={(e) => handleClick(item.id, item.title)}
                        >
                          <div className="card ">
                            <div className="card-body text-center">
                              <p className="card-text">{item.title}</p>
                              <img src={`${item.icon}`} alt="crime" />
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
          {/* /#page-content-wrapper */}
        </div>
      </Tester>
    </React.Fragment>
  );
};
const Tester = styled.div`
  .card {
    background-color: #405467;
  }
  .date-or-fiscal span {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    margin-top: 5px;
  }
  .date-or-fiscal hr {
    width: 93%;
  }
  .submit-butn {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: 5px;
  }
  .submit-butn button {
    border: 1px solid grey;
  }
  .show .dropdown-menu {
    width: 300px;
  }
  .select-fiscal-year {
    margin-left: 10px;
  }
  .date-or-fiscal {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .date-picker-main-div {
    display: flex;
  }
  .date-pick-main {
    margin-left: 10px;
    margin-right: 10px;
  }
  .form-control {
    background-color: white !important;
    border: none;
    cursor: pointer;
    width: 105px;
    border-radius: 5px;
  }
  .date-pick img {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
  .date-pick {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
  }
  .filter-data {
    height: auto;
    width: auto;
    display: flex;
    top: 10px;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
    margin-top: 40px;
    margin-left: 130px;
    background-color: white;
    box-shadow: 5px 10px 18px #ccc;
  }
  .secondary-sidebar {
    width: 200px;
    padding-top: 30px;
  }
  .secondary-sidebar h5 {
    margin-left: 15px;
  }
  .right-component {
    margin-left: 15px;
  }
  .right-component p {
    font-size: 15px;
  }
  .sidebar-1-design .right-image {
    border: 1px solid #d9dadb !important;
  }
  .right-image {
    margin-left: auto;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: flex;
    flex-direction: column;
  }

  .right-image img {
    margin-left: 5px;
    margin-right: 5px;
  }
  .right-container {
    display: flex;
  }
  .clicking-rights {
    display: flex;
  }
  .main-sidebar-content {
    width: 80px;
  }
  .main-sidebar-content-hide {
    width: 80px;
  }
  .right-name-display-sidebar {
    display: none;
  }
  .sidebar-1-design {
    display: flex;
    flex-direction: column;

    height: 50px;
  }
  .sidebar-1-design .right-image {
    display: flex;
    width: 80px;
    justify-content: center;
  }

  .test-table {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }
  .fiscal-year-filter {
    display: flex;
    margin-left: auto;
  }
  .fiscal-year-filter p {
    margin-top: 16px;
  }
  .fiscal-year button {
    border: none !important;
  }
  .fiscal-year {
    margin-top: 0px !important;
  }
  .main-body {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 7%;
    width: 80%;
    margin: 0 auto;
  }
  .main-body a {
    width: 30%;
    height: 20%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
  }
  .main-body a :hover {
    background-color: #e35163 !important;
  }
  .card img {
    height: 40px;
  }
  .card {
    height: 120px !important;
  }
  .card-body {
    display: flex;
  }
  .card-body img {
    margin-left: auto;
    margin-top: 10px;
  }
  .card-body p {
    text-decoration: none;
    color: white;
  }

  .card-text {
    font-weight: 700;
    margin-top: 20px;
    text-decoration: none;
  }
  .filter-add-btn {
    margin-left: 15px;
    display: flex;
    position: relative;
  }
  .filter-add-btn button {
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .view-data {
    margin-left: auto;
  }
  .view-data button {
    width: -webkit-fill-available;
    margin: 0;
  }
  .dropdown {
    margin-right: 10px;

    color: black;
  }
  .dropdown button {
    background-color: white;
    color: black;
    border: 1px solid;
  }
  .filter-div {
    display: flex;
  }
  .filter-div h1 {
    margin-left: 20px;
  }
  .breadcrumb {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-left: 20px;
    margin-top: 30px;
  }
  .bread-crumb {
    width: fit-content;
    margin-right: 10px;
  }

  .account-logo {
    margin-right: 5px;
  }
  .selected-language {
    background-color: #06356a !important;
  }
  .nep {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .eng {
    background-color: grey;
    border: none;
    color: white;
    outline: none;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    padding-right: 20px;

    cursor: pointer;
    width: 22px;
    height: 20px;
  }
  .toggle-on-click {
    left: 230px;
  }
  .main-content {
    display: flex;
    margin: 0;
    padding: 0;
    margin-top: 80px;
  }
  .navbar-brand img {
    height: 51px;
  }
  // .Sidebar-menu {
  //   border: none;
  //   background-color: #f8f9fa;
  //   color: black;
  // }
  // button.sidebar-menu :hover {
  //   background-color: transparent !important;
  //   text-decoration: none;
  // }
  #menu-toggle {
    position: absolute;
    top: 50vh;
  }
  #page-content-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  /*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

  #wrapper {
    overflow-x: hidden;
  }

  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }

  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }

  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  #sidebar-wrapper .list-group2 {
    width: 0px !important;
  }

  #page-content-wrapper {
    min-width: 100vw;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }

  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }

    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }

    // #wrapper.toggled #sidebar-wrapper {
    //   margin-left: -15rem;
    // }
  }
`;
const Wrapper = styled.div`
  .but {
    padding: 0px;
  }
  #earth-logo {
    height: 25px;
  }
  .overall-popup {
    overflow-y: scroll;
    height: 300px;
  }
  .popup-card {
    background-color: lightblue;
    color: black;
    margin-top: 5px;
    margin-bottom: 15px;
    height: 150px;
  }
  .mapboxgl-popup-content {
    height: 500px;
  }
  .btn {
    border: none;
  }
  .nav-control {
    width: 30px;
    margin-left: 10px;
    margin-top: 10px;
  }
  // .dot {
  // height: 25px;
  // width: 25px;
  // background-color: orange;
  // border-radius: 50%;
  // display: inline-block;
  // }

  .container-fluid {
    height: 100vh;
    width: 100vw;
  }
  .col-sm-8 {
    margin-top: 140px;
  }
  .container {
    margin-left: 300px;
  }

  .container h3 {
    color: #405467;
  }
  .card {
    margin-left: 15px;
    margin-right: 15px;
    width: 250px !important;
    height: 120px !important;
  }
  .card img {
    display: flex;
  }
  .card-text {
    display: flex;
    margin-top: 10px;
  }
`;
export default Landing_Page_new;
