import React, { useContext, useEffect, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import httpBrowsing_frontend from "../../Utilities/httpBrowsing_frontend";
import { Dropdown, DropdownButton } from "react-bootstrap";
import notification from "../../Utilities/Notification";
import { englishToNepaliNumber, nepaliToEnglishNumber } from "nepali-number";
import { saveAs } from "file-saver";

import {
  Tost_Context,
  Right_component_english_Context,
  Right_component_nepali_Context,
  Right_name_nepali_Context,
  Right_name_english_Context,
  Indicator_title_Context,
  Indicator_title_eng_Context,
  Right_indicator_id_Context,
  Selected_fiscal_year_Context,
  Download_Date_from_context,
  Download_Date_to_context,
  Filter_Data_context,
  percentage_total_context,
} from "../../Utilities/Store";
import styled from "styled-components";

// // Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "row",
//     backgroundColor: "#E4E4E4",
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
// });

const Percentage = (props) => {
  const ref = React.createRef();
  const [percentage_total, setPercentage_total] = useContext(
    percentage_total_context
  );
  const [Tost, setTost] = useContext(Tost_Context);
  const [Right_component_nepali, setRight_component_nepali] = useContext(
    Right_component_nepali_Context
  );
  const [Indicator_title, setIndicator_title] = useContext(
    Indicator_title_Context
  ); //used to store the fetched data title
  const [Indicator_title_eng, setIndicator_title_eng] = useContext(
    Indicator_title_eng_Context
  ); //used to store the fetched english data title
  const [Right_name_english, setRight_name_english] = useContext(
    Right_name_english_Context
  );
  const [Right_name_nepali, setRight_name_nepali] = useContext(
    Right_name_nepali_Context
  );
  const [Right_component_english, setRight_component_english] = useContext(
    Right_component_english_Context
  );
  const [Download_Date_from, setDownload_Date_from] = useContext(
    Download_Date_from_context
  ); //used to store date from when to download data
  const [Download_Date_to, setDownload_Date_to] = useContext(
    Download_Date_to_context
  ); //used to store date till when to download the da
  const [Right_indicator_id, setRight_indicator_id] = useContext(
    Right_indicator_id_Context
  );
  const [Click_download, setClick_download] = useState(false);
  const [Filter_Data, setFilter_Data] = useContext(Filter_Data_context); //when 1st filter of selector buttom is clicked and data is entered the details is stored here.
  const [Selected_fiscal_year, setSelected_fiscal_year] = useContext(
    Selected_fiscal_year_Context
  );
  const [mean_age, setMean_age] = useState(null);
  const [mean_child_age, setMean_child_age] = useState(null);
  const [mean_pass, setMean_pass] = useState(null);
  const [Gender_population, setGender_population] = useState(null);
  let Total_sum_gender = null;

  if (percentage_total.percent_male) {
    Total_sum_gender =
      percentage_total.percent_male.total +
      percentage_total.percent_female.total +
      percentage_total.percent_other.total;
  }

  useEffect(() => {
    httpBrowsing_frontend
      .get(
        `/frontend/data/eng/percentage/?indicator=${Right_indicator_id}&province=${Filter_Data.province}&district=${Filter_Data.district}&municipality=${Filter_Data.municipality}&fiscal_year=${Selected_fiscal_year}&start=${Download_Date_from}&end=${Download_Date_to}`
      )
      .then((res) => {
        setPercentage_total(res.data);
        // setChart_x_axis(Object.keys(res.data));
      })
      .catch((err) => {
        console.log("This isi % error>>>", err);
      });
  }, [
    Right_indicator_id,
    Filter_Data.province,
    Filter_Data.district,
    Filter_Data.municipality,
  ]);
  const handleSubmit_to_download = (e) => {
    e.preventDefault();

    httpBrowsing_frontend
      .get(
        `/frontend/data/download/${
          Tost ? "eng" : "nep"
        }/?indicator=${Right_indicator_id}&fiscal_year=${Selected_fiscal_year}&province=${
          Filter_Data.province
        }&district=${Filter_Data.district}&municipality=${
          Filter_Data.municipality
        }&start=${Download_Date_from}&end=${Download_Date_to}`
      )
      .then((res) => {
        setClick_download(false);
        window.open(res.data, "_self");

        notification.showSuccess("Downloading CSV!!!");
      })
      .catch((err) => {
        console.log("ths is error>>", err.response);
      });
  };
  const handleDownload_png = (capture) => {
    const canvasSave = document.getElementById(capture);
    canvasSave.toBlob(function (blob) {
      saveAs(
        blob,
        `${Tost ? Right_name_nepali : Right_name_english}_${
          Tost ? Right_component_english : Right_component_nepali
        }_${Tost ? Indicator_title_eng : Indicator_title}.png`
      );
      setClick_download(false);
      notification.showSuccess("Downloading PNG!!!");
    });
  };
  const Line_Chart = {
    labels: [Tost ? "Total Data" : "संख्या"],
    datasets: [
      {
        label: [
          Filter_Data.province_name &&
          Filter_Data.district_name &&
          Filter_Data.municipality_name
            ? Tost
              ? `${Filter_Data.municipality_name}  `
              : `${Filter_Data.municipality_name_nep}  `
            : Filter_Data.province_name && Filter_Data.district_name
            ? Tost
              ? `${Filter_Data.district_name}  `
              : `${Filter_Data.district_name_nep}  `
            : Filter_Data.province_name
            ? Tost
              ? `${Filter_Data.province_name}  `
              : `${Filter_Data.province_name_nep} `
            : Tost
            ? "Total Data "
            : "कुल संख्या ",
        ],
        maxBarThickness: 50,
        position: "bottom",
        fill: false,
        backgroundColor: "#9e9e9e",
        borderColor: "rgba(75,192,192,1)",
        data: [percentage_total.total],
      },
    ],
  };
  const Total_Data_percentage_gender = {
    labels: Tost ? ["Male", "Female", "Other"] : ["पुरुष", "महिला", "अन्य"],
    datasets: [
      {
        label: [
          Filter_Data.province_name &&
          Filter_Data.district_name &&
          Filter_Data.municipality_name
            ? Tost
              ? `${Filter_Data.municipality_name}  `
              : `${Filter_Data.municipality_name_nep}  `
            : Filter_Data.province_name && Filter_Data.district_name
            ? Tost
              ? `${Filter_Data.district_name}  `
              : `${Filter_Data.district_name_nep}  `
            : Filter_Data.province_name
            ? Tost
              ? `${Filter_Data.province_name}  `
              : `${Filter_Data.province_name_nep} `
            : Tost
            ? "Total Data "
            : "कुल संख्या ",
        ],

        position: "bottom",
        fill: false,
        backgroundColor: "#9e9e9e",
        categoryPercentage: 0.5,
        barPercentage: 1.0,

        borderColor: "rgba(75,192,192,1)",

        data: percentage_total.percent_male
          ? [
              percentage_total.percent_male.percent,
              percentage_total.percent_female.percent,
              percentage_total.percent_other.percent,
            ]
          : [],
      },
    ],
  };

  const Total_Data_percentage_age_group = {
    labels: Tost
      ? ["Below 20 Years", "20 to 40 Years ", "40 Years and Above"]
      : ["२० बर्ष मुनी", "२० देखी ४० बर्ष ", "४० बर्ष भन्दा माथि"],
    datasets: [
      {
        label: [
          Filter_Data.province_name &&
          Filter_Data.district_name &&
          Filter_Data.municipality_name
            ? Tost
              ? `${Filter_Data.municipality_name}  `
              : `${Filter_Data.municipality_name_nep}  `
            : Filter_Data.province_name && Filter_Data.district_name
            ? Tost
              ? `${Filter_Data.district_name}  `
              : `${Filter_Data.district_name_nep}  `
            : Filter_Data.province_name
            ? Tost
              ? `${Filter_Data.province_name}  `
              : `${Filter_Data.province_name_nep} `
            : Tost
            ? "Total Data "
            : "कुल संख्या ",
        ],

        position: "bottom",
        fill: false,
        backgroundColor: "#9e9e9e",
        borderColor: "rgba(75,192,192,1)",
        data: percentage_total.percent__0_to_20
          ? [
              percentage_total.percent__0_to_20.percent,
              percentage_total.percent__21_to_40.percent,
              percentage_total.percent__41_or_older.percent,
            ]
          : [],
      },
    ],
  };
  const Total_Data_percentage_child_age_group = {
    labels: Tost
      ? ["Below 16 Years", "Below 18 Years"]
      : ["१६ बर्ष भन्दा कम", "१८ बर्ष भन्दा कम"],
    datasets: [
      {
        label: [
          Filter_Data.province_name &&
          Filter_Data.district_name &&
          Filter_Data.municipality_name
            ? Tost
              ? `${Filter_Data.municipality_name}  `
              : `${Filter_Data.municipality_name_nep}  `
            : Filter_Data.province_name && Filter_Data.district_name
            ? Tost
              ? `${Filter_Data.district_name}  `
              : `${Filter_Data.district_name_nep}  `
            : Filter_Data.province_name
            ? Tost
              ? `${Filter_Data.province_name}  `
              : `${Filter_Data.province_name_nep} `
            : Tost
            ? "Total Data "
            : "कुल संख्या ",
        ],

        position: "bottom",
        fill: false,
        backgroundColor: "#9e9e9e",
        borderColor: "rgba(75,192,192,1)",
        data: percentage_total.percent__0_to_16
          ? [
              percentage_total.percent__0_to_16.percent,
              percentage_total.percent__17_to_18.percent,
            ]
          : [],
      },
    ],
  };
  const Total_Data_percentage_pass_fail = {
    labels: Tost ? ["Passed", "Failed"] : ["उत्तीर्ण ", "अनुत्तीर्ण"],
    datasets: [
      {
        label: [
          Filter_Data.province_name &&
          Filter_Data.district_name &&
          Filter_Data.municipality_name
            ? Tost
              ? `${Filter_Data.municipality_name}  `
              : `${Filter_Data.municipality_name_nep}  `
            : Filter_Data.province_name && Filter_Data.district_name
            ? Tost
              ? `${Filter_Data.district_name}  `
              : `${Filter_Data.district_name_nep}  `
            : Filter_Data.province_name
            ? Tost
              ? `${Filter_Data.province_name}  `
              : `${Filter_Data.province_name_nep} `
            : Tost
            ? "Total Data "
            : "कुल संख्या ",
        ],

        position: "bottom",
        fill: false,
        backgroundColor: "#9e9e9e",
        categoryPercentage: 0.5,
        barPercentage: 1.0,
        borderColor: "rgba(75,192,192,1)",
        data: percentage_total.percent_passed
          ? [
              percentage_total.percent_passed.percent,
              percentage_total.percent_failed.percent,
            ]
          : [],
      },
    ],
  };

  const Total_percentage = {
    labels: [Tost ? "Total Data" : "संख्या"],
    datasets: [
      {
        label: [
          Filter_Data.province_name &&
          Filter_Data.district_name &&
          Filter_Data.municipality_name
            ? Tost
              ? `${Filter_Data.municipality_name}  `
              : `${Filter_Data.municipality_name_nep}  `
            : Filter_Data.province_name && Filter_Data.district_name
            ? Tost
              ? `${Filter_Data.district_name}  `
              : `${Filter_Data.district_name_nep}  `
            : Filter_Data.province_name
            ? Tost
              ? `${Filter_Data.province_name}  `
              : `${Filter_Data.province_name_nep} `
            : Tost
            ? "Total Data "
            : "कुल संख्या ",
        ],
        maxBarThickness: 50,
        position: "bottom",
        fill: false,
        backgroundColor: "#9e9e9e",
        borderColor: "rgba(75,192,192,1)",
        data: percentage_total.total_percent
          ? [percentage_total.total_percent.percent]
          : [],
      },
    ],
  };

  return (
    <React.Fragment>
      <Wrapper>
        <div className="main-percentage-div">
          {percentage_total.total !== null &&
          !percentage_total.total_percent &&
          !percentage_total.percent_male &&
          !percentage_total.percent__0_to_20 &&
          !percentage_total.percent__0_to_16 &&
          !percentage_total.percent_passed ? (
            <div className="row" id="hell">
              <div className="col-sm-2 explore-title">
                <h4>{Tost ? "Total" : "कुल संख्या"}</h4>
                <h2>
                  {Tost
                    ? percentage_total.total
                    : englishToNepaliNumber(percentage_total.total)}
                </h2>
              </div>
              <div className="col-sm-10 explore-title-bar">
                <div className="percent-header">
                  <h4>{Tost ? "Total" : "कुल संख्या"}</h4>
                  {Tost ? (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        DOWNLOAD
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture")}
                        >
                          Download PNG
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        डाउनलोड
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture")}
                        >
                          डाउनलोड PNG
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <HorizontalBar
                  id="capture"
                  data={Line_Chart}
                  options={{
                    responsive: true,

                    title: {
                      display: true,
                      text: [
                        Tost
                          ? `${Right_name_english}/${Right_component_english}/${Indicator_title_eng}`
                          : `${Right_name_nepali}/${Right_component_nepali}/${Indicator_title}`,
                        Filter_Data.province_name
                          ? Tost
                            ? "Total Count"
                            : "कुल संख्या"
                          : "",
                      ],
                      position: "top",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            fontColor: "black",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "black",
                          },
                        },
                      ],
                    },
                  }}
                  width={800}
                  height={350}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {percentage_total.total_percent &&
          !percentage_total.percent_male &&
          !percentage_total.percent__0_to_20 &&
          !percentage_total.percent__0_to_16 &&
          !percentage_total.percent_passed ? (
            <div className="row" id="hell">
              <div className="col-sm-2 explore-title">
                <h4>{Tost ? "Total" : "कुल संख्या"}</h4>
                <h2>
                  {Tost
                    ? percentage_total.total_percent.total
                    : englishToNepaliNumber(
                        percentage_total.total_percent.total
                      )}
                </h2>
              </div>
              <div className="col-sm-10 explore-title-bar">
                <div className="percent-header">
                  <h4>{Tost ? "Total Count(%)" : "कुल संख्या(%)"}</h4>
                  {Tost ? (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture1")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        DOWNLOAD
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture1")}
                        >
                          Download PNG
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture1")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        डाउनलोड
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture1")}
                        >
                          डाउनलोड PNG
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <HorizontalBar
                  id="capture1"
                  data={Total_percentage}
                  options={{
                    responsive: true,

                    title: {
                      display: true,
                      text: [
                        Tost
                          ? `${Right_name_english}/${Right_component_english}/${Indicator_title_eng}`
                          : `${Right_name_nepali}/${Right_component_nepali}/${Indicator_title}`,
                        Tost ? "Total Count(%)" : "कुल संख्या(%)",
                      ],
                      position: "top",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            fontColor: "black",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "black",
                          },
                        },
                      ],
                    },
                  }}
                  width={800}
                  height={350}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {percentage_total.percent_male ? (
            <div className="row" id="hell">
              <div className="col-sm-2 explore-title">
                <h4>{Tost ? "Population" : "जनसंख्या"}</h4>
                <h2>
                  {Filter_Data.province_name
                    ? Tost
                      ? Total_sum_gender
                      : englishToNepaliNumber(Total_sum_gender)
                    : Tost
                    ? percentage_total.total
                    : englishToNepaliNumber(percentage_total.total)}
                </h2>
              </div>
              <div className="col-sm-10 explore-title-bar">
                <div className="percent-header">
                  <h4>
                    {Tost ? "Gender Breakdown(%)" : "लैङ्गिक वर्गीकरण(%)"}
                  </h4>

                  {Tost ? (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture2")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        DOWNLOAD
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture2")}
                        >
                          Download PNG
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture2")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        डाउनलोड
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture2")}
                        >
                          डाउनलोड PNG
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <HorizontalBar
                  id="capture2"
                  data={Total_Data_percentage_gender}
                  options={{
                    responsive: true,

                    title: {
                      display: true,
                      text: [
                        Tost
                          ? `${Right_name_english}/${Right_component_english}/${Indicator_title_eng}`
                          : `${Right_name_nepali}/${Right_component_nepali}/${Indicator_title}`,
                        Tost ? "Gender Breakdown(%)" : "लैङ्गिक वर्गीकरण(%)",
                      ],
                      position: "top",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            fontColor: "black",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "black",
                          },
                        },
                      ],
                    },
                  }}
                  width={800}
                  height={350}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {percentage_total.percent__0_to_20 ? (
            <div className="row" id="hell">
              <div className="col-sm-2 explore-title"></div>
              <div className="col-sm-10 explore-title-bar">
                <div className="percent-header">
                  <h4>
                    {Tost
                      ? "Population By Age Group(%)"
                      : "उमेर समूह अनुसार जनसंख्या(%)"}
                  </h4>
                  {Tost ? (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture3")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        DOWNLOAD
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture3")}
                        >
                          Download PNG
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture3")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        डाउनलोड
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture3")}
                        >
                          डाउनलोड PNG
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <HorizontalBar
                  id="capture3"
                  data={Total_Data_percentage_age_group}
                  options={{
                    responsive: true,

                    title: {
                      display: true,
                      text: [
                        Tost
                          ? `${Right_name_english}/${Right_component_english}/${Indicator_title_eng}`
                          : `${Right_name_nepali}/${Right_component_nepali}/${Indicator_title}`,
                        Tost
                          ? "Population By Age Group(%)"
                          : "उमेर समूह अनुसार जनसंख्या(%)",
                      ],
                      position: "top",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            fontColor: "black",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "black",
                          },
                        },
                      ],
                    },
                  }}
                  width={800}
                  height={350}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {percentage_total.percent__0_to_16 ? (
            <div className="row" id="hell">
              <div className="col-sm-2 explore-title"></div>
              <div className="col-sm-10 explore-title-bar">
                <div className="percent-header">
                  <h4>
                    {Tost
                      ? "Child Population By Age Group(%)"
                      : "बालबालिका उमेर समूह अनुसार जनसंख्या(%)"}
                  </h4>
                  {Tost ? (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture4")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        DOWNLOAD
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture4")}
                        >
                          Download PNG
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture4")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        डाउनलोड
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture4")}
                        >
                          डाउनलोड PNG
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <HorizontalBar
                  id="capture4"
                  data={Total_Data_percentage_child_age_group}
                  options={{
                    responsive: true,

                    title: {
                      display: true,
                      text: [
                        Tost
                          ? `${Right_name_english}/${Right_component_english}/${Indicator_title_eng}`
                          : `${Right_name_nepali}/${Right_component_nepali}/${Indicator_title}`,
                        Tost
                          ? "Child Population By Age Group(%)"
                          : "बालबालिका उमेर समूह अनुसार जनसंख्या(%)",
                      ],
                      position: "top",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            fontColor: "black",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "black",
                          },
                        },
                      ],
                    },
                  }}
                  width={800}
                  height={350}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {percentage_total.percent_passed ? (
            <div className="row" id="hell">
              <div className="col-sm-2 explore-title"></div>
              <div className="col-sm-10 explore-title-bar">
                <div className="percent-header">
                  <h4>{Tost ? "Pass/Fail(%)" : "उत्तीर्ण/अनुत्तीर्ण(%)"}</h4>
                  {Tost ? (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture5")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        DOWNLOAD
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture5")}
                        >
                          Download PNG
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="dropdown" style={{ marginLeft: "auto" }}>
                      <img
                        src="/dnload.svg"
                        alt="dl"
                        onClick={() => handleDownload_png("capture5")}
                        className="big-screen"
                      />
                      <button
                        className="btn dropdown-toggle small-screen"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        डाउनलोड
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => handleDownload_png("capture5")}
                        >
                          डाउनलोड PNG
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <HorizontalBar
                  id="capture5"
                  data={Total_Data_percentage_pass_fail}
                  options={{
                    responsive: true,

                    title: {
                      display: true,
                      text: [
                        Tost
                          ? `${Right_name_english}/${Right_component_english}/${Indicator_title_eng}`
                          : `${Right_name_nepali}/${Right_component_nepali}/${Indicator_title}`,
                        Tost ? "Pass/Fail(%)" : "उत्तीर्ण/अनुत्तीर्ण(%)",
                      ],
                      position: "top",
                    },
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            fontColor: "black",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "black",
                          },
                        },
                      ],
                    },
                  }}
                  width={800}
                  height={350}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Wrapper>
    </React.Fragment>
  );
};
const Wrapper = styled.div`
  .row {
    margin: 0;
    padding: 0;
  }
  .small-screen {
    display: inline;
  }
  .big-screen {
    display: none;
  }
  .explore-title-bar {
    min-height: 240px;
    min-width: 480px;
  }
  .explore-title {
    padding-left: 20px;
  }
  @media only screen and (max-width: 570px) {
    .main-percentage-div {
      overflow-x: auto;
    }

    .dropdown {
      margin-right: 10px !important;
    }
    .dropdown img {
      height: 25px !important;
      margin-top: -10px !important;
    }
    .big-screen {
      display: inline;
    }
    .small-screen {
      display: none;
    }
    .percent-header {
      margin-top: 10px !important;
    }
    .percent-header h4 {
      margin-left: 5px !important;
      font-size: 16px !important;
    }
    .explore-title h4 {
      font-size: 16px !important;
      margin-left: 5px !important;
    }
    .chartjs-render-monitor {
      margin-top: 20px !important;
    }
  }

  .explore-title h2 {
    font-size: 18px;
    margin-left: 5px;
  }
  .percent-header h4 {
    margin-left: 40px;
  }
  .percent-header {
    display: flex !important;
  }
  .download-dropdown {
    margin-left: auto !important;
  }
  .download-dropdown button {
    color: #405467 !important;
  }
  .dropdown {
    magin-left: auto;
    margin-right: 60px;
  }
  .dropdown button {
    border: 2px solid #405467;
    border-radius: 15px;
    color: #405467 !important;
    font-weight: 700;
  }
`;
export default Percentage;
